/***********************
* Banner Three
***********************/
.banner-three {
  position  : relative;
  display   : block;
  background: #f5f4ff;
  padding   : 290px 0 405px;
  z-index   : 1;

  .banner-three__shape-1 {
    position: absolute;
    bottom  : 35px;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .banner-three__shape-2 {
    position: absolute;
    bottom  : 35px;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .banner-three__shape-3 {
    position: absolute;
    top     : 118px;
    left    : 0;

    img {
      width: auto;
    }
  }

  .banner-three__shape-4 {
    position: absolute;
    top     : 225px;
    left    : 110px;

    img {
      width: auto;
    }
  }

  .banner-three__shape-5 {
    position: absolute;
    top     : 490px;
    left    : 480px;

    img {
      width: auto;
    }
  }

  .banner-three__shape-6 {
    position: absolute;
    top     : 0;
    right   : 215px;

    img {
      width: auto;
    }
  }

  .banner-three__shape-7 {
    position: absolute;
    top     : 202px;
    right   : 132px;

    img {
      width: auto;
    }
  }

  .banner-three__shape-8 {
    position: absolute;
    top     : 377px;
    right   : 424px;

    img {
      width: auto;
    }
  }

  .banner-three__inner {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .banner-three__sub-title-box {
    position: relative;
    display : inline-block;
  }

  .banner-three__star-1 {
    position: absolute;
    top     : -13px;
    left    : -36px;

    img {
      width: auto;
    }
  }

  .banner-three__star-2 {
    position: absolute;
    top     : 10px;
    right   : -41px;

    img {
      width: auto;
    }
  }

  .banner-three__sub-title {
    position     : relative;
    display      : inline-block;
    font-size    : 13px;
    font-weight  : 600;
    color        : var(--customVar-black);
    line-height  : 24px;
    margin       : 0;
    border       : 1px solid #edebff;
    border-radius: 20px;
    padding-left : 115px;
    padding-right: 15px;
  }

  .banner-three__flase-sale {
    font-size    : 12px;
    color        : var(--customVar-white);
    font-weight  : 600;
    line-height  : 12px;
    background   : #010d65;
    position     : absolute;
    top          : 4px;
    left         : 0;
    padding      : 7px 20px;
    margin       : 0;
    border-radius: 20px;
  }

  .banner-three__title {
    font-size    : 60px;
    line-height  : 70px;
    color        : var(--customVar-black);
    font-weight  : 700;
    margin       : 0;
    margin-top   : 20px;
    margin-bottom: 15px;
  }

  .banner-three__text {
    font-size  : 18px;
    line-height: 32px;
    margin     : 0;
  }

  .banner-three__btn-box {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-wrap      : wrap;
    margin-top     : 30px;
  }

  .banner-three__btn-1 {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .banner-three__btn-2 {
    background : transparent;
    border     : 2px solid #010c65;
    padding    : 12px 30px;
    margin-left: 20px;
  }
}

/***********************
* Why Choose Two
***********************/
.why-choose-two {
  position  : relative;
  display   : block;
  padding   : 128px 0 110px;
  margin-top: -260px;
  z-index   : 2;

  .why-choose-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: top center;
    z-index            : -1;
  }

  .why-choose-two__top-title {
    position     : relative;
    display      : block;
    text-align   : center;
    font-weight  : 700;
    font-size    : 38px;
    line-height  : 48px;
    text-align   : center;
    color        : #ffffff;
    margin-bottom: 40px;
  }

  .why-choose-two__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 15px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    text-align   : center;
    padding      : 48px 40px 25px;
    margin-bottom: 30px;
  }

  .why-choose-two__icon {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .why-choose-two__title {
    font-weight  : 600;
    font-size    : 22px;
    margin       : 0;
    line-height  : 32px;
    margin-top   : 15px;
    margin-bottom: 12px;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-secondary);
      }
    }
  }

  .why-choose-two__text {
    margin       : 0;
    line-height  : 26px;
    margin-bottom: 25px;
  }

  .why-choose-two__btn {
    font-size         : 18px;
    font-weight       : 600;
    letter-spacing    : 0.01em;
    color             : var(--customVar-secondary);
    display           : flex;
    align-items       : center;
    justify-content   : center;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    span {
      position   : relative;
      display    : inline-block;
      font-size  : 13px;
      margin-left: 10px;
    }

    &:hover {
      color: var(--customVar-black);
    }
  }
}

/***********************
* Right Hosting
***********************/
.right-hosting {
  position: relative;
  display : block;
  padding : 120px 0 45px;
  overflow: hidden;
  z-index : 1;

  .right-hosting__shape-1 {
    position: absolute;
    top     : -193px;
    left    : -10px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .right-hosting__left {
    position    : relative;
    display     : block;
    margin-right: 27px;
  }

  .right-hosting__img {
    position: relative;
    display : block;
    z-index : 2;

    img {
      width: 100%;
    }
  }

  .right-hosting__right {
    position   : relative;
    display    : block;
    margin-left: 45px;
  }

  .right-hosting__title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 40px;
  }

  .right-hosting__main-content-box {
    position: relative;
    display : block;
  }

  .right-hosting__inner {
    position: relative;
    display : block;

    .tab-buttons {
      position   : relative;
      display    : flex;
      align-items: center;
      margin     : 0;

      &:before {
        content         : "";
        position        : absolute;
        bottom          : -23px;
        left            : 0;
        right           : 0;
        height          : 1px;
        background-color: #e6e6e6;
        z-index         : -1;
      }

      .tab-btn {
        position   : relative;
        display    : flex;
        align-items: center;
        cursor     : pointer;
        transition : all 0.3s ease;
        z-index    : 1;

        &:before {
          content   : "";
          position  : absolute;
          left      : 0;
          right     : 0;
          bottom    : -22px;
          width     : 0%;
          height    : 3px;
          background: var(--customVar-secondary);
          transition: all 0.3s ease;
          z-index   : -1;
        }

        &.active-btn {
          &:before {
            width: 100%;
          }

          .icon span {
            color: var(--customVar-secondary);
          }

          .content h3 {
            color: var(--customVar-secondary);
          }
        }

        +.tab-btn {
          margin-left: 55px;
        }

        .icon {
          position   : relative;
          display    : flex;
          align-items: center;

          span {
            position  : relative;
            display   : inline-block;
            font-size : 24px;
            color     : var(--customVar-text);
            transition: all 0.3s ease;
          }
        }

        .content {
          position   : relative;
          display    : block;
          margin-left: 10px;

          h3 {
            font-size  : 18px;
            font-weight: 600;
            color      : var(--customVar-text);
            margin     : 0;
            transition : all 0.3s ease;
          }
        }
      }
    }

    .tabs-content {
      position: relative;
      display : block;

      .tab {
        position          : absolute;
        visibility        : hidden;
        opacity           : 0;
        @include transformMixin(translateY(35px));
        @include transitionMixin(transform 600ms ease);
        z-index           : 10;

        &.active-tab {
          visibility       : visible;
          opacity          : 1;
          @include transformMixin(translateY(0px));
        }
      }
    }
  }

  .right-hosting__tab-content-box {
    position  : relative;
    display   : block;
    margin-top: 52px;
  }

  .right-hosting__text {
    margin       : 0;
    margin-bottom: 14px;
  }

  .right-hosting__points-box {
    position     : relative;
    display      : flex;
    align-items  : center;
    flex-wrap    : wrap;
    margin-bottom: 30px;
  }

  .right-hosting__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }

  .right-hosting__points--two {
    margin-left: 55px;
  }

  .right-hosting__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/***********************
* Comparision Two
***********************/
.comparision-two {
  position: relative;
  display : block;
  padding : 0 0 120px;

  .comparision-two__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .comparision-two__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .comparision-two__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .comparision-two__table-responsive {
    position  : relative;
    display   : block;
    width     : 100%;
    overflow-x: auto;
  }

  .comparision-two__table {
    position : relative;
    margin   : 0;
    min-width: 1320px;
    width    : 100%;
    z-index  : 1;

    &:before {
      content   : "";
      position  : absolute;
      top       : 0;
      left      : 270px;
      bottom    : 0;
      width     : 180px;
      background: linear-gradient(181.57deg, #000c5c 1.65%, #0020f7 100%);
      z-index   : 1;
    }

    thead {
      tr {
        position  : relative;
        text-align: center;
      }

      th {
        padding       : 0;
        border        : none;
        padding-bottom: 20px;
        position      : relative;
        z-index       : 5;

        &:first-child {
          text-align  : left;
          padding-left: 30px;
        }

        .starting-from {
          font-size     : 16px;
          font-weight   : 600;
          letter-spacing: -0.015em;
          color         : #5369ff;
          margin        : 0;
        }

        .logo-box {
          position     : relative;
          display      : block;
          margin-bottom: 27px;

          img {
            width: auto;
          }
        }

        .price-box {
          position: relative;
          display : block;

          p {
            font-size  : 40px;
            color      : var(--customVar-black);
            font-weight: 800;
            line-height: 40px;
            margin     : 0;
            font-family: var(--customVar-title-font);

            .point {
              font-size: 20px;
              position : relative;
            }

            .validity {
              font-size  : 20px;
              font-weight: 500;
              position   : relative;
              left       : -9px;
            }
          }

          &.clr-white p {
            color: var(--customVar-white);
          }
        }
      }
    }

    tbody {
      position        : relative;
      background-color: #eef0ff;
    }

    tbody tr {
      position  : relative;
      text-align: center;

      &:nth-child(even) {
        background-color: #ffffff;
      }

      &:last-child {
        border-bottom: 0;
      }

      td {
        font-size     : 16px;
        font-weight   : 400;
        border        : 0;
        border-right  : 1px solid rgba(222, 225, 247, 1);
        padding       : 27.5px 0;
        vertical-align: middle;
        position      : relative;
        z-index       : 5;

        .first-child-name {
          letter-spacing: -0.015em;
          color         : #666666;
          margin        : 0;
          font-size     : 16px;
          line-height   : 16px;
        }

        .check-icon-box {
          position       : relative;
          display        : flex;
          align-items    : center;
          justify-content: center;
          height         : 25px;
          width          : 25px;
          background     : #b3bbf4;
          border-radius  : 50%;
          margin         : 0 auto;
          font-size      : 10px;
          color          : var(--customVar-white);

          &.clr {
            background: #00c14d;
            color     : #0015a2;
          }
        }

        .close-icon-box {
          position       : relative;
          display        : flex;
          align-items    : center;
          justify-content: center;
          height         : 25px;
          width          : 25px;
          background     : #b3bbf4;
          border-radius  : 50%;
          margin         : 0 auto;
          font-size      : 10px;
          color          : var(--customVar-white);
        }

        .pain-box {
          position: relative;
          display : block;
        }

        .pain {
          letter-spacing: -0.015em;
          color         : #b3bbf4;
          font-size     : 16px;
          font-weight   : 500;
          line-height   : 16px;
          margin        : 0;

          &.clr-white {
            color: #ffffff;
          }
        }

        &:first-child {
          text-align  : left;
          padding-left: 30px;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}

/***********************
* Choose Domain
***********************/
.choose-domain-two {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 68px 0 20px;
  overflow  : hidden;
  z-index   : 1;

  .choose-domain-two__shape-1 {
    position: absolute;
    top     : 0;
    left    : 100px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .choose-domain-two__shape-2 {
    position: absolute;
    bottom  : 0;
    right   : -30px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .choose-domain-two__shape-3 {
    position: absolute;
    bottom  : 235px;
    right   : 114px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .choose-domain-two__shape-4 {
    position: absolute;
    bottom  : 0;
    left    : 323px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .choose-domain-two__shape-5 {
    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .choose-domain-two__left {
    position    : relative;
    display     : block;
    margin-left : 92px;
    margin-right: 79px;
  }

  .choose-domain-two__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .choose-domain-two__right {
    position    : relative;
    display     : block;
    margin-top  : 33px;
    margin-left : 12px;
    margin-right: 93px;
  }

  .choose-domain-two__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : var(--customVar-white);
    margin     : 0;
  }

  .choose-domain-two__text {
    font-size    : 18px;
    color        : var(--customVar-white);
    line-height  : 32px;
    margin       : 0;
    margin-top   : 6px;
    margin-bottom: 30px;
  }

  .choose-domain-two__form {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    z-index    : 2;
  }

  .choose-domain-two__input-box {
    position : relative;
    display  : block;
    max-width: 540px;
    width    : 100%;

    input[type="text"] {
      outline           : none;
      -webkit-appearance: none;
      -moz-appearance   : none;
      appearance        : none;
      background        : rgba(255, 255, 255, 1);
      border            : 1.5px solid #e9e9e9;
      border-radius     : 10px;
      display           : block;
      max-width         : 540px;
      width             : 100%;
      height            : 60px;
      color             : var(--customVar-text);
      font-size         : 16px;
      font-weight       : 400;
      padding-left      : 25px;
      padding-right     : 176px;
    }
  }

  .choose-domain-two__btn {
    padding        : 0;
    color          : var(--customVar-white);
    margin-left    : 0;
    position       : absolute;
    top            : 0;
    bottom         : 0;
    right          : 0;
    height         : 60px;
    width          : 60px;
    display        : flex;
    align-items    : center;
    justify-content: center;

    &:before {
      background-color: var(--customVar-secondary);
    }

    span {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: center;
    }

    i {
      font-size         : 20px;
      color             : inherit;
      margin-left       : 0;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;
    }
  }

  .choose-domain-two__name {
    display          : -webkit-inline-box;
    display          : -ms-inline-flexbox;
    display          : inline-flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    position         : absolute;
    top              : 50%;
    right            : 90px;
    transform        : translateY(-50%);
    border-left      : 1.5px solid #e9e9e9;
    padding-left     : 20px;
    height           : 30px;
  }

  .choose-domain-two__bottom {
    position   : relative;
    display    : block;
    padding-top: 30px;
  }

  .choose-domain-two__list {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    margin     : 0;

    li {
      position : relative;
      flex     : 0 0 20%;
      max-width: 20%;
      width    : 100%;

      +li {
        margin-left: 22px;
      }

      &:nth-child(2) .choose-domain-two__type {
        color: #00c14d;
      }

      &:nth-child(3) .choose-domain-two__type {
        color: #ff5a74;
      }

      &:nth-child(4) .choose-domain-two__type {
        color: #ffa800;
      }
    }
  }

  .choose-domain-two__type {
    font-size     : 20px;
    letter-spacing: -0.015em;
    color         : #0ea5ff;
    font-weight   : 700;
    line-height   : 20px;
    margin        : 0;
  }

  .choose-domain-two__price {
    margin        : 0;
    letter-spacing: -0.015em;
    color         : var(--customVar-white);
  }
}

/***********************
* Services Two
***********************/
.services-two {
  position  : relative;
  display   : block;
  background: #f5f5f5;
  padding   : 110px 0 108px;
  z-index   : 1;

  .services-two__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .services-two__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .services-two__section-title__text {
    font-size  : 16px;
    margin     : 0;
    line-height: 26px;
  }

  .services-two__left {
    position: relative;
    display : block;
  }

  .services-two__img {
    position: relative;
    display : block;

    img {
      width                    : 100%;
      border-top-left-radius   : 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .services-two__right {
    position   : relative;
    display    : block;
    margin-left: -30px;
  }

  .services-two__list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position          : relative;
      display           : flex;
      align-items       : center;
      border            : 1px solid #eaedf8;
      background-color  : var(--customVar-white);
      padding           : 35px 40px 31px;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        background-color: #f3f6ff;
      }

      &:nth-child(1) {
        border-bottom          : 0;
        border-top-right-radius: 20px;
      }

      &:nth-child(2) {
        border-bottom: 0;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }

      &:nth-child(4) {
        border-bottom-right-radius: 20px;
      }

      .icon {
        position       : relative;
        display        : flex;
        align-items    : center;
        justify-content: center;
        height         : 70px;
        width          : 70px;
        border-radius  : 50%;
        background     : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);

        span {
          position : relative;
          display  : inline-block;
          font-size: 30px;
          color    : var(--customVar-white);
        }
      }

      .content {
        position   : relative;
        display    : block;
        max-width  : 355px;
        width      : 100%;
        margin-left: 40px;

        h4 {
          font-size    : 22px;
          color        : var(--customVar-black);
          font-weight  : 600;
          margin       : 0;
          margin-bottom: 9px;
        }

        p {
          letter-spacing: -0.015em;
          line-height   : 26px;
          margin        : 0;
        }
      }
    }
  }
}

/***********************
* How It Work
***********************/
.how-it-work {
  position     : relative;
  display      : block;
  background   : #f5f5f5;
  counter-reset: count;
  padding      : 0 0 90px;
  z-index      : 1;

  .how-it-work__inner {
    position: relative;
    display : block;
    z-index : 1;
  }

  .how-it-work__shape-1 {
    position  : absolute;
    bottom    : 51px;
    left      : 0;
    right     : 0;
    text-align: center;

    img {
      width: auto;
    }
  }

  .how-it-work__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .how-it-work__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .how-it-work__section-title__text {
    font-size  : 16px;
    margin     : 0;
    line-height: 26px;
  }

  .how-it-work__list {
    position   : relative;
    display    : flex;
    align-items: center;
    margin     : 0;

    li {
      position: relative;
      display : block;

      &:nth-child(1) {
        padding-right: 93px;
      }

      &:nth-child(2) {
        padding-left : 30px;
        padding-right: 63px;
      }

      &:nth-child(3) {
        padding-left : 50px;
        padding-right: 30px;
      }

      &:nth-child(4) {
        padding-left: 93px;
      }
    }
  }

  .how-it-work__single {
    position     : relative;
    display      : block;
    text-align   : center;
    margin-bottom: 20px;
  }

  .how-it-work__icon {
    position     : relative;
    display      : block;
    margin-bottom: 16px;

    img {
      width: auto;
    }
  }

  .how-it-work__title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 32px;
    margin-bottom: 12px;
  }

  .how-it-work__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }

  .how-it-work__step-text {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-top     : 57px;

    p {
      font-size  : 18px;
      color      : var(--customVar-black);
      line-height: 18px;
      font-weight: 500;
      margin     : 0;
      font-family: var(--customVar-title-font);
    }
  }

  .how-it-work__count {
    position: relative;
    display : block;

    &:before {
      position         : relative;
      font-size        : 18px;
      line-height      : 18px;
      font-weight      : 500;
      counter-increment: count;
      content          : "0"counter(count);
      transition       : all 200ms linear;
      transition-delay : 0.1s;
      color            : var(--customVar-black);
      text-align       : center;
      font-family      : var(--customVar-title-font);
    }
  }
}

/***********************
* Comparision
***********************/
.comparision {
  position  : relative;
  display   : block;
  background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 108px 0 120px;
  overflow  : hidden;
  z-index   : 1;
  height    : 1160px;

  .comparision__shape-1 {
    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision__shape-2 {
    position: absolute;
    bottom  : 40px;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision__shape-3 {
    position: absolute;
    bottom  : 0;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .comparision__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 9px;
  }

  .comparision__section-title__text {
    font-size  : 16px;
    color      : var(--customVar-white);
    margin     : 0;
    line-height: 26px;
  }

  .comparision__main-content-box {
    position: relative;
    display : block;
  }

  .comparision__inner {
    position: relative;
    display : block;

    .tab-buttons {
      position       : relative;
      display        : inline-flex;
      align-items    : center;
      justify-content: center;
      max-width      : 801px;
      width          : 100%;
      margin         : 0 auto 0;
      background     : #010e69;
      border         : 2px solid #ffffff;
      box-shadow     : 0px 7px 20px rgba(1, 14, 105, 0.25);
      border-radius  : 30px;
      padding        : 3px;
      z-index        : 11;

      .tab-btn {
        position: relative;
        display : inline-block;

        +.tab-btn {
          margin: 0;
        }

        span {
          position        : relative;
          display         : block;
          text-align      : center;
          transition      : all 200ms linear;
          transition-delay: 0.1s;
          font-size       : 16px;
          line-height     : 16px;
          color           : var(--customVar-white);
          background-color: transparent;
          padding         : 17px 42px 17px;
          border-radius   : 25px;
          font-weight     : 600;
          cursor          : pointer;
          transition      : all 0.3s ease;
          z-index         : 1;

          &:before {
            position        : absolute;
            top             : 0;
            left            : 0;
            right           : 0;
            bottom          : 0;
            height          : 0%;
            border-radius   : 25px;
            content         : "";
            background-color: var(--customVar-white);
            transition      : all 0.3s ease;
            z-index         : -1;
          }
        }

        &.active-btn span {
          color: var(--customVar-black);

          &:before {
            height: 100%;
          }
        }
      }

    }

    .tabs-content {
      position  : relative;
      display   : block;
      margin-top: -30px;

      .tab {
        width     : 100%;
        position  : absolute;
        visibility: hidden;
        opacity   : 0;
        @include transformMixin(translateY(35px));
        @include transitionMixin(transform 600ms ease);
        z-index: 10;

        &.active-tab {
          visibility: visible;
          opacity   : 1;
          @include transformMixin(translateY(0px));
        }
      }
    }
  }

  .comparision__tab-content-box {
    position: relative;
    display : block;
  }

  .comparision__tab-content-img {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    padding         : 50px 50px 50px;
    border-radius   : 20px;

    img {
      width        : 100%;
      border-radius: 20px;
    }
  }
}

/**********************************************
* Blog Two
***********************************************/
.blog-two {
  position: relative;
  display : block;
  padding : 0 0 90px;
  z-index : 1;

  .blog-two__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .blog-two__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .blog-two__section-title__text {
    font-size     : 16px;
    margin        : 0;
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : #666666;
  }

  .blog-two__single {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    box-shadow      : 0px 15px 40px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    margin-bottom   : 30px;

    &:hover .blog-two__img img {
      transform: scale(1.05);
    }
  }

  .blog-two__img-box {
    position: relative;
    display : block;
  }

  .blog-two__img {
    position               : relative;
    display                : block;
    border-top-left-radius : 20px;
    border-top-right-radius: 20px;
    overflow               : hidden;
    z-index                : 1;

    img {
      width                  : 100%;
      border-top-left-radius : 20px;
      border-top-right-radius: 20px;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }

  .blog-two__date {
    position: absolute;
    top     : 30px;
    left    : 30px;

    p {
      font-weight     : 600;
      font-size       : 12px;
      line-height     : 12px;
      letter-spacing  : 0.05em;
      color           : #121212;
      background-color: var(--customVar-white);
      text-transform  : uppercase;
      padding         : 10px 16px 10px;
      border-radius   : 17px;
      margin          : 0;

      span {
        position    : relative;
        display     : inline-block;
        font-size   : 16px;
        margin-right: 7px;
      }
    }
  }

  .blog-two__content {
    position     : relative;
    display      : block;
    padding      : 21px 30px 19px;
    border-bottom: 1px solid #e3e7f4;
  }

  .blog-two__title {
    margin     : 0;
    font-weight: 500;
    font-size  : 22px;
    line-height: 32px;

    a {
      color             : #141414;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .blog-two__text {
    letter-spacing: -0.015em;
    margin        : 8px 0 0;
    line-height   : 26px;
  }

  .blog-two__user-and-date {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 15px 30px 18px;
  }

  .blog-two__user {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .blog-two__user-img {
    position     : relative;
    display      : block;
    height       : 40px;
    width        : 40px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-two__user-name {
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    letter-spacing: 0.1em;
    color         : #666666;
    margin        : 0 0 0 10px;
    text-transform: uppercase;
  }

  .blog-two__date-2 {
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    letter-spacing: 0.1em;
    color         : #666666;
    text-transform: uppercase;
    margin        : 0;

    span {
      position    : relative;
      display     : inline-block;
      font-size   : 18px;
      top         : 3px;
      margin-right: 10px;
    }
  }
}



@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .right-hosting {
    padding: 120px 0 109px;

    .right-hosting__inner .tab-buttons .tab-btn+.tab-btn {
      margin-left: 40px;
    }

    .right-hosting__points-box {
      flex-direction: column;
      align-items   : baseline;
    }

    .right-hosting__points--two {
      margin-left: 0;
      margin-top : 8px;
    }
  }

  .services-two {
    .services-two__list li {
      padding: 35px 10px 31px;
    }
  }

  .how-it-work {
    .how-it-work__shape-1 {
      display: none;
    }
  }
}

  /* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .why-choose-two {
    .why-choose-two__single {
      padding: 48px 20px 25px;
    }
  }

  .right-hosting {
    padding: 120px 0 109px;

    .right-hosting__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .right-hosting__right {
      max-width: 600px;
      margin   : 0 auto 0;
    }
  }

  .choose-domain-two {
    .choose-domain-two__left {
      margin-left : 0;
      margin-right: 0;
    }

    .choose-domain-two__right {
      margin-left : 0;
      margin-right: 0;
    }
  }

  .services-two {
    .services-two__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .services-two__list li {
      &:nth-child(1) {
        border-top-right-radius: 0;
      }

      &:nth-child(4) {
        border-bottom-right-radius: 0;
      }
    }
  }

  .how-it-work {
    .how-it-work__shape-1 {
      display: none;
    }

    .how-it-work__list {
      flex-wrap: wrap;

      li {
        &:nth-child(1) {
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(3) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 0;
        }
      }
    }
  }

  .blog-two {
    .blog-two__user-and-date {
      flex-direction: column;
      align-items   : baseline;
    }

    .blog-two__user {
      margin-bottom: 20px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .banner-three {
    padding: 195px 0 405px;

    .banner-three__title {
      font-size: 55px;
    }

    .banner-three__text br {
      display: none;
    }

    .banner-three__shape-1,
    .banner-three__shape-2 {
      display: none;
    }
  }

  .right-hosting {
    padding: 120px 0 109px;

    .right-hosting__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .right-hosting__right {
      max-width: 600px;
      margin   : 0 auto 0;
    }
  }

  .choose-domain-two {
    .choose-domain-two {
      padding: 68px 0 109px;
    }
  }

  .services-two {
    .services-two__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .services-two__list li {
      &:nth-child(1) {
        border-top-right-radius: 0;
      }

      &:nth-child(4) {
        border-bottom-right-radius: 0;
      }
    }
  }

  .how-it-work {
    .how-it-work__shape-1 {
      display: none;
    }

    .how-it-work__list {
      flex-wrap: wrap;

      li {
        &:nth-child(1) {
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(3) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 0;
        }
      }
    }
  }

  .comparision {
    .comparision__inner .tab-buttons {
      flex-direction: column;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .banner-three {
    padding: 195px 0 405px;

    .banner-three__sub-title {
      padding-left: 15px;
    }

    .banner-three__flase-sale {
      position: relative;
    }

    .banner-three__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .banner-three__text br {
      display: none;
    }

    .banner-three__btn-box {
      flex-direction: column;
    }

    .banner-three__btn-2 {
      margin-left: 0;
      margin-top : 20px;
    }

    .banner-three__shape-1,
    .banner-three__shape-2,
    .banner-three__shape-3,
    .banner-three__shape-6 {
      display: none;
    }

  }

  .why-choose-two {
    .why-choose-two__single {
      padding: 48px 20px 25px;
    }
  }

  .right-hosting {
    padding: 120px 0 109px;

    .right-hosting__left {
      margin-right: 0;
    }

    .right-hosting__right {
      margin-left: 0;
    }

    .right-hosting__inner .tab-buttons {
      flex-direction: column;
      align-items   : baseline;

      .tab-btn+.tab-btn {
        margin-left: 0;
        margin-top : 20px;
      }

      .tab-btn:before {
        bottom: -4px;
      }
    }

    .right-hosting__points-box {
      flex-direction: column;
      align-items   : baseline;
    }

    .right-hosting__points--two {
      margin-left: 0;
      margin-top : 8px;
    }
  }

  .comparision-two {
    .comparision-two__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .comparision-two__section-title__text br {
      display: none;
    }
  }

  .choose-domain-two {
    padding: 68px 0 109px;

    .choose-domain-two__left {
      margin-left : 0;
      margin-right: 0;
    }

    .choose-domain-two__right {
      margin-left : 0;
      margin-right: 0;
    }
  }

  .services-two {
    .services-two__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .services-two__section-title__text br {
      display: none;
    }

    .services-two__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .services-two__list li {
      flex-direction: column;
      align-items   : baseline;
      padding       : 35px 20px 31px;

      .content {
        margin-left: 0;
        margin-top : 20px;
      }

      &:nth-child(1) {
        border-top-right-radius: 0;
      }

      &:nth-child(4) {
        border-bottom-right-radius: 0;
      }
    }
  }

  .how-it-work {
    .how-it-work__shape-1 {
      display: none;
    }

    .how-it-work__list {
      flex-wrap     : wrap;
      flex-direction: column;

      li {
        &:nth-child(1) {
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(3) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 0;
        }
      }
    }
  }

  .comparision {
    .comparision__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .comparision__section-title__text br {
      display: none;
    }

    .comparision__inner .tab-buttons {
      flex-direction: column;
    }

    .comparision__inner .tabs-content {
      margin-top: 0;
    }

    .comparision__shape-1,
    .comparision__shape-2,
    .comparision__shape-3 {
      display: none;
    }
  }

  .blog-two {
    .blog-two__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .blog-two__section-title__text br {
      display: none;
    }

    .comparision__inner .tab-buttons {
      flex-direction: column;
    }


    .blog-two__user-and-date {
      flex-direction: column;
      align-items   : baseline;
    }

    .blog-two__user {
      margin-bottom: 20px;
    }
  }
}