/***********************
* Contact
***********************/
.contact-one {
  background-color: #fafbff;
  padding         : 120px 0;

  .container {
    max-width: 900px;
  }

  .row {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
  }

  input[type="text"],
  input[type="email"],
  input[type="phone"],
  textarea {
    width        : 100%;
    display      : block;
    border       : none;
    outline      : none;
    border       : 1.5px solid #eaedf8;
    height       : 60px;
    border-radius: 10px;
    color        : var(--customVar-text, #666666);
    font-size    : 16px;
    font-weight  : 400;
    padding-left : 20px;
    padding-right: 20px;
  }

  textarea {
    height     : 140px;
    padding-top: 10px;
  }

  .contact-one__btn {
    color: #ffffff;

    &::before {
      background-color: var(--customVar-secondary, #3852ff);
    }
  }

  .contact-one__content {
    margin-bottom: 30px;
  }

  .contact-one__title {
    margin       : 0;
    color        : var(--customVar-black, #121212);
    font-size    : 30px;
    font-weight  : 700;
    font-family  : var(--customVar-title-font, "Outfit", sans-serif);
    margin-left  : auto;
    margin-right : auto;
    margin-bottom: 5px;
    margin-top   : -10px;
  }

  .contact-one__description {
    margin     : 0;
    font-size  : 16px;
    line-height: 1.7777777777777777em;
    font-weight: 400;
    color      : var(--customVar-text, #666666);
  }
}

.contact-info-one {
  background-color: #fafbff;

  .contact-info-one__inner {
    background        : #ffffff;
    -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
    box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);
    border-radius     : 20px;
    padding-bottom    : 39px;
    padding-top       : 39px;
    position          : relative;
  }

  .contact-info-one__item {
    text-align: center;

    [class*="col-"]+[class*="col-"] & {
      padding-top: 30px;
      margin-top : 30px;
      border-top : 1px solid #dbdbdb;
    }
  }

  .contact-info-one__icon {
    font-size: 38px;

    i {
      background-image       : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
    }
  }

  .contact-info-one__title {
    font-family   : "Outfit";
    font-style    : normal;
    font-weight   : 500;
    font-size     : 22px;
    line-height   : 160%;
    letter-spacing: -0.015em;
    color         : #121212;
    margin        : 0;
    margin-top    : 16px;
    margin-bottom : 4px;
  }

  .contact-info-one__text {
    margin     : 0;
    font-family: "Inter";
    font-style : normal;
    font-weight: 400;
    font-size  : 18px;
    line-height: 32px;
    text-align : center;
    color      : #666666;

    a {
      color             : inherit;
      background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
      background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        background-size: 100% 1px;
        color          : var(--customVar-base, #ffa065);
      }
    }
  }
}

.google-map-one {
  padding-top: 120px;

  >.container {
    height: 480px;
  }

  .google-map-one__box {
    display       : block;
    border        : none;
    outline       : none;
    width         : 100%;
    height        : 480px;
    -webkit-filter: grayscale(100%) invert(92%) contrast(83%);
    filter        : grayscale(100%) invert(92%) contrast(83%);
    border-radius : 20px;
  }
}

/***********************
* Page Header
***********************/
.page-banner-one {
  background-image   : url(../../../images/template/background/page-header-1-1-bg.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  padding-top        : 70px;
  padding-bottom     : 50px;

  .page-banner-one__image {
    margin-top: 60px;

    img {
      max-width: 100%;
    }
  }

  .page-banner-one__tagline {
    margin       : 0;
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 400;
    font-size    : 16px;
    line-height  : 1.78em;
    color        : #ffffff;
    margin-bottom: 4px;
  }

  .page-banner-one__title {
    font-family  : "Outfit";
    font-style   : normal;
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 1.2666666666666666em;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 40px;
    max-width    : 611px;
  }

  .page-banner-one__btn {
    color             : #fff;
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}



@media (min-width: $breakpoint-md) {
  .contact-one {
    .contact-one__description {
      font-size: 18px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  // .contact-one {
  //   .contact-one__title {
  //     font-size: 38px;
  //   }
  // }

  .contact-info-one {
    [class*="col-"]+[class*="col-"] .contact-info-one__item {
      margin-top : 0;
      padding-top: 0;
      border-top : 0;
      border-left: 1px solid #dbdbdb;
    }
  }

  .page-banner-one {
    padding-top   : 150px;
    padding-bottom: 48px;

    .row {
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
    }

    .page-banner-one__image {
      margin-top: 0;

      img {
        max-width: none;
        float    : right;
      }
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .page-banner-one {
    .page-banner-one__tagline {
      font-size: 18px;
    }

    .page-banner-one__title {
      font-size: 60px;
    }
  }
}