.register-one {
  position      : relative;
  z-index       : 1;
  padding-bottom: 120px;

  .register-one__inner {
    background-color  : #fff;
    -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
    box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);
    border-radius     : 20px;
    max-width         : 760px;
    width             : 100%;
    margin-left       : auto;
    margin-right      : auto;
    padding-top       : 65px;
    position          : relative;
    padding-left      : 30px;
    padding-right     : 30px;
    margin-top        : -125px;
    padding-bottom    : 65px;
  }

  .register-one__title {
    text-align : center;
    margin     : 0;
    font-size  : 32px;
    color      : var(--customVar-black, #121212);
    font-weight: bold;
  }

  .register-one__text {
    text-align   : center;
    margin       : 0;
    font-size    : 16px;
    line-height  : 1.7777777777777777em;
    max-width    : 447px;
    width        : 100%;
    margin-left  : auto;
    margin-right : auto;
    margin-bottom: 41px;
    margin-top   : 8px;
  }

  .register-one__tagline {
    text-align    : center;
    margin        : 0;
    font-size     : 16px;
    line-height   : 160%;
    color         : var(--customVar-text, #666666);
    max-width     : 430px;
    margin-left   : auto;
    margin-right  : auto;
    letter-spacing: -1.5%;

    a {
      background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
      background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
      background-size   : 100% 1px;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base, #ffa065);
      }
    }
  }

  .register-one__tagline a,
  .register-one__tagline--highlight {
    color     : var(--customVar-black, #121212);
    margin-top: 10px;

    a {
      color          : var(--customVar-secondary, #3852ff);
      background     : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
      background     : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
      background-size: 100% 1px;

      &:hover {
        color: var(--customVar-base, #ffa065);
      }
    }
  }

  .register-one__form {
    margin-bottom: 23px;

    .row {
      --bs-gutter-x: 20px;
      --bs-gutter-y: 20px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      border       : none;
      outline      : none;
      width        : 100%;
      display      : block;
      height       : 60px;
      border-radius: 10px;
      border       : 2px solid #ebebeb;
      padding-left : 20px;
      padding-right: 20px;
      color        : var(--customVar-text, #666666);
      font-size    : 16px;
    }
  }

  .register-one__form__password {
    position: relative;

    .password-toggler {
      position          : absolute;
      top               : 50%;
      right             : 20px;
      -webkit-transform : translateY(-50%);
      transform         : translateY(-50%);
      font-size         : 24px;
      cursor            : pointer;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &.show-password {
        opacity: 0.4;
      }
    }
  }

  .register-one__form__forgot {
    color         : #3852ff;
    font-size     : 16px;
    letter-spacing: -1.5%;
  }

  .register-one__btn {
    color             : #fff;
    padding-left      : 55.5px;
    padding-right     : 55.5px;
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }
  }

  .register-one__social {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-top       : 20px;
    margin-bottom    : 20px;

    a {
      width            : 74px;
      height           : 60px;
      background-color : #fff;
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-pack : center;
      -ms-flex-pack    : center;
      justify-content  : center;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      text-align       : center;
      border           : 2px solid #ebebeb;
      border-radius    : 10px;

      img {
        -webkit-transform : scale(1);
        transform         : scale(1);
        -webkit-transition: -webkit-transform 500ms ease;
        transition        : -webkit-transform 500ms ease;
        transition        : transform 500ms ease;
        transition        : transform 500ms ease, -webkit-transform 500ms ease;
      }

      &:hover img {
        -webkit-transform: scale(0.9);
        transform        : scale(0.9);
      }

      +a {
        margin-left: 20px;
      }
    }
  }
}



@media (min-width: $breakpoint-md) {
  .register-one {
    .register-one__inner {
      padding-left : 60px;
      padding-right: 60px;
    }

    .register-one__text {
      font-size: 18px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .register-one {
    .register-one__inner {
      padding-left : 80px;
      padding-right: 80px;
    }

    .register-one__title {
      font-size: 38px;
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .register-one {
    .register-one__inner {
      padding-left : 120px;
      padding-right: 120px;
    }
  }
}