/***********************
* Game Hosting Page Banner
***********************/
.game-hosting-page-banner {
  padding-bottom: 65px;

  .page-banner-three__right {
    margin-left: 0;
    margin-top : -35px;
  }
}

/***********************
* Feature Seven
***********************/
.feature-seven {
  position        : relative;
  display         : block;
  padding         : 109px 0 70px;
  background-color: #fafbff;


  .feature-seven__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .feature-seven__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .feature-seven__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .feature-seven__single {
    position     : relative;
    display      : block;
    margin-bottom: 43px;

    &:hover .feature-seven__icon {
      background-color: var(--customVar-black);
      color           : var(--customVar-white);
    }
  }

  .feature-seven__icon {
    position          : relative;
    display           : flex;
    align-items       : center;
    justify-content   : center;
    height            : 70px;
    width             : 70px;
    background        : #f0f5ff;
    border-radius     : 20px;
    color             : var(--customVar-secondary);
    font-size         : 17px;
    margin-bottom     : 27px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }


  .feature-seven__title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 22px;
    margin-bottom: 15px;
  }

  .feature-seven__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }
}

/***********************
* Popular Games
***********************/
.popular-games {
  position  : relative;
  display   : block;
  background: #f5f7ff;
  padding   : 109px 0 120px;
  z-index   : 1;

  .popular-games__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .popular-games__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .popular-games__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .popular-games__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 30px;

    &:hover .popular-games__img:before {
      visibility       : visible;
      -webkit-transform: translateY(0%);
      transform        : translateY(0%);
      opacity          : 1;
    }

    &:hover .popular-games__btn-box {
      visibility       : visible;
      -webkit-transform: translateY(0%);
      transform        : translateY(0%);
      opacity          : 1;
    }
  }

  .popular-games__img {
    position     : relative;
    display      : block;
    border-radius: 20px;
    overflow     : hidden;
    z-index      : 1;

    &:before {
      content           : "";
      position          : absolute;
      top               : 0;
      left              : 0;
      right             : 0;
      bottom            : 0;
      background-color  : rgba(var(--customVar-black-rgb), .70);
      box-shadow        : 0px 30px 60px rgba(0, 0, 0, 0.05);
      border-radius     : 20px;
      -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
      transition        : opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
      transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
      transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
      visibility        : hidden;
      opacity           : 0;
      -webkit-transform : translateY(-70%);
      transform         : translateY(-70%);
      z-index           : 1;
    }

    img {
      width        : 100%;
      border-radius: 20px;
    }
  }

  .popular-games__btn-box {
    position          : absolute;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    display           : flex;
    align-items       : center;
    justify-content   : center;
    -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition        : opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
    transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
    visibility        : hidden;
    opacity           : 0;
    -webkit-transform : translateY(70%);
    transform         : translateY(70%);
    z-index           : 2;
  }

  .popular-games__btn {
    color: var(--customVar-white);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }

  .popular-games__content {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    padding        : 32px 30px 32px;
  }

  .popular-games__title {
    font-size  : 22px;
    font-weight: 600;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 22px;
  }

  .popular-games__price {
    position   : relative;
    display    : inline-block;
    font-size  : 22px;
    font-weight: 600;
    color      : var(--customVar-text);
    margin     : 0;
    line-height: 22px;
    font-family: var(--customVar-title-font);
  }

  .popular-games__price span {
    position    : relative;
    display     : inline-block;
    font-size   : 16px;
    margin-right: 10px;

    &:before {
      content         : "";
      position        : absolute;
      bottom          : 9px;
      left            : -4px;
      right           : -4px;
      height          : 3px;
      background-color: #ff6b71;
      transform       : rotate(-9deg);
    }
  }

  .popular-games__btn-two-box {
    position  : relative;
    display   : block;
    text-align: center;
    margin-top: 20px;
  }

  .popular-games__btn-two {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/***********************
* Why Choose Four
***********************/
.why-choose-four {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 120px;

  .why-choose-four__left {
    position    : relative;
    display     : block;
    margin-right: 65px;
  }

  .why-choose-four__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : #121212;
    margin     : 0;
  }

  .why-choose-four__text {
    margin       : 0;
    line-height  : 32px;
    font-size    : 18px;
    margin-top   : 21px;
    margin-bottom: 25px;
  }

  .why-choose-four__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .why-choose-four__right {
    position   : relative;
    display    : block;
    margin-left: 65px;
  }

  .why-choose-four__points-box {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .why-choose-four__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 14px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          font-size: 18px;
          margin   : 0;
        }
      }
    }
  }

  .why-choose-four__points--two {
    margin-left: 85px;
  }
}

/***********************
* Game Server
***********************/
.game-server {
  position  : relative;
  display   : block;
  background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 85px 0 84px;
  overflow  : hidden;
  z-index   : 1;

  .game-server__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .game-server__left {
    position    : relative;
    display     : block;
    margin-right: 54px;
  }

  .game-server__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .game-server__right {
    position   : relative;
    display    : block;
    margin-top : 89px;
    margin-left: 65px;
  }

  .game-server__title {
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : #ffffff;
    margin     : 0;
  }

  .game-server__text {
    font-size    : 18px;
    line-height  : 32px;
    color        : #ffffff;
    margin       : 0;
    margin-top   : 22px;
    margin-bottom: 40px;
  }

  .game-server__points {
    position: relative;
    display : block;

    li {
      position   : relative;
      display    : flex;
      align-items: center;
      flex-wrap  : wrap;

      +li {
        margin-top: 34px;
      }

      .icon {
        position          : relative;
        display           : flex;
        align-items       : center;
        justify-content   : center;
        height            : 40px;
        width             : 40px;
        background        : #f0f5ff;
        border-radius     : 50%;
        font-size         : 13px;
        color             : var(--customVar-secondary);
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
      }

      &:hover .icon {
        background-color: var(--customVar-black);
        color           : var(--customVar-white);
      }

      .text {
        margin-left: 25px;

        h4 {
          font-weight   : 600;
          font-size     : 22px;
          line-height   : 22px;
          letter-spacing: -0.015em;
          color         : #ffffff;
          margin        : 0;
        }
      }
    }
  }
}

/***********************
* Get Access
***********************/
.get-access {
  position        : relative;
  display         : block;
  padding         : 204px 0 270px;
  overflow        : hidden;
  background-color: #fafbff;
  z-index         : 1;

  .get-access__img {
    position: absolute;
    top     : -102px;
    right   : -15px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .get-access__left {
    position    : relative;
    display     : block;
    margin-right: 96px;
  }

  .get-access__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    margin     : 0;
    color      : #121212;
  }

  .get-access__text {
    font-size    : 18px;
    line-height  : 32px;
    margin       : 0;
    margin-top   : 21px;
    margin-bottom: 24px;
  }

  .get-access__btn-box {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .get-access__btn-box-content {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .get-access__btn-icon {
    position     : relative;
    display      : block;
    width        : 120px;
    border-radius: 50%;
    z-index      : 2;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .get-access__btn {
    position          : relative;
    display           : flex;
    align-items       : center;
    font-size         : 18px;
    font-weight       : 600;
    font-family       : var(--customVar-title-font);
    color             : var(--customVar-white);
    background-color  : var(--customVar-black);
    border-radius     : 10px;
    padding           : 14px 35px 14px;
    margin-left       : -32px;
    margin-top        : 3px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    span {
      position    : relative;
      display     : inline-block;
      font-size   : 13px;
      padding-left: 10px;
    }

    &:hover {
      background-color: var(--customVar-secondary);
    }
  }
}

/***********************
* guides One
***********************/
.guides-one {
  position        : relative;
  display         : block;
  background-color: #fafbff;

  .guides-one__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .guides-one__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .guides-one__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .guides-one__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 30px;

    &:hover .guides-one__img img {
      transform: scale(1.05);
    }
  }

  .guides-one__img {
    position               : relative;
    display                : block;
    border-top-right-radius: 20px;
    border-top-left-radius : 20px;
    overflow               : hidden;
    z-index                : 1;

    img {
      width                  : 100%;
      border-top-right-radius: 20px;
      border-top-left-radius : 20px;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }


  .guides-one__content {
    position  : relative;
    display   : block;
    text-align: center;
    padding   : 27px 37px 40px;
  }

  .guides-one__title {
    font-weight: 500;
    font-size  : 22px;
    line-height: 22px;
    margin     : 0;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-secondary);
      }
    }
  }

  .guides-one__text {
    line-height   : 26px;
    margin        : 0;
    letter-spacing: -0.015em;
    margin-top    : 13px;
    margin-bottom : 27px;
  }

  .guides-one__read-more {
    position          : relative;
    display           : flex;
    align-items       : center;
    justify-content   : center;
    font-weight       : 600;
    font-size         : 18px;
    line-height       : 18px;
    text-align        : center;
    letter-spacing    : 0.01em;
    color             : #3852ff;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    span {
      position    : relative;
      display     : inline-block;
      font-size   : 13px;
      padding-left: 10px;
    }

    &:hover {
      color: var(--customVar-black);
    }
  }
}



@media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .why-choose-four {
    .why-choose-four__left {
      margin-right: 0;
    }

    .why-choose-four__right {
      margin-left: 0;
    }

    .why-choose-four__points--two {
      margin-left: 50px;
    }
  }

  .get-access {
    .get-access__left {
      margin-right: 0;
    }

    .get-access__btn-box-content--two {
      margin-left: 19px;
    }

    .get-access__img {
      right: -240px;
    }
  }
}

/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .game-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .why-choose-four {
    .why-choose-four__left {
      margin-right: 0;
    }

    .why-choose-four__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .game-server {
    .game-server__left {
      margin-right: 0;
    }

    .game-server__right {
      margin-left: 0;
    }

    .game-server__title {
      font-size: 36px;
    }
  }

  .get-access {
    padding: 109px 0 109px;

    .get-access__img {
      display: none;
    }
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .game-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 40px;
    }
  }

  .why-choose-four {
    .why-choose-four__left {
      margin-right: 0;
    }

    .why-choose-four__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .game-server {
    .game-server__left {
      margin-right: 0;
    }

    .game-server__right {
      margin-top : 40px;
      margin-left: 0;
    }
  }

  .get-access {
    padding: 109px 0 109px;

    .get-access__img {
      display: none;
    }

    .get-access__left {
      margin-right: 0;
    }
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .game-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 40px;
    }
  }

  .feature-seven {
    .feature-seven__section-title__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .feature-seven__section-title__title br {
      display: none;
    }
  }

  .popular-games {
    .popular-games__content {
      flex-direction: column;
      align-items   : baseline;
    }

    .popular-games__price {
      margin-top: 20px;
    }
  }

  .why-choose-four {
    .why-choose-four__left {
      margin-right: 0;
    }

    .why-choose-four__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .why-choose-four__points-box {
      flex-direction: column;
      align-items   : baseline;
    }

    .why-choose-four__points--two {
      margin-left: 0;
      margin-top : 14px;
    }
  }

  .game-server {
    .game-server__left {
      margin-right: 0;
    }

    .game-server__right {
      margin-top : 40px;
      margin-left: 0;
    }

    .game-server__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .game-server__points li .text h4 {
      font-size: 20px;
    }
  }

  .get-access {
    padding: 109px 0 109px;

    .get-access__left {
      margin-right: 0;
    }

    .get-access__img {
      display: none;
    }

    .get-access__btn-box {
      flex-direction: column;
      align-items   : baseline;
    }

    .get-access__btn-box-content--two {
      margin-left: 0;
      margin-top : 20px;
    }

    .get-access__btn-box-content {
      flex-direction: column;
      align-items   : baseline;
    }
  }

  .guides-one {
    .guides-one__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }
}