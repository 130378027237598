/***********************
* hosting option
***********************/
.hosting-option {
  background-color: #f5f7ff;
  background-image: url(../../../images/template/background/hosting-option-bg.png);
  background-size : cover;
  padding         : 120px 0;

  .hosting-option .row {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 30px;
  }

  .hosting-option__content {
    text-align: center;
  }

  .hosting-option__title {
    font-weight  : 700;
    font-size    : 32px;
    line-height  : 1.5em;
    text-align   : center;
    color        : var(--customVar-black, #121212);
    margin       : 0;
    margin-bottom: 5px;
  }

  .hosting-option__text {
    margin       : 0;
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 400;
    font-size    : 16px;
    line-height  : 160%;
    text-align   : center;
    margin-bottom: 40px;
  }


  .hosting-option__item {
    background-color: #fff;
    border-radius   : 20px;
    padding         : 50px 38px;
  }

  .hosting-option__item__icon {
    background       : #f0f5ff;
    border-radius    : 20px;
    width            : 90px;
    height           : 90px;
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 20px;
    -ms-flex-negative: 0;
    flex-shrink      : 0;
  }

  .hosting-option__item__title {
    margin       : 0;
    font-weight  : 600;
    font-size    : 22px;
    line-height  : 160%;
    color        : var(--customVar-black, #121212);
    margin-bottom: 9px;

    a {
      color             : inherit;
      background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
      background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        background-size: 100% 1px;
      }
    }
  }

  .hosting-option__item__text {
    margin        : 0;
    font-weight   : 400;
    font-size     : 16px;
    line-height   : 160%;
    letter-spacing: -0.015em;
  }
}

/***********************
* data center
***********************/
.data-center {
  padding-top   : 110px;
  padding-bottom: 60px;

  .data-center__title {
    margin       : 0;
    color        : var(--customVar-black, #121212);
    font-size    : 30px;
    font-weight  : bold;
    margin-bottom: 65px;

    br {
      display: none;
    }
  }

  .data-center__item {
    margin-bottom: 60px;

    .row {
      --bs-gutter-y: 30px;
    }
  }

  .data-center__item__image img {
    max-width: 100%;
  }

  .data-center__item__title {
    font-size    : 20px;
    font-weight  : 600;
    font-family  : var(--customVar-title-font, "Outfit", sans-serif);
    color        : var(--customVar-black, #121212);
    margin-bottom: 16px;

    img {
      border-radius: 50%;
      margin-right : 10px;
    }
  }

  .data-center__item__content {
    padding           : 30px 40px;
    border-radius     : 20px;
    background-color  : #fff;
    -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
    box-shadow        : 0 15px 40px rgba(0, 0, 0, 0.05);
  }

  .data-center__item__list {
    margin       : 0;
    margin-bottom: 18px;

    li {
      font-size  : 16px;
      line-height: 2.3333333333333335em;

      span {
        font-weight: 600;
      }
    }
  }

  .data-center__item__btn {
    width           : 100%;
    -webkit-box-pack: center;
    -ms-flex-pack   : center;
    justify-content : center;
    color           : #fff;

    &::before {
      background-color: var(--customVar-secondary, #3852ff);
    }
  }
}



@media (min-width: $breakpoint-md) {
  .hosting-option {
    .hosting-option__item {
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
    }

    .hosting-option__item__icon {
      margin-right: 37px;
    }
  }

  .data-center {
    .data-center__title {
      font-size: 35px;

      br {
        display: inherit;
      }
    }

    .data-center__item__title {
      font-size: 26px;
    }

    .data-center__item__list li {
      font-size: 18px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .hosting-option {
    .hosting-option__title {
      font-size: 38px;
    }

    .hosting-option__text {
      font-size: 18px;
    }
  }

  .data-center {
    .data-center__title {
      font-size: 40px;
    }
  }
}


@media (min-width: $breakpoint-xl) {
  .hosting-option {
    .hosting-option .container {
      max-width: 1135px;
    }
  }

  .data-center {
    .data-center__item .row {
      --bs-gutter-y    : 0;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
    }

    .data-center__item__content {
      margin-left: auto;
      max-width  : 400px;
    }
  }
}