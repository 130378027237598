/*************************************************************
* Reseller Hosting Page Banner
**************************************************************/
.reseller-hosting-page-banner {
  padding-bottom: 16px;

  .page-banner-three__right {
    margin-left: 70px;
    margin-top : -52px;
  }
}

/*************************************************************
* Pricing Eight
**************************************************************/
.pricing-eight {
  position        : relative;
  display         : block;
  background-color: #f4f7fe;
  padding         : 109px 0 120px;
  z-index         : 1;

  .pricing-eight__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-eight__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-eight__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;

    li {
      a {
        font-size     : 18px;
        font-weight   : 500;
        color         : var(--customVar-black);
        padding-left  : 10px;
        padding-right : 10px;
        display       : block;
        letter-spacing: 0.01em;
      }

      &.active a {
        color: var(--customVar-secondary);
      }
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;

    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    border          : 1px solid #dde1fd;
    border-radius   : 20px;

    .row {
      --bs-gutter-x: 0;
    }

    >div {
      // position  : absolute;
      position  : relative;
      height    : 0px;
      visibility: hidden;
      opacity   : 0;
      @include transformMixin(translateY(35px));
      @include transitionMixin(transform 600ms ease);
  
      &.active {
        height    : auto;
        visibility: visible;
        opacity   : 1;
        @include transformMixin(translateY(0px));
      }
    }
  }



  .pricing-eight__single {
    position: relative;
    display : block;
    padding : 46px 40px 36px;
    z-index : 1;
  }

  .pricing-eight__single-2 {
    border-left : 1px solid #dde1fd;
    border-right: 1px solid #dde1fd;
  }

  .pricing-eight__title {
    margin     : 0;
    font-weight: 600;
    font-size  : 26px;
    line-height: 33px;
    text-align : center;
    color      : var(--customVar-black);
  }

  .pricing-eight__price-box {
    position  : relative;
    display   : block;
    text-align: center;

    p {
      font-weight     : 400;
      font-size       : 14px;
      line-height     : 14px;
      text-align      : center;
      margin          : 0;
      background-color: #ebecfe;
      position        : relative;
      display         : inline-block;
      color           : var(--customVar-black);
      padding         : 5px 16px 6px;
      border-radius   : 15px;
    }
  }

  .pricing-eight__price {
    font-weight: 800;
    font-size  : 50px;
    line-height: 60px;
    text-align : center;
    margin     : 13px 0 5px;
    color      : var(--customVar-black);
  }

  .pricing-eight__price-validity {
    font-weight: 500;
    font-size  : 20px;
    line-height: 25px;
  }

  .pricing-eight__text {
    margin     : 8px 0 30px;
    line-height: 26px;
    text-align : center;
  }

  .pricing-eight__btn-box {
    position: relative;
    display : block;
  }

  .pricing-eight__btn {
    position  : relative;
    display   : block;
    text-align: center;
    color     : var(--customVar-white);

    &::before {
      background-color: var(--customVar-secondary);
    }
  }

  .pricing-eight__points-box {
    position   : relative;
    display    : block;
    margin-left: 27px;
    margin-top : 27px;
  }

  .pricing-eight__points-list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      .icon {
        position: relative;
        display : inline-block;
        top     : 3px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 21px;
          color    : #00c14d;
        }
      }

      .text {
        position   : relative;
        display    : block;
        margin-left: 20px;

        p {
          margin        : 0;
          font-weight   : 400;
          font-size     : 16px;
          line-height   : 40px;
          letter-spacing: -0.015em;
          color         : var(--customVar-text);

          >span {
            position   : relative;
            display    : inline-block;
            font-size  : 17px;
            color      : #aaaaaa;
            margin-left: 10px;
            top        : 2px;
          }
        }
      }
    }
  }

  .pricing-eight__points-title {
    font-weight: 600;
    font-size  : 18px;
    line-height: 22px;
    color      : #121212;
    font-family: var(--customVar-body-font);
    margin     : 40px 0 17px;
  }
}

/*************************************************************
* Feature Five
**************************************************************/
.feature-five {
  position        : relative;
  display         : block;
  padding         : 109px 0 56px;
  background-color: #fafbff;
  z-index         : 1;


  .feature-five__section-title {
    position   : relative;
    display    : block;
    margin     : 0 0 40px;
    text-align : center;
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : var(--customVar-black);
  }

  .feature-five__single {
    position     : relative;
    display      : block;
    margin-bottom: 57px;

    &:hover .feature-five__icon img {
      transform: scale(0.9);
    }
  }

  .feature-five__icon {
    position: relative;
    display : inline-block;

    img {
      width                   : auto;
      -webkit-transition      : all 500ms linear;
      transition              : all 500ms linear;
      -webkit-transition-delay: 0.1s;
      transition-delay        : 0.1s;
    }
  }

  .feature-five__title {
    margin     : 21px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : var(--customVar-black);
  }

  .feature-five__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}

/*************************************************************
* Which Plan
**************************************************************/
.which-plan {
  position   : relative;
  display    : block;
  padding-top: 70px;

  .which-plan__left {
    position    : relative;
    display     : block;
    margin-right: -15px;
    margin-top  : 70px;
  }

  .which-plan__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : var(--customVar-black);
    margin     : 0 0 35px;
  }

  .which-plan__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position: relative;
      display : flex;

      +li {
        margin-top: 22px;
      }

      .icon {
        position: relative;
        display : inline-block;
        top     : 10px;

        span {
          position               : relative;
          display                : inline-block;
          font-size              : 48px;
          background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
          -webkit-background-clip: text;
          -moz-background-clip   : text;
          background-clip        : text;
          -webkit-text-fill-color: transparent;
          -webkit-transition     : all 500ms ease;
          transition             : all 500ms ease;
        }
      }

      .content {
        position   : relative;
        display    : block;
        margin-left: 38px;

        h3 {
          margin     : 0;
          font-weight: 600;
          font-size  : 22px;
          line-height: 32px;
          color      : var(--customVar-black);
        }

        p {
          margin        : 10px 0 0;
          letter-spacing: -0.015em;
          line-height   : 26px;
        }
      }
    }
  }

  .which-plan__btn-box {
    position  : relative;
    display   : block;
    margin-top: 33px;
  }

  .which-plan__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .which-plan__right {
    position   : relative;
    display    : block;
    margin-left: -11px;
  }

  .which-plan__img {
    position: relative;
    display : block;
    z-index : 2;

    img {
      width: 100%;
    }
  }

  .which-plan__img-2 {
    position: absolute;
    top     : 70px;
    left    : 90px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .which-plan__img-3 {
    position: absolute;
    top     : 156px;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }
}

/*************************************************************
* Why Us Two
**************************************************************/
.why-us-two {
  position: relative;
  display : block;
  padding : 108px 0 120px;
  z-index : 1;

  .why-us-two .container {
    max-width: 710px;
  }

  .why-us-two__title {
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    text-align : center;
    color      : var(--customVar-black);
    margin     : 0;
  }

  .why-us-two__points-box {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-top     : 39px;
    margin-bottom  : 28px;
  }

  .why-us-two__points {
    position: relative;
    display : block;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 20px;
      }

      .icon {
        position        : relative;
        display         : flex;
        align-items     : center;
        justify-content : center;
        width           : 50px;
        height          : 50px;
        border-radius   : 50%;
        background-color: #f1f5ff;

        span {
          position : relative;
          display  : inline-block;
          font-size: 15px;
          color    : var(--customVar-secondary);
        }
      }

      .text {
        margin-left: 30px;

        p {
          margin     : 0;
          font-weight: 600;
          font-size  : 22px;
          line-height: 32px;
          font-family: var(--customVar-title-font);
          color      : var(--customVar-black);
        }
      }
    }
  }

  .why-us-two__btn-box {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .why-us-two__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/*************************************************************
* comparision Three
**************************************************************/
.comparision-three {
  position  : relative;
  display   : block;
  background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 109px 0 120px;
  overflow  : hidden;
  z-index   : 1;

  .comparision-three__shape-1 {
    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision-three__shape-2 {
    position: absolute;
    bottom  : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision-three__shape-3 {
    position: absolute;
    bottom  : 0;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .comparision-three__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 40px;
  }

  .comparision-three__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 9px;
  }

  .comparision-three__section-title__text {
    font-size  : 18px;
    color      : var(--customVar-white);
    margin     : 0;
    line-height: 32px;
  }

  .comparision-three__inner {
    position     : relative;
    display      : block;
    background   : #ffffff;
    border-radius: 20px;
    padding      : 120px 110px 100px;
    padding-left : 80px;
  }

  .comparision-three__progress-box {
    position: relative;
    display : block;
    z-index : 1;

    &:before {
      content : "";
      position: absolute;
      top     : -50px;
      bottom  : -50px;
      left    : 217px;
      border  : 1px dashed #cccfe9;
      z-index : -1;
    }

    &:after {
      content : "";
      position: absolute;
      top     : -50px;
      bottom  : -50px;
      right   : 100px;
      border  : 1px dashed #cccfe9;
      z-index : -1;
    }
  }

  .comparision-three__progress-box-border {
    position: absolute;
    top     : -50px;
    bottom  : -50px;
    right   : 492px;
    border  : 1px dashed #cccfe9;
    z-index : -1;
  }

  .comparision-three__progress {
    position     : relative;
    display      : flex;
    align-items  : center;
    margin-bottom: 80px;

    .bar {
      position                  : relative;
      width                     : 100%;
      height                    : 100px;
      background                : transparent;
      border-top-right-radius   : 10px;
      border-bottom-right-radius: 10px;
    }

    .bar-inner {
      position                  : relative;
      display                   : block;
      width                     : 0px;
      height                    : 100px;
      border-top-right-radius   : 10px;
      border-bottom-right-radius: 10px;
      background                : #00ad6f;
      -webkit-transition        : all 1500ms ease;
      -ms-transition            : all 1500ms ease;
      -o-transition             : all 1500ms ease;
      -moz-transition           : all 1500ms ease;
      transition                : all 1500ms ease;

      &.counted .count-text {
        opacity: 1;
      }
    }

    .bar-inner-two {
      background: linear-gradient(-85deg, #f86b3f 0%, #0eb679 22.9%, #00ad6f 100%);
    }

    .count-text {
      position          : absolute;
      right             : 30px;
      top               : 50%;
      transform         : translateY(-50%);
      color             : var(--customVar-white);
      line-height       : 16px;
      font-size         : 16px;
      text-align        : center;
      font-weight       : 500;
      opacity           : 0;
      -webkit-transition: all 500ms ease;
      -ms-transition    : all 500ms ease;
      -o-transition     : all 500ms ease;
      -moz-transition   : all 500ms ease;
      transition        : all 500ms ease;
    }

    &.marb-0 {
      margin-bottom: 0;
    }
  }

  .comparision-three__progress-title {
    font-size   : 16px;
    font-weight : 500;
    line-height : 26px;
    position    : relative;
    display     : block;
    max-width   : 210px;
    width       : 100%;
    margin      : 0;
    margin-right: 9px;
  }
}

/*************************************************************
* Awarded Two
**************************************************************/
.awarded-two {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 110px 0 0;

  .awarded-two__left {
    position    : relative;
    display     : block;
    margin-right: 43px;
    margin-top  : 37px;
  }

  .awarded-two__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : var(--customVar-black);
  }

  .awarded-two__text {
    margin     : 21px 0 22px;
    font-size  : 18px;
    line-height: 32px;
  }

  .awarded-two__btn-box {
    position: relative;
    display : block;
  }

  .awarded-two__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .awarded-two__right {
    position   : relative;
    display    : block;
    margin-left: 95px;
  }

  .awarded-two__list {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin         : 0;

    li {
      position     : relative;
      display      : block;
      margin-bottom: 40px;
    }
  }

  .awarded-two__img {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }
}



/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .reseller-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-eight {
    .pricing-eight__single {
      padding: 46px 10px 36px;
    }
  }

  .which-plan {
    .which-plan__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .which-plan__right {
      max-width: 600px;
      margin   : 0 auto 0;
    }
  }

  .awarded-two {
    .awarded-two__left {
      margin-right: 0;
      margin-top  : 0;
    }

    .awarded-two__right {
      margin-left: 0;
    }
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .reseller-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 40px;
    }
  }

  .which-plan {
    .which-plan__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .which-plan__right {
      max-width: 600px;
      margin   : 0 auto 0;
    }
  }

  .awarded-two {
    .awarded-two__left {
      margin-right: 0;
      margin-top  : 0;
    }

    .awarded-two__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .reseller-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 40px;
    }
  }

  .pricing-eight {
    .pricing-eight__single {
      padding: 46px 10px 36px;
    }

    .pricing-eight__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .feature-five {
    .feature-five__section-title {
      font-size  : 30px;
      line-height: 40px;
    }
  }

  .which-plan {
    .which-plan__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .which-plan__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .why-us-two {
    .why-us-two__points-box {
      flex-direction: column;
      align-items   : baseline;
    }

    .why-us-two__points--two {
      margin-top: 4px;
    }

    .why-us-two__title {
      font-size  : 30px;
      line-height: 40px;
    }
  }

  .comparision-three {
    .comparision-three__inner {
      padding     : 120px 20px 100px;
      padding-left: 20px;
    }

    .comparision-three__progress-box:before,
    .comparision-three__progress-box:after,
    .comparision-three__progress-box-border {
      display: none;
    }

    .comparision-three__progress {
      margin-bottom : 30px;
      flex-direction: column;
      align-items   : baseline;
    }

    .comparision-three__progress-title {
      margin-right : 0;
      margin-bottom: 20px;
    }

    .comparision-three__section-title__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .comparision-three__section-title__title br,
    .comparision-three__shape-1 {
      display: none;
    }
  }

  .awarded-two {
    .awarded-two__left {
      margin-right: 0;
      margin-top  : 0;
    }

    .awarded-two__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .awarded-two__list {
      flex-direction: column;
    }
  }
}