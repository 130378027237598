/***********************
* VPS Hosting Page Banner
***********************/
.vps-hosting-page-banner {
  .page-banner-three__right {
    margin-left: 70px;
    margin-top : -80px;
  }
}

/***********************
* Pricing Six
***********************/
.pricing-six {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 109px 0 120px;

  .pricing-six__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .pricing-six__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .pricing-six__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .pricing-six__inner {
    position     : relative;
    display      : block;
    background   : #ffffff;
    border       : 1px solid #dde1fd;
    border-radius: 20px;

    .row {
      --bs-gutter-x: 0px;
    }
  }

  .pricing-six__single {
    position: relative;
    display : block;
    padding : 47px 35px 35px;
  }

  .pricing-six__single--two {
    border-left : 1px solid #dde1fd;
    border-right: 1px solid #dde1fd;
  }

  .pricing-six__top {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .pricing-six__pack-name {
    font-size    : 26px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 26px;
    margin-bottom: 21px;
  }

  .pricing-six__price {
    font-size  : 50px;
    font-weight: 800;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 50px;

    span {
      position   : relative;
      font-size  : 20px;
      font-weight: 500;
      left       : -10px;
    }
  }

  .pricing-six__save {
    position     : relative;
    display      : inline-block;
    font-size    : 14px;
    font-weight  : 400;
    line-height  : 14px;
    color        : var(--customVar-black);
    margin       : 0;
    background   : #ebedff;
    border-radius: 13px;
    padding      : 6px 15px;
  }

  .pricing-six__text {
    margin       : 0;
    margin-top   : 5px;
    margin-bottom: 25px;
  }

  .pricing-six__btn-box {
    position: relative;
    display : block;
  }

  .pricing-six__btn {
    width          : 100%;
    color          : var(--customVar-white);
    text-align     : center;
    justify-content: center;

    &::before {
      background-color: var(--customVar-secondary);
    }
  }

  .pricing-six__points {
    position  : relative;
    display   : block;
    margin    : 0;
    margin-top: 30px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }
}

/***********************
* Pay Less
***********************/
.pay-less {
  position  : relative;
  display   : block;
  background: #fafbff;
  padding   : 109px 0 120px;

  .pay-less__inner {
    position: relative;
    display : block;
    margin  : 0 60px;
  }

  .pay-less__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .pay-less__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .pay-less__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .pay-less__left {
    position: relative;
    display : block;
  }

  .pay-less__list {
    position: relative;
    display : block;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 20px;
      }

      .icon {
        position          : relative;
        display           : flex;
        align-items       : center;
        justify-content   : center;
        height            : 50px;
        width             : 50px;
        background        : #f0f5ff;
        border-radius     : 50%;
        font-size         : 15px;
        color             : var(--customVar-secondary);
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
      }

      &:hover .icon {
        background-color: var(--customVar-black);
        color           : var(--customVar-white);
      }

      .text {
        margin-left: 30px;

        p {
          font-size  : 21px;
          color      : var(--customVar-black);
          font-weight: 600;
          font-family: var(--customVar-title-font);
          margin     : 0;
        }
      }
    }
  }

  .pay-less__btn-box {
    position  : relative;
    display   : block;
    text-align: center;
    margin-top: 39px;
  }

  .pay-less__btn {
    color             : var(--customVar-white);
    padding           : 14px 35px;
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }
  }
}

/***********************
* Database
***********************/
.database {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 109px 0 110px;
  overflow  : hidden;
  z-index   : 1;

  .database__shape-1 {
    position: absolute;
    top     : 0;
    left    : 0;

    img {
      width: auto;
    }
  }

  .database__shape-2 {
    position: absolute;
    top     : 0;
    right   : 0;

    img {
      width: auto;
    }
  }

  .database__shape-3 {
    position: absolute;
    bottom  : 445px;
    right   : 115px;

    img {
      width: auto;
    }
  }

  .database__shape-4 {
    position: absolute;
    bottom  : 85px;
    right   : 0;

    img {
      width: auto;
    }
  }

  .database__shape-5 {
    position: absolute;
    bottom  : 0;
    left    : 185px;

    img {
      width: auto;
    }
  }

  .database__title {
    position     : relative;
    display      : block;
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    text-align   : center;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 44px;
  }

  .database__table-responsive {
    position  : relative;
    display   : block;
    width     : 100%;
    overflow-x: auto;
  }

  .database__table {
    position : relative;
    margin   : 0;
    min-width: 1320px;
    width    : 100%;

    thead {
      tr {
        position        : relative;
        text-align      : center;
        background-color: var(--customVar-white);
      }

      th {
        padding       : 0;
        border        : none;
        font-size     : 20px;
        font-weight   : 600;
        color         : var(--customVar-black);
        font-family   : var(--customVar-title-font);
        text-align    : center;
        vertical-align: middle;
        padding       : 24px 0;
      }
    }

    tbody {
      position        : relative;
      background-color: transparent;

      tr {
        position  : relative;
        text-align: center;

        &:nth-child(even) {
          background-color: #475eb9;
        }

        &:last-child {
          border-bottom: 0;
        }

        td {
          font-size       : 18px;
          font-weight     : 400;
          border          : 0;
          padding         : 24px 0;
          vertical-align  : middle;
          color           : var(--customVar-white);
          background-color: transparent;

          &:first-child {
            text-align  : left;
            padding-left: 40px;
          }

          &:last-child {
            text-align   : right;
            padding-right: 40px;
          }
        }
      }
    }
  }

  .database__btn {
    position      : relative;
    display       : inline-block;
    font-size     : 18px;
    color         : var(--hsoutp-white);
    font-weight   : 600;
    letter-spacing: 0.01em;
    font-family   : var(--customVar-title-font);

    &:before {
      content         : "";
      position        : absolute;
      bottom          : 6px;
      left            : 0;
      right           : 39px;
      height          : 1px;
      background-color: var(--customVar-white);
    }

    i {
      position   : relative;
      display    : inline-block;
      font-size  : 15px;
      top        : 1px;
      margin-left: 10px;
    }
  }

  .database__bottom-text {
    font-size : 18px;
    color     : var(--customVar-white);
    text-align: center;
    margin    : 0;
    margin-top: 40px;
  }
}

/***********************
* Feature Three
***********************/
.feature-three {
  position: relative;
  display : block;
  padding : 109px 0 90px;
  z-index : 1;

  .feature-three__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .feature-three__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .feature-three__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .feature-three__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .feature-three .container {
    max-width: 1136px;
  }

  .feature-three__single {
    position     : relative;
    display      : flex;
    align-items  : center;
    flex-wrap    : wrap;
    background   : #ffffff;
    border-radius: 20px;
    margin-bottom: 30px;
    padding      : 57px 35px 50px;
  }

  .feature-three__icon {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 90px;
    width          : 90px;
    background     : #f0f5ff;
    border-radius  : 20px;

    img {
      width: auto;
    }
  }

  .feature-three__content {
    position   : relative;
    display    : block;
    max-width  : 335px;
    width      : 100%;
    margin-left: 35px;
  }

  .feature-three__title {
    font-size    : 22px;
    color        : var(--customVar-black);
    font-weight  : 600;
    margin       : 0;
    line-height  : 22px;
    margin-bottom: 12px;
  }

  .feature-three__text {
    letter-spacing: -0.015em;
    margin        : 0;
  }
}

/***********************
* Competitors
***********************/
.competitors {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 120px;

  .competitors__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .competitors__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .competitors__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .competitors__table-responsive {
    position  : relative;
    display   : block;
    width     : 100%;
    overflow-x: auto;
  }

  .competitors__table {
    position : relative;
    margin   : 0;
    min-width: 1320px;
    width    : 100%;

    thead {
      tr {
        position  : relative;
        text-align: center;
        background: #eef0ff;
        border    : 1px solid #eef0ff;
      }

      th {
        padding       : 0;
        border        : none;
        font-size     : 20px;
        font-weight   : 600;
        color         : var(--customVar-black);
        font-family   : var(--customVar-title-font);
        text-align    : center;
        vertical-align: middle;
        padding       : 24px 0;
        background    : none;
      }
    }

    tbody {
      position        : relative;
      background-color: transparent;

      tr {
        position    : relative;
        text-align  : center;
        border-left : 1px solid #dde1fd;
        border-right: 1px solid #dde1fd;

        &:last-child {
          border-bottom: 1px solid #dde1fd;
        }

        td {
          font-size     : 18px;
          font-weight   : 400;
          border        : 0;
          padding       : 24px 0;
          vertical-align: middle;
          color         : var(--customVar-text);
          border-right  : 1px solid #dde1fd;

          &:first-child {
            font-weight : 600;
            color       : var(--customVar-black);
            text-align  : left;
            padding-left: 60px;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

/***********************
* Database Two
***********************/
.database-two {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 109px 0 0;
  z-index   : 1;

  &:before {
    content         : "";
    position        : absolute;
    bottom          : 0;
    left            : 0;
    right           : 0;
    height          : 250px;
    background-color: #fafbff;
    z-index         : -1;
  }

  .database-two__shape {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: top center;
    z-index            : -1;
  }

  .database-two .container {
    max-width: 1140px;
  }

  .database-two-top__title {
    position     : relative;
    display      : block;
    text-align   : center;
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 39px;
  }

  .database-two__single {
    position     : relative;
    display      : block;
    text-align   : center;
    background   : #ffffff;
    box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding      : 40px 50px 28px;
    margin-bottom: 30px;
  }

  .database-two__icon {
    position: relative;
    display : block;
  }

  .database-two__title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 35px;
    margin-top   : 24px;
    margin-bottom: 10px;
  }

  .database-two__text {
    letter-spacing: -0.015em;
    margin        : 0;
  }

  .database-two__read-more {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    font-weight    : 600;
    font-size      : 18px;
    line-height    : 150%;
    text-align     : center;
    letter-spacing : 0.01em;
    color          : #3852ff;
    font-family    : var(--customVar-title-font);
    margin-top     : 17px;

    span {
      position   : relative;
      font-size  : 14px;
      top        : 1px;
      margin-left: 10px;
    }
  }
}



@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .vps-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .vps-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-six {
    .pricing-six__single {
      padding: 47px 15px 35px;
    }
  }

  .feature-three {
    .feature-three__single {
      flex-direction: column;
      align-items   : baseline;
    }

    .feature-three__content {
      margin-left: 0;
      margin-top : 20px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .vps-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .vps-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-six {
    .pricing-six__single {
      padding: 47px 15px 35px;
    }

    .pricing-six__section-title__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .pricing-six__section-title__title br {
      display: none;
    }
  }

  .pay-less {
    .pay-less__inner {
      margin: 0 0px;
    }

    .pay-less__section-title__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .pay-less__section-title__title br {
      display: none;
    }
  }

  .feature-three {
    .feature-three__single {
      flex-direction: column;
      align-items   : baseline;
    }

    .feature-three__content {
      margin-left: 0;
      margin-top : 20px;
    }
  }

  .competitors {
    .competitors__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .database-two {
    .database-two-top__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }
}