/***********************
* Page Banner Four
***********************/
.page-banner-four {
  background-image   : url(../../../images/template/background/page-header-1-3-bg.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  padding-top        : 160px;
  padding-bottom     : 83px;

  .page-banner-four__left {
    position  : relative;
    display   : block;
    margin-top: 45px;
  }

  .page-banner-four__title {
    font-weight  : 700;
    font-size    : 60px;
    line-height  : 76px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 10px;
  }

  .page-banner-four__text {
    font-size    : 18px;
    line-height  : 32px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 30px;
  }

  .page-banner-four__form {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    max-width  : 590px;
    width      : 100%;
    z-index    : 2;
  }

  .page-banner-four__input-box {
    position : relative;
    display  : block;
    max-width: 590px;
    width    : 100%;

    input[type="text"] {
      outline           : none;
      -webkit-appearance: none;
      -moz-appearance   : none;
      appearance        : none;
      background        : rgba(255, 255, 255, 1);
      border            : 1.5px solid #e9e9e9;
      border-radius     : 10px;
      display           : block;
      width             : 100%;
      height            : 60px;
      color             : var(--customVar-text);
      font-size         : 16px;
      font-weight       : 400;
      padding-left      : 25px;
      padding-right     : 176px;
    }
  }

  .page-banner-four__btn {
    padding        : 0;
    color          : var(--customVar-white);
    margin-left    : 0;
    position       : absolute;
    top            : 0;
    bottom         : 0;
    right          : 0;
    height         : 60px;
    width          : 60px;
    display        : flex;
    align-items    : center;
    justify-content: center;

    &:before {
      background-color: var(--customVar-secondary);
    }

    span {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: center;
    }

    i {
      font-size         : 20px;
      color             : inherit;
      margin-left       : 0;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;
    }
  }

  .page-banner-four__name {
    display          : -webkit-inline-box;
    display          : -ms-inline-flexbox;
    display          : inline-flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    position         : absolute;
    top              : 50%;
    right            : 90px;
    transform        : translateY(-50%);
    border-left      : 1.5px solid #e9e9e9;
    padding-left     : 20px;
    height           : 30px;

    .dropdown-toggle {
      font-size  : 16px;
      font-family: var(--customVar-body-font);
      font-weight: 400;
      color      : var(--customVar-black);

      &::after {
        margin-left: 8px;
        font-size  : 17px;
        position   : relative;
        top        : 2px;
      }
    }
  }

  .page-banner-four__bottom {
    position      : relative;
    display       : block;
    padding-top   : 30px;
    padding-bottom: 27px;
  }

  .page-banner-four__list {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    margin     : 0;

    li {
      position : relative;
      flex     : 0 0 20%;
      max-width: 20%;
      width    : 100%;

      +li {
        margin-left: 2px;
      }

      &:nth-child(2) .page-banner-four__type {
        color: #00c14d;
      }

      &:nth-child(3) .page-banner-four__type {
        color: #ff5a74;
      }

      &:nth-child(4) .page-banner-four__type {
        color: #ffa800;
      }
    }
  }

  .page-banner-four__type {
    font-size     : 20px;
    letter-spacing: -0.015em;
    color         : #0ea5ff;
    font-weight   : 700;
    line-height   : 20px;
    margin        : 0;
  }

  .page-banner-four__price {
    margin        : 0;
    letter-spacing: -0.015em;
    color         : var(--customVar-white);
  }

  .page-banner-four__bottom-text {
    font-size  : 18px;
    line-height: 32px;
    color      : #ffffff;
    margin     : 0;

    a {
      position   : relative;
      display    : inline-block;
      color      : #ffffff;
      font-weight: 700;

      &:before {
        content         : "";
        position        : absolute;
        bottom          : 6px;
        left            : 0;
        right           : 0;
        height          : 1px;
        background-color: var(--customVar-white);
      }
    }
  }

  .page-banner-four__right {
    position   : relative;
    display    : block;
    margin-left: 121px;
  }

  .page-banner-four__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/***********************
* Buy Domain
***********************/
.buy-domain {
  position  : relative;
  display   : block;
  background: #f5f7ff;
  padding   : 109px 0 90px;

  .buy-domain__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .buy-domain__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .buy-domain__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .buy-domain__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    border-radius: 20px;
    padding      : 30px 30px 30px;
    margin-bottom: 30px;
  }

  .buy-domain__tag {
    position       : absolute;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : #000d61;
    border-radius  : 0px 20px 0px 15px;
    max-width      : 140px;
    width          : 100%;
    top            : 0;
    right          : 0;
  }

  .buy-domain__tag-icon {
    position: relative;
    display : block;
    top     : -2px;

    img {
      width: auto;
    }
  }

  .buy-domain__tag-text {
    font-size     : 11px;
    margin        : 0;
    line-height   : 11px;
    color         : var(--customVar-white);
    font-weight   : 500;
    text-transform: uppercase;
    margin-left   : 5px;
  }

  .buy-domain__title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : #ff8383;
    margin       : 0;
    margin-bottom: 8px;
  }

  .buy-domain__text {
    letter-spacing: -0.015em;
    line-height   : 26px;
    margin        : 0;
    margin-bottom : 30px;
  }

  .buy-domain__price-box {
    font-weight: 800;
    font-size  : 50px;
    line-height: 60px;
    color      : #121212;
    margin     : 0;
  }

  .buy-domain__price-point {
    font-size: 20px;
    position : relative;
    left     : -10px;
  }

  .buy-domain__price-validity {
    font-size  : 20px;
    font-weight: 500;
    position   : relative;
    left       : -19px;
  }

  .buy-domain__save {
    color        : #2959eb;
    font-size    : 16px;
    line-height  : 16px;
    margin       : 0;
    font-weight  : 400;
    position     : relative;
    display      : block;
    margin-top   : -4px;
    margin-bottom: 32px;
  }

  .buy-domain__btn {
    width          : 100%;
    color          : var(--customVar-white);
    text-align     : center;
    justify-content: center;

    &::before {
      background-color: var(--customVar-secondary);
    }
  }

  .buy-domain__title--online {
    color: #f7c64b;
  }

  .buy-domain__title--store {
    color: #93db7a;
  }

  .buy-domain__title--info {
    color: #63ead2;
  }

  .buy-domain__title--co {
    color: #59afff;
  }

  .buy-domain__title--org {
    color: #ff9fe4;
  }

  .buy-domain__title--net {
    color: #b28dff;
  }

  .buy-domain__title--live {
    color: #ffb571;
  }

  .buy-domain__title--tech {
    color: #86a8ff;
  }

  .buy-domain__title--social {
    color: #f0e940;
  }

  .buy-domain__title--shop {
    color: #e09fff;
  }

  .buy-domain__title--site {
    color: #87ffb7;
  }
}

/***********************
* Benefits One
***********************/
.benefits-one {
  position        : relative;
  display         : block;
  padding         : 109px 0 70px;
  background-color: #fafbff;

  .benefits-one-top__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    text-align   : center;
    margin       : 0;
    margin-bottom: 42px;
  }

  .benefits-one__single {
    position     : relative;
    display      : block;
    margin-bottom: 43px;

    &:hover .benefits-one__icon {
      background-color: var(--customVar-black);
      color           : var(--customVar-white);
    }
  }

  .benefits-one__icon {
    position          : relative;
    display           : flex;
    align-items       : center;
    justify-content   : center;
    height            : 70px;
    width             : 70px;
    background        : #f0f5ff;
    border-radius     : 20px;
    color             : var(--customVar-secondary);
    font-size         : 17px;
    margin-bottom     : 27px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }

  .benefits-one__title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 22px;
    margin-bottom: 15px;
  }

  .benefits-one__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }
}

/***********************
* Feature Nine
***********************/
.feature-nine {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 70px;

  .feature-nine__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 60px;
  }

  .feature-nine__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .feature-nine__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .feature-nine__single {
    position     : relative;
    display      : block;
    margin-bottom: 50px;
  }

  .feature-nine__img-box {
    position    : relative;
    display     : block;
    margin-left : 170px;
    margin-right: 76px;

    img {
      width: 100%;
    }
  }

  .feature-nine__content-box {
    position    : relative;
    display     : block;
    margin-top  : 37px;
    margin-right: 165px;
  }

  .feature-nine__title {
    font-weight: 700;
    font-size  : 26px;
    line-height: 33px;
    color      : #121212;
    margin     : 0;
  }

  .feature-nine__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
    margin-top    : 16px;
    margin-bottom : 16px;
  }

  .feature-nine__text-2 {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
    margin-bottom : 26px;
  }

  .feature-nine__read-more {
    position          : relative;
    display           : flex;
    align-items       : center;
    font-weight       : 600;
    font-size         : 18px;
    line-height       : 18px;
    text-align        : center;
    letter-spacing    : 0.01em;
    color             : #3852ff;
    font-family       : var(--customVar-title-font);
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    span {
      position    : relative;
      display     : inline-block;
      font-size   : 13px;
      padding-left: 10px;
      top         : 2px;
    }

    &:hover {
      color: var(--customVar-black);
    }
  }

  .feature-nine__single--two {
    .feature-nine__content-box {
      margin-top  : 52px;
      margin-right: 0;
      margin-left : 173px;
    }

    .feature-nine__img-box {
      margin-left : 105px;
      margin-right: 200px;
    }
  }

  .feature-nine__single--three {
    .feature-nine__img-box {
      margin-left : 209px;
      margin-right: 120px;
    }

    .feature-nine__content-box {
      margin-top: 53px;
    }
  }
}

/***********************
* Buy Domain Two
***********************/
.buy-domain-two {
  position: relative;
  display : block;
  padding : 104px 0 105px;
  z-index : 1;

  .buy-domain-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .buy-domain-two__left {
    position: relative;
    display : block;
  }

  .buy-domain-two__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : #121212;
    margin     : 0;
  }

  .buy-domain-two__text {
    font-size    : 18px;
    line-height  : 32px;
    margin       : 0;
    margin-top   : 21px;
    margin-bottom: 30px;
  }

  .buy-domain-two__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .buy-domain-two__right {
    position   : relative;
    display    : block;
    margin-left: 50px;
  }

  .buy-domain-two__points {
    position  : relative;
    display   : block;
    margin-top: 5px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 14px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          font-size: 18px;
          color    : var(--customVar-black);
          margin   : 0;
        }
      }
    }
  }
}



/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .page-banner-four {
    padding-bottom: 120px;

    .page-banner-four__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .page-banner-four__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }

  .feature-nine {
    .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }

    .feature-nine__single--two .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }

    .feature-nine__single--three .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }
  }

  .buy-domain-two {
    .buy-domain-two__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .page-banner-four {
    padding-bottom: 120px;


    .page-banner-four__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .page-banner-four__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }

  .feature-nine {
    .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }

    .feature-nine__content-box {
      margin-right: 0;
    }

    .feature-nine__single--two {
      .feature-nine__content-box {
        margin-left: 0;
      }

      .feature-nine__img-box {
        margin-left : 0;
        margin-right: 0;
      }
    }

    .feature-nine__single--three .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }
  }

  .buy-domain-two {
    .buy-domain-two__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .page-banner-four {
    .page-banner-four__left {
      margin-top: 0;
    }

    .page-banner-four__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .page-banner-four__list li {
      flex     : 0 0 100%;
      max-width: 100%;

      +li {
        margin-left: 0;
        margin-top : 20px;
      }
    }

    .page-banner-four__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .page-banner-four__text br {
      display: none;
    }
  }

  .buy-domain {
    .buy-domain__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .feature-nine {
    .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }

    .feature-nine__content-box {
      margin-right: 0;
    }

    .feature-nine__single--two {
      .feature-nine__content-box {
        margin-left: 0;
      }

      .feature-nine__img-box {
        margin-left : 0;
        margin-right: 0;
      }
    }

    .feature-nine__single--three .feature-nine__img-box {
      margin-left : 0;
      margin-right: 0;
    }

    .feature-nine__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .buy-domain-two {
    .buy-domain-two__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .buy-domain-two__title {
      font-size  : 30px;
      line-height: 40px;
    }
  }
}