/********************************************************
* Blog single
*********************************************************/
.blog-single {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 120px 0 120px;
  z-index         : 1;

  .blog-single__left {
    position: relative;
    display : block;
  }

  .blog-single__img {
    position: relative;
    display : block;

    img {
      width        : 100%;
      border-radius: 20px;
    }
  }

  .blog-single__user-and-date {
    position     : relative;
    display      : flex;
    align-items  : center;
    margin-top   : 30px;
    margin-bottom: 7px;
  }

  .blog-single__user {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .blog-single__user-img {
    position     : relative;
    display      : block;
    height       : 35px;
    width        : 35px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-single__user-name {
    font-size     : 12px;
    color         : var(--customVar-text);
    font-weight   : 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin        : 0;
    line-height   : 12px;
    margin-left   : 10px;
  }

  .blog-single__date {
    margin        : 0;
    margin-left   : 34px;
    font-weight   : 500;
    font-size     : 12px;
    letter-spacing: 0.1em;
    position      : relative;

    span {
      position               : relative;
      display                : inline-block;
      font-size              : 18px;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      top                    : 4px;
      margin-right           : 10px;
    }
  }

  .blog-single__title {
    font-weight: 500;
    font-size  : 26px;
    line-height: 36px;
    color      : #141414;
  }

  .blog-single__text-1 {
    font-weight   : 400;
    font-size     : 16px;
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : #666666;
    margin        : 0;
  }

  .blog-single__text-2 {
    margin: 26px 0 21px;
  }

  .blog-single__text-4 {
    margin: 21px 0 33px;
  }

  .blog-single__quote-box {
    position        : relative;
    display         : flex;
    align-items     : center;
    background-color: #121b5e;
    padding         : 58px 75px 52px;
  }

  .blog-single__quote-icon {
    position: relative;
    display : inline-block;

    span {
      position : relative;
      display  : inline-block;
      font-size: 65px;
      color    : var(--customVar-white);
    }
  }

  .blog-single__quote-text {
    margin        : 0;
    font-weight   : 400;
    font-size     : 20px;
    line-height   : 32px;
    letter-spacing: -0.015em;
    margin-left   : 35px;
    color         : var(--customVar-white);
  }

  .blog-single__text-5 {
    margin: 24px 0 21px;
  }

  .blog-single__pagenation {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 47px 0 49px;
    border-bottom  : 1px solid #eaedf8;
    border-top     : 1px solid #eaedf8;
    margin-top     : 36px;
    margin-bottom  : 52px;
  }

  .blog-single__pagenation-left {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
  }

  .blog-single__pagenation-left-img {
    position     : relative;
    display      : block;
    width        : 60px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-single__pagenation-left-content {
    margin-left: 17px;
  }

  .blog-single__pagenation-left-title {
    font-size    : 16px;
    line-height  : 21px;
    margin-bottom: 5px;
    font-weight  : 500;

    a {
      color             : #141414;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .blog-single__pagenation-btn {
    position: relative;
    display : block;

    a {
      position          : relative;
      display           : flex;
      align-items       : center;
      font-weight       : 500;
      font-size         : 14px;
      line-height       : 24px;
      letter-spacing    : -0.015em;
      color             : var(--customVar-text);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);

        span {
          color: var(--customVar-base);
        }
      }

      span {
        position          : relative;
        display           : inline-block;
        transform         : rotate(-180deg);
        font-size         : 12px;
        color             : var(--customVar-text);
        margin-right      : 10px;
        top               : 1px;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
      }
    }
  }

  .blog-single__pagenation-right {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: end;
  }

  .blog-single__pagenation-right-content {
    position  : relative;
    display   : block;
    text-align: right;
  }

  .blog-single__pagenation-right-title {
    font-size    : 16px;
    line-height  : 21px;
    margin-bottom: 5px;
    font-weight  : 500;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .blog-single__pagenation-btn-2 {
    position: relative;
    display : block;

    a {
      position          : relative;
      display           : flex;
      align-items       : center;
      justify-content   : end;
      font-weight       : 500;
      font-size         : 14px;
      line-height       : 24px;
      letter-spacing    : -0.015em;
      color             : var(--customVar-text);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);

        span {
          color: var(--customVar-base);
        }
      }

      span {
        position          : relative;
        display           : inline-block;
        font-size         : 12px;
        color             : var(--customVar-text);
        margin-left       : 10px;
        top               : 1px;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
      }
    }
  }

  .blog-single__pagenation-right-img {
    position     : relative;
    display      : block;
    width        : 60px;
    margin-left  : 17px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-single__social-box {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .blog-single__social {
    margin       : 0;
    display      : -webkit-box;
    display      : -ms-flexbox;
    display      : flex;
    -ms-flex-wrap: wrap;
    flex-wrap    : wrap;

    li {
      +li {
        margin-left: 18px;
      }

      a {
        width             : 45px;
        height            : 45px;
        border            : 2px solid #666666;
        border-radius     : 10px;
        display           : -webkit-box;
        display           : -ms-flexbox;
        display           : flex;
        -webkit-box-pack  : center;
        -ms-flex-pack     : center;
        justify-content   : center;
        -webkit-box-align : center;
        -ms-flex-align    : center;
        align-items       : center;
        text-align        : center;
        color             : #666666;
        font-size         : 18px;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;

        &:hover {
          background-color: var(--customVar-base);
          border          : 2px solid transparent;
          color           : var(--customVar-black, #121212);
        }
      }
    }
  }

  .blog-single__social-title {
    margin     : 0 0 6px;
    text-align : center;
    font-weight: 500;
    font-size  : 16px;
    line-height: 26px;
    color      : #141414;
  }

  .blog-single__comment-and-form {
    position: relative;
    display : block;
  }
}

.comment-one__title,
.comment-form__title {
  margin       : 0;
  margin-bottom: 26px;
  font-weight  : 600;
  font-size    : 22px;
  line-height  : 32px;
  color        : #141414;
}

.comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  +.comment-one__single {
    margin-top: 30px;
  }
}

.comment-one__single-2 {
  margin-left: 100px;
}

.comment-one__content {
  position     : relative;
  display      : block;
  margin-left  : 30px;
  border       : 2px solid #eaecf8;
  border-radius: 10px;
  max-width    : 770px;
  width        : 100%;
  padding      : 26px 32px 32px;

  h3 {
    margin     : 0;
    font-weight: 500;
    font-size  : 20px;
    line-height: 30px;
    color      : #121212;

    span {
      font-weight   : 500;
      font-size     : 16px;
      line-height   : 16px;
      letter-spacing: -0.015em;
      color         : var(--customVar-text);
      margin-left   : 15px;
    }
  }

  p {
    margin        : 7px 0 0;
    font-weight   : 400;
    line-height   : 26px;
    letter-spacing: -0.015em;
  }
}

.comment-one__btn {
  position          : absolute;
  top               : 30px;
  right             : 28px;
  font-size         : 18px;
  line-height       : 23px;
  color             : #3852ff;
  font-family       : var(--customVar-title-font);
  font-weight       : 500;
  -webkit-transition: all 500ms ease;
  transition        : all 500ms ease;

  &:hover {
    color: var(--customVar-base);
  }

  span {
    position    : relative;
    display     : inline-block;
    font-size   : 15px;
    margin-right: 8px;
  }
}

.comment-one__image {
  position     : relative;
  display      : block;
  border-radius: 50%;

  img {
    width        : auto;
    border-radius: 50%;
  }
}


.comment-form {
  position   : relative;
  display    : block;
  padding-top: 50px;

  .row {
    --bs-gutter-x: 15px;
  }
}

.comment-form__input-box {
  position     : relative;
  display      : block;
  margin-bottom: 15px;

  input[type="text"],
  input[type="email"] {
    height            : 60px;
    width             : 100%;
    border            : 1px solid #eaedf8;
    background-color  : var(--customVar-white);
    outline           : none;
    padding-left      : 20px;
    padding-right     : 20px;
    font-size         : 16px;
    font-weight       : 400;
    letter-spacing    : -0.015em;
    color             : var(--customVar-text);
    display           : block;
    border-radius     : 10px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }

  textarea {
    font-size         : 16px;
    font-weight       : 400;
    letter-spacing    : -0.015em;
    color             : var(--customVar-text);
    height            : 140px;
    width             : 100%;
    border            : 1px solid #eaedf8;
    background-color  : var(--customVar-white);
    padding           : 12px 20px 30px;
    outline           : none;
    margin-bottom     : 0px;
    border-radius     : 10px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }

  &.text-message-box {
    height: 140px;
  }
}

.comment-one__form .checked-box {
  position     : relative;
  display      : block;
  min-height   : 30px;
  margin-top   : 24px;
  margin-bottom: 36px;

  label {
    position      : relative;
    display       : inline-block;
    padding-left  : 40px;
    margin-right  : 0px;
    margin-bottom : 0;
    color         : var(--customVar-text);
    font-size     : 16px;
    font-weight   : 400;
    cursor        : pointer;
    min-height    : 30px;
    letter-spacing: -0.015em;

    span:before {
      content                 : "";
      position                : absolute;
      top                     : 3px;
      left                    : 6px;
      display                 : block;
      border-bottom           : 2px solid #eaedf8;
      border-right            : 2px solid #eaedf8;
      width                   : 8px;
      height                  : 13px;
      pointer-events          : none;
      -webkit-transform-origin: 66% 66%;
      -ms-transform-origin    : 66% 66%;
      transform-origin        : 66% 66%;
      -webkit-transform       : rotate(45deg);
      -ms-transform           : rotate(45deg);
      transform               : rotate(45deg);
      -webkit-transition      : all 0.15s ease-in-out;
      transition              : all 0.15s ease-in-out;
      opacity                 : 0;
    }
  }

  input[type="checkbox"] {
    display: none;

    +label span {
      position          : absolute;
      top               : 3px;
      left              : 0;
      width             : 25px;
      height            : 25px;
      vertical-align    : middle;
      border            : 1px solid #eaedf8;
      border-radius     : 5px;
      cursor            : pointer;
      -webkit-transition: all 300ms ease;
      -moz-transition   : all 300ms ease;
      -ms-transition    : all 300ms ease;
      -o-transition     : all 300ms ease;
      transition        : all 300ms ease;
    }

    &:checked+label span {
      border-color: #eaedf8;

      &:before {
        opacity: 1;
      }
    }
  }
}

.comment-form__btn {
  color  : var(--customVar-white);
  padding: 14px 50px;

  &::before {
    background-color: var(--customVar-secondary);
  }
}



/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .blog-single {
    .blog-single__pagenation {
      flex-direction: column;
    }

    .blog-single__social-box {
      position     : relative;
      top          : 0;
      left         : 0;
      transform    : translateX(0%) translateY(0%);
      margin-bottom: 50px;
    }

    .blog-single__pagenation-left {
      margin-bottom: 30px;
    }
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .blog-single {
    .blog-single__user-and-date {
      flex-direction: column;
      align-items   : baseline;
    }

    .blog-single__date {
      margin-left: 0;
      margin-top : 10px;
    }

    .blog-single__quote-box {
      padding       : 58px 30px 52px;
      flex-direction: column;
      text-align    : center;
    }

    .blog-single__quote-text {
      margin-left: 0;
    }

    .blog-single__pagenation {
      flex-direction: column;
    }

    .blog-single__social-box {
      position     : relative;
      top          : 0;
      left         : 0;
      transform    : translateX(0%) translateY(0%);
      margin-bottom: 50px;
    }

    .blog-single__pagenation-left {
      margin-bottom: 30px;
    }
  }

  .comment-one__single {
    flex-direction: column;
  }

  .comment-one__content {
    margin-left: 0;
    margin-top : 20px;
    padding    : 26px 20px 32px;
  }

  .comment-one__btn {
    position   : relative;
    display    : block;
    top        : 0;
    right      : 0;
    padding-top: 10px;
  }

  .comment-one__single-2 {
    margin-left: 0;
  }
}