/***********************
*  Banner One
***********************/
.banner-one {
  background-image   : url(../../images/background/banner-one-bg.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  padding-top        : 221px;
  padding-bottom     : 90px;

  &.no-image {
    padding-top: 100px;
  }


  .banner-one__left {
    position  : relative;
    display   : block;
    margin-top: 115px;
  }

  .banner-one__tagline {
    margin       : 0;
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 400;
    font-size    : 16px;
    line-height  : 1.78em;
    color        : #ffffff;
    margin-bottom: 4px;
  }

  .banner-one__sub-title-box {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .banner-one__sub-title-icon {
    position   : relative;
    display    : flex;
    align-items: center;

    span {
      font-size: 20px;
      color    : #00c14d;
      position : relative;
      display  : inline-block;
    }
  }

  .banner-one__sub-title {
    color      : var(--customVar-white);
    margin     : 0;
    margin-left: 10px;
  }

  .banner-one__title {
    font-weight  : 700;
    font-size    : 60px;
    line-height  : 76px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-top   : 17px;
    margin-bottom: 15px;
  }

  .banner-one__text {
    color        : var(--customVar-white);
    margin       : 0;
    line-height  : 32px;
    margin-bottom: 30px;

    .count-text {
      font-size  : 18px;
      font-weight: 700;
    }
  }

  .banner-one__right {
    position: relative;
    display : block;
  }

  .banner-one__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

.banner-one__btn {
  color             : var(--customVar-white);
  -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
  box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

  &:hover {
    color: var(--customVar-black);
  }

  &:before {
    background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
  }

  &:after {
    background-color: var(--customVar-white);
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .banner-one {
    &.no-image {
      padding-top: 120px;
    }

    .banner-one__left {
      margin-top: 0;
    }

    .banner-one__title {
      font-size  : 48px;
      line-height: 58px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .banner-one {
    .banner-one__left {
      margin-top: 0;
    }

    .banner-one__right {
      margin-top: 50px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .banner-one {
    padding-top: 163px;

    &.no-image {
      padding-top: 80px;
    }

    .banner-one__left {
      margin-top: 0;
    }

    .banner-one__right {
      margin-top: 50px;
    }

    .banner-one__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .banner-one__text br {
      display: none;
    }
  }
}