/***********************
* Services Page Why Choose
***********************/
.services-page-why-choose {
  margin-top      : 0;
  padding         : 0px 0 110px;
  background-color: #fafbff;

  .why-choose-two__bg {
    display: none;
  }

  .why-choose-two__top-title {
    color: var(--customVar-black);
  }
}