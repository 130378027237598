.page-wrapper {
  overflow : hidden;
  position : relative;
  margin   : 0 auto;
  width    : 100%;
  height   : 100%;
  min-width: 300px;
  overflow : hidden;
}

.container {
  padding-left : 15px;
  padding-right: 15px;
}

@media (min-width: $breakpoint-xl) {
  .container {
    max-width: 1350px;
  }
}

.row {
  --bs-gutter-x: 30px;
}

.striped-bg {
  background-image   : url(../images/background/striped-bg.jpg);
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;
}

// .gutter-y-30 {
//   --bs-gutter-y: 30px;
// }

// .gutter-y-40 {
//   --bs-gutter-y: 40px;
// }

// .gutter-y-50 {
//   --bs-gutter-y: 50px;
// }

// .gutter-y-60 {
//   --bs-gutter-y: 60px;
// }

// .google-map iframe {
//   border : none;
//   display: block;
//   width  : 100%;
//   height : 480px;
// }

// .datepicker {
//   padding: 10px;
// }

// .datepicker .datepicker-switch:hover,
// .datepicker .next:hover,
// .datepicker .prev:hover,
// .datepicker tfoot tr th:hover {
//   background-color: var(--customVar-gray, #c2c2c2);
// }

// .datepicker thead td,
// .datepicker thead th {
//   font-size  : 18px;
//   font-family: var(--customVar-title-font, "Outfit", sans-serif);
//   font-weight: 700;
//   color      : var(--customVar-black, #121212);
// }

// .datepicker table tr td {
//   color      : var(--customVar-black, #121212);
//   padding    : 10px;
//   font-size  : 16px;
//   font-family: var(--customVar-body-font, "Inter", sans-serif);
//   line-height: 1em;
//   font-weight: 400;
// }

// .datepicker table tr td.new,
// .datepicker table tr td.old {
//   color: var(--customVar-text, #666666);
// }

// .owl-with-shadow .owl-stage-outer {
//   overflow: visible;
// }

// .owl-with-shadow .owl-item {
//   opacity           : 0;
//   visibility        : hidden;
//   -webkit-transition: opacity 500ms ease, visibility 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease;
// }

// .owl-with-shadow .owl-item.active {
//   opacity   : 1;
//   visibility: visible;
// }

// .owl-dot-one .owl-dots {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-top       : 60px !important;
// }

// .owl-dot-one .owl-dots .owl-dot span {
//   background-color  : var(--customVar-base, #ffa065);
//   margin            : 0;
//   width             : 17px;
//   height            : 17px;
//   border-radius     : 50%;
//   position          : relative;
//   border            : 6px solid #fff;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// @media (min-width: $breakpoint-sm) {
//   .owl-dot-one .owl-dots .owl-dot span {
//     width       : 21px;
//     height      : 21px;
//     border-width: 7px;
//   }
// }

// .owl-dot-one .owl-dots .owl-dot.active span,
// .owl-dot-one .owl-dots .owl-dot:hover span {
//   background-color  : var(--customVar-base, #ffa065);
//   -webkit-box-shadow: 0 0 0 1px var(--customVar-base, #ffa065);
//   box-shadow        : 0 0 0 1px var(--customVar-base, #ffa065);
// }

// .owl-dot-one .owl-dots .owl-dot+.owl-dot span {
//   margin-left: 3px;
// }

.thm-btn {
  border            : none;
  outline           : none;
  -webkit-appearance: none;
  -moz-appearance   : none;
  appearance        : none;
  background-color  : #fff;
  border-radius     : 10px;
  font-weight       : 600;
  font-size         : 18px;
  font-family       : var(--customVar-title-font, "Outfit", sans-serif);
  padding           : 14px 30px;
  color             : var(--customVar-black, #121212);
  display           : inline-flex;
  -webkit-box-align : center;
  -ms-flex-align    : center;
  align-items       : center;
  overflow          : hidden;
  position          : relative;
  letter-spacing    : 0.01em;

  &::before {
    content                : "";
    position               : absolute;
    top                    : -50%;
    left                   : 50%;
    -webkit-transform      : translateX(-50%);
    transform              : translateX(-50%);
    width                  : 150%;
    height                 : 150%;
    border-top-left-radius : 50%;
    border-top-right-radius: 50%;
    background-color       : #fff;
    -webkit-transition     : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition             : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  &::after {
    content                           : "";
    position                          : absolute;
    bottom                            : 100%;
    left                              : 50%;
    -webkit-transform                 : translateX(-50%);
    transform                         : translateX(-50%);
    width                             : 150%;
    height                            : 150%;
    border-bottom-left-radius         : 50%;
    border-bottom-right-radius        : 50%;
    background-color                  : var(--customVar-black, #121212);
    -webkit-transition-duration       : 700ms;
    transition-duration               : 700ms;
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function        : cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  &:hover {
    color: #fff;
  }

  &:hover::before {
    top: 100%;
  }

  &:hover::after {
    bottom: -50%;
  }

  span {
    position: relative;
    color   : inherit;
    z-index : 2;
  }

  i {
    font-size         : 12px;
    color             : inherit;
    margin-left       : 9px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }
}

.close-btn {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
 
  color: var(--customVar-white);
  -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
  box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
  background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);

  -webkit-transition     : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition             : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);

  &:hover {
    background: linear-gradient(-43.72deg, #ff5a74 6.68%, #ffa065 76.23%);
  }
}

.gray-bg {
  background-color: #fafbff;
}

// .bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
// .bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
// .bootstrap-select .show>.btn-light.dropdown-toggle {
//   -webkit-box-shadow: none !important;
//   box-shadow        : none !important;
// }

// .bootstrap-select>.dropdown-toggle {
//   -webkit-box-shadow: none !important;
//   box-shadow        : none !important;
//   outline           : none !important;
//   outline-offset    : 0 !important;
//   border            : none;
//   background-color  : transparent;
//   border-color      : transparent;
//   padding           : 0;
// }

// .bootstrap-select>.dropdown-toggle::after {
//   margin     : 0;
//   border     : none;
//   content    : "\f107";
//   font-family: "Font Awesome 5 Free";
//   font-weight: 900;
//   font-size  : 14px;
// }

// .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
//   width: auto;
// }

// .bootstrap-select .dropdown-toggle:focus,
// .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
//   outline: none !important;
// }

// .bootstrap-select .dropdown-menu {
//   border        : 0;
//   padding-top   : 0;
//   padding-bottom: 0;
//   margin-top    : 0;
//   z-index       : 991;
//   border-radius : 0;
// }

// .bootstrap-select .dropdown-menu>li+li>a {
//   border-top: 1px solid rgba(255, 255, 255, 0.2);
// }

// .bootstrap-select .dropdown-menu>li.selected>a {
//   background: var(--customVar-black, #121212);
//   color     : #fff;
// }

// .bootstrap-select .dropdown-menu>li>a {
//   font-size         : 14px;
//   font-weight       : 400;
//   padding           : 4px 20px;
//   color             : #ffffff;
//   background        : var(--customVar-base, #ffa065);
//   -webkit-transition: all 0.4s ease;
//   transition        : all 0.4s ease;
//   display           : -webkit-inline-box;
//   display           : -ms-inline-flexbox;
//   display           : inline-flex;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
// }

// .bootstrap-select .dropdown-menu>li>a img {
//   margin-right: 10px;
// }

// .bootstrap-select .dropdown-menu>li>a:hover {
//   background: var(--customVar-black, #121212);
//   color     : #ffffff;
//   cursor    : pointer;
// }

// /***********************
// * Custom Animation
// ***********************/
// @-webkit-keyframes anime {
//   0% {
//     width             : 0px;
//     height            : 0px;
//     -webkit-box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
//     box-shadow        : inset 0 0 0 0px rgba(0, 0, 0, 0);
//   }

//   20% {
//     -webkit-box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
//     box-shadow        : inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
//   }

//   40% {
//     -webkit-box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
//     box-shadow        : inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
//   }

//   100% {
//     width             : 150px;
//     height            : 150px;
//     -webkit-box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
//     box-shadow        : inset 0 0 0 0px rgba(0, 0, 0, 0);
//   }
// }

// @keyframes anime {
//   0% {
//     width             : 0px;
//     height            : 0px;
//     -webkit-box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
//     box-shadow        : inset 0 0 0 0px rgba(0, 0, 0, 0);
//   }

//   20% {
//     -webkit-box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
//     box-shadow        : inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
//   }

//   40% {
//     -webkit-box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
//     box-shadow        : inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
//   }

//   100% {
//     width             : 150px;
//     height            : 150px;
//     -webkit-box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
//     box-shadow        : inset 0 0 0 0px rgba(0, 0, 0, 0);
//   }
// }

.float-bob-y {
  -webkit-animation-name           : float-bob-y;
  animation-name                   : float-bob-y;
  -webkit-animation-duration       : 2s;
  animation-duration               : 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count        : infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function        : linear;
}

.float-bob-x {
  -webkit-animation-name           : float-bob-x;
  animation-name                   : float-bob-x;
  -webkit-animation-duration       : 2s;
  animation-duration               : 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count        : infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function        : linear;
}

// /***********************
// * Subscribe
// ***********************/
// .subscribe-one {
//   padding-bottom: 80px;
//   position      : relative;
//   z-index       : 10;
// }

// .subscribe-one:before {
//   content         : "";
//   position        : absolute;
//   bottom          : 75px;
//   left            : 0;
//   right           : 0;
//   top             : 0;
//   background-color: #fafbff;
//   z-index         : -1;
// }

// @media (min-width: $breakpoint-xl) {
//   .subscribe-one {
//     padding-bottom: 0;
//     margin-bottom : -75px;
//   }
// }

// .subscribe-one__inner {
//   background        : linear-gradient(92.84deg, #ffa065 1.25%, #ff5a74 98.84%);
//   -webkit-box-shadow: 0px 50px 30px rgba(0, 8, 50, 0.08);
//   box-shadow        : 0px 50px 30px rgba(0, 8, 50, 0.08);
//   border-radius     : 20px;
// }

// .subscribe-one__top {
//   padding-top   : 20px;
//   padding-bottom: 30px;
//   padding-left  : 30px;
//   padding-right : 30px;
// }

// @media (min-width: $breakpoint-lg) {
//   .subscribe-one__top {
//     padding-top   : 43px;
//     padding-bottom: 43px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .subscribe-one__top {
//     padding-left : 50px;
//     padding-right: 50px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .subscribe-one__top .row {
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//   }
// }

// .subscribe-one__title {
//   margin     : 0;
//   font-size  : 25px;
//   color      : #fff;
//   font-weight: 700;
// }

// @media (min-width: $breakpoint-lg) {
//   .subscribe-one__title {
//     font-size: 30px;
//   }
// }

// .subscribe-one__btn {
//   margin-top: 9px;
// }

// .subscribe-one__form {
//   margin-top: 30px;
// }

// @media (min-width: $breakpoint-md) {
//   .subscribe-one__form {
//     display          : -webkit-box;
//     display          : -ms-flexbox;
//     display          : flex;
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//     -ms-flex-wrap    : wrap;
//     flex-wrap        : wrap;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .subscribe-one__form {
//     margin-top: 0;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .subscribe-one__form {
//     padding-left: 55px;
//   }
// }

// .subscribe-one__form input[type="text"],
// .subscribe-one__form input[type="email"] {
//   border            : none;
//   outline           : none;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : rgba(255, 255, 255, 0.1);
//   border            : 2px solid #ffffff;
//   border-radius     : 10px;
//   display           : block;
//   width             : 100%;
//   height            : 60px;
//   color             : #fff;
//   font-size         : 16px;
//   font-weight       : 400;
//   padding-left      : 20px;
//   padding-right     : 20px;
// }

// @media (min-width: $breakpoint-md) {

//   .subscribe-one__form input[type="text"],
//   .subscribe-one__form input[type="email"] {
//     max-width: 300px;
//   }
// }

// @media (min-width: $breakpoint-xl) {

//   .subscribe-one__form input[type="text"],
//   .subscribe-one__form input[type="email"] {
//     max-width: 394px;
//   }
// }

// @media (min-width: $breakpoint-md) {
//   .subscribe-one__form button[type="submit"] {
//     margin-top : 0;
//     margin-left: 9px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .subscribe-one__form button[type="submit"] {
//     padding: 14px 55.5px;
//   }
// }

// .subscribe-one__list {
//   border-top    : 1px solid rgba(255, 255, 255, 0.4);
//   padding-left  : 30px;
//   padding-right : 30px;
//   margin        : 0;
//   padding-top   : 30px;
//   padding-bottom: 30px;
// }

// @media (min-width: $breakpoint-md) {
//   .subscribe-one__list {
//     display           : -webkit-box;
//     display           : -ms-flexbox;
//     display           : flex;
//     -webkit-box-align : center;
//     -ms-flex-align    : center;
//     align-items       : center;
//     -webkit-box-pack  : center;
//     -ms-flex-pack     : center;
//     justify-content   : center;
//     -ms-flex-wrap     : wrap;
//     flex-wrap         : wrap;
//     -webkit-column-gap: 30px;
//     column-gap        : 30px;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .subscribe-one__list {
//     padding-top       : 21px;
//     padding-bottom    : 21px;
//     -webkit-column-gap: 55px;
//     column-gap        : 55px;
//   }
// }

// .subscribe-one__list li {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   position         : relative;
//   font-size        : 16px;
//   font-weight      : 500;
//   color            : #fff;
//   padding-left     : 34px;
// }

// @media (min-width: $breakpoint-lg) {
//   .subscribe-one__list li {
//     font-size: 18px;
//   }
// }

// .subscribe-one__list li>[class*="icon-"] {
//   position : absolute;
//   top      : 7px;
//   left     : 0;
//   font-size: 18px;
//   color    : #fff;
// }

// /***********************
// * Contact
// ***********************/
// .contact-one {
//   background-color: #fafbff;
//   padding         : 120px 0;
// }

// .contact-one .container {
//   max-width: 900px;
// }

// .contact-one .row {
//   --bs-gutter-x: 20px;
//   --bs-gutter-y: 20px;
// }

// .contact-one input[type="text"],
// .contact-one input[type="email"],
// .contact-one input[type="phone"],
// .contact-one textarea {
//   width        : 100%;
//   display      : block;
//   border       : none;
//   outline      : none;
//   border       : 1.5px solid #eaedf8;
//   height       : 60px;
//   border-radius: 10px;
//   color        : var(--customVar-text, #666666);
//   font-size    : 16px;
//   font-weight  : 400;
//   padding-left : 20px;
//   padding-right: 20px;
// }

// .contact-one textarea {
//   height     : 140px;
//   padding-top: 10px;
// }

// .contact-one__btn {
//   color: #ffffff;
// }

// .contact-one__btn::before {
//   background-color: var(--customVar-secondary, #3852ff);
// }

// .contact-one__content {
//   margin-bottom: 30px;
// }

// .contact-one__title {
//   margin       : 0;
//   color        : var(--customVar-black, #121212);
//   font-size    : 30px;
//   font-weight  : 700;
//   font-family  : var(--customVar-title-font, "Outfit", sans-serif);
//   max-width    : 498px;
//   margin-left  : auto;
//   margin-right : auto;
//   margin-bottom: 5px;
//   margin-top   : -10px;
// }

// @media (min-width: $breakpoint-lg) {
//   .contact-one__title {
//     font-size: 38px;
//   }
// }

// .contact-one__description {
//   margin     : 0;
//   font-size  : 16px;
//   line-height: 1.7777777777777777em;
//   font-weight: 400;
//   color      : var(--customVar-text, #666666);
// }

// @media (min-width: $breakpoint-md) {
//   .contact-one__description {
//     font-size: 18px;
//   }
// }

// .contact-info-one {
//   background-color: #fafbff;
// }

// .contact-info-one__inner {
//   background        : #ffffff;
//   -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
//   box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius     : 20px;
//   padding-bottom    : 39px;
//   padding-top       : 39px;
//   position          : relative;
// }

// [class*="col-"]+[class*="col-"] .contact-info-one__item {
//   padding-top: 30px;
//   margin-top : 30px;
//   border-top : 1px solid #dbdbdb;
// }

// @media (min-width: $breakpoint-lg) {
//   [class*="col-"]+[class*="col-"] .contact-info-one__item {
//     margin-top : 0;
//     padding-top: 0;
//     border-top : 0;
//     border-left: 1px solid #dbdbdb;
//   }
// }

// .contact-info-one__item {
//   text-align: center;
// }

// .contact-info-one__icon {
//   font-size: 38px;
// }

// .contact-info-one__icon i {
//   background-image       : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
// }

// .contact-info-one__title {
//   font-family   : "Outfit";
//   font-style    : normal;
//   font-weight   : 500;
//   font-size     : 22px;
//   line-height   : 160%;
//   letter-spacing: -0.015em;
//   color         : #121212;
//   margin        : 0;
//   margin-top    : 16px;
//   margin-bottom : 4px;
// }

// .contact-info-one__text {
//   margin     : 0;
//   font-family: "Inter";
//   font-style : normal;
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 32px;
//   text-align : center;
//   color      : #666666;
// }

// .contact-info-one__text a {
//   color             : inherit;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .contact-info-one__text a:hover {
//   background-size: 100% 1px;
// }

// .contact-info-one__text a:hover {
//   color: var(--customVar-base, #ffa065);
// }

// .google-map-one {
//   padding-top: 120px;
// }

// .google-map-one__box {
//   display       : block;
//   border        : none;
//   outline       : none;
//   width         : 100%;
//   height        : 480px;
//   -webkit-filter: grayscale(100%) invert(92%) contrast(83%);
//   filter        : grayscale(100%) invert(92%) contrast(83%);
//   border-radius : 20px;
// }

// .register-one {
//   position      : relative;
//   z-index       : 1;
//   padding-bottom: 120px;
// }

// .register-one__inner {
//   background-color  : #fff;
//   -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
//   box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius     : 20px;
//   max-width         : 760px;
//   width             : 100%;
//   margin-left       : auto;
//   margin-right      : auto;
//   padding-top       : 65px;
//   position          : relative;
//   padding-left      : 30px;
//   padding-right     : 30px;
//   margin-top        : -125px;
//   padding-bottom    : 65px;
// }

// @media (min-width: $breakpoint-md) {
//   .register-one__inner {
//     padding-left : 60px;
//     padding-right: 60px;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .register-one__inner {
//     padding-left : 80px;
//     padding-right: 80px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .register-one__inner {
//     padding-left : 120px;
//     padding-right: 120px;
//   }
// }

// .register-one__title {
//   text-align : center;
//   margin     : 0;
//   font-size  : 32px;
//   color      : var(--customVar-black, #121212);
//   font-weight: bold;
// }

// @media (min-width: $breakpoint-lg) {
//   .register-one__title {
//     font-size: 38px;
//   }
// }

// .register-one__text {
//   text-align   : center;
//   margin       : 0;
//   font-size    : 16px;
//   line-height  : 1.7777777777777777em;
//   max-width    : 447px;
//   width        : 100%;
//   margin-left  : auto;
//   margin-right : auto;
//   margin-bottom: 41px;
//   margin-top   : 8px;
// }

// @media (min-width: $breakpoint-md) {
//   .register-one__text {
//     font-size: 18px;
//   }
// }

// .register-one__tagline {
//   text-align    : center;
//   margin        : 0;
//   font-size     : 16px;
//   line-height   : 160%;
//   color         : var(--customVar-text, #666666);
//   max-width     : 430px;
//   margin-left   : auto;
//   margin-right  : auto;
//   letter-spacing: -1.5%;
// }

// .register-one__tagline a {
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
//   background-size   : 100% 1px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .register-one__tagline a:hover {
//   color: var(--customVar-base, #ffa065);
// }

// .register-one__tagline a,
// .register-one__tagline--highlight {
//   color     : var(--customVar-black, #121212);
//   margin-top: 10px;
// }

// .register-one__tagline a a,
// .register-one__tagline--highlight a {
//   color          : var(--customVar-secondary, #3852ff);
//   background     : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 2px no-repeat;
//   background     : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
//   background-size: 100% 1px;
// }

// .register-one__tagline a a:hover,
// .register-one__tagline--highlight a:hover {
//   color: var(--customVar-base, #ffa065);
// }

// .register-one__form {
//   margin-bottom: 23px;
// }

// .register-one__form .row {
//   --bs-gutter-x: 20px;
//   --bs-gutter-y: 20px;
// }

// .register-one__form input[type="text"],
// .register-one__form input[type="email"],
// .register-one__form input[type="password"] {
//   border       : none;
//   outline      : none;
//   width        : 100%;
//   display      : block;
//   height       : 60px;
//   border-radius: 10px;
//   border       : 2px solid #ebebeb;
//   padding-left : 20px;
//   padding-right: 20px;
//   color        : var(--customVar-text, #666666);
//   font-size    : 16px;
// }

// .register-one__form__password {
//   position: relative;
// }

// .register-one__form__password .password-toggler {
//   position          : absolute;
//   top               : 50%;
//   right             : 20px;
//   -webkit-transform : translateY(-50%);
//   transform         : translateY(-50%);
//   font-size         : 24px;
//   cursor            : pointer;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .register-one__form__password .password-toggler.show-password {
//   opacity: 0.4;
// }

// .register-one__form__forgot {
//   color         : #3852ff;
//   font-size     : 16px;
//   letter-spacing: -1.5%;
// }

// .register-one__btn {
//   color             : #fff;
//   padding-left      : 55.5px;
//   padding-right     : 55.5px;
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .register-one__btn::before {
//   background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// .register-one__social {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-top       : 20px;
//   margin-bottom    : 20px;
// }

// .register-one__social a {
//   width            : 74px;
//   height           : 60px;
//   background-color : #fff;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   text-align       : center;
//   border           : 2px solid #ebebeb;
//   border-radius    : 10px;
// }

// .register-one__social a img {
//   -webkit-transform : scale(1);
//   transform         : scale(1);
//   -webkit-transition: -webkit-transform 500ms ease;
//   transition        : -webkit-transform 500ms ease;
//   transition        : transform 500ms ease;
//   transition        : transform 500ms ease, -webkit-transform 500ms ease;
// }

// .register-one__social a:hover img {
//   -webkit-transform: scale(0.9);
//   transform        : scale(0.9);
// }

// .register-one__social a+a {
//   margin-left: 20px;
// }

// /***********************
// * Footer
// ***********************/
// .footer-main {
//   background-color: #010b3c;
//   padding-bottom  : 30px;
//   padding-top     : 60px;
// }

// @media (min-width: $breakpoint-xl) {
//   .footer-main {
//     padding-top: 135px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .footer-main--normal-padding {
//     padding-top: 120px;
//   }
// }

// .footer-widget {
//   margin-bottom: 30px;
// }

// .footer-widget__logo {
//   display: -webkit-inline-box;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
// }

// .footer-widget__title {
//   font-style   : normal;
//   font-weight  : 600;
//   font-size    : 22px;
//   line-height  : 140%;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 14px;
// }

// .footer-widget__text {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 160%;
//   letter-spacing: -0.015em;
//   color         : #c2c2c2;
//   margin-top    : 16px;
//   margin-bottom : 28px;
// }

// .footer-widget__phone {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-bottom    : 32px;
// }

// .footer-widget__phone i.icon {
//   font-size   : 26px;
//   color       : #fff;
//   margin-right: 10px;
// }

// .footer-widget__phone a {
//   font-weight   : 700;
//   font-size     : 20px;
//   line-height   : 160%;
//   letter-spacing: -0.015em;
//   color         : #ffffff;
// }

// .footer-widget__payment {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -ms-flex-wrap    : wrap;
//   flex-wrap        : wrap;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-left      : -6px;
//   margin-right     : -6px;
//   margin-top       : -12px;
// }

// .footer-widget__payment a {
//   display      : inline-block;
//   padding-left : 6px;
//   padding-right: 6px;
//   margin-top   : 12px;
// }

// .footer-widget__nav {
//   margin: 0;
// }

// .footer-widget__nav li {
//   font-style    : normal;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 240%;
//   letter-spacing: -0.015em;
//   color         : #c2c2c2;
//   position      : relative;
// }

// .footer-widget__nav li a {
//   color             : inherit;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   display           : -webkit-inline-box;
//   display           : -ms-inline-flexbox;
//   display           : inline-flex;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
// }

// .footer-widget__nav li a:hover {
//   background-size: 100% 1px;
// }

// .footer-widget__nav li a::before {
//   content         : "";
//   width           : 5px;
//   height          : 5px;
//   border-radius   : 50%;
//   background-color: currentColor;
//   margin-right    : 10px;
// }

// .footer-widget__nav li a:hover {
//   color: #fff;
// }

// .footer-widget__social {
//   margin       : 0;
//   display      : -webkit-box;
//   display      : -ms-flexbox;
//   display      : flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap    : wrap;
//   margin-top   : 25px;
//   margin-bottom: 63px;
// }

// .footer-widget__social li+li {
//   margin-left: 8px;
// }

// .footer-widget__social li a {
//   width             : 40px;
//   height            : 40px;
//   border            : 2px solid #ffffff;
//   border-radius     : 10px;
//   display           : -webkit-box;
//   display           : -ms-flexbox;
//   display           : flex;
//   -webkit-box-pack  : center;
//   -ms-flex-pack     : center;
//   justify-content   : center;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
//   text-align        : center;
//   color             : #fff;
//   font-size         : 18px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// @media (min-width: $breakpoint-lg) {
//   .footer-widget__social li a {
//     width : 55px;
//     height: 55px;
//   }
// }

// .footer-widget__social li a:hover {
//   background-color: #fff;
//   color           : var(--customVar-black, #121212);
// }

// .footer-widget__contact {
//   margin: 0;
// }

// .footer-widget__contact li {
//   font-style    : normal;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 160%;
//   letter-spacing: -0.015em;
//   color         : #c2c2c2;
// }

// .footer-widget__contact li+li {
//   margin-top: 10px;
// }

// .footer-widget__contact li i {
//   font-size   : 20px;
//   color       : #fff;
//   margin-right: 15px;
// }

// .footer-widget__contact li a {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   color            : inherit;
// }

// @media (min-width: $breakpoint-xl) {
//   .footer-widget--services {
//     padding-left: 80px;
//   }
// }

// @media (min-width: $breakpoint-xl) {
//   .footer-widget--contact {
//     padding-left: 60px;
//   }
// }

// .footer-bottom {
//   background-color: var(--customVar-dark, #000932);
// }

// .footer-bottom__inner {
//   display              : -webkit-box;
//   display              : -ms-flexbox;
//   display              : flex;
//   -webkit-box-pack     : center;
//   -ms-flex-pack        : center;
//   justify-content      : center;
//   -webkit-box-align    : center;
//   -ms-flex-align       : center;
//   align-items          : center;
//   -webkit-box-orient   : vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction   : column;
//   flex-direction       : column;
//   padding              : 29px 0;
// }

// @media (min-width: $breakpoint-md) {
//   .footer-bottom__inner {
//     -webkit-box-orient   : horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction   : row;
//     flex-direction       : row;
//     -webkit-box-pack     : justify;
//     -ms-flex-pack        : justify;
//     justify-content      : space-between;
//   }
// }

// .footer-bottom__inner p {
//   margin    : 0;
//   color     : #c2c2c2;
//   text-align: center;
// }

// @media (min-width: $breakpoint-md) {
//   .footer-bottom__inner p {
//     text-align: left;
//   }
// }

// .footer-bottom__inner p a {
//   color             : inherit;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .footer-bottom__inner p a:hover {
//   background-size: 100% 1px;
// }

// .footer-bottom__inner p a:hover {
//   color: #fff;
// }

// .footer-bottom__links {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin           : 0;
// }

// .footer-bottom__links li {
//   color: #c2c2c2;
// }

// .footer-bottom__links li a {
//   color             : inherit;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .footer-bottom__links li a:hover {
//   background-size: 100% 1px;
// }

// .footer-bottom__links li a:hover {
//   color: #fff;
// }

// .footer-bottom__links li+li::before {
//   content     : "-";
//   margin-left : 9px;
//   margin-right: 4px;
// }

// /***********************
// *  Banner One
// ***********************/
// .banner-one {
//   background-image   : url(../images/template/background/banner-one-bg.png);
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center center;
//   padding-top        : 221px;
//   padding-bottom     : 90px;
// }

// .banner-one__left {
//   position  : relative;
//   display   : block;
//   margin-top: 115px;
// }

// .banner-one__sub-title-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .banner-one__sub-title-icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .banner-one__sub-title-icon span {
//   font-size: 20px;
//   color    : #00c14d;
//   position : relative;
//   display  : inline-block;
// }

// .banner-one__sub-title {
//   color      : var(--customVar-white);
//   margin     : 0;
//   margin-left: 10px;
// }

// .banner-one__title {
//   font-weight  : 700;
//   font-size    : 60px;
//   line-height  : 76px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-top   : 17px;
//   margin-bottom: 15px;
// }

// .banner-one__text {
//   color        : var(--customVar-white);
//   margin       : 0;
//   line-height  : 32px;
//   margin-bottom: 30px;
// }

// .banner-one__text .count-text {
//   font-size  : 18px;
//   font-weight: 700;
// }

// .banner-one__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .banner-one__btn:hover {
//   color: var(--customVar-black);
// }

// .banner-one__btn:before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .banner-one__btn:after {
//   background-color: var(--customVar-white);
// }

// .banner-one__right {
//   position: relative;
//   display : block;
// }

// .banner-one__img {
//   position: relative;
//   display : block;
// }

// .banner-one__img img {
//   width: 100%;
// }





// /***********************
// * Page Header
// ***********************/
// .page-banner-one {
//   background-image   : url(../images/template/background/page-header-1-1-bg.png);
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center center;
//   padding-top        : 70px;
//   padding-bottom     : 50px;
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-one {
//     padding-top   : 150px;
//     padding-bottom: 48px;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-one .row {
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//   }
// }

// .page-banner-one__image {
//   margin-top: 60px;
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-one__image {
//     margin-top: 0;
//   }
// }

// .page-banner-one__image img {
//   max-width: 100%;
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-one__image img {
//     max-width: none;
//     float    : right;
//   }
// }

// .page-banner-one__tagline {
//   margin       : 0;
//   font-family  : "Inter";
//   font-style   : normal;
//   font-weight  : 400;
//   font-size    : 16px;
//   line-height  : 1.78em;
//   color        : #ffffff;
//   margin-bottom: 4px;
// }

// @media (min-width: $breakpoint-xl) {
//   .page-banner-one__tagline {
//     font-size: 18px;
//   }
// }

// .page-banner-one__title {
//   font-family  : "Outfit";
//   font-style   : normal;
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 1.2666666666666666em;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 40px;
//   max-width    : 611px;
// }

// @media (min-width: $breakpoint-xl) {
//   .page-banner-one__title {
//     font-size: 60px;
//   }
// }

// .page-banner-one__btn {
//   color             : #fff;
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .page-banner-one__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .page-banner-two {
//   background-image   : url(../images/template/background/page-header-1-2-bg.png);
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center center;
//   text-align         : center;
//   padding-top        : 117px;
//   padding-bottom     : 117px;
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-two {
//     padding-top: 178px;
//   }
// }

// .page-banner-two--has-bottom-padding {
//   padding-bottom: 250px;
// }

// .page-banner-two__title {
//   margin    : 0;
//   font-size : 35px;
//   color     : #fff;
//   margin-top: 8px;
// }

// @media (min-width: $breakpoint-lg) {
//   .page-banner-two__title {
//     font-size: 46px;
//   }
// }

// .thm-breadcrumb {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin           : 0;
// }

// .thm-breadcrumb li {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   color            : #fff;
//   font-size        : 14px;
// }

// .thm-breadcrumb li:not(:last-child)::after {
//   content     : "/";
//   margin-left : 6px;
//   margin-right: 6px;
// }

// .thm-breadcrumb a {
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .thm-breadcrumb a:hover {
//   color: var(--customVar-base, #ffa065);
// }

// .thm-breadcrumb a,
// .thm-breadcrumb span {
//   color: inherit;
// }

// /***********************
// * Main Menu
// ***********************/
// .main-header-one {
//   position        : absolute;
//   top             : 0;
//   left            : 0;
//   width           : 100%;
//   z-index         : 99;
//   position        : relative;
//   background-color: var(--customVar-black, #121212);
// }

// @media (min-width: $breakpoint-lg) {
//   .main-header-one {
//     position        : absolute;
//     background-color: transparent;
//   }
// }

// .main-header-one .container {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   padding-top      : 30px;
//   padding-bottom   : 30px;
// }

// .main-header-one__nav {
//   margin-left: auto;
// }

// .main-header-one__right {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
// }

// .main-header-one__btn {
//   font-size     : 17px;
//   padding-top   : 9px;
//   padding-bottom: 9px;
//   color         : #fff;
//   display       : none;
//   margin-left   : 30px;
// }

// @media (min-width: $breakpoint-lg) {
//   .main-header-one__btn {
//     display: -webkit-inline-box;
//     display: -ms-inline-flexbox;
//     display: inline-flex;
//   }
// }

// .main-header-one__btn:hover {
//   color: var(--customVar-black, #121212);
// }

// .main-header-one__btn::before {
//   background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// .main-header-one__btn::after {
//   background-color: #fff;
// }

// .main-header-one .language-switcher {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-left      : 30px;
// }

// .main-header-one .language-switcher i {
//   color       : #fff;
//   font-size   : 18px;
//   margin-right: 8px;
// }

// .main-header-one .language-switcher .bootstrap-select>.dropdown-toggle {
//   font-size  : 17px;
//   font-family: var(--customVar-title-font, "Outfit", sans-serif);
//   font-weight: 400;
//   color      : #fff;
// }

// .main-header-one .language-switcher .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 12px;
// }

// .main-header-one__cart {
//   margin-left: 30px;
// }

// .main-header-one__cart i {
//   color    : #fff;
//   font-size: 18px;
// }

// .main-header-one .mobile-nav__toggler {
//   display              : none;
//   -webkit-box-align    : center;
//   -ms-flex-align       : center;
//   align-items          : center;
//   -webkit-box-orient   : vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction   : column;
//   flex-direction       : column;
// }

// @media (max-width: calc($breakpoint-xl - 1px)) {
//   .main-header-one .mobile-nav__toggler {
//     display: -webkit-inline-box;
//     display: -ms-inline-flexbox;
//     display: inline-flex;
//   }
// }

// .main-header-one .mobile-nav__toggler span {
//   display         : block;
//   width           : 20px;
//   height          : 2px;
//   background-color: #fff;
// }

// .main-header-one .mobile-nav__toggler span:nth-child(2) {
//   margin-top   : 3px;
//   margin-bottom: 3px;
// }

// .main-menu__list {
//   display   : none;
//   margin    : 0;
//   padding   : 0;
//   list-style: none;
// }

// @media (min-width: $breakpoint-xl) {
//   .main-menu__list {
//     display          : -webkit-box;
//     display          : -ms-flexbox;
//     display          : flex;
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//     margin-left      : auto;
//   }
// }

// .main-menu__list>li {
//   position: relative;
//   padding : 9px 0;
// }

// .main-menu__list>li+li {
//   margin-left: 40px;
// }

// @media (min-width: $breakpoint-xl) {
//   .main-menu__list>li+li {
//     margin-left: 58px;
//   }
// }

// .main-menu__list>li>a {
//   color             : #ffffff;
//   font-weight       : 400;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   display           : block;
//   font-size         : 17px;
// }

// .main-menu__list>li>a::after {
//   content    : "+";
//   margin-left: 5px;
// }

// .main-menu__list>li>a:only-child::after {
//   content: "";
// }

// .main-menu__list>li:hover>a,
// .main-menu__list>li.current>a {
//   text-shadow: 0 0 1px currentColor;
//   color      : var(--customVar-primary, #ff5a74);
// }

// .main-menu__list>li>ul {
//   left              : 0;
//   top               : 100%;
//   margin            : 0;
//   padding           : 0;
//   list-style        : none;
//   position          : absolute;
//   min-width         : 220px;
//   background-color  : #fff;
//   opacity           : 0;
//   visibility        : hidden;
//   -webkit-transition: 500ms ease;
//   transition        : 500ms ease;
//   z-index           : 99;
//   -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
//   box-shadow        : 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
// }

// .main-menu__list>li>ul>li {
//   position: relative;
// }

// .main-menu__list>li>ul>li>a {
//   color             : var(--customVar-black, #121212);
//   font-weight       : 400;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   display           : block;
//   padding           : 7px 20px;
//   font-size         : 16px;
//   font-family       : var(--customVar-title-font, "Outfit", sans-serif);
// }

// .main-menu__list>li>ul>li>a:hover {
//   background-color: var(--customVar-primary, #ff5a74);
//   color           : #fff;
// }

// .main-menu__list>li>ul>li>ul {
//   left              : 100%;
//   top               : 0%;
//   margin            : 0;
//   padding           : 0;
//   list-style        : none;
//   position          : absolute;
//   min-width         : 220px;
//   background-color  : #fff;
//   opacity           : 0;
//   visibility        : hidden;
//   -webkit-transition: 500ms ease;
//   transition        : 500ms ease;
//   z-index           : 99;
//   -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
//   box-shadow        : 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
// }

// .main-menu__list>li>ul>li>ul>li>a {
//   color             : var(--customVar-black, #121212);
//   font-weight       : 400;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   display           : block;
//   padding           : 7px 20px;
//   font-size         : 16px;
//   font-family       : var(--customVar-title-font, "Outfit", sans-serif);
// }

// .main-menu__list>li>ul>li>ul>li>a:hover {
//   background-color: var(--customVar-primary, #ff5a74);
//   color           : #fff;
// }

// .main-menu__list>li>ul>li>ul>li>ul {
//   display: none;
// }

// .main-menu__list>li>ul>li:hover>ul {
//   opacity   : 1;
//   visibility: visible;
// }

// .main-menu__list>li:hover>ul {
//   opacity   : 1;
//   visibility: visible;
// }

// .sticky-header--cloned {
//   position          : fixed;
//   z-index           : 991;
//   top               : 0;
//   left              : 0;
//   background-color  : var(--customVar-black, #121212);
//   width             : 100%;
//   visibility        : hidden;
//   -webkit-transform : translateY(-120%);
//   transform         : translateY(-120%);
//   -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : transform 500ms ease, visibility 500ms ease;
//   transition        : transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
//   -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
//   box-shadow        : 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
// }

// @media (max-width: calc($breakpoint-xl - 1px)) {
//   .sticky-header--cloned {
//     display: none !important;
//   }
// }

// .sticky-header--cloned.sticky-fixed {
//   -webkit-transform: translateY(0);
//   transform        : translateY(0);
//   visibility       : visible;
// }

// .main-header-one__nav .main-menu__list>.megamenu {
//   position: static;
// }

// .main-header-one__nav .main-menu__list>.megamenu>ul {
//   top               : 100% !important;
//   left              : 0 !important;
//   right             : 0 !important;
//   background-color  : transparent;
//   -webkit-box-shadow: none;
//   box-shadow        : none;
// }

// .main-header-one__nav .main-menu__list>.megamenu>ul>li {
//   padding: 0 !important;
// }

// .domain-showcase .container {
//   padding-top: 0;
// }

// .domain-showcase__inner {
//   background-color: #fafbff;
//   display         : block;
//   width           : 100%;
//   padding         : 30px;
//   border-radius   : 20px;
// }

// .domain-showcase__icon {
//   width            : 70px;
//   height           : 70px;
//   background-color : #f0f5ff;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   border-radius    : 20px;
//   color            : #3852ff;
//   font-size        : 20px;
// }

// .domain-showcase__title {
//   font-family  : var(--customVar-title-font, "Outfit", sans-serif);
//   font-size    : 20px;
//   font-weight  : bold;
//   color        : var(--customVar-black, #121212);
//   margin       : 0;
//   margin-top   : 20px;
//   margin-bottom: 10px;
// }

// .domain-showcase__tagline {
//   margin     : 0;
//   font-size  : 14px;
//   line-height: 160%;
// }

// .mobile-nav__container .domain-showcase .container {
//   padding: 0;
// }

// .mobile-nav__container .domain-showcase .row {
//   --bs-gutter-y: 30px;
// }

// .mobile-nav__container .domain-showcase [class*="col-"] {
//   width: 100%;
// }

// /***********************
// * Mobile Drawer
// ***********************/
// .mobile-nav__wrapper {
//   position                : fixed;
//   z-index                 : 991;
//   top                     : 0;
//   left                    : 0;
//   width                   : 100vw;
//   height                  : 100vh;
//   -webkit-transform       : translateX(-100%);
//   transform               : translateX(-100%);
//   -webkit-transform-origin: left center;
//   transform-origin        : left center;
//   -webkit-transition      : visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
//   transition              : visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
//   transition              : transform 500ms ease 500ms, visibility 500ms ease 500ms;
//   transition              : transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
//   visibility              : hidden;
// }

// .mobile-nav__wrapper.expanded {
//   opacity           : 1;
//   -webkit-transform : translateX(0%);
//   transform         : translateX(0%);
//   visibility        : visible;
//   -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
//   transition        : visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
//   transition        : transform 500ms ease 0ms, visibility 500ms ease 0ms;
//   transition        : transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
// }

// .mobile-nav__overlay {
//   position        : absolute;
//   top             : 0;
//   left            : 0;
//   right           : 0;
//   bottom          : 0;
//   background-color: #000000;
//   opacity         : 0.9;
// }

// .mobile-nav__content {
//   width             : 100%;
//   max-width         : 300px;
//   position          : absolute;
//   top               : 0;
//   left              : 0;
//   overflow          : hidden;
//   overflow-y        : auto;
//   height            : 100%;
//   background-color  : #010b3c;
//   -ms-overflow-style: none;
//   scrollbar-width   : none;
//   padding           : 30px 15px;
//   opacity           : 0;
//   visibility        : hidden;
//   -webkit-transform : translateX(-100%);
//   transform         : translateX(-100%);
//   -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
//   transition        : opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
//   transition        : opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
//   transition        : opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
// }

// .mobile-nav__content::-webkit-scrollbar {
//   display: none;
// }

// .mobile-nav__wrapper.expanded .mobile-nav__content {
//   opacity           : 1;
//   visibility        : visible;
//   -webkit-transform : translateX(0);
//   transform         : translateX(0);
//   -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
//   transition        : opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
//   transition        : opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
//   transition        : opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
// }

// .mobile-menu__list {
//   margin       : 0;
//   padding      : 0;
//   list-style   : none;
//   margin-top   : 30px;
//   margin-bottom: 10px;
// }

// .mobile-menu__list .menu-item-has-children button {
//   width            : 30px;
//   height           : 30px;
//   background-color : #fff;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   font-size        : 14px;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   color            : var(--customVar-black, #121212);
//   border           : none;
//   outline          : none;
//   float            : right;
//   margin-top       : 5px;
// }

// .mobile-menu__list li.current>a {
//   color: #fff;
// }

// .mobile-menu__list>li+li {
//   border-top: 1px solid rgba(255, 255, 255, 0.1);
// }

// .mobile-menu__list>li>a {
//   font-weight: 400;
//   font-size  : 14px;
//   color      : #c2c2c2;
//   display    : block;
//   line-height: 40px;
// }

// .mobile-menu__list>li>ul {
//   margin      : 0;
//   padding     : 0;
//   list-style  : none;
//   display     : none;
//   padding-left: 5px;
//   border-top  : 1px solid rgba(255, 255, 255, 0.1);
// }

// .mobile-menu__list>li>ul>li+li {
//   border-top: 1px solid rgba(255, 255, 255, 0.1);
// }

// .mobile-menu__list>li>ul>li>a {
//   text-transform: uppercase;
//   font-weight   : 400;
//   font-size     : 14px;
//   color         : #c2c2c2;
//   display       : block;
//   line-height   : 40px;
// }

// .mobile-menu__list>li>ul>li>ul {
//   margin      : 0;
//   padding     : 0;
//   list-style  : none;
//   padding-left: 5px;
//   display     : none;
//   border-top  : 1px solid rgba(255, 255, 255, 0.1);
// }

// .mobile-menu__list>li>ul>li>ul>li+li {
//   border-top: 1px solid rgba(255, 255, 255, 0.1);
// }

// .mobile-menu__list>li>ul>li>ul>li>a {
//   text-transform: uppercase;
//   font-weight   : 400;
//   font-size     : 14px;
//   color         : #c2c2c2;
//   display       : block;
//   line-height   : 40px;
// }

// .mobile-menu__list>li>ul>li>ul>li>ul {
//   display: none;
// }

// .mobile-nav__social {
//   margin           : 0;
//   padding          : 0;
//   list-style       : none;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-left      : -7px;
//   margin-right     : -7px;
// }

// .mobile-nav__social li {
//   margin: 0 7px;
// }

// .mobile-nav__social li a {
//   width             : 40px;
//   height            : 40px;
//   border            : 1px solid var(--customVar-base, #ffa065);
//   color             : #fff;
//   font-size         : 14px;
//   display           : -webkit-box;
//   display           : -ms-flexbox;
//   display           : flex;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
//   -webkit-box-pack  : center;
//   -ms-flex-pack     : center;
//   justify-content   : center;
//   text-align        : center;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   border-radius     : 50%;
// }

// .mobile-nav__social li a:hover {
//   color           : #fff;
//   background-color: var(--customVar-primary, #ff5a74);
// }

// .mobile-nav__contact {
//   margin       : 0;
//   padding      : 0;
//   list-style   : none;
//   margin-bottom: 20px;
// }

// .mobile-nav__contact li {
//   position    : relative;
//   padding-left: 33px;
//   color       : #c2c2c2;
//   font-size   : 16px;
//   line-height : 36px;
// }

// .mobile-nav__contact li>i {
//   font-size: 16px;
//   color    : var(--customVar-base, #ffa065);
//   position : absolute;
//   top      : 11px;
//   left     : 0;
// }

// .mobile-nav__contact li a {
//   color             : inherit;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .mobile-nav__contact li a:hover {
//   color: #fff;
// }

// .mobile-nav__close {
//   width   : 25px;
//   height  : 25px;
//   display : block;
//   position: absolute;
//   top     : 20px;
//   right   : 15px;
// }

// .mobile-nav__close span {
//   width            : 2px;
//   height           : 100%;
//   display          : block;
//   background-color : #fff;
//   position         : absolute;
//   top              : 50%;
//   left             : 50%;
//   -webkit-transform: translate(-50%, -50%) rotate(45deg);
//   transform        : translate(-50%, -50%) rotate(45deg);
// }

// .mobile-nav__close span:nth-child(2) {
//   -webkit-transform: translate(-50%, -50%) rotate(-45deg);
//   transform        : translate(-50%, -50%) rotate(-45deg);
// }

// /***********************
// * Under construction
// ***********************/
// .under-construction {
//   padding-top   : 120px;
//   padding-bottom: 120px;
// }

// @media (min-width: $breakpoint-xl) {
//   .under-construction .container {
//     max-width: 778px;
//   }
// }

// .under-construction__image {
//   margin-bottom: 50px;
// }

// @media (min-width: $breakpoint-lg) {
//   .under-construction__image {
//     margin-bottom: 80px;
//   }
// }

// .under-construction__image img {
//   max-width: 100%;
// }

// .under-construction__title {
//   margin     : 0;
//   color      : var(--customVar-black, #121212);
//   text-align : center;
//   font-weight: bold;
//   font-size  : 35px;
// }

// @media (min-width: $breakpoint-lg) {
//   .under-construction__title {
//     font-size: 46px;
//   }
// }

// .under-construction__text {
//   margin      : 0;
//   font-size   : 18px;
//   text-align  : center;
//   line-height : 1.7777777777777777em;
//   max-width   : 476px;
//   margin-left : auto;
//   margin-right: auto;
// }

// .under-construction__form {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -ms-flex-wrap    : wrap;
//   flex-wrap        : wrap;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-top       : 26px;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
// }

// .under-construction__form input[type="text"],
// .under-construction__form input[type="email"] {
//   border       : none;
//   outline      : none;
//   width        : 100%;
//   max-width    : 520px;
//   font-size    : 16px;
//   color        : var(--customVar-text, #666666);
//   border-radius: 10px;
//   border       : 2px solid #ebebeb;
//   padding-left : 20px;
//   padding-right: 20px;
//   height       : 60px;
//   margin-right : 10px;
//   margin-bottom: 10px;
// }

// @media (min-width: $breakpoint-lg) {

//   .under-construction__form input[type="text"],
//   .under-construction__form input[type="email"] {
//     margin-bottom: 0;
//   }
// }

// .under-construction__btn {
//   color  : #fff;
//   padding: 14px 52px;
// }

// .under-construction__btn::before {
//   background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// /***********************
// * host review
// ***********************/
// .host-review-one {
//   padding-top   : 120px;
//   padding-bottom: 80px;
// }

// .host-review-one__item {
//   margin-bottom: 40px;
//   text-align   : center;
// }

// @media (min-width: $breakpoint-md) {
//   .host-review-one__item {
//     text-align: left;
//   }
// }

// .host-review-one__item__content {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   margin-top       : 7px;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
// }

// @media (min-width: $breakpoint-md) {
//   .host-review-one__item__content {
//     -webkit-box-pack: start;
//     -ms-flex-pack   : start;
//     justify-content : flex-start;
//   }
// }

// .host-review-one__item__star {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
// }

// .host-review-one__item__star i {
//   font-size: 23px;
//   color    : #ffa800;
// }

// .host-review-one__item__star i+i {
//   margin-left: 9px;
// }

// .host-review-one__item__count {
//   font-size  : 22px;
//   font-weight: 600;
//   color      : var(--customVar-black, #121212);
//   margin-left: 9px;
// }

// /***********************
// * hosting option
// ***********************/
// .hosting-option {
//   background-color: #f5f7ff;
//   background-image: url(../images/template/background/hosting-option-bg.png);
//   background-size : cover;
//   padding         : 120px 0;
// }

// @media (min-width: $breakpoint-xl) {
//   .hosting-option .container {
//     max-width: 1135px;
//   }
// }

// .hosting-option .row {
//   --bs-gutter-x: 30px;
//   --bs-gutter-y: 30px;
// }

// .hosting-option__content {
//   text-align: center;
// }

// .hosting-option__title {
//   font-weight  : 700;
//   font-size    : 32px;
//   line-height  : 1.5em;
//   text-align   : center;
//   color        : var(--customVar-black, #121212);
//   margin       : 0;
//   margin-bottom: 5px;
// }

// @media (min-width: $breakpoint-lg) {
//   .hosting-option__title {
//     font-size: 38px;
//   }
// }

// .hosting-option__text {
//   margin       : 0;
//   font-family  : "Inter";
//   font-style   : normal;
//   font-weight  : 400;
//   font-size    : 16px;
//   line-height  : 160%;
//   text-align   : center;
//   margin-bottom: 40px;
// }

// @media (min-width: $breakpoint-lg) {
//   .hosting-option__text {
//     font-size: 18px;
//   }
// }

// .hosting-option__item {
//   background-color: #fff;
//   border-radius   : 20px;
//   padding         : 50px 38px;
// }

// @media (min-width: $breakpoint-md) {
//   .hosting-option__item {
//     display          : -webkit-box;
//     display          : -ms-flexbox;
//     display          : flex;
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//   }
// }

// .hosting-option__item__icon {
//   background       : #f0f5ff;
//   border-radius    : 20px;
//   width            : 90px;
//   height           : 90px;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 20px;
//   -ms-flex-negative: 0;
//   flex-shrink      : 0;
// }

// @media (min-width: $breakpoint-md) {
//   .hosting-option__item__icon {
//     margin-right: 37px;
//   }
// }

// .hosting-option__item__title {
//   margin       : 0;
//   font-weight  : 600;
//   font-size    : 22px;
//   line-height  : 160%;
//   color        : var(--customVar-black, #121212);
//   margin-bottom: 9px;
// }

// .hosting-option__item__title a {
//   color             : inherit;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .hosting-option__item__title a:hover {
//   background-size: 100% 1px;
// }

// .hosting-option__item__text {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 160%;
//   letter-spacing: -0.015em;
// }

// /***********************
// * data center
// ***********************/
// .data-center {
//   padding-top   : 110px;
//   padding-bottom: 60px;
// }

// .data-center__title {
//   margin       : 0;
//   color        : var(--customVar-black, #121212);
//   font-size    : 30px;
//   font-weight  : bold;
//   margin-bottom: 65px;
// }

// .data-center__title br {
//   display: none;
// }

// @media (min-width: $breakpoint-md) {
//   .data-center__title br {
//     display: inherit;
//   }
// }

// @media (min-width: $breakpoint-md) {
//   .data-center__title {
//     font-size: 35px;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .data-center__title {
//     font-size: 40px;
//   }
// }

// .data-center__item {
//   margin-bottom: 60px;
// }

// .data-center__item .row {
//   --bs-gutter-y: 30px;
// }

// @media (min-width: $breakpoint-xl) {
//   .data-center__item .row {
//     --bs-gutter-y    : 0;
//     -webkit-box-align: center;
//     -ms-flex-align   : center;
//     align-items      : center;
//   }
// }

// .data-center__item__image img {
//   max-width: 100%;
// }

// .data-center__item__title {
//   font-size    : 20px;
//   font-weight  : 600;
//   font-family  : var(--customVar-title-font, "Outfit", sans-serif);
//   color        : var(--customVar-black, #121212);
//   margin-bottom: 16px;
// }

// @media (min-width: $breakpoint-md) {
//   .data-center__item__title {
//     font-size: 26px;
//   }
// }

// .data-center__item__title img {
//   border-radius: 50%;
//   margin-right : 10px;
// }

// .data-center__item__content {
//   padding           : 30px 40px;
//   border-radius     : 20px;
//   background-color  : #fff;
//   -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
//   box-shadow        : 0 15px 40px rgba(0, 0, 0, 0.05);
// }

// @media (min-width: $breakpoint-xl) {
//   .data-center__item__content {
//     margin-left: auto;
//     max-width  : 400px;
//   }
// }

// .data-center__item__list {
//   margin       : 0;
//   margin-bottom: 18px;
// }

// .data-center__item__list li {
//   font-size  : 16px;
//   line-height: 2.3333333333333335em;
// }

// @media (min-width: $breakpoint-md) {
//   .data-center__item__list li {
//     font-size: 18px;
//   }
// }

// .data-center__item__list li span {
//   font-weight: 600;
// }

// .data-center__item__btn {
//   width           : 100%;
//   -webkit-box-pack: center;
//   -ms-flex-pack   : center;
//   justify-content : center;
//   color           : #fff;
// }

// .data-center__item__btn::before {
//   background-color: var(--customVar-secondary, #3852ff);
// }

// /***********************
// * Faq
// ***********************/
// .faq-one {
//   padding-top   : 110px;
//   padding-bottom: 110px;
// }

// @media (min-width: $breakpoint-lg) {
//   .faq-one .container {
//     max-width: 905px;
//   }
// }

// .faq-one__top__title {
//   margin     : 0;
//   font-size  : 30px;
//   color      : var(--customVar-black, #121212);
//   font-weight: bold;
// }

// @media (min-width: $breakpoint-md) {
//   .faq-one__top__title {
//     font-size: 32px;
//   }
// }

// @media (min-width: $breakpoint-lg) {
//   .faq-one__top__title {
//     font-size: 38px;
//   }
// }

// .faq-one__top__text {
//   margin     : 0;
//   font-size  : 16px;
//   line-height: 1.7777777777777777em;
// }

// @media (min-width: $breakpoint-lg) {
//   .faq-one__top__text {
//     font-size: 18px;
//   }
// }

// .faq-one__tab__btns {
//   margin-top      : 34px;
//   margin-bottom   : 40px;
//   display         : -webkit-inline-box;
//   display         : -ms-inline-flexbox;
//   display         : inline-flex;
//   border          : 2px solid #e9eaf2;
//   border-radius   : 10px;
//   padding         : 5px;
//   -webkit-box-pack: center;
//   -ms-flex-pack   : center;
//   justify-content : center;
// }

// .faq-one__tab__btns .nav-link {
//   border       : none;
//   color        : var(--customVar-black, #121212);
//   font-size    : 16px;
//   font-weight  : 600;
//   padding      : 9px 25px;
//   border-radius: 10px;
// }

// .faq-one__tab__btns .nav-link.active {
//   background-color: var(--customVar-secondary, #3852ff);
//   color           : #fff;
// }

// .faq-one__accordion .accordion-button:not(.collapsed) {
//   color             : inherit;
//   background-color  : rgba(0, 0, 0, 0);
//   -webkit-box-shadow: none;
//   box-shadow        : none;
// }

// .faq-one__accordion .accordion-button:focus {
//   -webkit-box-shadow: none;
//   box-shadow        : none;
// }

// .faq-one__accordion__item {
//   background-color: #fff;
//   border          : 0;
//   border-radius   : 20px;
//   padding-top     : 28px;
//   padding-bottom  : 28px;
// }

// .faq-one__accordion__item.accordion--active {
//   -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
//   box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);
// }

// .faq-one__accordion__item.accordion--active .accordion-button {
//   color: var(--customVar-secondary, #3852ff);
// }

// .faq-one__accordion__item.accordion--active .faq-one__accordion__icon::after {
//   opacity: 0;
// }

// .faq-one__accordion__item+.faq-one__accordion__item {
//   margin-top: 15px;
// }

// .faq-one__accordion__button {
//   padding          : 0px 30px;
//   font-size        : 18px;
//   font-weight      : bold;
//   color            : var(--customVar-black, #121212);
//   position         : relative;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
// }

// .faq-one__accordion__button::before {
//   content         : "";
//   width           : 6px;
//   height          : 6px;
//   background-color: currentColor;
//   border-radius   : 50%;
//   margin-right    : 13px;
// }

// @media (min-width: $breakpoint-lg) {
//   .faq-one__accordion__button {
//     font-size: 20px;
//   }
// }

// .faq-one__accordion__button::after {
//   display: none;
// }

// .faq-one__accordion__icon {
//   width            : 35px;
//   height           : 35px;
//   background-color : #e0e4ff;
//   border-radius    : 50%;
//   position         : relative;
//   margin-left      : auto;
//   -ms-flex-negative: 0;
//   flex-shrink      : 0;
// }

// .faq-one__accordion__icon::before,
// .faq-one__accordion__icon::after {
//   content                 : "";
//   -webkit-transform       : translate(-50%, -50%);
//   transform               : translate(-50%, -50%);
//   width                   : 15px;
//   height                  : 2px;
//   background-color        : var(--customVar-secondary, #3852ff);
//   position                : absolute;
//   top                     : 50%;
//   left                    : 50%;
//   -webkit-transform-origin: center center;
//   transform-origin        : center center;
//   opacity                 : 1;
//   -webkit-transition      : opacity 500ms ease;
//   transition              : opacity 500ms ease;
// }

// .faq-one__accordion__icon::after {
//   width : 2px;
//   height: 15px;
// }

// .faq-one__accordion__body {
//   padding      : 0;
//   font-size    : 16px;
//   line-height  : 160%;
//   padding-left : 46px;
//   padding-right: 30px;
//   padding-top  : 15px;
// }

// .faq-one__bottom {
//   color     : var(--customVar-text, #666666);
//   font-size : 16px;
//   margin-top: 36px;
// }

// @media (min-width: $breakpoint-lg) {
//   .faq-one__bottom {
//     font-size: 18px;
//   }
// }

// .faq-one__bottom a {
//   margin-left       : 10px;
//   color             : #1061ff;
//   display           : -webkit-inline-box;
//   display           : -ms-inline-flexbox;
//   display           : inline-flex;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
//   line-height       : 1em;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   font-weight       : 600;
//   font-family       : var(--customVar-title-font, "Outfit", sans-serif);
// }

// .faq-one__bottom a:hover {
//   color: var(--customVar-primary, #ff5a74);
// }

// .faq-one__bottom a span {
//   display           : inline-block;
//   padding-bottom    : 2px;
//   background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
//   background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faq-one__bottom a span:hover {
//   background-size: 100% 1px;
// }

// .faq-one__bottom a i {
//   font-size  : 12px;
//   margin-left: 7px;
// }

// /***********************
// * Main Header Two
// ***********************/
// .main-header-two__top {
//   position  : relative;
//   display   : block;
//   background: #00031c;
// }

// @media (max-width: calc($breakpoint-md - 1px)) {
//   .main-header-two__top {
//     display: none;
//   }
// }

// .main-header-two__top .container {
//   padding-top   : 19px;
//   padding-bottom: 19px;
// }

// .main-header-two__inner {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   flex           : 1;
// }

// .main-header-two__call {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .main-header-two__call-icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .main-header-two__call-icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 16px;
//   color    : var(--customVar-white);
// }

// .main-header-two__call-number {
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   letter-spacing: -0.015em;
//   margin        : 0;
//   margin-left   : 10px;
// }

// .main-header-two__call-number a {
//   font-weight: 800;
//   color      : var(--customVar-white);
// }

// .main-header-two__support-and-language-switcher {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .main-header-two__support-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .main-header-two__support-icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .main-header-two__support-icon span {
//   font-size: 20px;
//   color    : var(--customVar-white);
// }

// .main-header-two__support-text {
//   font-weight   : 500;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
//   margin        : 0;
//   margin-left   : 10px;
// }

// .main-header-two .main-header-one__cart {
//   font-size: 17px;
//   color    : var(--customVar-white);
// }

// .main-header-two .main-header-one__cart i {
//   position     : relative;
//   padding-right: 5px;
// }

// /***********************
// * Main Header Three
// ***********************/
// .main-header-three {
//   top: 40px;
// }

// .main-header-three__nav-box .container {
//   padding-top     : 15px;
//   padding-bottom  : 15px;
//   padding-left    : 30px;
//   padding-right   : 30px;
//   background-color: var(--customVar-white);
//   box-shadow      : 0px 10px 20px rgba(0, 0, 0, 0.05);
//   border-radius   : 15px;
// }

// .main-header-three__nav .main-menu__list>li>a {
//   color: var(--customVar-text);
// }

// .main-header-three__nav .main-menu__list>li:hover>a,
// .main-header-three__nav .main-menu__list>li.current>a {
//   text-shadow: 0 0 1px currentColor;
//   color      : var(--customVar-primary, #ff5a74);
// }

// .main-header-three .main-header-one__cart i {
//   color: var(--customVar-text);
// }

// .main-header-three .language-switcher {
//   position: relative;
// }

// .main-header-three .language-switcher:before {
//   content         : "";
//   position        : absolute;
//   top             : 7px;
//   bottom          : 7px;
//   left            : -15px;
//   width           : 1px;
//   background-color: var(--customVar-text);
// }

// .main-header-three .language-switcher i {
//   color: var(--customVar-text);
// }

// .main-header-three .main-header-one__btn:hover {
//   color: var(--customVar-white);
// }

// .main-header-three .main-header-one__btn::after {
//   background-color: var(--customVar-black);
// }

// .main-header-three .language-switcher .bootstrap-select>.dropdown-toggle {
//   color: var(--customVar-text);
// }

// .main-header-three__nav-box.sticky-header--cloned {
//   background-color: transparent;
//   box-shadow      : none;
// }

// /***********************
// * Main Header Four
// ***********************/
// .main-header-four {
//   margin-top: 50px;
// }

// /***********************
// * Banner Two
// ***********************/
// .banner-two {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(189.06deg, #000739 10.6%, #1a2258 93.35%);
//   padding   : 204px 0 260px;
//   z-index   : 1;
// }

// .banner-two__shape-1 {
//   position           : absolute;
//   top                : 70px;
//   left               : 0;
//   right              : 0;
//   height             : 416px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .banner-two__shape-2 {
//   position: absolute;
//   bottom  : 0;
//   right   : -30px;
//   z-index : -1;
// }

// .banner-two__shape-2 img {
//   width: auto;
// }

// .banner-two__left {
//   position  : relative;
//   display   : block;
//   margin-top: 179px;
// }

// .banner-two__sub-title-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .banner-two__sub-title-icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .banner-two__sub-title-icon span {
//   font-size: 20px;
//   color    : #00c14d;
//   position : relative;
//   display  : inline-block;
// }

// .banner-two__sub-title {
//   color      : var(--customVar-white);
//   margin     : 0;
//   margin-left: 10px;
// }

// .banner-two__title {
//   font-weight  : 700;
//   font-size    : 60px;
//   line-height  : 76px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-top   : 17px;
//   margin-bottom: 15px;
// }

// .banner-two__text {
//   color        : var(--customVar-white);
//   margin       : 0;
//   line-height  : 32px;
//   margin-bottom: 30px;
// }

// .banner-two__text .count-text {
//   font-size  : 18px;
//   font-weight: 700;
// }

// .banner-two__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .banner-two__btn:hover {
//   color: var(--customVar-black);
// }

// .banner-two__btn:before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .banner-two__btn:after {
//   background-color: var(--customVar-white);
// }

// .banner-two__right {
//   position   : relative;
//   display    : block;
//   margin-left: 65px;
// }

// .banner-two__img {
//   position: relative;
//   display : block;
// }

// .banner-two__img img {
//   width: 100%;
// }

// /***********************
// * Banner Three
// ***********************/
// .banner-three {
//   position  : relative;
//   display   : block;
//   background: #f5f4ff;
//   padding   : 290px 0 405px;
//   z-index   : 1;
// }

// .banner-three__shape-1 {
//   position: absolute;
//   bottom  : 35px;
//   left    : 0;
//   z-index : -1;
// }

// .banner-three__shape-1 img {
//   width: auto;
// }

// .banner-three__shape-2 {
//   position: absolute;
//   bottom  : 35px;
//   right   : 0;
//   z-index : -1;
// }

// .banner-three__shape-2 img {
//   width: auto;
// }

// .banner-three__shape-3 {
//   position: absolute;
//   top     : 118px;
//   left    : 0;
// }

// .banner-three__shape-3 img {
//   width: auto;
// }

// .banner-three__shape-4 {
//   position: absolute;
//   top     : 225px;
//   left    : 110px;
// }

// .banner-three__shape-4 img {
//   width: auto;
// }

// .banner-three__shape-5 {
//   position: absolute;
//   top     : 490px;
//   left    : 480px;
// }

// .banner-three__shape-5 img {
//   width: auto;
// }

// .banner-three__shape-6 {
//   position: absolute;
//   top     : 0;
//   right   : 215px;
// }

// .banner-three__shape-6 img {
//   width: auto;
// }

// .banner-three__shape-7 {
//   position: absolute;
//   top     : 202px;
//   right   : 132px;
// }

// .banner-three__shape-7 img {
//   width: auto;
// }

// .banner-three__shape-8 {
//   position: absolute;
//   top     : 377px;
//   right   : 424px;
// }

// .banner-three__shape-8 img {
//   width: auto;
// }

// .banner-three__inner {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .banner-three__sub-title-box {
//   position: relative;
//   display : inline-block;
// }

// .banner-three__star-1 {
//   position: absolute;
//   top     : -13px;
//   left    : -36px;
// }

// .banner-three__star-1 img {
//   width: auto;
// }

// .banner-three__star-2 {
//   position: absolute;
//   top     : 10px;
//   right   : -41px;
// }

// .banner-three__star-2 img {
//   width: auto;
// }

// .banner-three__sub-title {
//   position     : relative;
//   display      : inline-block;
//   font-size    : 13px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   line-height  : 24px;
//   margin       : 0;
//   border       : 1px solid #edebff;
//   border-radius: 20px;
//   padding-left : 115px;
//   padding-right: 15px;
// }

// .banner-three__flase-sale {
//   font-size    : 12px;
//   color        : var(--customVar-white);
//   font-weight  : 600;
//   line-height  : 12px;
//   background   : #010d65;
//   position     : absolute;
//   top          : 4px;
//   left         : 0;
//   padding      : 7px 20px;
//   margin       : 0;
//   border-radius: 20px;
// }

// .banner-three__title {
//   font-size    : 60px;
//   line-height  : 70px;
//   color        : var(--customVar-black);
//   font-weight  : 700;
//   margin       : 0;
//   margin-top   : 20px;
//   margin-bottom: 15px;
// }

// .banner-three__text {
//   font-size  : 18px;
//   line-height: 32px;
//   margin     : 0;
// }

// .banner-three__btn-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   flex-wrap      : wrap;
//   margin-top     : 30px;
// }

// .banner-three__btn-1 {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .banner-three__btn-1::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .banner-three__btn-2 {
//   background : transparent;
//   border     : 2px solid #010c65;
//   padding    : 12px 30px;
//   margin-left: 20px;
// }

// /***********************
// * Banner Four
// ***********************/
// .banner-four {
//   position: relative;
//   display : block;
//   padding : 217px 0 55px;
//   z-index : 1;
// }

// .banner-four__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .banner-four__left {
//   position  : relative;
//   display   : block;
//   margin-top: 185px;
// }

// .banner-four__right {
//   position    : relative;
//   display     : block;
//   margin-left : 54px;
//   margin-right: -54px;
// }

// .banner-four__title {
//   font-size  : 60px;
//   color      : var(--customVar-white);
//   font-weight: 700;
//   line-height: 76px;
//   margin     : 0;
// }

// .banner-four__text {
//   font-size    : 18px;
//   line-height  : 32px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-top   : 16px;
//   margin-bottom: 30px;
// }

// .banner-four__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .banner-four__btn:hover {
//   color: var(--customVar-black);
// }

// .banner-four__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .banner-four__btn::after {
//   background-color: var(--customVar-white, #ffffff);
// }

// .banner-four__img {
//   position: relative;
//   display : block;
// }

// .banner-four__img img {
//   width: 100%;
// }

// /***********************
// * Client Logo
// ***********************/
// .client-logo {
//   position: relative;
//   display : block;
//   padding : 74px 0 71px;
// }

// .client-logo__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   margin     : 0;
// }

// .client-logo__list li {
//   position : relative;
//   flex     : 0 0 25%;
//   max-width: 25%;
//   width    : 100%;
// }

// .client-logo__single {
//   position: relative;
//   display : block;
// }

// .client-logo__img {
//   position     : relative;
//   display      : block;
//   margin-bottom: 20px;
// }

// .client-logo__img img {
//   width: auto;
// }

// .client-logo__rating-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .client-logo__rate {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .client-logo__rate i {
//   font-size: 24px;
//   color    : #ffa801;
// }

// .client-logo__rate i+i {
//   margin-left: 10px;
// }

// .client-logo__point {
//   margin     : 0;
//   font-size  : 22px;
//   color      : var(--customVar-black);
//   font-weight: 600;
//   margin-left: 10px;
// }

// /***********************
// * Pricing One
// ***********************/
// .pricing-one {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   overflow        : hidden;
//   padding         : 108px 0 90px;
//   z-index         : 1;
// }

// .pricing-one__shape-1 {
//   position: absolute;
//   top     : 50px;
//   right   : 485px;
// }

// .pricing-one__shape-1 img {
//   width: auto;
// }

// .pricing-one__shape-2 {
//   position      : absolute;
//   top           : 0;
//   right         : 0;
//   mix-blend-mode: multiply;
// }

// .pricing-one__shape-2 img {
//   width: auto;
// }

// .pricing-one__shape-3 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
// }

// .pricing-one__shape-3 img {
//   width: auto;
// }

// .pricing-one__shape-4 {
//   position: absolute;
//   top     : 0;
//   right   : 288px;
// }

// .pricing-one__shape-4 img {
//   width: auto;
// }

// .pricing-one__shape-5 {
//   position: absolute;
//   top     : 0;
//   right   : 270px;
// }

// .pricing-one__shape-5 img {
//   width: auto;
// }

// .pricing-one__shape-6 {
//   position: absolute;
//   top     : 30px;
//   right   : 0px;
// }

// .pricing-one__shape-6 img {
//   width: auto;
// }

// .pricing-one__shape-7 {
//   position: absolute;
//   top     : 120px;
//   right   : 290px;
// }

// .pricing-one__shape-7 img {
//   width: auto;
// }

// .pricing-one__shape-8 {
//   position: absolute;
//   top     : 285px;
//   right   : 45px;
// }

// .pricing-one__shape-8 img {
//   width: auto;
// }

// .pricing-one__shape-22 {
//   position: absolute;
//   top     : 116px;
//   right   : 30px;
//   z-index : 2;
// }

// .pricing-one__shape-22 img {
//   width: auto;
// }

// .pricing-one__shape-9 {
//   position: absolute;
//   top     : 185px;
//   right   : 425px;
// }

// .pricing-one__shape-9 img {
//   width: auto;
// }

// .pricing-one__shape-10 {
//   position: absolute;
//   top     : 295px;
//   right   : 275px;
// }

// .pricing-one__shape-10 img {
//   width: auto;
// }

// .pricing-one__shape-11 {
//   position: absolute;
//   bottom  : 350px;
//   left    : 0px;
// }

// .pricing-one__shape-11 img {
//   width: auto;
// }

// .pricing-one__shape-12 {
//   position: absolute;
//   bottom  : 450px;
//   left    : 30px;
//   z-index : 2;
// }

// .pricing-one__shape-12 img {
//   width: auto;
// }

// .pricing-one__shape-13 {
//   position: absolute;
//   bottom  : 100px;
//   left    : 465px;
//   z-index : 1;
// }

// .pricing-one__shape-13 img {
//   width: auto;
// }

// .pricing-one__shape-14 {
//   position: absolute;
//   bottom  : 375px;
//   left    : 210px;
// }

// .pricing-one__shape-14 img {
//   width: auto;
// }

// .pricing-one__shape-15 {
//   position: absolute;
//   bottom  : 0px;
//   left    : 0px;
// }

// .pricing-one__shape-15 img {
//   width: auto;
// }

// .pricing-one__shape-16 {
//   position: absolute;
//   bottom  : 82px;
//   left    : 0px;
// }

// .pricing-one__shape-16 img {
//   width: auto;
// }

// .pricing-one__shape-17 {
//   position: absolute;
//   bottom  : 100px;
//   left    : 0px;
// }

// .pricing-one__shape-17 img {
//   width: auto;
// }

// .pricing-one__shape-18 {
//   position: absolute;
//   bottom  : 100px;
//   left    : 77px;
// }

// .pricing-one__shape-18 img {
//   width: auto;
// }

// .pricing-one__shape-19 {
//   position: absolute;
//   bottom  : 0px;
//   left    : 40px;
// }

// .pricing-one__shape-19 img {
//   width: auto;
// }

// .pricing-one__shape-21 {
//   position: absolute;
//   bottom  : 185px;
//   left    : 290px;
// }

// .pricing-one__shape-20 img {
//   width: auto;
// }

// .pricing-one__shape-20 {
//   position: absolute;
//   bottom  : 0px;
//   left    : 167px;
// }

// .pricing-one__shape-21 img {
//   width: auto;
// }

// .pricing-one__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-one__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-one__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-one ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-one ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-one ul.switch-toggler-list li.active a {
//   color: var(--customVar-secondary);
// }

// .pricing-one .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-one .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-one .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-one .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-one .slider.round {
//   border-radius: 5px;
// }

// .pricing-one .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-one__single {
//   position          : relative;
//   display           : block;
//   background-color  : var(--customVar-white);
//   padding           : 48px 30px 40px;
//   border-radius     : 20px;
//   margin-bottom     : 30px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   z-index           : 1;
// }

// .pricing-one__single:hover {
//   box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
// }

// .pricing-one__price-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .pricing-one__price {
//   font-size      : 50px;
//   font-weight    : 800;
//   color          : var(--customVar-black);
//   line-height    : 50px;
//   margin         : 0;
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .pricing-one__price-point {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   top        : 11px;
// }

// .pricing-one__price-validity {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   font-weight: 500;
//   top        : 11px;
// }

// .pricing-one__price-renew {
//   color        : #2959eb;
//   font-weight  : 400;
//   line-height  : 20px;
//   margin       : 0;
//   margin-top   : 10px;
//   margin-bottom: 9px;
// }

// .pricing-one__title {
//   font-size  : 26px;
//   font-weight: 600;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 36px;
// }

// .pricing-one__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .pricing-one__title a:hover {
//   color: var(--customVar-secondary);
// }

// .pricing-one__img {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   margin-top   : 26px;
//   margin-bottom: 26px;
// }

// .pricing-one__img img {
//   width: auto;
// }

// .pricing-one__btn-box {
//   position: relative;
//   display : block;
// }

// .pricing-one__btn {
//   width          : 100%;
//   text-align     : center;
//   justify-content: center;
//   color          : var(--customVar-white);
// }

// .pricing-one__btn:hover {
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .pricing-one__btn:before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-one__btn:after {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .pricing-one__points {
//   position  : relative;
//   display   : block;
//   margin-top: 35px;
// }

// .pricing-one__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-one__points li+li {
//   margin-top: 8px;
// }

// .pricing-one__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-one__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .pricing-one__points li .text {
//   margin-left: 20px;
// }

// .pricing-one__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-one__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// /***********************
// * Feature One
// ***********************/
// .feature-one {
//   position        : relative;
//   display         : block;
//   padding         : 108px 0 90px;
//   background-color: #fafbff;
// }

// .feature-one__sec-title {
//   position     : relative;
//   display      : block;
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 40px;
//   text-align   : center;
// }

// .feature-one__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .feature-one__list li {
//   position     : relative;
//   flex         : 0 0 25%;
//   max-width    : 25%;
//   width        : 100%;
//   margin-bottom: 3px;
// }

// .feature-one__list li:nth-child(1) {
//   padding-right: 75px;
// }

// .feature-one__list li:nth-child(2) {
//   padding-right: 50px;
//   padding-left : 20px;
// }

// .feature-one__list li:nth-child(3) {
//   padding-left : 40px;
//   padding-right: 40px;
// }

// .feature-one__list li:nth-child(4) {
//   padding-left: 50px;
// }

// .feature-one__single {
//   position: relative;
//   display : block;
// }

// .feature-one__icon {
//   position: relative;
//   display : block;
// }

// .feature-one__icon img {
//   width: auto;
// }

// .feature-one__title {
//   position     : relative;
//   display      : block;
//   font-size    : 22px;
//   font-weight  : 600;
//   line-height  : 32px;
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .feature-one__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-one__title a:hover {
//   color: var(--customVar-secondary);
// }

// .feature-one__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
// }

// .feature-one__list li:nth-child(2) .feature-one__title {
//   margin-top: -17px;
// }

// .feature-one__list li:nth-child(3) .feature-one__title {
//   margin-top: -12px;
// }

// /***********************
// * Robust One
// ***********************/
// .robust-one {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 63px 0 120px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .robust-one__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .robust-one__left {
//   position    : relative;
//   display     : block;
//   margin-top  : 65px;
//   margin-right: -26px;
// }

// .robust-one__title {
//   font-size    : 40px;
//   color        : var(--customVar-white);
//   font-weight  : 700;
//   line-height  : 50px;
//   margin       : 0;
//   margin-bottom: 30px;
// }

// .robust-one__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .robust-one__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .robust-one__points li+li {
//   margin-top: 5px;
// }

// .robust-one__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .robust-one__points li .icon span {
//   font-size: 20px;
//   color    : var(--customVar-white);
// }

// .robust-one__points li .text {
//   margin-left: 20px;
// }

// .robust-one__points li .text p {
//   color         : var(--customVar-white);
//   letter-spacing: -0.015em;
// }

// .robust-one__counter-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   margin     : 0;
//   margin-top : 25px;
// }

// .robust-one__counter-box li {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   padding      : 40px 30px 30px;
//   max-width    : 260px;
//   width        : 100%;
// }

// .robust-one__counter-box li+li {
//   margin-left: 20px;
// }

// .robust-one__counter-count-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .robust-one__counter-count-box h3 {
//   font-size     : 40px;
//   color         : var(--customVar-black);
//   letter-spacing: -0.035em;
//   font-weight   : 700;
//   margin        : 0;
//   line-height   : 40px;
// }

// .robust-one__counter-text {
//   font-size     : 40px;
//   color         : var(--customVar-black);
//   letter-spacing: -0.035em;
//   font-weight   : 700;
//   margin        : 0;
//   line-height   : 40px;
// }

// .robust-one__counter-count-box i {
//   position               : relative;
//   display                : inline-block;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   transform              : rotate(-90deg);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   font-size              : 15px;
//   padding-top            : 5px;
// }

// .robust-one__counter-text-2 {
//   margin        : 0;
//   color         : var(--customVar-black);
//   letter-spacing: -0.015em;
//   margin-top    : 7px;
// }

// .robust-one__right {
//   position    : relative;
//   display     : block;
//   margin-left : 67px;
//   margin-right: -11px;
// }

// .robust-one__img {
//   position: relative;
//   display : block;
// }

// .robust-one__img img {
//   width: 100%;
// }

// /***********************
// * Data Center Two
// ***********************/
// .data-center-two {
//   position: relative;
//   display : block;
//   padding : 95px 0 0;
// }

// .data-center-two__top {
//   position : relative;
//   display  : block;
//   max-width: 1100px;
//   width    : 100%;
//   margin   : 0 auto;
// }

// .data-center-two__top-title {
//   position     : relative;
//   display      : block;
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   text-align   : center;
//   margin       : 0;
//   margin-bottom: 50px;
// }

// .data-center-two__map {
//   position: relative;
//   display : block;
// }

// .data-center-two__map img {
//   width: 100%;
// }

// .data-center-two__location-1 {
//   position: absolute;
//   width   : 192px;
//   height  : 155px;
//   top     : -8%;
//   left    : 2%;
// }

// .data-center-two__map-markar {
//   position        : absolute;
//   top             : 179px;
//   left            : 117px;
//   border-radius   : 50%;
//   background-color: rgba(25, 213, 100, 0.38);
//   z-index         : 5;
//   height          : 30px;
//   width           : 30px;
//   cursor          : pointer;
// }

// .data-center-two__map-markar:before {
//   position        : absolute;
//   top             : 5px;
//   bottom          : 5px;
//   right           : 5px;
//   left            : 5px;
//   background-color: #19cf62;
//   content         : "";
//   border-radius   : 50%;
// }

// .data-center-two__popup-box {
//   position                  : absolute;
//   top                       : 0px;
//   left                      : 0px;
//   z-index                   : 5;
//   opacity                   : 1;
//   transform                 : perspective(400px) rotateX(90deg) translateY(10px);
//   transform-origin          : bottom;
//   transition-delay          : 0.1s;
//   transition-timing-function: ease-in-out;
//   transition-duration       : 0.5s;
//   transition-property       : all;
// }

// .data-center-two__location-1:hover .data-center-two__popup-box {
//   opacity                   : 1;
//   transform                 : perspective(400px) rotateX(0deg) translateY(0);
//   transition-delay          : 0.3s;
//   transition-timing-function: ease-in-out;
//   transition-duration       : 0.5s;
//   transition-property       : all;
// }

// .data-center-two__popup {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   box-shadow      : 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
//   max-width       : 192px;
//   width           : 100%;
//   padding         : 5px;
// }

// .data-center-two__popup:before {
//   content      : "";
//   position     : absolute;
//   bottom       : -15px;
//   right        : 68px;
//   border-bottom: 15px solid transparent;
//   border-right : 15px solid var(--customVar-white);
// }

// .data-center-two__popup-inner {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-base);
//   padding         : 17px 17px 18px;
// }

// .data-center-two__popup-inner:before {
//   content      : "";
//   position     : absolute;
//   bottom       : -15px;
//   right        : 58px;
//   border-bottom: 15px solid transparent;
//   border-right : 15px solid var(--customVar-base);
// }

// .data-center-two__popup-title {
//   font-size  : 16px;
//   font-weight: 700;
//   color      : var(--customVar-white);
//   line-height: 18px;
// }

// .data-center-two__popup-text {
//   font-size  : 12px;
//   color      : var(--customVar-white);
//   line-height: 20px;
//   padding-top: 7px;
// }

// .data-center-two__popup-km {
//   font-size     : 12px;
//   font-weight   : 700;
//   color         : var(--customVar-white);
//   text-transform: uppercase;
//   line-height   : 12px;
//   display       : block;
//   text-align    : right;
//   margin-top    : -3px;
// }

// .data-center-two__location-2 {
//   left: 15%;
//   top : 0%;
// }

// .data-center-two__map-markar-2 {
//   left: 120px;
//   top : 172px;
// }

// .data-center-two__location-3 {
//   left  : 17%;
//   top   : auto;
//   bottom: 43%;
// }

// .data-center-two__map-markar-3 {
//   left : auto;
//   right: 40px;
//   top  : 175px;
// }

// .data-center-two__location-4 {
//   left  : 31%;
//   top   : auto;
//   bottom: 48%;
// }

// .data-center-two__map-markar-4 {
//   left : auto;
//   right: 35px;
//   top  : 177px;
// }

// .data-center-two__location-5 {
//   left: 42%;
//   top : 0%;
// }

// .data-center-two__map-markar-5 {
//   left : auto;
//   right: 35px;
//   top  : 177px;
// }

// .data-center-two__location-6 {
//   left : auto;
//   right: 18%;
//   top  : -8%;
// }

// .data-center-two__map-markar-6 {
//   left : auto;
//   right: 35px;
//   top  : 177px;
// }

// .data-center-two__location-7 {
//   left  : auto;
//   top   : auto;
//   right : 28%;
//   bottom: 52%;
// }

// .data-center-two__map-markar-7 {
//   left : auto;
//   right: 35px;
//   top  : 177px;
// }

// .data-center-two__location-8 {
//   left  : 31%;
//   top   : auto;
//   bottom: 48%;
// }

// .data-center-two__location-8 {
//   left  : auto;
//   top   : auto;
//   right : 12%;
//   bottom: 27%;
// }

// .data-center-two__bottom {
//   position: relative;
//   display : block;
//   padding : 50px 0 100px;
// }

// .data-center-two__bottom .row {
//   --bs-gutter-x: 20px;
// }

// .data-center-two__single {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   background   : #ffffff;
//   box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   margin-bottom: 20px;
//   padding      : 30px 30px 30px;
// }

// .data-center-two__flag {
//   position     : relative;
//   display      : block;
//   width        : 40px;
//   border-radius: 50%;
// }

// .data-center-two__flag img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .data-center-two__country-name {
//   font-size  : 22px;
//   font-weight: 600;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 32px;
//   margin-left: 20px;
// }

// /***********************
// * Why Choose One
// ***********************/
// .why-choose-one {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 120px 0 93px;
//   z-index         : 1;
// }

// .why-choose-one__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   mix-blend-mode     : multiply;
//   opacity            : 0.08;
//   z-index            : -1;
// }

// .why-choose-one__left {
//   position    : relative;
//   display     : block;
//   margin-right: 14px;
// }

// .why-choose-one__img {
//   position: relative;
//   display : block;
// }

// .why-choose-one__img img {
//   width: 100%;
// }

// .why-choose-one__right {
//   position   : relative;
//   display    : block;
//   margin-top : 12px;
//   margin-left: 70px;
// }

// .why-choose-one__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   color        : var(--customVar-black);
//   line-height  : 50px;
//   margin       : 0;
//   margin-bottom: 25px;
// }

// .why-choose-one__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .why-choose-one__points li {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   background   : #ffffff;
//   box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   padding      : 25px 48px 26px;
// }

// .why-choose-one__points li+li {
//   margin-top: 15px;
// }

// .why-choose-one__icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .why-choose-one__icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 50px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .why-choose-one__content {
//   margin-left: 35px;
// }

// .why-choose-one__content-title {
//   font-size    : 22px;
//   font-weight  : 600;
//   line-height  : 32px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 5px;
// }

// .why-choose-one__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /***********************
// * Testimonial One
// ***********************/
// .testimonial-one {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 109px 0 120px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .testimonial-one__shape-1 {
//   position: absolute;
//   top     : 0;
//   left    : 0;
//   z-index : -1;
// }

// .testimonial-one__shape-1 img {
//   width: auto;
// }

// .testimonial-one__shape-2 {
//   position: absolute;
//   bottom  : 0;
//   right   : 0;
//   z-index : -1;
// }

// .testimonial-one__shape-2 img {
//   width: auto;
// }

// .testimonial-one__inner {
//   position    : relative;
//   display     : block;
//   margin-right: -450px;
// }

// .testimonial-one__title {
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 40px;
// }

// .testimonial-one__carousel {
//   position: relative;
//   display : block;
// }

// .testimonial-one__carousel.owl-carousel .owl-stage-outer {
//   overflow: visible;
// }

// .testimonial-one__carousel.owl-carousel .owl-item {
//   opacity   : 0;
//   visibility: hidden;
//   transition: opacity 500ms ease, visibility 500ms ease;
// }

// .testimonial-one__carousel.owl-carousel .owl-item.active {
//   opacity   : 1;
//   visibility: visible;
// }

// .testimonial-one__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
// }

// .testimonial-one__content-box {
//   position     : relative;
//   display      : block;
//   border-bottom: 1px solid #e3e3e3;
//   padding      : 35px 35px 42px;
// }

// .testimonial-one__text {
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin        : 0;
// }

// .testimonial-one__text span {
//   font-weight: 600;
//   color      : var(--customVar-black);
// }

// .testimonial-one__rating-and-quote-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   margin-top     : 26px;
// }

// .testimonial-one__rating-logo {
//   position     : relative;
//   display      : block;
//   margin-bottom: 11px;
// }

// .testimonial-one__rating-logo img {
//   width: auto !important;
// }

// .testimonial-one__rating-star {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .testimonial-one__rating-star i {
//   position : relative;
//   display  : inline-block;
//   font-size: 20px;
//   color    : #ffa800;
// }

// .testimonial-one__rating-star i+i {
//   margin-left: 5px;
// }

// .testimonial-one__quote {
//   position: relative;
//   display : block;
// }

// .testimonial-one__quote img {
//   width: auto !important;
// }

// .testimonial-one__client-info {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   padding    : 20px 35px 23px;
// }

// .testimonial-one__client-img {
//   position     : relative;
//   display      : block;
//   width        : 70px;
//   border-radius: 50%;
// }

// .testimonial-one__client-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .testimonial-one__client-content {
//   margin-left: 20px;
// }

// .testimonial-one__name {
//   font-size    : 20px;
//   color        : var(--customVar-black);
//   font-weight  : 600;
//   line-height  : 30px;
//   margin       : 0;
//   margin-bottom: 3px;
// }

// .testimonial-one__sub-title {
//   font-size  : 15px;
//   line-height: 15px;
//   margin     : 0;
//   color      : var(--customVar-secondary);
// }

// .testimonial-one__carousel.owl-theme .owl-nav {
//   position: absolute;
//   top     : -112px;
//   right   : 450px;
//   margin  : 0;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next {
//   height            : 60px;
//   width             : 60px;
//   line-height       : 60px;
//   color             : var(--customVar-white);
//   border-radius     : 50%;
//   background-color  : transparent;
//   font-size         : 14px;
//   margin            : 0;
//   text-align        : center;
//   border            : 2px solid var(--customVar-white);
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   position          : relative;
//   display           : inline-block;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next:before,
// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev:before {
//   position        : absolute;
//   top             : 0;
//   left            : 0;
//   right           : 0;
//   bottom          : 0;
//   content         : "";
//   background      : linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
//   border-radius   : 10px;
//   transform       : scale(0);
//   transform-origin: center;
//   transform-style : preserve-3d;
//   transition      : all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
//   z-index         : -1;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev {
//   height            : 60px;
//   width             : 60px;
//   line-height       : 60px;
//   color             : var(--customVar-white);
//   border-radius     : 50%;
//   background-color  : transparent;
//   font-size         : 14px;
//   margin            : 0;
//   text-align        : center;
//   border            : 2px solid var(--customVar-white);
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   position          : relative;
//   display           : inline-block;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next {
//   margin-left: 5px;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev {
//   margin-right: 5px;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next span,
// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev span {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev span {
//   transform: rotate(180deg);
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next:hover,
// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev:hover {
//   border: 2px solid transparent;
// }

// .testimonial-one__carousel.owl-theme .owl-nav .owl-next:hover:before,
// .testimonial-one__carousel.owl-theme .owl-nav .owl-prev:hover:before {
//   transform: scaleX(1);
// }

// /***********************
// * Apps One
// ***********************/
// .apps-one {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 120px 0 90px;
//   z-index         : 1;
// }

// .apps-one__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .apps-one__left {
//   position  : relative;
//   display   : block;
//   margin-top: 10px;
// }

// .apps-one__title {
//   font-size  : 40px;
//   font-weight: 700;
//   color      : var(--customVar-black);
//   line-height: 50px;
//   margin     : 0;
// }

// .apps-one__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
//   margin-top    : 23px;
//   margin-bottom : 27px;
// }

// .apps-one__btn {
//   color     : var(--customVar-white);
//   box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .apps-one__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .apps-one__btn::after {
//   background-color: var(--customVar-black);
// }

// .apps-one__right {
//   position   : relative;
//   display    : block;
//   margin-left: 74px;
// }

// .apps-one__list {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   flex-wrap      : wrap;
//   margin         : 0;
// }

// .apps-one__list li {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   background     : #ffffff;
//   box-shadow     : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius  : 20px;
//   height         : 100px;
//   width          : 210px;
//   margin-bottom  : 30px;
// }

// .apps-one__list li+li {
//   margin-left: 26px;
// }

// .apps-one__logo {
//   position: relative;
//   display : block;
// }

// .apps-one__logo img {
//   width: auto;
// }

// /***********************
// * Blog One
// ***********************/
// .blog-one {
//   position        : relative;
//   display         : block;
//   padding         : 109px 0 90px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .blog-one__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .blog-one__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .blog-one__section-title__text {
//   font-size     : 16px;
//   margin        : 0;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
// }

// .blog-one__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   margin-bottom: 30px;
// }

// .blog-one__img {
//   position               : relative;
//   display                : block;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
//   overflow               : hidden;
//   z-index                : 1;
// }

// .blog-one__img img {
//   width             : 100%;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-one__single:hover .blog-one__img img {
//   transform: scale(1.05);
// }

// .blog-one__content {
//   position: relative;
//   display : block;
//   padding : 35px 30px 25px;
// }

// .blog-one__title {
//   font-size  : 22px;
//   font-weight: 500;
//   line-height: 32px;
//   margin     : 0;
// }

// .blog-one__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-one__title a:hover {
//   color: var(--customVar-base);
// }

// .blog-one__text {
//   margin        : 0;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   margin-top    : 5px;
//   margin-bottom : 22px;
// }

// .blog-one__btn {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-size         : 18px;
//   font-weight       : 600;
//   letter-spacing    : 0.01em;
//   color             : var(--customVar-secondary);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-one__btn span {
//   position    : relative;
//   display     : inline-block;
//   padding-left: 10px;
//   font-size   : 12px;
// }

// .blog-one__btn:hover {
//   color: var(--customVar-base);
// }

// .blog-one__user-and-date {
//   position        : absolute;
//   display         : flex;
//   align-items     : center;
//   justify-content : space-between;
//   flex-wrap       : wrap;
//   background-color: #333333;
//   border-radius   : 20px;
//   padding-right   : 23px;
//   top             : -20px;
//   left            : 30px;
//   right           : 30px;
//   z-index         : 2;
// }

// .blog-one__user {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .blog-one__user-img {
//   position     : relative;
//   display      : block;
//   height       : 35px;
//   width        : 35px;
//   border-radius: 50%;
// }

// .blog-one__user-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-one__user-name {
//   font-size     : 12px;
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   margin        : 0;
//   line-height   : 12px;
//   margin-left   : 10px;
// }

// .blog-one__date {
//   font-size     : 12px;
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   margin        : 0;
//   line-height   : 12px;
// }

// /***********************
// * Customar Rating
// ***********************/
// .choose-domain {
//   position  : relative;
//   display   : block;
//   margin-top: -145px;
//   z-index   : 3;
// }

// .choose-domain__inner {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 5px 20px rgba(0, 0, 0, 0.07);
//   border-radius: 20px;
// }

// .choose-domain__inner-top {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   border-bottom  : 1px solid rgba(221, 221, 221, 0.4);
//   padding        : 42px 50px 42px;
//   z-index        : 3;
// }

// .choose-domain__title {
//   font-size  : 30px;
//   font-weight: 700;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 40px;
// }

// .choose-domain__right {
//   position : relative;
//   display  : block;
//   max-width: 724px;
//   width    : 100%;
// }

// .choose-domain__form {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .choose-domain__input-box {
//   position : relative;
//   display  : block;
//   max-width: 480px;
//   width    : 100%;
// }

// .choose-domain__input-box input[type="text"] {
//   outline           : none;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : rgba(255, 255, 255, 1);
//   border            : 1.5px solid #e9e9e9;
//   border-radius     : 10px;
//   display           : block;
//   max-width         : 480px;
//   width             : 100%;
//   height            : 60px;
//   color             : var(--customVar-text);
//   font-size         : 16px;
//   font-weight       : 400;
//   padding-left      : 25px;
//   padding-right     : 106px;
// }

// .choose-domain__btn {
//   padding    : 14px 50px;
//   color      : var(--customVar-white);
//   margin-left: 15px;
// }

// .choose-domain__btn:before {
//   background-color: var(--customVar-secondary);
// }

// .choose-domain__name {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   position         : absolute;
//   top              : 50%;
//   right            : 20px;
//   transform        : translateY(-50%);
//   border-left      : 1.5px solid #e9e9e9;
//   padding-left     : 20px;
//   height           : 30px;
// }

// .choose-domain__name .bootstrap-select>.dropdown-toggle {
//   font-size  : 16px;
//   font-family: var(--customVar-body-font);
//   font-weight: 400;
//   color      : var(--customVar-black);
// }

// .choose-domain__name .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 17px;
//   position   : relative;
//   top        : 2px;
// }

// .choose-domain__bottom {
//   position      : relative;
//   display       : block;
//   padding-top   : 13px;
//   padding-bottom: 9px;
//   padding-left  : 295px;
//   padding-right : 295px;
// }

// .choose-domain__list {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   flex-wrap      : wrap;
//   margin         : 0;
// }

// .choose-domain__list li {
//   position  : relative;
//   flex      : 0 0 20%;
//   max-width : 20%;
//   width     : 100%;
//   text-align: center;
// }

// .choose-domain__type {
//   font-size     : 20px;
//   letter-spacing: -0.015em;
//   color         : #0ea5ff;
//   font-weight   : 700;
//   line-height   : 20px;
//   margin        : 0;
// }

// .choose-domain__price {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .choose-domain__list li:nth-child(2) .choose-domain__type {
//   color: #00c14d;
// }

// .choose-domain__list li:nth-child(3) .choose-domain__type {
//   color: #ff5a74;
// }

// .choose-domain__list li:nth-child(4) .choose-domain__type {
//   color: #ffa800;
// }

// .choose-domain__list li:nth-child(5) .choose-domain__type {
//   color: #586eff;
// }

// /***********************
// * Customar Rating
// ***********************/
// .customar-rating {
//   position        : relative;
//   display         : block;
//   padding         : 120px 0 120px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .customar-rating__left {
//   position    : relative;
//   display     : block;
//   margin-left : 39px;
//   margin-right: 39px;
// }

// .customar-rating__left-content {
//   position  : relative;
//   display   : block;
//   max-width : 455px;
//   width     : 100%;
//   text-align: center;
//   z-index   : 1;
// }

// .customar-rating__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .customar-rating__left-content-inner {
//   position: relative;
//   display : block;
//   overflow: hidden;
//   padding : 34px 0 45px;
//   z-index : 1;
// }

// .customar-rating__star-1 {
//   position: absolute;
//   top     : 55px;
//   left    : 116px;
// }

// .customar-rating__star-1 img {
//   width: auto;
// }

// .customar-rating__star-2 {
//   position: absolute;
//   top     : 46px;
//   right   : 98px;
// }

// .customar-rating__star-2 img {
//   width: auto;
// }

// .customar-rating__star-3 {
//   position: absolute;
//   bottom  : 152px;
//   left    : 50px;
// }

// .customar-rating__star-3 img {
//   width: auto;
// }

// .customar-rating__star-4 {
//   position: absolute;
//   bottom  : 65px;
//   right   : 50px;
// }

// .customar-rating__star-4 img {
//   width: auto;
// }

// .customar-rating__icon {
//   position: relative;
//   display : block;
// }

// .customar-rating__icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 60px;
//   color    : var(--customVar-white);
// }

// .customar-rating__best-number {
//   font-size     : 40px;
//   line-height   : 40px;
//   letter-spacing: 0.02em;
//   color         : var(--customVar-white);
//   font-weight   : 700;
//   margin        : 0;
//   margin-top    : 3px;
//   margin-bottom : 9px;
// }

// .customar-rating__curved-circle {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   height         : 55px;
//   width          : 235px;
//   margin         : 0 auto;
// }

// .customar-rating__curved-circle .curved-circle {
//   position      : absolute;
//   font-size     : 30px;
//   font-weight   : 800;
//   color         : #fff;
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   top           : 20px;
//   left          : 0;
//   right         : 0;
// }

// .customar-rating__title-two {
//   font-size     : 50px;
//   color         : var(--customVar-white);
//   font-weight   : 600;
//   text-transform: uppercase;
//   letter-spacing: 0.02em;
//   line-height   : 55px;
//   margin        : 0;
//   margin-top    : 40px;
//   margin-bottom : 6px;
// }

// .customar-rating__year {
//   font-size     : 45px;
//   font-weight   : 800;
//   letter-spacing: 0.02em;
//   color         : #ffd60c;
//   line-height   : 45px;
// }

// .customar-rating__right {
//   position    : relative;
//   display     : block;
//   margin-left : 40px;
//   margin-right: 40px;
// }

// .customar-rating__list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .customar-rating__list li {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   border-bottom  : 1px solid rgba(221, 221, 221, 0.4);
//   padding-bottom : 24px;
//   margin-bottom  : 27px;
// }

// .customar-rating__list li:last-child {
//   border-bottom : 0;
//   padding-bottom: 0;
//   margin-bottom : 0;
// }

// .customar-rating__content {
//   position: relative;
//   display : block;
// }

// .customar-rating__logo {
//   position: relative;
//   display : block;
// }

// .customar-rating__logo img {
//   width: auto;
// }

// .customar-rating__text {
//   letter-spacing: -0.015em;
//   color         : #000000;
//   margin        : 0;
// }

// .customar-rating__rating-point-and-star {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .customar-rating__rating-point {
//   font-size    : 30px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   line-height  : 30px;
//   margin       : 0;
//   margin-bottom: 13px;
// }

// .customar-rating__star {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .customar-rating__star i {
//   font-size: 18px;
//   color    : #ffa800;
// }

// .customar-rating__star i+i {
//   margin-left: 5px;
// }

// /***********************
// * Services One
// ***********************/
// .services-one {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 108px 0 90px;
//   z-index         : 1;
// }

// .services-one__shape-1 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
//   z-index : -1;
// }

// .services-one__shape-1 img {
//   width: auto;
// }

// .services-one__shape-2 {
//   position: absolute;
//   bottom  : 0;
//   left    : 0;
//   z-index : -1;
// }

// .services-one__shape-2 img {
//   width: auto;
// }

// .services-one__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .services-one__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .services-one__section-title__text {
//   font-size  : 16px;
//   margin     : 0;
//   line-height: 26px;
// }

// .services-one__single {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   border-radius   : 20px;
//   padding         : 40px 40px 34px;
//   margin-bottom   : 30px;
// }

// .services-one__tag {
//   position       : absolute;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   background     : #000d61;
//   border-radius  : 0px 20px 0px 15px;
//   max-width      : 140px;
//   width          : 100%;
//   top            : 0;
//   right          : 0;
// }

// .services-one__tag-icon {
//   position: relative;
//   display : block;
//   top     : -2px;
// }

// .services-one__tag-icon img {
//   width: auto;
// }

// .services-one__tag-text {
//   font-size     : 11px;
//   margin        : 0;
//   line-height   : 11px;
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   text-transform: uppercase;
//   margin-left   : 5px;
// }

// .services-one__icon {
//   position: relative;
//   display : block;
// }

// .services-one__icon img {
//   width: auto;
// }

// .services-one__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   line-height  : 32px;
//   margin       : 0;
//   margin-top   : 15px;
//   margin-bottom: 10px;
// }

// .services-one__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .services-one__title a:hover {
//   color: var(--customVar-secondary);
// }

// .services-one__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// .services-one__bottom-text {
//   font-weight: 600;
//   color      : var(--customVar-secondary);
//   margin     : 0;
//   margin-top : 18px;
// }

// /***********************
// * Pricing Two
// ***********************/
// .pricing-two {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 108px 0 120px;
//   z-index   : 1;
// }

// .pricing-two__shape-1 {
//   position: absolute;
//   left    : 0;
//   top     : 180px;
//   z-index : -1;
// }

// .pricing-two__shape-1 img {
//   width: auto;
// }

// .pricing-two__shape-2 {
//   position: absolute;
//   top     : 0;
//   right   : 186px;
//   z-index : -1;
// }

// .pricing-two__shape-2 img {
//   width: auto;
// }

// .pricing-two__shape-3 {
//   position: absolute;
//   bottom  : 0;
//   right   : -30px;
// }

// .pricing-two__shape-3 img {
//   width: auto;
// }

// .pricing-two__shape-4 {
//   position: absolute;
//   top     : 0;
//   left    : 1074px;
//   opacity : 0.2;
//   z-index : -1;
// }

// .pricing-two__shape-4 img {
//   width: auto;
// }

// .pricing-two__shape-5 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
//   opacity : 0.1;
//   z-index : -1;
// }

// .pricing-two__shape-5 img {
//   width: auto;
// }

// .pricing-two__title {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   font-size    : 40px;
//   font-weight  : 700;
//   color        : var(--customVar-white);
//   line-height  : 50px;
//   margin       : 0;
//   margin-bottom: 40px;
// }

// .pricing-two__inner {
//   position: relative;
//   display : block;
// }

// .pricing-two__tab-buttons-box {
//   position    : relative;
//   display     : block;
//   margin-right: -25px;
//   margin-top  : 55px;
// }

// .pricing-two__inner .tab-buttons {
//   position: relative;
//   display : block;
// }

// .pricing-two__inner .tab-buttons .tab-btn {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   border          : 1.5px solid rgba(255, 255, 255, 0.3);
//   border-radius   : 20px;
//   background-color: transparent;
//   cursor          : pointer;
//   padding         : 31px 30px 29px;
//   overflow        : hidden;
//   transition      : all 0.3s ease;
//   z-index         : 1;
// }

// .pricing-two__inner .tab-buttons .tab-btn:before {
//   content      : "";
//   position     : absolute;
//   top          : 0;
//   left         : 0;
//   right        : 0;
//   bottom       : 0;
//   height       : 0%;
//   background   : rgba(255, 255, 255, 0.2);
//   border-radius: 20px;
//   transition   : all 0.3s ease;
//   z-index      : -1;
// }

// .pricing-two__inner .tab-buttons .tab-btn.active-btn:before {
//   height: 100%;
// }

// .pricing-two__inner .tab-buttons .tab-btn+.tab-btn {
//   margin-top: 10px;
// }

// .pricing-two__inner .tab-buttons .tab-btn .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-two__inner .tab-buttons .tab-btn .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 45px;
//   color    : var(--customVar-white);
// }

// .pricing-two__inner .tab-buttons .tab-btn .content {
//   position   : relative;
//   display    : block;
//   margin-left: 30px;
// }

// .pricing-two__inner .tab-buttons .tab-btn .content h3 {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 10px;
// }

// .pricing-two__inner .tab-buttons .tab-btn .content p {
//   font-size     : 16px;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
//   margin        : 0;
// }

// .pricing-two__tabs-content-box {
//   position   : relative;
//   display    : block;
//   margin-left: -5px;
// }

// .pricing-two__inner .tabs-content {
//   position: relative;
//   display : block;
// }

// .pricing-two__inner .tabs-content .tab {
//   position          : relative;
//   display           : none;
//   -webkit-transform : translateY(35px);
//   -ms-transform     : translateY(35px);
//   transform         : translateY(35px);
//   -webkit-transition: all 600ms ease;
//   -moz-transition   : all 600ms ease;
//   -ms-transition    : all 600ms ease;
//   -o-transition     : all 600ms ease;
//   transition        : all 600ms ease;
//   z-index           : 10;
// }

// .pricing-two__inner .tabs-content .tab.active-tab {
//   display          : block;
//   margin-top       : 0px;
//   -webkit-transform: translateY(0px);
//   -ms-transform    : translateY(0px);
//   transform        : translateY(0px);
// }

// .pricing-two__inner-content {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   border-radius   : 20px;
//   padding         : 38px 50px 35px;
// }

// .pricing-two__inner-content-top {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   margin-bottom  : 19px;
// }

// .pricing-two__inner-content-top-left {
//   position: relative;
//   display : block;
// }

// .pricing-two__inner-content-top-left p {
//   font-size    : 13px;
//   line-height  : 13px;
//   font-weight  : 600;
//   color        : #ff756f;
//   margin       : 0;
//   background   : #ffebea;
//   border       : 1.5px solid #ffd3d2;
//   border-radius: 15px;
//   position     : relative;
//   display      : inline-block;
//   padding      : 7.5px 11px;
//   margin-bottom: 3px;
// }

// .pricing-two__inner-content-top-left h3 {
//   font-size  : 22px;
//   font-weight: 600;
//   line-height: 32px;
//   margin     : 0;
//   color      : var(--customVar-black);
// }

// .pricing-two__price {
//   font-size      : 60px;
//   font-weight    : 800;
//   color          : var(--customVar-black);
//   line-height    : 60px;
//   margin         : 0;
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .pricing-two__price-point {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   top        : 11px;
// }

// .pricing-two__price-validity {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   font-weight: 500;
//   top        : 11px;
// }

// .pricing-two__renew-box {
//   position      : relative;
//   display       : block;
//   border-bottom : 1px dashed #d9d9d9;
//   padding-top   : 17px;
//   padding-bottom: 6px;
//   margin-bottom : 30px;
// }

// .pricing-two__renew-box p {
//   font-size     : 16px;
//   line-height   : 20px;
//   text-align    : center;
//   letter-spacing: -0.015em;
//   color         : #2959eb;
//   font-family   : var(--customVar-title-font);
// }

// .pricing-two__btn {
//   width          : 100%;
//   text-align     : center;
//   justify-content: center;
//   color          : var(--customVar-white);
// }

// .pricing-two__btn:before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .pricing-two__btn:after {
//   background-color: var(--customVar-secondary);
// }

// .pricing-two__points-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .pricing-two__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-two__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-two__points li+li {
//   margin-top: 8px;
// }

// .pricing-two__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-two__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .pricing-two__points li .text {
//   margin-left: 20px;
// }

// .pricing-two__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-two__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// .pricing-two__points--two {
//   margin-left: 110px;
// }

// /***********************
// * Awarded One
// ***********************/
// .awarded-one {
//   position        : relative;
//   display         : block;
//   padding         : 108px 0 90px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .awarded-one__inner {
//   position    : relative;
//   display     : block;
//   margin-left : 154px;
//   margin-right: 154px;
// }

// .awarded-one__title {
//   position     : relative;
//   display      : block;
//   font-size    : 40px;
//   color        : var(--customVar-black);
//   font-weight  : 700;
//   line-height  : 50px;
//   text-align   : center;
//   margin       : 0;
//   margin-bottom: 37px;
// }

// .awarded-one__list {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   margin         : 0;
// }

// .awarded-one__list li {
//   position     : relative;
//   display      : block;
//   margin-bottom: 26px;
// }

// .awarded-one__img {
//   position: relative;
//   display : block;
// }

// .awarded-one__img img {
//   width: auto;
// }

// /***********************
// * Max Performance
// ***********************/
// .max-performance {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 0 0 120px;
//   z-index         : 1;
// }

// .max-performance__left {
//   position    : relative;
//   display     : block;
//   margin-right: 125px;
// }

// .max-performance__img {
//   position: relative;
//   display : block;
// }

// .max-performance__img img {
//   width: 100%;
// }

// .max-performance__right {
//   position  : relative;
//   display   : block;
//   margin-top: 16px;
// }

// .max-performance__title-top {
//   position   : relative;
//   display    : block;
//   font-size  : 40px;
//   color      : var(--customVar-black);
//   font-weight: 700;
//   line-height: 50px;
//   margin     : 0;
// }

// .max-performance__list {
//   position     : relative;
//   display      : block;
//   margin       : 0;
//   margin-top   : 47px;
//   margin-bottom: 45px;
// }

// .max-performance__list li {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   border-bottom  : 1px solid #dfdfdf;
//   padding-bottom : 26px;
// }

// .max-performance__list li+li {
//   margin-top: 17px;
// }

// .max-performance__list li:last-child {
//   border-bottom : 0;
//   padding-bottom: 0;
// }

// .max-performance__list-left {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .max-performance__icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .max-performance__icon span {
//   position                : relative;
//   display                 : inline-block;
//   font-size               : 45px;
//   background              : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip : text;
//   -moz-background-clip    : text;
//   background-clip         : text;
//   -webkit-text-fill-color : transparent;
//   -webkit-transition      : all 500ms linear;
//   transition              : all 500ms linear;
//   -webkit-transition-delay: 0.1s;
//   transition-delay        : 0.1s;
// }

// .max-performance__list li:hover .max-performance__icon span {
//   transform: scale(0.9);
// }

// .max-performance__title {
//   font-size  : 22px;
//   color      : var(--customVar-black);
//   line-height: 26px;
//   font-weight: 600;
//   margin     : 0;
//   margin-left: 25px;
// }

// .max-performance__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// .max-performance__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .max-performance__btn:before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .max-performance__btn:after {
//   background-color: var(--customVar-black);
// }

// /***********************
// * Testimonial Two
// ***********************/
// .testimonial-two {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 108px 0 120px;
//   z-index         : 1;
// }

// .testimonial-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .testimonial-two__top-title {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   font-size    : 40px;
//   line-height  : 50px;
//   font-weight  : 700;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 40px;
// }

// .testimonial-two__top {
//   position: relative;
//   display : block;
// }

// .testimonial-two__carousel-one {
//   position: relative;
//   display : block;
// }

// .testimonial-two__carousel-one.owl-carousel .owl-stage-outer {
//   overflow: visible;
// }

// .testimonial-two__carousel-two.owl-carousel .owl-stage-outer {
//   overflow: visible;
// }

// .testimonial-two__carousel-two {
//   margin-top: 25px;
// }

// .testimonial-two__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   padding      : 40px 35px 40px;
//   margin-bottom: 30px;
// }

// .testimonial-two__quote {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   margin-bottom: 32px;
// }

// .testimonial-two__quote span {
//   color    : #111b5d;
//   font-size: 40px;
// }

// .testimonial-two__quote-text {
//   font-size    : 20px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   line-height  : 20px;
//   margin       : 0;
//   margin-bottom: 13px;
// }

// .testimonial-two__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// .testimonial-two__client-info {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   margin-top     : 95px;
// }

// .testimonial-two__client-content {
//   position: relative;
//   display : block;
// }

// .testimonial-two__client-name {
//   font-size  : 20px;
//   font-weight: 600;
//   line-height: 20px;
//   color      : var(--customVar-black);
//   margin     : 0;
// }

// .testimonial-two__client-sub-title {
//   font-size    : 15px;
//   color        : var(--customVar-secondary);
//   line-height  : 20px;
//   margin       : 0;
//   margin-top   : 6px;
//   margin-bottom: 6px;
// }

// .testimonial-two__rating-star {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .testimonial-two__rating-star i {
//   color    : #ffa800;
//   font-size: 15px;
// }

// .testimonial-two__rating-star i+i {
//   margin-left: 4px;
// }

// .testimonial-two__client-img {
//   position     : relative;
//   display      : block;
//   width        : 70px;
//   border-radius: 50%;
// }

// .testimonial-two__client-img img {
//   border-radius: 50%;
// }

// /***********************
// * Support One
// ***********************/
// .support-one {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 134px 0 135px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .support-one__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .support-one__img {
//   position: absolute;
//   bottom  : 0;
//   right   : 330px;
// }

// .support-one__img img {
//   width: auto;
// }

// .support-one__left {
//   position: relative;
//   display : block;
// }

// .support-one__title {
//   font-size  : 40px;
//   font-weight: 700;
//   line-height: 50px;
//   color      : var(--customVar-white);
//   margin     : 0;
// }

// .support-one__text {
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   color         : var(--customVar-white);
//   margin        : 0;
//   margin-top    : 25px;
//   margin-bottom : 31px;
// }

// .support-one__details {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   flex-wrap    : wrap;
//   background   : #ffffff;
//   box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   margin       : 0;
// }

// .support-one__details li {
//   position  : relative;
//   flex      : 0 0 33.333333%;
//   max-width : 33.333333%;
//   width     : 100%;
//   text-align: center;
// }

// .support-one__single {
//   position: relative;
//   display : block;
//   padding : 40px 0 38px;
// }

// .support-one__single:before {
//   content         : "";
//   position        : absolute;
//   top             : 42px;
//   bottom          : 42px;
//   left            : 0;
//   width           : 1px;
//   background-color: #dbdbdb;
// }

// .support-one__details li:first-child .support-one__single:before {
//   display: none;
// }

// .support-one__icon {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   margin-bottom  : 16px;
// }

// .support-one__icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 38px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .support-one__text-2 {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   line-height   : 18px;
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// /***********************
// * Footer Main Two
// ***********************/
// .footer-main-two {
//   position      : relative;
//   display       : block;
//   padding-top   : 0;
//   padding-bottom: 70px;
// }

// .footer-main-two__top {
//   position        : relative;
//   display         : block;
//   background-color: #000621;
// }

// .footer-main-two__top-inner {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   padding        : 35px 0 35px;
// }

// .footer-main-two__top-text {
//   font-size  : 18px;
//   line-height: 28px;
//   margin     : 0;
//   color      : var(--customVar-white);
// }

// .footer-main-two__top-text a {
//   position: relative;
//   display : inline-block;
//   color   : var(--customVar-white);
// }

// .footer-main-two__top-text a:before {
//   position        : absolute;
//   bottom          : 2px;
//   left            : 0;
//   right           : 0;
//   height          : 1px;
//   background-color: var(--customVar-white);
//   content         : "";
// }

// .footer-main-two__top-inner .language-switcher {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   border           : 2px solid #636c94;
//   border-radius    : 10px;
//   padding          : 7px 30px;
// }

// .footer-main-two__top-inner .language-switcher i {
//   color       : #fff;
//   font-size   : 18px;
//   margin-right: 8px;
//   position    : relative;
//   top         : 2px;
// }

// .footer-main-two__top-inner .language-switcher .bootstrap-select>.dropdown-toggle {
//   font-size  : 17px;
//   font-family: var(--customVar-title-font, "Outfit", sans-serif);
//   font-weight: 400;
//   color      : #fff;
// }

// .footer-main-two__top-inner .language-switcher .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 12px;
// }

// .footer-main-two__middle {
//   position   : relative;
//   display    : block;
//   padding-top: 70px;
// }

// .footer-widget-two {
//   position    : relative;
//   display     : block;
//   margin-right: 40px;
// }

// .footer-widget-two__logo {
//   position: relative;
//   display : block;
// }

// .footer-widget-two__text {
//   letter-spacing: -0.015em;
//   color         : #c2c2c2;
//   margin        : 0;
//   line-height   : 26px;
//   margin-top    : 25px;
// }

// .footer-widget-two .footer-widget__social {
//   margin-top   : 33px;
//   margin-bottom: 0;
// }

// .footer-widget-two--domain {
//   margin-left: 20px;
// }

// .footer-widget-two--services {
//   margin-left : 60px;
//   margin-right: -20px;
// }

// .footer-widget-two--company {
//   margin-left: 73px;
// }

// .footer-widget-two--help {
//   margin-left : 48px;
//   margin-right: 17px;
// }

// .footer-main-two__contact-and-newsletter {
//   position   : relative;
//   display    : block;
//   padding-top: 22px;
// }

// .footer-main-two__contact {
//   position    : relative;
//   display     : block;
//   margin-top  : 51px;
//   margin-right: -30px;
// }

// .footer-main-two__contact-list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   margin     : 0;
// }

// .footer-main-two__contact-list li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .footer-main-two__contact-list li+li {
//   margin-left: 60px;
// }

// .footer-main-two__contact-list li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .footer-main-two__contact-list li .icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 46px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .footer-main-two__contact-list li .content {
//   margin-left: 25px;
// }

// .footer-main-two__contact-list li .content span {
//   position      : relative;
//   display       : inline-block;
//   font-size     : 20px;
//   font-weight   : 500;
//   line-height   : 20px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
// }

// .footer-main-two__contact-list li .content p {
//   font-size     : 26px;
//   color         : var(--customVar-white);
//   line-height   : 26px;
//   margin        : 0;
//   letter-spacing: -0.015em;
//   margin-top    : 8px;
// }

// .footer-main-two__contact-list li .content p a {
//   color             : var(--customVar-white);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .footer-main-two__contact-list li .content p a:hover {
//   color: var(--customVar-base);
// }

// .footer-main-two__newsletter {
//   position   : relative;
//   display    : block;
//   margin-left: 61px;
// }

// .footer-main-two__newsletter-title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 37px;
// }

// .footer-main-two__newsletter-form {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -ms-flex-wrap    : wrap;
//   flex-wrap        : wrap;
// }

// .footer-main-two__newsletter-form input[type="text"],
// .footer-main-two__newsletter-form input[type="email"] {
//   border            : none;
//   outline           : none;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : rgba(255, 255, 255, 1);
//   border            : 1.5px solid #e9e9e9;
//   border-radius     : 10px;
//   display           : block;
//   max-width         : 400px;
//   width             : 100%;
//   height            : 60px;
//   color             : var(--customVar-text);
//   font-size         : 16px;
//   font-weight       : 400;
//   padding-left      : 25px;
//   padding-right     : 25px;
// }

// .footer-main-two__newsletter-btn {
//   margin-left: 10px;
//   color      : var(--customVar-white);
// }

// .footer-main-two__newsletter-btn:hover {
//   color: var(--customVar-secondary);
// }

// .footer-main-two__newsletter-btn:before {
//   background-color: var(--customVar-secondary);
// }

// .footer-main-two__newsletter-btn:after {
//   background-color: var(--customVar-white);
// }

// .footer-bottom-two {
//   background-color: #010b3c;
//   border-top      : 1px solid #0f1c5c;
// }

// /***********************
// * Why Choose Two
// ***********************/
// .why-choose-two {
//   position  : relative;
//   display   : block;
//   padding   : 128px 0 110px;
//   margin-top: -260px;
//   z-index   : 2;
// }

// .why-choose-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: top center;
//   z-index            : -1;
// }

// .why-choose-two__top-title {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   font-weight  : 700;
//   font-size    : 38px;
//   line-height  : 48px;
//   text-align   : center;
//   color        : #ffffff;
//   margin-bottom: 40px;
// }

// .why-choose-two__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 15px 20px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   text-align   : center;
//   padding      : 48px 40px 25px;
//   margin-bottom: 30px;
// }

// .why-choose-two__icon {
//   position: relative;
//   display : block;
// }

// .why-choose-two__icon img {
//   width: auto;
// }

// .why-choose-two__title {
//   font-weight  : 600;
//   font-size    : 22px;
//   margin       : 0;
//   line-height  : 32px;
//   margin-top   : 15px;
//   margin-bottom: 12px;
// }

// .why-choose-two__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .why-choose-two__title a:hover {
//   color: var(--customVar-secondary);
// }

// .why-choose-two__text {
//   margin       : 0;
//   line-height  : 26px;
//   margin-bottom: 25px;
// }

// .why-choose-two__btn {
//   font-size         : 18px;
//   font-weight       : 600;
//   letter-spacing    : 0.01em;
//   color             : var(--customVar-secondary);
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .why-choose-two__btn span {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 13px;
//   margin-left: 10px;
// }

// .why-choose-two__btn:hover {
//   color: var(--customVar-black);
// }

// /***********************
// * Right Hosting
// ***********************/
// .right-hosting {
//   position: relative;
//   display : block;
//   padding : 120px 0 45px;
//   overflow: hidden;
//   z-index : 1;
// }

// .right-hosting__shape-1 {
//   position: absolute;
//   top     : -193px;
//   left    : -10px;
//   z-index : -1;
// }

// .right-hosting__shape-1 img {
//   width: auto;
// }

// .right-hosting__left {
//   position    : relative;
//   display     : block;
//   margin-right: 27px;
// }

// .right-hosting__img {
//   position: relative;
//   display : block;
//   z-index : 2;
// }

// .right-hosting__img img {
//   width: 100%;
// }

// .right-hosting__right {
//   position   : relative;
//   display    : block;
//   margin-left: 45px;
// }

// .right-hosting__title {
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 40px;
// }

// .right-hosting__main-content-box {
//   position: relative;
//   display : block;
// }

// .right-hosting__inner {
//   position: relative;
//   display : block;
// }

// .right-hosting__inner .tab-buttons {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   margin     : 0;
// }

// .right-hosting__inner .tab-buttons:before {
//   content         : "";
//   position        : absolute;
//   bottom          : -23px;
//   left            : 0;
//   right           : 0;
//   height          : 1px;
//   background-color: #e6e6e6;
//   z-index         : -1;
// }

// .right-hosting__inner .tab-buttons .tab-btn {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   cursor     : pointer;
//   transition : all 0.3s ease;
//   z-index    : 1;
// }

// .right-hosting__inner .tab-buttons .tab-btn:before {
//   content   : "";
//   position  : absolute;
//   left      : 0;
//   right     : 0;
//   bottom    : -22px;
//   width     : 0%;
//   height    : 3px;
//   background: var(--customVar-secondary);
//   transition: all 0.3s ease;
//   z-index   : -1;
// }

// .right-hosting__inner .tab-buttons .tab-btn.active-btn:before {
//   width: 100%;
// }

// .right-hosting__inner .tab-buttons .tab-btn+.tab-btn {
//   margin-left: 55px;
// }

// .right-hosting__inner .tab-buttons .tab-btn .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .right-hosting__inner .tab-buttons .tab-btn .icon span {
//   position  : relative;
//   display   : inline-block;
//   font-size : 24px;
//   color     : var(--customVar-text);
//   transition: all 0.3s ease;
// }

// .right-hosting__inner .tab-buttons .tab-btn.active-btn .icon span {
//   color: var(--customVar-secondary);
// }

// .right-hosting__inner .tab-buttons .tab-btn .content {
//   position   : relative;
//   display    : block;
//   margin-left: 10px;
// }

// .right-hosting__inner .tab-buttons .tab-btn .content h3 {
//   font-size  : 18px;
//   font-weight: 600;
//   color      : var(--customVar-text);
//   margin     : 0;
//   transition : all 0.3s ease;
// }

// .right-hosting__inner .tab-buttons .tab-btn.active-btn .content h3 {
//   color: var(--customVar-secondary);
// }

// .right-hosting__inner .tabs-content {
//   position: relative;
//   display : block;
// }

// .right-hosting__inner .tabs-content .tab {
//   position          : relative;
//   display           : none;
//   -webkit-transform : translateY(35px);
//   -ms-transform     : translateY(35px);
//   transform         : translateY(35px);
//   -webkit-transition: all 600ms ease;
//   -moz-transition   : all 600ms ease;
//   -ms-transition    : all 600ms ease;
//   -o-transition     : all 600ms ease;
//   transition        : all 600ms ease;
//   z-index           : 10;
// }

// .right-hosting__inner .tabs-content .tab.active-tab {
//   display          : block;
//   margin-top       : 0px;
//   -webkit-transform: translateY(0px);
//   -ms-transform    : translateY(0px);
//   transform        : translateY(0px);
// }

// .right-hosting__tab-content-box {
//   position  : relative;
//   display   : block;
//   margin-top: 52px;
// }

// .right-hosting__text {
//   margin       : 0;
//   margin-bottom: 14px;
// }

// .right-hosting__points-box {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   flex-wrap    : wrap;
//   margin-bottom: 30px;
// }

// .right-hosting__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .right-hosting__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .right-hosting__points li+li {
//   margin-top: 8px;
// }

// .right-hosting__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .right-hosting__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .right-hosting__points li .text {
//   margin-left: 20px;
// }

// .right-hosting__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .right-hosting__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// .right-hosting__points--two {
//   margin-left: 55px;
// }

// .right-hosting__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .right-hosting__btn:before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /***********************
// * Choose Domain
// ***********************/
// .choose-domain-two {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 68px 0 20px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .choose-domain-two__shape-1 {
//   position: absolute;
//   top     : 0;
//   left    : 100px;
//   z-index : -1;
// }

// .choose-domain-two__shape-1 img {
//   width: auto;
// }

// .choose-domain-two__shape-2 {
//   position: absolute;
//   bottom  : 0;
//   right   : -30px;
//   z-index : -1;
// }

// .choose-domain-two__shape-2 img {
//   width: auto;
// }

// .choose-domain-two__shape-3 {
//   position: absolute;
//   bottom  : 235px;
//   right   : 114px;
//   z-index : -1;
// }

// .choose-domain-two__shape-3 img {
//   width: auto;
// }

// .choose-domain-two__shape-4 {
//   position: absolute;
//   bottom  : 0;
//   left    : 323px;
//   z-index : -1;
// }

// .choose-domain-two__shape-4 img {
//   width: auto;
// }

// .choose-domain-two__shape-5 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
//   z-index : -1;
// }

// .choose-domain-two__shape-5 img {
//   width: auto;
// }

// .choose-domain-two__left {
//   position    : relative;
//   display     : block;
//   margin-left : 92px;
//   margin-right: 79px;
// }

// .choose-domain-two__img {
//   position: relative;
//   display : block;
// }

// .choose-domain-two__img img {
//   width: 100%;
// }

// .choose-domain-two__right {
//   position    : relative;
//   display     : block;
//   margin-top  : 33px;
//   margin-left : 12px;
//   margin-right: 93px;
// }

// .choose-domain-two__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : var(--customVar-white);
//   margin     : 0;
// }

// .choose-domain-two__text {
//   font-size    : 18px;
//   color        : var(--customVar-white);
//   line-height  : 32px;
//   margin       : 0;
//   margin-top   : 6px;
//   margin-bottom: 30px;
// }

// .choose-domain-two__form {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   z-index    : 2;
// }

// .choose-domain-two__input-box {
//   position : relative;
//   display  : block;
//   max-width: 540px;
//   width    : 100%;
// }

// .choose-domain-two__input-box input[type="text"] {
//   outline           : none;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : rgba(255, 255, 255, 1);
//   border            : 1.5px solid #e9e9e9;
//   border-radius     : 10px;
//   display           : block;
//   max-width         : 540px;
//   width             : 100%;
//   height            : 60px;
//   color             : var(--customVar-text);
//   font-size         : 16px;
//   font-weight       : 400;
//   padding-left      : 25px;
//   padding-right     : 176px;
// }

// .choose-domain-two__btn {
//   padding        : 0;
//   color          : var(--customVar-white);
//   margin-left    : 0;
//   position       : absolute;
//   top            : 0;
//   bottom         : 0;
//   right          : 0;
//   height         : 60px;
//   width          : 60px;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .choose-domain-two__btn:before {
//   background-color: var(--customVar-secondary);
// }

// .choose-domain-two__btn span {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .choose-domain-two__btn i {
//   font-size         : 20px;
//   color             : inherit;
//   margin-left       : 0;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .choose-domain-two__name {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   position         : absolute;
//   top              : 50%;
//   right            : 90px;
//   transform        : translateY(-50%);
//   border-left      : 1.5px solid #e9e9e9;
//   padding-left     : 20px;
//   height           : 30px;
// }

// .choose-domain-two__name .bootstrap-select>.dropdown-toggle {
//   font-size  : 16px;
//   font-family: var(--customVar-body-font);
//   font-weight: 400;
//   color      : var(--customVar-black);
// }

// .choose-domain-two__name .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 17px;
//   position   : relative;
//   top        : 2px;
// }

// .choose-domain-two__bottom {
//   position   : relative;
//   display    : block;
//   padding-top: 30px;
// }

// .choose-domain-two__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   margin     : 0;
// }

// .choose-domain-two__list li {
//   position : relative;
//   flex     : 0 0 20%;
//   max-width: 20%;
//   width    : 100%;
// }

// .choose-domain-two__list li+li {
//   margin-left: 22px;
// }

// .choose-domain-two__type {
//   font-size     : 20px;
//   letter-spacing: -0.015em;
//   color         : #0ea5ff;
//   font-weight   : 700;
//   line-height   : 20px;
//   margin        : 0;
// }

// .choose-domain-two__price {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
// }

// .choose-domain-two__list li:nth-child(2) .choose-domain-two__type {
//   color: #00c14d;
// }

// .choose-domain-two__list li:nth-child(3) .choose-domain-two__type {
//   color: #ff5a74;
// }

// .choose-domain-two__list li:nth-child(4) .choose-domain-two__type {
//   color: #ffa800;
// }

// /***********************
// * Services Two
// ***********************/
// .services-two {
//   position  : relative;
//   display   : block;
//   background: #f5f5f5;
//   padding   : 110px 0 108px;
//   z-index   : 1;
// }

// .services-two__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .services-two__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .services-two__section-title__text {
//   font-size  : 16px;
//   margin     : 0;
//   line-height: 26px;
// }

// .services-two__left {
//   position: relative;
//   display : block;
// }

// .services-two__img {
//   position: relative;
//   display : block;
// }

// .services-two__img img {
//   width                    : 100%;
//   border-top-left-radius   : 20px;
//   border-bottom-left-radius: 20px;
// }

// .services-two__right {
//   position   : relative;
//   display    : block;
//   margin-left: -30px;
// }

// .services-two__list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .services-two__list li {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   border            : 1px solid #eaedf8;
//   background-color  : var(--customVar-white);
//   padding           : 35px 40px 31px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .services-two__list li:hover {
//   background-color: #f3f6ff;
// }

// .services-two__list li:nth-child(1) {
//   border-bottom          : 0;
//   border-top-right-radius: 20px;
// }

// .services-two__list li:nth-child(2) {
//   border-bottom: 0;
// }

// .services-two__list li:nth-child(3) {
//   border-bottom: 0;
// }

// .services-two__list li:nth-child(4) {
//   border-bottom-right-radius: 20px;
// }

// .services-two__list li .icon {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   height         : 70px;
//   width          : 70px;
//   border-radius  : 50%;
//   background     : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// .services-two__list li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 30px;
//   color    : var(--customVar-white);
// }

// .services-two__list li .content {
//   position   : relative;
//   display    : block;
//   max-width  : 355px;
//   width      : 100%;
//   margin-left: 40px;
// }

// .services-two__list li .content h4 {
//   font-size    : 22px;
//   color        : var(--customVar-black);
//   font-weight  : 600;
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .services-two__list li .content p {
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin        : 0;
// }

// /***********************
// * How It Work
// ***********************/
// .how-it-work {
//   position     : relative;
//   display      : block;
//   background   : #f5f5f5;
//   counter-reset: count;
//   padding      : 0 0 90px;
//   z-index      : 1;
// }

// .how-it-work__inner {
//   position: relative;
//   display : block;
//   z-index : 1;
// }

// .how-it-work__shape-1 {
//   position  : absolute;
//   bottom    : 51px;
//   left      : 0;
//   right     : 0;
//   text-align: center;
// }

// .how-it-work__shape-1 img {
//   width: auto;
// }

// .how-it-work__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .how-it-work__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .how-it-work__section-title__text {
//   font-size  : 16px;
//   margin     : 0;
//   line-height: 26px;
// }

// .how-it-work__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   margin     : 0;
// }

// .how-it-work__list li {
//   position: relative;
//   display : block;
// }

// .how-it-work__list li:nth-child(1) {
//   padding-right: 93px;
// }

// .how-it-work__list li:nth-child(2) {
//   padding-left : 30px;
//   padding-right: 63px;
// }

// .how-it-work__list li:nth-child(3) {
//   padding-left : 50px;
//   padding-right: 30px;
// }

// .how-it-work__list li:nth-child(4) {
//   padding-left: 93px;
// }

// .how-it-work__single {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   margin-bottom: 20px;
// }

// .how-it-work__icon {
//   position     : relative;
//   display      : block;
//   margin-bottom: 16px;
// }

// .how-it-work__icon img {
//   width: auto;
// }

// .how-it-work__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 32px;
//   margin-bottom: 12px;
// }

// .how-it-work__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// .how-it-work__step-text {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   margin-top     : 57px;
// }

// .how-it-work__step-text p {
//   font-size  : 18px;
//   color      : var(--customVar-black);
//   line-height: 18px;
//   font-weight: 500;
//   margin     : 0;
//   font-family: var(--customVar-title-font);
// }

// .how-it-work__count {
//   position: relative;
//   display : block;
// }

// .how-it-work__count:before {
//   position         : relative;
//   font-size        : 18px;
//   line-height      : 18px;
//   font-weight      : 500;
//   counter-increment: count;
//   content          : "0"counter(count);
//   transition       : all 200ms linear;
//   transition-delay : 0.1s;
//   color            : var(--customVar-black);
//   text-align       : center;
//   font-family      : var(--customVar-title-font);
// }

// /***********************
// * Comparision
// ***********************/
// .comparision {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 108px 0 120px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .comparision__shape-1 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
//   z-index : -1;
// }

// .comparision__shape-1 img {
//   width: auto;
// }

// .comparision__shape-2 {
//   position: absolute;
//   bottom  : 40px;
//   right   : 0;
//   z-index : -1;
// }

// .comparision__shape-2 img {
//   width: auto;
// }

// .comparision__shape-3 {
//   position: absolute;
//   bottom  : 0;
//   left    : 0;
//   z-index : -1;
// }

// .comparision__shape-3 img {
//   width: auto;
// }

// .comparision__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .comparision__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .comparision__section-title__text {
//   font-size  : 16px;
//   color      : var(--customVar-white);
//   margin     : 0;
//   line-height: 26px;
// }

// .comparision__main-content-box {
//   position: relative;
//   display : block;
// }

// .comparision__inner {
//   position: relative;
//   display : block;
// }

// .comparision__inner .tab-buttons {
//   position       : relative;
//   display        : inline-flex;
//   align-items    : center;
//   justify-content: center;
//   max-width      : 801px;
//   width          : 100%;
//   margin         : 0 auto 0;
//   background     : #010e69;
//   border         : 2px solid #ffffff;
//   box-shadow     : 0px 7px 20px rgba(1, 14, 105, 0.25);
//   border-radius  : 30px;
//   padding        : 3px;
//   z-index        : 11;
// }

// .comparision__inner .tab-buttons .tab-btn {
//   position: relative;
//   display : inline-block;
// }

// .comparision__inner .tab-buttons .tab-btn+.tab-btn {
//   margin: 0;
// }

// .comparision__inner .tab-buttons .tab-btn span {
//   position        : relative;
//   display         : block;
//   text-align      : center;
//   transition      : all 200ms linear;
//   transition-delay: 0.1s;
//   font-size       : 16px;
//   line-height     : 16px;
//   color           : var(--customVar-white);
//   background-color: transparent;
//   padding         : 17px 42px 17px;
//   border-radius   : 25px;
//   font-weight     : 600;
//   cursor          : pointer;
//   transition      : all 0.3s ease;
//   z-index         : 1;
// }

// .comparision__inner .tab-buttons .tab-btn.active-btn span {
//   color: var(--customVar-black);
// }

// .comparision__inner .tab-buttons .tab-btn span:before {
//   position        : absolute;
//   top             : 0;
//   left            : 0;
//   right           : 0;
//   bottom          : 0;
//   height          : 0%;
//   border-radius   : 25px;
//   content         : "";
//   background-color: var(--customVar-white);
//   transition      : all 0.3s ease;
//   z-index         : -1;
// }

// .comparision__inner .tab-buttons .tab-btn.active-btn span:before {
//   height: 100%;
// }

// .comparision__inner .tabs-content {
//   position  : relative;
//   display   : block;
//   margin-top: -30px;
// }

// .comparision__inner .tabs-content .tab {
//   position          : relative;
//   display           : none;
//   -webkit-transform : translateY(35px);
//   -ms-transform     : translateY(35px);
//   transform         : translateY(35px);
//   -webkit-transition: all 600ms ease;
//   -moz-transition   : all 600ms ease;
//   -ms-transition    : all 600ms ease;
//   -o-transition     : all 600ms ease;
//   transition        : all 600ms ease;
//   z-index           : 10;
// }

// .comparision__inner .tabs-content .tab.active-tab {
//   display          : block;
//   margin-top       : 0px;
//   -webkit-transform: translateY(0px);
//   -ms-transform    : translateY(0px);
//   transform        : translateY(0px);
// }

// .comparision__tab-content-box {
//   position: relative;
//   display : block;
// }

// .comparision__tab-content-img {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   padding         : 50px 50px 50px;
//   border-radius   : 20px;
// }

// .comparision__tab-content-img img {
//   width        : 100%;
//   border-radius: 20px;
// }

// /**********************************************
// * Blog Two
// ***********************************************/
// .blog-two {
//   position: relative;
//   display : block;
//   padding : 0 0 90px;
//   z-index : 1;
// }

// .blog-two__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .blog-two__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .blog-two__section-title__text {
//   font-size     : 16px;
//   margin        : 0;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : #666666;
// }

// .blog-two__single {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   box-shadow      : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   margin-bottom   : 30px;
// }

// .blog-two__img-box {
//   position: relative;
//   display : block;
// }

// .blog-two__img {
//   position               : relative;
//   display                : block;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
//   overflow               : hidden;
//   z-index                : 1;
// }

// .blog-two__img img {
//   width                  : 100%;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .blog-two__single:hover .blog-two__img img {
//   transform: scale(1.05);
// }

// .blog-two__date {
//   position: absolute;
//   top     : 30px;
//   left    : 30px;
// }

// .blog-two__date p {
//   font-weight     : 600;
//   font-size       : 12px;
//   line-height     : 12px;
//   letter-spacing  : 0.05em;
//   color           : #121212;
//   background-color: var(--customVar-white);
//   text-transform  : uppercase;
//   padding         : 10px 16px 10px;
//   border-radius   : 17px;
//   margin          : 0;
// }

// .blog-two__date p span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 16px;
//   margin-right: 7px;
// }

// .blog-two__content {
//   position     : relative;
//   display      : block;
//   padding      : 21px 30px 19px;
//   border-bottom: 1px solid #e3e7f4;
// }

// .blog-two__title {
//   margin     : 0;
//   font-weight: 500;
//   font-size  : 22px;
//   line-height: 32px;
// }

// .blog-two__title a {
//   color             : #141414;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-two__title a:hover {
//   color: var(--customVar-base);
// }

// .blog-two__text {
//   letter-spacing: -0.015em;
//   margin        : 8px 0 0;
//   line-height   : 26px;
// }

// .blog-two__user-and-date {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   padding        : 15px 30px 18px;
// }

// .blog-two__user {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .blog-two__user-img {
//   position     : relative;
//   display      : block;
//   height       : 40px;
//   width        : 40px;
//   border-radius: 50%;
// }

// .blog-two__user-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-two__user-name {
//   font-weight   : 500;
//   font-size     : 12px;
//   line-height   : 22px;
//   letter-spacing: 0.1em;
//   color         : #666666;
//   margin        : 0 0 0 10px;
//   text-transform: uppercase;
// }

// .blog-two__date-2 {
//   font-weight   : 500;
//   font-size     : 12px;
//   line-height   : 22px;
//   letter-spacing: 0.1em;
//   color         : #666666;
//   text-transform: uppercase;
//   margin        : 0;
// }

// .blog-two__date-2 span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 18px;
//   top         : 3px;
//   margin-right: 10px;
// }

// /**********************************************
// * Pricing Three
// ***********************************************/
// .pricing-three {
//   position        : relative;
//   display         : block;
//   background-color: #0e122d;
//   padding         : 108px 0 90px;
//   z-index         : 1;
// }

// .pricing-three__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .pricing-three__section-title__title {
//   font-size    : 38px;
//   font-weight  : 700;
//   line-height  : 48px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .pricing-three__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 26px;
//   color      : var(--customVar-white);
// }

// .pricing-three__single {
//   position        : relative;
//   display         : block;
//   background-color: #151939;
//   border-radius   : 20px;
//   padding         : 0 0 50px;
//   margin-bottom   : 30px;
// }

// .pricing-three__img {
//   position: relative;
//   display : block;
// }

// .pricing-three__img img {
//   width                  : 100%;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
// }

// .pricing-three__img-text {
//   position       : absolute;
//   top            : 0;
//   left           : 0;
//   bottom         : 0;
//   right          : 0;
//   text-align     : center;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .pricing-three__img-text p {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 26px;
//   font-family: var(--customVar-title-font);
//   color      : var(--customVar-white);
// }

// .pricing-three__content {
//   position: relative;
//   display : block;
//   padding : 32px 40px 35px;
// }

// .pricing-three__text {
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : #dfdfdf;
//   margin        : 0;
//   margin-bottom : 21px;
// }

// .pricing-three__price-box {
//   position: relative;
//   display : block;
// }

// .pricing-three__price {
//   font-size  : 40px;
//   font-weight: 800;
//   color      : var(--customVar-white);
//   line-height: 40px;
//   margin     : 0;
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-three__price-point {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   font-weight: 500;
//   top        : 8px;
// }

// .pricing-three__price-validity {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   font-weight: 500;
//   top        : 8px;
// }

// .pricing-three__price-renew {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : #dfdfdf;
//   margin-top    : 5px;
// }

// .pricing-three__points-title {
//   margin        : 23px 0 13px;
//   font-weight   : 600;
//   letter-spacing: -0.015em;
//   color         : #dfdfdf;
// }

// .pricing-three__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-three__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-three__points li+li {
//   margin-top: 8px;
// }

// .pricing-three__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-three__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .pricing-three__points li .text {
//   margin-left: 20px;
// }

// .pricing-three__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   color         : #dfdfdf;
// }

// .pricing-three__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// .pricing-three__btn-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   padding   : 0 30px 0;
// }

// .pricing-three__btn {
//   color          : var(--customVar-white);
//   width          : 100%;
//   justify-content: center;
// }

// .pricing-three__btn:hover {
//   color: var(--customVar-black);
// }

// .pricing-three__btn::before {
//   background-color: #3852ff;
// }

// .pricing-three__btn::after {
//   background-color: var(--customVar-white);
// }

// /***********************
// * Comming Soon
// ***********************/
// .comming-soon {
//   position        : relative;
//   display         : block;
//   background-color: #0f122c;
//   padding         : 120px 0 90px;
//   z-index         : 1;
// }

// .comming-soon__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   opacity            : 0.6;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .comming-soon__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .comming-soon__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 8px;
// }

// .comming-soon__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 26px;
//   color      : var(--customVar-white);
// }

// .comming-soon__single {
//   position        : relative;
//   display         : block;
//   border-radius   : 20px;
//   background-color: #1e2348;
//   margin-bottom   : 30px;
// }

// .comming-soon__img-box {
//   position: relative;
//   display : block;
// }

// .comming-soon__img {
//   position: relative;
//   display : block;
// }

// .comming-soon__img img {
//   width        : 100%;
//   border-radius: 20px;
// }

// .comming-soon__sub-title {
//   position        : absolute;
//   bottom          : -16px;
//   left            : 50%;
//   transform       : translateX(-50%);
//   background-color: var(--customVar-white);
//   max-width       : 405px;
//   width           : 100%;
//   text-align      : center;
//   text-transform  : uppercase;
//   border-radius   : 18px;
//   padding         : 12px 5px 11px;
// }

// .comming-soon__sub-title p {
//   font-weight   : 500;
//   font-size     : 12px;
//   line-height   : 12px;
//   letter-spacing: 0.1em;
//   color         : #121212;
//   margin        : 0;
// }

// .comming-soon__content {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   padding        : 45px 30px 39px;
// }

// .comming-soon__title-box {
//   position: relative;
//   display : block;
// }

// .comming-soon__title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
// }

// .comming-soon__title a {
//   color             : var(--customVar-white);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .comming-soon__title a:hover {
//   color: var(--customVar-base);
// }

// .comming-soon__price-box {
//   position: relative;
//   display : block;
// }

// .comming-soon__price-box p {
//   margin     : 3px 0 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-white);
// }

// .comming-soon__price-box p span {
//   font-weight : 600;
//   font-size   : 16px;
//   line-height : 26px;
//   margin-right: 15px;
//   position    : relative;
//   display     : inline-block;
// }

// .comming-soon__price-box p span::before {
//   content         : "";
//   position        : absolute;
//   bottom          : 12px;
//   left            : 0;
//   width           : 54px;
//   height          : 3px;
//   background-color: #ff6b71;
//   transform       : rotate(-9.64deg);
// }

// .comming-soon__btn-box {
//   position: relative;
//   display : block;
// }

// .comming-soon__btn {
//   padding           : 14px 35px;
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .comming-soon__btn:hover {
//   color: var(--customVar-black);
// }

// .comming-soon__btn::after {
//   background-color: var(--customVar-white);
// }

// .comming-soon__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /********************************************************
// * NFS One
// *********************************************************/
// .nfs-one {
//   position        : relative;
//   display         : block;
//   background-color: #0f122c;
//   padding         : 104px 0 110px;
//   z-index         : 1;
// }

// .nfs-one__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .nfs-one__inner {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .nfs-one__img {
//   position : relative;
//   display  : block;
//   max-width: 512px;
//   width    : 100%;
//   margin   : 0 auto;
// }

// .nfs-one__img img {
//   width: 100%;
// }

// .nfs-one__text {
//   margin     : -30px 0 32px;
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 32px;
//   color      : var(--customVar-white);
// }

// .nfs-one__btn-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .nfs-one__btn {
//   padding           : 14px 40px;
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .nfs-one__btn:hover {
//   color: var(--customVar-black);
// }

// .nfs-one__btn::after {
//   background-color: var(--customVar-white);
// }

// .nfs-one__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .nfs-one__btn-2 {
//   color           : var(--customVar-white);
//   border          : 2px solid var(--customVar-white);
//   background-color: transparent;
//   margin-left     : 11px;
//   padding         : 12px 21px;
// }

// .nfs-one__btn-2:hover {
//   color: var(--customVar-black);
// }

// .nfs-one__btn-2::before {
//   background-color: transparent;
// }

// .nfs-one__btn-2::after {
//   background-color: var(--customVar-white);
// }

// /********************************************************
// * Why Choose Three
// *********************************************************/
// .why-choose-three {
//   position        : relative;
//   display         : block;
//   background-color: #0f122c;
//   padding         : 109px 0 120px;
//   overflow        : hidden;
//   z-index         : 1;
// }

// .why-choose-three__shape-1 {
//   position: absolute;
//   top     : 0;
//   left    : 0;
//   z-index : -1;
// }

// .why-choose-three__shape-1 img {
//   width: auto;
// }

// .why-choose-three__inner {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .why-choose-three__title {
//   margin     : 0 0 44px;
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   text-align : center;
//   color      : var(--customVar-white);
// }

// .why-choose-three__points {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   margin         : 0;
// }

// .why-choose-three__points li {
//   position     : relative;
//   display      : block;
//   margin-bottom: 23px;
// }

// .why-choose-three__points li .icon {
//   position: relative;
//   display : inline-block;
// }

// .why-choose-three__points li .icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 80px;
//   color                  : rgba(255, 90, 116, 1);
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .why-choose-three__points li h3 {
//   margin     : 19px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-white);
// }

// .why-choose-three__points li p {
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
//   margin        : 0;
// }

// .why-choose-three__bottom {
//   position  : relative;
//   display   : block;
//   margin-top: 30px;
// }

// .why-choose-three__ratting-and-text {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   margin          : 0 auto;
//   max-width       : 950px;
//   background-color: #151939;
//   border-radius   : 10px;
//   padding         : 16px 52px 21px;
//   width           : 100%;
// }

// .why-choose-three__ratting-box {
//   position     : relative;
//   display      : flex;
//   padding-right: 30px;
//   border-right : 1px solid #333866;
//   align-items  : center;
//   margin-right : 30px;
// }

// .why-choose-three__ratting-text {
//   position: relative;
//   display : inline-block;
//   z-index : 1;
// }

// .why-choose-three__ratting-text p {
//   margin      : 0;
//   font-weight : 700;
//   font-size   : 30px;
//   line-height : 40px;
//   color       : #ffffff;
//   font-family : var(--hostuo-title-font);
//   margin-right: 34px;
// }

// .why-choose-three__ratting-shape-1 {
//   position: absolute;
//   top     : -15px;
//   left    : -14px;
//   z-index : -1;
// }

// .why-choose-three__ratting-shape-1 img {
//   width: auto;
// }

// .why-choose-three__ratting-title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 20px;
//   line-height: 30px;
//   color      : var(--customVar-white);
// }

// .why-choose-three__ratting {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   margin-top : 5px;
// }

// .why-choose-three__ratting span {
//   position : relative;
//   display  : inline-block;
//   font-size: 18px;
//   color    : #ffa800;
// }

// .why-choose-three__text-box {
//   position: relative;
//   display : block;
// }

// .why-choose-three__text {
//   color      : var(--customVar-white);
//   margin     : 0;
//   line-height: 32px;
//   position   : relative;
//   display    : inline-block;
// }

// .why-choose-three__text span {
//   font-size  : 16px;
//   font-weight: 700;
// }

// .why-choose-three__text .count-text {
//   font-size  : 16px;
//   font-weight: 700;
// }

// .why-choose-three__text-2 {
//   color      : var(--customVar-white);
//   margin     : 0;
//   line-height: 32px;
//   position   : relative;
//   display    : inline-block;
// }

// .why-choose-three__text-2 span {
//   font-size  : 16px;
//   font-weight: 700;
// }

// .why-choose-three__text-2 .count-text {
//   font-size  : 16px;
//   font-weight: 700;
// }

// /********************************************************
// * BLog Three
// *********************************************************/
// .blog-three {
//   position        : relative;
//   display         : block;
//   background-color: #0f122c;
//   padding-bottom  : 90px;
//   overflow        : hidden;
//   z-index         : 1;
// }

// .blog-three__shape-1 {
//   position: absolute;
//   top     : 56px;
//   left    : 0;
// }

// .blog-three__shape-1 img {
//   width: auto;
// }

// .blog-three__shape-2 {
//   position: absolute;
//   top     : 93px;
//   right   : 235px;
// }

// .blog-three__shape-2 img {
//   width: auto;
// }

// .blog-three__shape-3 {
//   position: absolute;
//   bottom  : 0;
//   right   : -30px;
// }

// .blog-three__shape-3 img {
//   width: auto;
// }

// .blog-three__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .blog-three__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .blog-three__section-title__text {
//   font-size     : 16px;
//   margin        : 0;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
// }

// .blog-three__single {
//   position        : relative;
//   display         : block;
//   background-color: #151939;
//   box-shadow      : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   margin-bottom   : 30px;
// }

// .blog-three__img-box {
//   position: relative;
//   display : block;
// }

// .blog-three__img {
//   position               : relative;
//   display                : block;
//   overflow               : hidden;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
//   z-index                : 1;
// }

// .blog-three__img img {
//   width                  : 100%;
//   border-top-left-radius : 20px;
//   border-top-right-radius: 20px;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .blog-three__single:hover .blog-three__img img {
//   transform: scale(1.05);
// }

// .blog-three__date {
//   position: absolute;
//   top     : 30px;
//   left    : 30px;
//   z-index : 2;
// }

// .blog-three__date p {
//   font-weight     : 600;
//   font-size       : 12px;
//   line-height     : 12px;
//   letter-spacing  : 0.05em;
//   color           : #121212;
//   background-color: var(--customVar-white);
//   text-transform  : uppercase;
//   padding         : 7px 17px 11px;
//   border-radius   : 17px;
//   margin          : 0;
// }

// .blog-three__date p span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 16px;
//   margin-right: 7px;
//   top         : 2px;
// }

// .blog-three__content {
//   position     : relative;
//   display      : block;
//   padding      : 21px 30px 19px;
//   border-bottom: 1px solid #333866;
// }

// .blog-three__title {
//   margin     : 0;
//   font-weight: 500;
//   font-size  : 22px;
//   line-height: 32px;
// }

// .blog-three__title a {
//   color             : var(--customVar-white);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-three__title a:hover {
//   color: var(--customVar-base);
// }

// .blog-three__text {
//   letter-spacing: -0.015em;
//   margin        : 8px 0 0;
//   line-height   : 26px;
//   color         : var(--customVar-white);
// }

// .blog-three__user-and-date {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   padding        : 15px 30px 18px;
// }

// .blog-three__user {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .blog-three__user-img {
//   position     : relative;
//   display      : block;
//   height       : 40px;
//   width        : 40px;
//   border-radius: 50%;
// }

// .blog-three__user-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-three__user-name {
//   font-weight   : 500;
//   font-size     : 12px;
//   line-height   : 22px;
//   letter-spacing: 0.1em;
//   color         : var(--customVar-white);
//   margin        : 0 0 0 10px;
//   text-transform: uppercase;
// }

// .blog-three__date-2 {
//   font-weight   : 500;
//   font-size     : 12px;
//   line-height   : 22px;
//   letter-spacing: 0.1em;
//   color         : var(--customVar-white);
//   text-transform: uppercase;
//   margin        : 0;
// }

// .blog-three__date-2 span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 18px;
//   top                    : 3px;
//   margin-right           : 10px;
//   color                  : rgba(255, 90, 116, 1);
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
// }

// /********************************************************
// * Contact Two
// *********************************************************/
// .contact-two {
//   position        : relative;
//   display         : block;
//   background-color: #0f122c;
//   padding         : 109px 0 90px;
//   z-index         : 1;
// }

// .contact-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-size    : cover;
//   background-position: center;
//   background-repeat  : no-repeat;
//   z-index            : -1;
// }

// .contact-two__left {
//   position    : relative;
//   display     : block;
//   margin-right: 53px;
// }

// .contact-two__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 50px;
// }

// .contact-two__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 15px;
// }

// .contact-two__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
//   color      : var(--customVar-white);
// }

// .contact-two__platform-box {
//   position: relative;
//   display : block;
// }

// .contact-two__platform-list {
//   margin         : 0;
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
// }

// .contact-two__platform-list-1 {
//   margin-right : 38px;
//   margin-bottom: 25px;
// }

// .contact-two__platform-list li {
//   position     : relative;
//   display      : block;
//   margin-bottom: 30px;
// }

// .contact-two__platform-img {
//   position: relative;
//   display : block;
// }

// .contact-two__platform-img img {
//   width: auto;
// }

// .contact-two__right {
//   position   : relative;
//   display    : block;
//   margin-left: 50px;
//   margin-top : 12px;
// }

// .contact-two__form-box {
//   position: relative;
//   display : block;
// }

// .contact-two__form {
//   position: relative;
//   display : block;
// }

// .contact-two__form-box .row {
//   --bs-gutter-x: 20px;
// }

// .contact-two__input-box {
//   position     : relative;
//   display      : block;
//   margin-bottom: 20px;
// }

// .contact-two__input-box input[type="text"],
// .contact-two__input-box input[type="email"] {
//   height          : 60px;
//   width           : 100%;
//   border          : none;
//   background-color: transparent;
//   border          : 2px solid var(--customVar-white);
//   border-radius   : 10px;
//   padding-left    : 22px;
//   padding-right   : 30px;
//   outline         : none;
//   font-size       : 16px;
//   color           : var(--customVar-white);
//   display         : block;
//   font-weight     : 400;
// }

// .contact-two__showing-sort {
//   position: relative;
//   display : block;
//   width   : 100%;
// }

// .contact-two__showing-sort .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
//   position: relative;
//   display : block;
//   width   : 100% !important;
// }

// .contact-two__showing-sort .bootstrap-select>.dropdown-toggle::after {
//   display: none;
// }

// .contact-two__showing-sort .bootstrap-select>.dropdown-toggle {
//   position           : relative;
//   height             : 60px;
//   outline            : none !important;
//   border-radius      : 10px;
//   background-color   : transparent;
//   border             : 2px solid var(--customVar-white);
//   margin             : 0;
//   padding            : 0;
//   padding-left       : 22px;
//   padding-right      : 30px;
//   color              : var(--customVar-white) !important;
//   font-size          : 16px;
//   line-height        : 63px;
//   font-weight        : 400;
//   box-shadow         : none !important;
//   background-repeat  : no-repeat;
//   background-size    : 14px 12px;
//   background-position: right 25.75px center;
// }

// .contact-two__showing-sort .bootstrap-select>.dropdown-toggle:before {
//   position   : absolute;
//   top        : 0;
//   bottom     : 0;
//   right      : 26px;
//   font-family: "Font Awesome 5 Free";
//   content    : "\f107";
//   font-weight: 900;
//   font-size  : 14px;
//   color      : var(--customVar-white);
// }

// .contact-two__input-box textarea {
//   font-size       : 16px;
//   font-weight     : 400;
//   color           : var(--customVar-white);
//   height          : 140px;
//   width           : 100%;
//   background-color: transparent;
//   border          : 2px solid var(--customVar-white);
//   padding         : 20px 22px 30px;
//   border-radius   : 10px;
//   outline         : none;
//   margin-bottom   : 0px;
// }

// .contact-two__btn-box {
//   position: relative;
//   display : block;
// }

// .contact-two__btn {
//   border            : none;
//   color             : var(--customVar-white);
//   padding           : 14px 50px;
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .contact-two__btn:hover {
//   color: var(--customVar-black);
// }

// .contact-two__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .contact-two__btn::after {
//   background-color: var(--customVar-white);
// }

// .contact-two__input-box.text-message-box {
//   height: 140px;
// }

// /********************************************************
// * Blog Page
// *********************************************************/
// .blog-page {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 120px 0 120px;
//   z-index         : 1;
// }

// .blog-page__pagination {
//   position  : relative;
//   display   : block;
//   margin-top: 50px;
// }

// .blog-page__pagination .pg-pagination {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .blog-page__pagination .pg-pagination li {
//   display     : inline-block;
//   margin-right: 8px;
// }

// .blog-page__pagination .pg-pagination li:last-child {
//   margin-right: 0;
// }

// .blog-page__pagination .pg-pagination li a {
//   height            : 55px;
//   width             : 55px;
//   text-align        : center;
//   line-height       : 54px;
//   display           : inline-block;
//   border            : 2px solid #141414;
//   font-weight       : 400;
//   font-size         : 16px;
//   border-radius     : 10px;
//   letter-spacing    : -0.015em;
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-page__pagination .pg-pagination li a:hover {
//   background-color: var(--customVar-secondary);
//   border          : 2px solid var(--customVar-secondary);
//   color           : var(--customVar-white);
// }

// .blog-page__pagination .pg-pagination li a span {
//   font-size         : 12px;
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-page__pagination .pg-pagination li a:hover span {
//   color: var(--customVar-white);
// }

// /*--------------------------------------------------------------
// ## Sidebar
// --------------------------------------------------------------*/
// .sidebar {
//   position: relative;
//   display : block;
//   z-index : 1;
// }

// .sidebar__search {
//   position: relative;
//   display : block;
// }

// .sidebar__search-form {
//   position: relative;
//   display : block;
// }

// .sidebar__search-form-inner {
//   position: relative;
//   display : block;
// }

// .sidebar__search-form input[type="search"] {
//   position        : relative;
//   display         : block;
//   outline         : none;
//   background-color: var(--customVar-white);
//   font-size       : 16px;
//   padding-left    : 20px;
//   height          : 60px;
//   font-weight     : 400;
//   width           : 100%;
//   padding-right   : 80px;
//   border          : 2px solid #eaedf8;
//   border-radius   : 10px;
//   letter-spacing  : -0.015em;
//   color           : #666666;
// }

// .sidebar__search-form button[type="submit"] {
//   color             : var(--customVar-white);
//   font-size         : 21px;
//   position          : absolute;
//   top               : 0;
//   right             : 0;
//   width             : 60px;
//   height            : 60px;
//   outline           : none;
//   border            : none;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   padding           : 0;
//   border-radius     : 10px;
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .sidebar__search-form button[type="submit"]:hover {
//   background-color: var(--customVar-base);
//   color           : var(--customVar-white);
// }

// .sidebar__single+.sidebar__single {
//   margin-top: 40px;
// }

// .sidebar__category {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   border          : 2px solid #eaedf8;
//   border-radius   : 10px;
//   padding         : 21px 28px 26px;
// }

// .sidebar__title-box {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   margin-bottom: 21px;
// }

// .sidebar__title-box::before {
//   content         : "";
//   position        : absolute;
//   top             : 17px;
//   right           : 0;
//   width           : 60%;
//   height          : 2px;
//   background-color: #d1d5f1;
// }

// .sidebar__title-box::after {
//   content   : "";
//   position  : absolute;
//   top       : 17px;
//   right     : 41%;
//   width     : 19%;
//   height    : 2px;
//   background: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// .sidebar__title {
//   font-weight: 500;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
//   margin     : 0;
// }

// .sidebar__category-list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .sidebar__category-list li {
//   position: relative;
//   display : block;
// }

// .sidebar__category-list li+li {
//   margin-top: 14px;
// }

// .sidebar__category-list li a {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-weight       : 400;
//   font-size         : 16px;
//   line-height       : 26px;
//   letter-spacing    : -0.015em;
//   color             : #666666;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .sidebar__category-list li:hover a {
//   color: var(--customVar-base);
// }

// .sidebar__category-list li a>span {
//   font-weight: 600;
//   margin-left: 5px;
// }

// .sidebar__category-list li a>i {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 18px;
//   margin-right           : 18px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
// }

// .sidebar__post {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   border          : 2px solid #eaedf8;
//   border-radius   : 10px;
//   padding         : 21px 28px 33px;
//   z-index         : 1;
// }

// .sidebar__post .sidebar__title-box {
//   margin-bottom: 19px;
// }

// .sidebar__post-list {
//   margin: 0;
// }

// .sidebar__post-list li {
//   position         : relative;
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
// }

// .sidebar__post-list li+li {
//   margin-top: 19px;
// }

// .sidebar__post-image {
//   margin-right : 20px;
//   border-radius: 50%;
// }

// .sidebar__post-image>img {
//   width        : 70px;
//   border-radius: 50%;
// }

// .sidebar__post-content {
//   position: relative;
//   top     : -3px;
// }

// .sidebar__post-content h3 {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 26px;
// }

// .sidebar__post-content-meta {
//   line-height       : 30px;
//   font-size         : 12px;
//   font-weight       : 500;
//   letter-spacing    : 0.05em;
//   color             : var(--customVar-text);
//   font-family       : var(--customVar-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .sidebar__post-content-meta i {
//   font-size              : 16px;
//   padding-right          : 10px;
//   top                    : 2px;
//   position               : relative;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
// }

// .sidebar__post-content h3 a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   display           : block;
//   font-size         : 18px;
//   font-weight       : 500;
//   line-height       : 22px;
//   margin-top        : 2px;
// }

// .sidebar__post-content h3 a:hover {
//   color: var(--customVar-base);
// }

// .sidebar__tags {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   border          : 2px solid #eaedf8;
//   border-radius   : 10px;
//   padding         : 21px 28px 36px;
//   z-index         : 1;
// }

// .sidebar__tags .sidebar__title-box::before {
//   width: 77%;
// }

// .sidebar__tags .sidebar__title-box::after {
//   right: 58%;
// }

// .sidebar__tags .sidebar__title-box {
//   margin-bottom: 27px;
// }

// .sidebar__tags-list {
//   margin-top : -10px;
//   margin-left: -7px;
// }

// .sidebar__tags-list a {
//   font-size         : 16px;
//   color             : var(--customVar-text);
//   -webkit-transition: all 0.4s ease;
//   transition        : all 0.4s ease;
//   display           : inline-block;
//   padding           : 6px 19px 7px;
//   padding-right     : 16px;
//   margin-left       : 7px;
//   font-weight       : 400;
//   letter-spacing    : -0.015em;
//   background-color  : #edeffe;
//   border-radius     : 10px;
// }

// .sidebar__tags-list a+a {
//   margin-left: 6px;
//   margin-top : 10px;
// }

// .sidebar__tags-list a:hover {
//   color     : var(--customVar-black);
//   background: var(--customVar-base);
// }

// .sidebar__sale-box {
//   position     : relative;
//   display      : block;
//   background   : linear-gradient(380.2deg, #436cff 20.13%, #000b3c 88.51%);
//   border-radius: 10px;
//   text-align   : center;
//   padding      : 80px 0 65px;
//   overflow     : hidden;
//   z-index      : 1;
// }

// .sidebar__sale-box-bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .sidebar__sale-box-title {
//   font-weight   : 800;
//   font-size     : 30px;
//   line-height   : 30px;
//   text-align    : center;
//   color         : #ffffff;
//   margin        : 0;
//   text-transform: uppercase;
// }

// .sidebar__sale-box-content-box {
//   position     : relative;
//   display      : block;
//   margin-top   : 31px;
//   margin-bottom: 26px;
// }

// .sidebar__sale-box-content-box .timer-box {
//   position: relative;
//   display : block;
// }

// .sidebar__sale-box-content-box .countdown-timer .default-coundown .box {
//   position: relative;
//   display : block;
// }

// .sidebar__sale-box-content-box .countdown-timer li {
//   position  : relative;
//   display   : inline-block;
//   float     : none;
//   padding   : 0;
//   text-align: center;
//   margin    : 0px 7px 0px;
// }

// .sidebar__sale-box-content-box .countdown-timer li:first-child {
//   margin-left: 0;
// }

// .sidebar__sale-box-content-box .countdown-timer li:last-child {
//   margin-right: 0;
// }

// .sidebar__sale-box-content-box .countdown-timer li span.days,
// .sidebar__sale-box-content-box .countdown-timer li span.hours,
// .sidebar__sale-box-content-box .countdown-timer li span.minutes,
// .sidebar__sale-box-content-box .countdown-timer li span.seconds {
//   position        : relative;
//   display         : block;
//   width           : 70px;
//   height          : 70px;
//   color           : var(--customVar-black);
//   font-size       : 35px;
//   line-height     : 35px;
//   text-align      : center;
//   background-color: var(--customVar-white);
//   font-weight     : 600;
//   border-radius   : 10px;
//   font-family     : var(--customVar-title-font);
//   padding-top     : 10px;
// }

// .sidebar__sale-box-content-box .countdown-timer li span.timeRef {
//   position      : absolute;
//   bottom        : 8px;
//   left          : 50%;
//   color         : var(--customVar-black);
//   font-size     : 14px;
//   line-height   : 14px;
//   font-weight   : 500;
//   text-transform: capitalize;
//   display       : block;
//   text-align    : center;
//   transform     : translateX(-50%);
// }

// .sidebar__sale-box-text {
//   font-size    : 22px;
//   color        : var(--customVar-white);
//   font-weight  : 500;
//   margin       : 0;
//   line-height  : 35px;
//   font-family  : var(--customVar-title-font);
//   margin-bottom: 20px;
// }

// .sidebar__sale-box__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .sidebar__sale-box__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /********************************************************
// * Blog single
// *********************************************************/
// .blog-single {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 120px 0 120px;
//   z-index         : 1;
// }

// .blog-single__left {
//   position: relative;
//   display : block;
// }

// .blog-single__img {
//   position: relative;
//   display : block;
// }

// .blog-single__img img {
//   width        : 100%;
//   border-radius: 20px;
// }

// .blog-single__user-and-date {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   margin-top   : 30px;
//   margin-bottom: 7px;
// }

// .blog-single__user {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .blog-single__user-img {
//   position     : relative;
//   display      : block;
//   height       : 35px;
//   width        : 35px;
//   border-radius: 50%;
// }

// .blog-single__user-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-single__user-name {
//   font-size     : 12px;
//   color         : var(--customVar-text);
//   font-weight   : 500;
//   text-transform: uppercase;
//   letter-spacing: 0.1em;
//   margin        : 0;
//   line-height   : 12px;
//   margin-left   : 10px;
// }

// .blog-single__date {
//   margin        : 0;
//   margin-left   : 34px;
//   font-weight   : 500;
//   font-size     : 12px;
//   letter-spacing: 0.1em;
//   position      : relative;
// }

// .blog-single__date span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 18px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   top                    : 4px;
//   margin-right           : 10px;
// }

// .blog-single__title {
//   font-weight: 500;
//   font-size  : 26px;
//   line-height: 36px;
//   color      : #141414;
// }

// .blog-single__text-1 {
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : #666666;
//   margin        : 0;
// }

// .blog-single__text-2 {
//   margin: 26px 0 21px;
// }

// .blog-single__text-4 {
//   margin: 21px 0 33px;
// }

// .blog-single__quote-box {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   background-color: #121b5e;
//   padding         : 58px 75px 52px;
// }

// .blog-single__quote-icon {
//   position: relative;
//   display : inline-block;
// }

// .blog-single__quote-icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 65px;
//   color    : var(--customVar-white);
// }

// .blog-single__quote-text {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 20px;
//   line-height   : 32px;
//   letter-spacing: -0.015em;
//   margin-left   : 35px;
//   color         : var(--customVar-white);
// }

// .blog-single__text-5 {
//   margin: 24px 0 21px;
// }

// .blog-single__pagenation {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   padding        : 47px 0 49px;
//   border-bottom  : 1px solid #eaedf8;
//   border-top     : 1px solid #eaedf8;
//   margin-top     : 36px;
//   margin-bottom  : 52px;
// }

// .blog-single__pagenation-left {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
// }

// .blog-single__pagenation-left-img {
//   position     : relative;
//   display      : block;
//   width        : 60px;
//   border-radius: 50%;
// }

// .blog-single__pagenation-left-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-single__pagenation-left-content {
//   margin-left: 17px;
// }

// .blog-single__pagenation-left-title {
//   font-size    : 16px;
//   line-height  : 21px;
//   margin-bottom: 5px;
//   font-weight  : 500;
// }

// .blog-single__pagenation-left-title a {
//   color             : #141414;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-left-title a:hover {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn {
//   position: relative;
//   display : block;
// }

// .blog-single__pagenation-btn a {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-weight       : 500;
//   font-size         : 14px;
//   line-height       : 24px;
//   letter-spacing    : -0.015em;
//   color             : var(--customVar-text);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-btn a:hover {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn a:hover span {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn a span {
//   position          : relative;
//   display           : inline-block;
//   transform         : rotate(-180deg);
//   font-size         : 12px;
//   color             : var(--customVar-text);
//   margin-right      : 10px;
//   top               : 1px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-right {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: end;
// }

// .blog-single__pagenation-right-content {
//   position  : relative;
//   display   : block;
//   text-align: right;
// }

// .blog-single__pagenation-right-title {
//   font-size    : 16px;
//   line-height  : 21px;
//   margin-bottom: 5px;
//   font-weight  : 500;
// }

// .blog-single__pagenation-right-title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-right-title a:hover {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn-2 {
//   position: relative;
//   display : block;
// }

// .blog-single__pagenation-btn-2 a {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : end;
//   font-weight       : 500;
//   font-size         : 14px;
//   line-height       : 24px;
//   letter-spacing    : -0.015em;
//   color             : var(--customVar-text);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-btn-2 a:hover {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn-2 a:hover span {
//   color: var(--customVar-base);
// }

// .blog-single__pagenation-btn-2 a span {
//   position          : relative;
//   display           : inline-block;
//   font-size         : 12px;
//   color             : var(--customVar-text);
//   margin-left       : 10px;
//   top               : 1px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__pagenation-right-img {
//   position     : relative;
//   display      : block;
//   width        : 60px;
//   margin-left  : 17px;
//   border-radius: 50%;
// }

// .blog-single__pagenation-right-img img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .blog-single__social-box {
//   position : absolute;
//   top      : 50%;
//   left     : 50%;
//   transform: translateX(-50%) translateY(-50%);
// }

// .blog-single__social {
//   margin       : 0;
//   display      : -webkit-box;
//   display      : -ms-flexbox;
//   display      : flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap    : wrap;
// }

// .blog-single__social li+li {
//   margin-left: 18px;
// }

// .blog-single__social li a {
//   width             : 45px;
//   height            : 45px;
//   border            : 2px solid #666666;
//   border-radius     : 10px;
//   display           : -webkit-box;
//   display           : -ms-flexbox;
//   display           : flex;
//   -webkit-box-pack  : center;
//   -ms-flex-pack     : center;
//   justify-content   : center;
//   -webkit-box-align : center;
//   -ms-flex-align    : center;
//   align-items       : center;
//   text-align        : center;
//   color             : #666666;
//   font-size         : 18px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .blog-single__social li a:hover {
//   background-color: var(--customVar-base);
//   border          : 2px solid transparent;
//   color           : var(--customVar-black, #121212);
// }

// .blog-single__social-title {
//   margin     : 0 0 6px;
//   text-align : center;
//   font-weight: 500;
//   font-size  : 16px;
//   line-height: 26px;
//   color      : #141414;
// }

// /*--------------------------------------------------------------
// ## Comments
// --------------------------------------------------------------*/
// .blog-single__comment-and-form {
//   position: relative;
//   display : block;
// }

// .comment-one__title,
// .comment-form__title {
//   margin       : 0;
//   margin-bottom: 26px;
//   font-weight  : 600;
//   font-size    : 22px;
//   line-height  : 32px;
//   color        : #141414;
// }

// .comment-one__single {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
// }

// .comment-one__single+.comment-one__single {
//   margin-top: 30px;
// }

// .comment-one__single-2 {
//   margin-left: 100px;
// }

// .comment-one__content {
//   position     : relative;
//   display      : block;
//   margin-left  : 30px;
//   border       : 2px solid #eaecf8;
//   border-radius: 10px;
//   max-width    : 770px;
//   width        : 100%;
//   padding      : 26px 32px 32px;
// }

// .comment-one__content h3 {
//   margin     : 0;
//   font-weight: 500;
//   font-size  : 20px;
//   line-height: 30px;
//   color      : #121212;
// }

// .comment-one__content h3 span {
//   font-weight   : 500;
//   font-size     : 16px;
//   line-height   : 16px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-text);
//   margin-left   : 15px;
// }

// .comment-one__content p {
//   margin        : 7px 0 0;
//   font-weight   : 400;
//   line-height   : 26px;
//   letter-spacing: -0.015em;
// }

// .comment-one__btn {
//   position          : absolute;
//   top               : 30px;
//   right             : 28px;
//   font-size         : 18px;
//   line-height       : 23px;
//   color             : #3852ff;
//   font-family       : var(--customVar-title-font);
//   font-weight       : 500;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .comment-one__btn:hover {
//   color: var(--customVar-base);
// }

// .comment-one__btn span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 15px;
//   margin-right: 8px;
// }

// .comment-one__image {
//   position     : relative;
//   display      : block;
//   border-radius: 50%;
// }

// .comment-one__image img {
//   width        : auto;
//   border-radius: 50%;
// }

// .comment-form {
//   position   : relative;
//   display    : block;
//   padding-top: 50px;
// }

// .comment-form .row {
//   --bs-gutter-x: 15px;
// }

// .comment-form__input-box {
//   position     : relative;
//   display      : block;
//   margin-bottom: 15px;
// }

// .comment-form__input-box input[type="text"],
// .comment-form__input-box input[type="email"] {
//   height            : 60px;
//   width             : 100%;
//   border            : 1px solid #eaedf8;
//   background-color  : var(--customVar-white);
//   outline           : none;
//   padding-left      : 20px;
//   padding-right     : 20px;
//   font-size         : 16px;
//   font-weight       : 400;
//   letter-spacing    : -0.015em;
//   color             : var(--customVar-text);
//   display           : block;
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .comment-form__input-box textarea {
//   font-size         : 16px;
//   font-weight       : 400;
//   letter-spacing    : -0.015em;
//   color             : var(--customVar-text);
//   height            : 140px;
//   width             : 100%;
//   border            : 1px solid #eaedf8;
//   background-color  : var(--customVar-white);
//   padding           : 12px 20px 30px;
//   outline           : none;
//   margin-bottom     : 0px;
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .comment-one__form .checked-box {
//   position     : relative;
//   display      : block;
//   min-height   : 30px;
//   margin-top   : 24px;
//   margin-bottom: 36px;
// }

// .comment-one__form .checked-box label {
//   position      : relative;
//   display       : inline-block;
//   padding-left  : 40px;
//   margin-right  : 0px;
//   margin-bottom : 0;
//   color         : var(--customVar-text);
//   font-size     : 16px;
//   font-weight   : 400;
//   cursor        : pointer;
//   min-height    : 30px;
//   letter-spacing: -0.015em;
// }

// .comment-one__form .checked-box input[type="checkbox"] {
//   display: none;
// }

// .comment-one__form .checked-box input[type="checkbox"]+label span {
//   position          : absolute;
//   top               : 3px;
//   left              : 0;
//   width             : 25px;
//   height            : 25px;
//   vertical-align    : middle;
//   border            : 1px solid #eaedf8;
//   border-radius     : 5px;
//   cursor            : pointer;
//   -webkit-transition: all 300ms ease;
//   -moz-transition   : all 300ms ease;
//   -ms-transition    : all 300ms ease;
//   -o-transition     : all 300ms ease;
//   transition        : all 300ms ease;
// }

// .comment-one__form .checked-box label span:before {
//   content                 : "";
//   position                : absolute;
//   top                     : 3px;
//   left                    : 6px;
//   display                 : block;
//   border-bottom           : 2px solid #eaedf8;
//   border-right            : 2px solid #eaedf8;
//   width                   : 8px;
//   height                  : 13px;
//   pointer-events          : none;
//   -webkit-transform-origin: 66% 66%;
//   -ms-transform-origin    : 66% 66%;
//   transform-origin        : 66% 66%;
//   -webkit-transform       : rotate(45deg);
//   -ms-transform           : rotate(45deg);
//   transform               : rotate(45deg);
//   -webkit-transition      : all 0.15s ease-in-out;
//   transition              : all 0.15s ease-in-out;
//   opacity                 : 0;
// }

// .comment-one__form .checked-box input[type="checkbox"]:checked+label span {
//   border-color: #eaedf8;
// }

// .comment-one__form .checked-box input[type="checkbox"]:checked+label span:before {
//   opacity: 1;
// }

// .comment-form__btn {
//   color  : var(--customVar-white);
//   padding: 14px 50px;
// }

// .comment-form__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .comment-form__input-box.text-message-box {
//   height: 140px;
// }

// /***********************
// * Sale One
// ***********************/
// .sale-one {
//   position: absolute;
//   top     : 20px;
//   left    : 0;
//   right   : 0;
//   z-index : 2;
// }

// .sale-one__inner {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .sale-one__shape-1 {
//   position: absolute;
//   left    : 236px;
//   top     : 0;
// }

// .sale-one__shape-1 img {
//   width: auto;
// }

// .sale-one__shape-2 {
//   position: absolute;
//   right   : 233px;
//   top     : 19px;
// }

// .sale-one__shape-2 img {
//   width: auto;
// }

// .sale-one__text-1 {
//   font-size  : 16px;
//   color      : var(--customVar-white);
//   margin     : 0;
//   font-weight: 500;
// }

// .sale-one__text-2 {
//   font-size  : 16px;
//   color      : var(--customVar-white);
//   margin     : 0;
//   font-weight: 500;
// }

// .sale-one__content-box {
//   position    : relative;
//   display     : block;
//   margin-left : 20px;
//   margin-right: 20px;
// }

// .sale-one__content-box .timer-box {
//   position: relative;
//   display : block;
// }

// .sale-one__content-box .countdown-timer .default-coundown .box {
//   position: relative;
//   display : block;
// }

// .sale-one__content-box .countdown-timer li {
//   position  : relative;
//   display   : inline-block;
//   float     : none;
//   padding   : 0;
//   text-align: center;
//   margin    : 0px 7px 0px;
// }

// .sale-one__content-box .countdown-timer li:first-child {
//   margin-left: 0;
// }

// .sale-one__content-box .countdown-timer li:last-child {
//   margin-right: 0;
// }

// .sale-one__content-box .countdown-timer li span.days,
// .sale-one__content-box .countdown-timer li span.hours,
// .sale-one__content-box .countdown-timer li span.minutes,
// .sale-one__content-box .countdown-timer li span.seconds {
//   position        : relative;
//   display         : block;
//   width           : 60px;
//   height          : 50px;
//   color           : var(--customVar-white);
//   font-size       : 22px;
//   line-height     : 31px;
//   text-align      : center;
//   background-color: transparent;
//   font-weight     : 600;
//   border-style    : solid;
//   border-width    : 2px;
//   border-image    : linear-gradient(to right, #ffa065 -5.32%, #ff5a74) 1;
//   border-radius   : 10px;
//   font-family     : var(--customVar-title-font);
// }

// .sale-one__content-box .countdown-timer li span.timeRef {
//   position      : absolute;
//   bottom        : 8px;
//   left          : 50%;
//   color         : var(--customVar-white);
//   font-size     : 12px;
//   line-height   : 12px;
//   font-weight   : 500;
//   text-transform: capitalize;
//   display       : block;
//   text-align    : center;
//   transform     : translateX(-50%);
// }

// /***********************
// * Faq Two
// ***********************/
// .faq-two {
//   position  : relative;
//   display   : block;
//   background: #0f122c;
//   padding   : 130px 0 120px;
//   z-index   : 3;
// }

// .faq-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .faq-two__left {
//   position: relative;
//   display : block;
// }

// .faq-two__img {
//   position: absolute;
//   top     : -32px;
//   left    : 90px;
// }

// .faq-two__img img {
//   width: auto;
// }

// .faq-two__right {
//   position     : relative;
//   display      : block;
//   counter-reset: count;
// }

// .faq-two__title {
//   font-weight  : 700;
//   font-size    : 38px;
//   line-height  : 48px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 43px;
// }

// .faq-two__right .accrodion-grp {
//   margin-left: 85px;
// }

// .faq-two__right .faq-one-accrodion .accrodion {
//   position          : relative;
//   display           : block;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faq-two__right .faq-one-accrodion .accrodion:before {
//   content   : "";
//   position  : absolute;
//   top       : 32px;
//   bottom    : -42px;
//   left      : -46px;
//   width     : 3px;
//   background: rgb(255, 255, 255);
//   background: linear-gradient(180deg, rgba(255, 255, 255, 1) 33%, rgba(238, 242, 255, 0) 100%);
// }

// .faq-two__right .faq-one-accrodion .accrodion.active:before {
//   background: rgb(255, 96, 15);
//   background: linear-gradient(180deg, rgba(255, 96, 15, 1) 33%, rgba(16, 18, 44, 0.07) 100%);
// }

// .faq-two__right .faq-one-accrodion .accrodion.last-child:before {
//   display: none;
// }

// .faq-two__right .faq-one-accrodion .accrodion-title {
//   position        : relative;
//   display         : block;
//   cursor          : pointer;
//   transition      : all 200ms linear;
//   transition-delay: 0.1s;
// }

// .faq-two__right .faq-one-accrodion .accrodion-title h4 {
//   margin            : 0;
//   font-size         : 22px;
//   color             : var(--customVar-white);
//   font-weight       : 600;
//   line-height       : 30px;
//   position          : relative;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faq-two__right .faq-one-accrodion .accrodion.active .accrodion-title h4 {}

// .faq-two__right .faq-one-accrodion .accrodion+.accrodion {
//   margin-top: 41px;
// }

// .faq-two__right .faq-one-accrodion .accrodion-title h4::before {
//   position          : absolute;
//   font-size         : 22px;
//   line-height       : 22px;
//   font-weight       : 600;
//   counter-increment : count;
//   content           : "0"counter(count);
//   color             : var(--customVar-white);
//   height            : 30px;
//   width             : 30px;
//   top               : 5px;
//   left              : -57px;
//   font-family       : var(--customVar-title-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faq-two__right .faq-one-accrodion .accrodion.active .accrodion-title h4::before {
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-clip        : text;
//   text-fill-color        : transparent;
// }

// .faq-two__right .faq-one-accrodion .accrodion-content {
//   position   : relative;
//   padding-top: 10px;
// }

// .faq-two__right .faq-one-accrodion .accrodion-content p {
//   margin: 0;
//   color : var(--customVar-white);
// }

// .faq-two__btn {
//   color             : var(--customVar-white);
//   padding           : 14px 45px;
//   margin-top        : 50px;
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .faq-two__btn:hover {
//   color: var(--customVar-black);
// }

// .faq-two__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .faq-two__btn::after {
//   background-color: var(--customVar-white);
// }

// /***********************
// * Comparision Two
// ***********************/
// .comparision-two {
//   position: relative;
//   display : block;
//   padding : 0 0 120px;
// }

// .comparision-two__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .comparision-two__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .comparision-two__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .comparision-two__table-responsive {
//   position  : relative;
//   display   : block;
//   width     : 100%;
//   overflow-x: auto;
// }

// .comparision-two__table {
//   position : relative;
//   margin   : 0;
//   min-width: 1320px;
//   width    : 100%;
//   z-index  : 1;
// }

// .comparision-two__table:before {
//   content   : "";
//   position  : absolute;
//   top       : 0;
//   left      : 270px;
//   bottom    : 0;
//   width     : 180px;
//   background: linear-gradient(181.57deg, #000c5c 1.65%, #0020f7 100%);
//   z-index   : 1;
// }

// .comparision-two__table thead tr {
//   position  : relative;
//   text-align: center;
// }

// .comparision-two__table thead th {
//   padding       : 0;
//   border        : none;
//   padding-bottom: 20px;
//   position      : relative;
//   z-index       : 5;
// }

// .comparision-two__table thead th:first-child {
//   text-align  : left;
//   padding-left: 30px;
// }

// .comparision-two__table thead th .starting-from {
//   font-size     : 16px;
//   font-weight   : 600;
//   letter-spacing: -0.015em;
//   color         : #5369ff;
//   margin        : 0;
// }

// .comparision-two__table thead th .logo-box {
//   position     : relative;
//   display      : block;
//   margin-bottom: 27px;
// }

// .comparision-two__table thead th .logo-box img {
//   width: auto;
// }

// .comparision-two__table thead th .price-box {
//   position: relative;
//   display : block;
// }

// .comparision-two__table thead th .price-box p {
//   font-size  : 40px;
//   color      : var(--customVar-black);
//   font-weight: 800;
//   line-height: 40px;
//   margin     : 0;
//   font-family: var(--customVar-title-font);
// }

// .comparision-two__table thead th .price-box.clr-white p {
//   color: var(--customVar-white);
// }

// .comparision-two__table thead th .price-box p .point {
//   font-size: 20px;
//   position : relative;
// }

// .comparision-two__table thead th .price-box p .validity {
//   font-size  : 20px;
//   font-weight: 500;
//   position   : relative;
//   left       : -9px;
// }

// .comparision-two__table tbody {
//   position        : relative;
//   background-color: #eef0ff;
// }

// .comparision-two__table tbody tr {
//   position  : relative;
//   text-align: center;
// }

// .comparision-two__table tbody tr:nth-child(even) {
//   background-color: #ffffff;
// }

// .comparision-two__table tbody tr:last-child {
//   border-bottom: 0;
// }

// .comparision-two__table tbody tr td {
//   font-size     : 16px;
//   font-weight   : 400;
//   border        : 0;
//   border-right  : 1px solid rgba(222, 225, 247, 1);
//   padding       : 27.5px 0;
//   vertical-align: middle;
//   position      : relative;
//   z-index       : 5;
// }

// .comparision-two__table tbody tr td .first-child-name {
//   letter-spacing: -0.015em;
//   color         : #666666;
//   margin        : 0;
//   font-size     : 16px;
//   line-height   : 16px;
// }

// .comparision-two__table tbody tr td .check-icon-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   height         : 25px;
//   width          : 25px;
//   background     : #b3bbf4;
//   border-radius  : 50%;
//   margin         : 0 auto;
//   font-size      : 10px;
//   color          : var(--customVar-white);
// }

// .comparision-two__table tbody tr td .check-icon-box.clr {
//   background: #00c14d;
//   color     : #0015a2;
// }

// .comparision-two__table tbody tr td .close-icon-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   height         : 25px;
//   width          : 25px;
//   background     : #b3bbf4;
//   border-radius  : 50%;
//   margin         : 0 auto;
//   font-size      : 10px;
//   color          : var(--customVar-white);
// }

// .comparision-two__table tbody tr td .pain-box {
//   position: relative;
//   display : block;
// }

// .comparision-two__table tbody tr td .pain {
//   letter-spacing: -0.015em;
//   color         : #b3bbf4;
//   font-size     : 16px;
//   font-weight   : 500;
//   line-height   : 16px;
//   margin        : 0;
// }

// .comparision-two__table tbody tr td .pain.clr-white {
//   color: #ffffff;
// }

// .comparision-two__table tbody tr td:first-child {
//   text-align  : left;
//   padding-left: 30px;
// }

// .comparision-two__table tbody tr td:last-child {
//   border-right: 0;
// }

// /*************************************************************
// * page-banner-three
// **************************************************************/
// .page-banner-three {
//   background-image   : url(../images/template/background/page-header-1-3-bg.png);
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center center;
//   padding-top        : 178px;
//   padding-bottom     : 11px;
// }

// .page-banner-three__left {
//   position: relative;
//   display : block;
// }

// .page-banner-three__sub-title {
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 32px;
//   color      : var(--customVar-white);
//   margin     : 0;
// }

// .page-banner-three__title {
//   font-weight: 700;
//   font-size  : 60px;
//   line-height: 76px;
//   color      : var(--customVar-white);
//   margin     : 4px 0 16px;
// }

// .page-banner-three__points {
//   position    : relative;
//   display     : block;
//   padding-left: 28px;
//   margin      : 0;
// }

// .page-banner-three__points li {
//   position: relative;
//   display : block;
// }

// .page-banner-three__points li .text {
//   position: relative;
//   display : block;
// }

// .page-banner-three__points li .text::before {
//   content         : "";
//   position        : absolute;
//   top             : 50%;
//   left            : -17px;
//   width           : 6px;
//   height          : 6px;
//   background-color: var(--customVar-white);
//   border-radius   : 50%;
//   transform       : translateY(-50%);
// }

// .page-banner-three__points li .text p {
//   margin     : 0;
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 40px;
//   color      : var(--customVar-white);
// }

// .page-banner-three__title-2 {
//   margin     : 21px 0 33px;
//   font-weight: 700;
//   font-size  : 26px;
//   line-height: 33px;
//   color      : var(--customVar-white);
// }

// .page-banner-three__btn-box {
//   position: relative;
//   display : block;
// }

// .page-banner-three__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .page-banner-three__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .page-banner-three__right {
//   position   : relative;
//   display    : block;
//   margin-left: 104px;
//   margin-top : -20px;
// }

// .page-banner-three__img {
//   position: relative;
//   display : block;
// }

// .page-banner-three__img img {
//   width: 100%;
// }

// /*********************************************
// * Pricing Four
// **********************************************/
// .pricing-four {
//   position        : relative;
//   display         : block;
//   background-color: #f4f7fe;
//   padding         : 108px 0 90px;
//   z-index         : 1;
// }

// .pricing-four__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-four__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-four__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-four ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-four ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-four ul.switch-toggler-list li.active a {
//   color: var(--customVar-black);
// }

// .pricing-four .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-four .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-four .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-four .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-four .slider.round {
//   border-radius: 5px;
// }

// .pricing-four .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-four__single {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   padding         : 46px 30px 36px;
//   border-radius   : 20px;
//   margin-bottom   : 30px;
//   overflow        : hidden;
//   z-index         : 1;
// }

// .pricing-four__price-box {
//   position: relative;
//   display : block;
// }

// .pricing-four__price {
//   font-size  : 50px;
//   font-weight: 800;
//   color      : var(--customVar-black);
//   line-height: 50px;
//   margin     : 0;
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-four__price-point {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   top        : 11px;
// }

// .pricing-four__price-validity {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 20px;
//   line-height: 20px;
//   font-weight: 500;
//   top        : 11px;
// }

// .pricing-four__price-renew {
//   margin     : 12px 0 9px;
//   font-weight: 400;
//   font-size  : 16px;
//   line-height: 20px;
//   color      : #2959eb;
// }

// .pricing-four__price-renew span {
//   position: relative;
//   display : inline-block;
// }

// .pricing-four__price-renew span::before {
//   content         : "";
//   position        : absolute;
//   top             : 10px;
//   left            : 3px;
//   width           : 27px;
//   height          : 1px;
//   background-color: #2959eb;
// }

// .pricing-four__title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 26px;
//   line-height: 33px;
// }

// .pricing-four__title a {
//   color             : #121212;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .pricing-four__title a:hover {
//   color: #2959eb;
// }

// .pricing-four__points-box {
//   position  : relative;
//   display   : block;
//   margin-top: 33px;
// }

// .pricing-four__points-title {
//   margin     : 0 0 10px;
//   font-weight: 600;
//   font-size  : 18px;
//   line-height: 22px;
//   color      : #121212;
// }

// .pricing-four__points-title span {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 17px;
//   color      : #aaaaaa;
//   margin-left: 10px;
//   top        : 2px;
// }

// .pricing-four__points-title-2 {
//   margin-top: 31px;
// }

// .pricing-four__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-four__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-four__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 3px;
// }

// .pricing-four__points li .icon i {
//   position : relative;
//   display  : inline-block;
//   font-size: 21px;
//   color    : #00c14d;
// }

// .pricing-four__points li .text {
//   position   : relative;
//   display    : block;
//   margin-left: 20px;
// }

// .pricing-four__points li .text p {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 40px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-text);
// }

// .pricing-four__points li .text p span {
//   font-weight     : 600;
//   font-size       : 12px;
//   line-height     : 30px;
//   background-color: #020d66;
//   color           : var(--customVar-white);
//   padding         : 4px 15px 7px;
//   letter-spacing  : 0;
//   border-radius   : 13px;
//   margin-left     : 6px;
//   top             : -2px;
//   position        : relative;
// }

// .pricing-four__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 22px;
// }

// .pricing-four__btn {
//   position      : relative;
//   letter-spacing: 0.01em;
//   display       : block;
//   text-align    : center;
//   color         : var(--customVar-white);
// }

// .pricing-four__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-four__btn::after {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .pricing-four__recomanded {
//   position: absolute;
//   top     : 0;
//   right   : 0;
// }

// .pricing-four__recomanded p {
//   margin                   : 0;
//   background-color         : #010d61;
//   font-weight              : 600;
//   font-size                : 11px;
//   line-height              : 21px;
//   color                    : var(--customVar-white);
//   text-transform           : uppercase;
//   padding                  : 6px 20px 5px;
//   border-bottom-left-radius: 20px;
//   opacity                  : 0;
//   -webkit-transition       : all 500ms ease;
//   transition               : all 500ms ease;
// }

// .pricing-four__recomanded p span {
//   position    : relative;
//   display     : inline-block;
//   margin-right: 6px;
// }

// .pricing-four__single:hover .pricing-four__recomanded p {
//   opacity: 1;
// }

// /*************************************************************
// * Addons One
// **************************************************************/
// .addons-one {
//   position: relative;
//   display : block;
//   padding : 109px 0 70px;
//   z-index : 1;
// }

// .addons-one__section-title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   text-align : center;
//   color      : #121212;
//   margin     : 0;
// }

// .addons-one__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .addons-one__icon {
//   position: relative;
//   display : inline-block;
// }

// .addons-one__icon span {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   width           : 70px;
//   height          : 70px;
//   font-size       : 20px;
//   color           : var(--customVar-secondary);
//   background-color: #f0f5ff;
//   border-radius   : 20px;
// }

// .addons-one__title {
//   margin     : 23px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : #121212;
// }

// .addons-one__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /*************************************************************
// * Featured Two
// **************************************************************/
// .feature-two {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 120px 0 115px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .feature-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .feature-two__left {
//   position     : relative;
//   display      : block;
//   counter-reset: count;
//   margin-top   : 38px;
// }

// .feature-two__title {
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 41px;
// }

// .feature-two__left .accrodion-grp {
//   margin-left: 85px;
// }

// .feature-two__left .faq-one-accrodion .accrodion {
//   position          : relative;
//   display           : block;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-two__left .faq-one-accrodion .accrodion:before {
//   content   : "";
//   position  : absolute;
//   top       : 32px;
//   bottom    : -42px;
//   left      : -46px;
//   width     : 3px;
//   background: rgb(255, 255, 255);
//   background: linear-gradient(180deg, rgba(255, 255, 255, 1) 33%, rgba(238, 242, 255, 0) 100%);
// }

// .feature-two__left .faq-one-accrodion .accrodion.active:before {
//   background: rgb(255, 255, 255);
//   background: linear-gradient(180deg, rgba(255, 255, 255, 1) 33%, rgba(238, 242, 255, 0) 100%);
// }

// .feature-two__left .faq-one-accrodion .accrodion.last-child:before {
//   display: none;
// }

// .feature-two__left .faq-one-accrodion .accrodion-title {
//   position        : relative;
//   display         : block;
//   cursor          : pointer;
//   transition      : all 200ms linear;
//   transition-delay: 0.1s;
// }

// .feature-two__left .faq-one-accrodion .accrodion-title h4 {
//   margin            : 0;
//   font-size         : 22px;
//   color             : var(--customVar-white);
//   font-weight       : 600;
//   line-height       : 30px;
//   position          : relative;
//   letter-spacing    : -0.015em;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-two__left .faq-one-accrodion .accrodion+.accrodion {
//   margin-top: 41px;
// }

// .feature-two__left .faq-one-accrodion .accrodion-title h4::before {
//   position          : absolute;
//   font-size         : 22px;
//   line-height       : 22px;
//   font-weight       : 600;
//   counter-increment : count;
//   content           : "0"counter(count);
//   color             : var(--customVar-white);
//   height            : 30px;
//   width             : 30px;
//   top               : 5px;
//   left              : -57px;
//   font-family       : var(--customVar-title-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-two__left .faq-one-accrodion .accrodion.active .accrodion-title h4::before {
//   color: var(--customVar-white);
// }

// .feature-two__left .faq-one-accrodion .accrodion-content {
//   position   : relative;
//   padding-top: 10px;
// }

// .feature-two__left .faq-one-accrodion .accrodion-content p {
//   margin        : 0;
//   color         : var(--customVar-white);
//   letter-spacing: -0.015em;
// }

// .feature-two__right {
//   position: relative;
//   display : block;
// }

// .feature-two__img {
//   position: relative;
//   display : block;
// }

// .feature-two__img img {
//   width: 100%;
// }

// /*************************************************************
// * Shared Hosting
// **************************************************************/
// .shared-hosting {
//   position        : relative;
//   display         : block;
//   padding         : 40px 0 110px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .shared-hosting__left {
//   position    : relative;
//   display     : block;
//   margin-right: 87px;
// }

// .shared-hosting__img {
//   position: relative;
//   display : block;
// }

// .shared-hosting__img img {
//   width: 100%;
// }

// .shared-hosting__right {
//   position   : relative;
//   display    : block;
//   margin-left: 20px;
//   margin-top : 130px;
// }

// .shared-hosting__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : #121212;
// }

// .shared-hosting__text-1 {
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   margin        : 20px 0 15px;
// }

// .shared-hosting__text-2 {
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   margin        : 0;
// }

// .shared-hosting__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 38px;
// }

// .shared-hosting__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .shared-hosting__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /*************************************************************
// * Why Us
// **************************************************************/
// .why-us {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding-bottom  : 90px;
//   z-index         : 1;
// }

// .why-us .row {
//   --bs-gutter-x: 20px;
// }

// .why-us__single {
//   position        : relative;
//   display         : block;
//   text-align      : center;
//   background-color: var(--customVar-white);
//   border-radius   : 20px;
//   padding         : 40px 30px 37px;
//   margin-bottom   : 30px;
// }

// .why-us__icon {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   width           : 90px;
//   height          : 90px;
//   background-color: #f0f5ff;
//   border-radius   : 20px;
//   margin          : 0 auto;
// }

// .why-us__icon img {
//   width: auto;
// }

// .why-us__title {
//   margin     : 23px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : #121212;
// }

// .why-us__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /*************************************************************
// * Counter One
// **************************************************************/
// .counter-one {
//   position: relative;
//   display : block;
//   padding : 103px 0 78px;
//   z-index : 1;
// }

// .counter-one__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .counter-one__title {
//   margin       : 0;
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   text-align   : center;
//   color        : var(--customVar-white);
//   margin-bottom: 58px;
// }

// .counter-one__single {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   margin-bottom: 23px;
// }

// .counter-one__count-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .counter-one__count-box h3 {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 50px;
//   line-height: 50px;
//   color      : var(--customVar-white);
// }

// .counter-one__count-text {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 50px;
//   line-height: 50px;
//   color      : var(--customVar-white);
// }

// .counter-one__count-box span {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 50px;
//   line-height: 50px;
//   color      : var(--customVar-white);
//   top        : -4px;
//   position   : relative;
// }

// .counter-one__text {
//   line-height   : 26px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
//   margin        : 6px 0 0;
// }

// /***********************
// * VPS Hosting Page Banner
// ***********************/
// .vps-hosting-page-banner .page-banner-three__right {
//   margin-left: 70px;
//   margin-top : -80px;
// }

// /***********************
// * Pricing Six
// ***********************/
// .pricing-six {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 109px 0 120px;
// }

// .pricing-six__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .pricing-six__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .pricing-six__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .pricing-six__inner {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   border       : 1px solid #dde1fd;
//   border-radius: 20px;
// }

// .pricing-six__inner .row {
//   --bs-gutter-x: 0px;
// }

// .pricing-six__single {
//   position: relative;
//   display : block;
//   padding : 47px 35px 35px;
// }

// .pricing-six__single--two {
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .pricing-six__top {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .pricing-six__pack-name {
//   font-size    : 26px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 26px;
//   margin-bottom: 21px;
// }

// .pricing-six__price {
//   font-size  : 50px;
//   font-weight: 800;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 50px;
// }

// .pricing-six__price span {
//   position   : relative;
//   font-size  : 20px;
//   font-weight: 500;
//   left       : -10px;
// }

// .pricing-six__save {
//   position     : relative;
//   display      : inline-block;
//   font-size    : 14px;
//   font-weight  : 400;
//   line-height  : 14px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   background   : #ebedff;
//   border-radius: 13px;
//   padding      : 6px 15px;
// }

// .pricing-six__text {
//   margin       : 0;
//   margin-top   : 5px;
//   margin-bottom: 25px;
// }

// .pricing-six__btn-box {
//   position: relative;
//   display : block;
// }

// .pricing-six__btn {
//   width          : 100%;
//   color          : var(--customVar-white);
//   text-align     : center;
//   justify-content: center;
// }

// .pricing-six__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-six__points {
//   position  : relative;
//   display   : block;
//   margin    : 0;
//   margin-top: 30px;
// }

// .pricing-six__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-six__points li+li {
//   margin-top: 8px;
// }

// .pricing-six__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-six__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .pricing-six__points li .text {
//   margin-left: 20px;
// }

// .pricing-six__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-six__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// /***********************
// * Pay Less
// ***********************/
// .pay-less {
//   position  : relative;
//   display   : block;
//   background: #fafbff;
//   padding   : 109px 0 120px;
// }

// .pay-less__inner {
//   position: relative;
//   display : block;
//   margin  : 0 60px;
// }

// .pay-less__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .pay-less__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .pay-less__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .pay-less__left {
//   position: relative;
//   display : block;
// }

// .pay-less__list {
//   position: relative;
//   display : block;
// }

// .pay-less__list li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pay-less__list li+li {
//   margin-top: 20px;
// }

// .pay-less__list li .icon {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   height            : 50px;
//   width             : 50px;
//   background        : #f0f5ff;
//   border-radius     : 50%;
//   font-size         : 15px;
//   color             : var(--customVar-secondary);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .pay-less__list li:hover .icon {
//   background-color: var(--customVar-black);
//   color           : var(--customVar-white);
// }

// .pay-less__list li .text {
//   margin-left: 30px;
// }

// .pay-less__list li .text p {
//   font-size  : 21px;
//   color      : var(--customVar-black);
//   font-weight: 600;
//   font-family: var(--customVar-title-font);
//   margin     : 0;
// }

// .pay-less__btn-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   margin-top: 39px;
// }

// .pay-less__btn {
//   color             : var(--customVar-white);
//   padding           : 14px 35px;
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .pay-less__btn::before {
//   background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
// }

// /***********************
// * Database
// ***********************/
// .database {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 109px 0 110px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .database__shape-1 {
//   position: absolute;
//   top     : 0;
//   left    : 0;
// }

// .database__shape-1 img {
//   width: auto;
// }

// .database__shape-2 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
// }

// .database__shape-2 img {
//   width: auto;
// }

// .database__shape-3 {
//   position: absolute;
//   bottom  : 445px;
//   right   : 115px;
// }

// .database__shape-3 img {
//   width: auto;
// }

// .database__shape-4 {
//   position: absolute;
//   bottom  : 85px;
//   right   : 0;
// }

// .database__shape-4 img {
//   width: auto;
// }

// .database__shape-5 {
//   position: absolute;
//   bottom  : 0;
//   left    : 185px;
// }

// .database__shape-5 img {
//   width: auto;
// }

// .database__title {
//   position     : relative;
//   display      : block;
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   text-align   : center;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 44px;
// }

// .database__table-responsive {
//   position  : relative;
//   display   : block;
//   width     : 100%;
//   overflow-x: auto;
// }

// .database__table {
//   position : relative;
//   margin   : 0;
//   min-width: 1320px;
//   width    : 100%;
// }

// .database__table thead tr {
//   position        : relative;
//   text-align      : center;
//   background-color: var(--customVar-white);
// }

// .database__table thead th {
//   padding       : 0;
//   border        : none;
//   font-size     : 20px;
//   font-weight   : 600;
//   color         : var(--customVar-black);
//   font-family   : var(--customVar-title-font);
//   text-align    : center;
//   vertical-align: middle;
//   padding       : 24px 0;
// }

// .database__table thead th:first-child {}

// .database__table tbody {
//   position        : relative;
//   background-color: transparent;
// }

// .database__table tbody tr {
//   position  : relative;
//   text-align: center;
// }

// .database__table tbody tr:nth-child(even) {
//   background-color: #475eb9;
// }

// .database__table tbody tr:last-child {
//   border-bottom: 0;
// }

// .database__table tbody tr td {
//   font-size     : 18px;
//   font-weight   : 400;
//   border        : 0;
//   padding       : 24px 0;
//   vertical-align: middle;
//   color         : var(--customVar-white);
// }

// .database__table tbody tr td:first-child {
//   text-align  : left;
//   padding-left: 40px;
// }

// .database__table tbody tr td:last-child {
//   text-align   : right;
//   padding-right: 40px;
// }

// .database__btn {
//   position      : relative;
//   display       : inline-block;
//   font-size     : 18px;
//   color         : var(--hsoutp-white);
//   font-weight   : 600;
//   letter-spacing: 0.01em;
//   font-family   : var(--customVar-title-font);
// }

// .database__btn:before {
//   content         : "";
//   position        : absolute;
//   bottom          : 6px;
//   left            : 0;
//   right           : 39px;
//   height          : 1px;
//   background-color: var(--customVar-white);
// }

// .database__btn i {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 15px;
//   top        : 1px;
//   margin-left: 10px;
// }

// .database__bottom-text {
//   font-size : 18px;
//   color     : var(--customVar-white);
//   text-align: center;
//   margin    : 0;
//   margin-top: 40px;
// }

// /***********************
// * Feature Three
// ***********************/
// .feature-three {
//   position: relative;
//   display : block;
//   padding : 109px 0 90px;
//   z-index : 1;
// }

// .feature-three__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .feature-three__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-three__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .feature-three__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .feature-three .container {
//   max-width: 1136px;
// }

// .feature-three__single {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   flex-wrap    : wrap;
//   background   : #ffffff;
//   border-radius: 20px;
//   margin-bottom: 30px;
//   padding      : 57px 35px 50px;
// }

// .feature-three__icon {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   height         : 90px;
//   width          : 90px;
//   background     : #f0f5ff;
//   border-radius  : 20px;
// }

// .feature-three__icon img {
//   width: auto;
// }

// .feature-three__content {
//   position   : relative;
//   display    : block;
//   max-width  : 335px;
//   width      : 100%;
//   margin-left: 35px;
// }

// .feature-three__title {
//   font-size    : 22px;
//   color        : var(--customVar-black);
//   font-weight  : 600;
//   margin       : 0;
//   line-height  : 22px;
//   margin-bottom: 12px;
// }

// .feature-three__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
// }

// /***********************
// * Competitors
// ***********************/
// .competitors {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 120px;
// }

// .competitors__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .competitors__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .competitors__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .competitors__table-responsive {
//   position  : relative;
//   display   : block;
//   width     : 100%;
//   overflow-x: auto;
// }

// .competitors__table {
//   position : relative;
//   margin   : 0;
//   min-width: 1320px;
//   width    : 100%;
// }

// .competitors__table thead tr {
//   position  : relative;
//   text-align: center;
//   background: #eef0ff;
//   border    : 1px solid #eef0ff;
// }

// .competitors__table thead th {
//   padding       : 0;
//   border        : none;
//   font-size     : 20px;
//   font-weight   : 600;
//   color         : var(--customVar-black);
//   font-family   : var(--customVar-title-font);
//   text-align    : center;
//   vertical-align: middle;
//   padding       : 24px 0;
// }

// .competitors__table tbody {
//   position        : relative;
//   background-color: transparent;
// }

// .competitors__table tbody tr {
//   position    : relative;
//   text-align  : center;
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .competitors__table tbody tr:last-child {
//   border-bottom: 1px solid #dde1fd;
// }

// .competitors__table tbody tr td {
//   font-size     : 18px;
//   font-weight   : 400;
//   border        : 0;
//   padding       : 24px 0;
//   vertical-align: middle;
//   color         : var(--customVar-text);
//   border-right  : 1px solid #dde1fd;
// }

// .competitors__table tbody tr td:first-child {
//   font-weight : 600;
//   color       : var(--customVar-black);
//   text-align  : left;
//   padding-left: 60px;
// }

// .competitors__table tbody tr td:last-child {
//   border-right: 0;
// }

// /***********************
// * Database Two
// ***********************/
// .database-two {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 109px 0 0;
//   z-index   : 1;
// }

// .database-two:before {
//   content         : "";
//   position        : absolute;
//   bottom          : 0;
//   left            : 0;
//   right           : 0;
//   height          : 250px;
//   background-color: #fafbff;
//   z-index         : -1;
// }

// .database-two__shape {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: top center;
//   z-index            : -1;
// }

// .database-two .container {
//   max-width: 1140px;
// }

// .database-two-top__title {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 39px;
// }

// .database-two__single {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   background   : #ffffff;
//   box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   padding      : 40px 50px 28px;
//   margin-bottom: 30px;
// }

// .database-two__icon {
//   position: relative;
//   display : block;
// }

// .database-two__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 35px;
//   margin-top   : 24px;
//   margin-bottom: 10px;
// }

// .database-two__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
// }

// .database-two__read-more {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   font-weight    : 600;
//   font-size      : 18px;
//   line-height    : 150%;
//   text-align     : center;
//   letter-spacing : 0.01em;
//   color          : #3852ff;
//   font-family    : var(--customVar-title-font);
//   margin-top     : 17px;
// }

// .database-two__read-more span {
//   position   : relative;
//   font-size  : 14px;
//   top        : 1px;
//   margin-left: 10px;
// }

// .vps-hosting-faq-page {
//   padding-top: 81px;
// }

// /***********************
// *Cloud Hosting Page Banner
// ***********************/
// .cloud-hosting-page-banner {
//   padding-bottom: 60px;
// }

// .cloud-hosting-page-banner .page-banner-three__right {
//   margin-left : 138px;
//   margin-right: -6px;
//   margin-top  : -47px;
// }

// /***********************
// *Pricing Seven
// ***********************/
// .pricing-seven {
//   position  : relative;
//   display   : block;
//   background: #f5f7ff;
//   padding   : 109px 0 120px;
// }

// .pricing-seven__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-seven__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-seven__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-seven ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-seven ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-seven ul.switch-toggler-list li.active a {
//   color: var(--customVar-secondary);
// }

// .pricing-seven .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-seven .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-seven .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-seven .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-seven .slider.round {
//   border-radius: 5px;
// }

// .pricing-seven .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-seven .tabed-content {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   border       : 1px solid #dde1fd;
//   border-radius: 20px;
// }

// .pricing-seven .tabed-content .row {
//   --bs-gutter-x: 0px;
// }

// .pricing-seven__single {
//   position: relative;
//   display : block;
//   padding : 47px 35px 35px;
// }

// .pricing-seven__single--two {
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .pricing-seven__top {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .pricing-seven__pack-name {
//   font-size    : 26px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 26px;
//   margin-bottom: 21px;
// }

// .pricing-seven__price {
//   font-size  : 50px;
//   font-weight: 800;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 50px;
// }

// .pricing-seven__price span {
//   position   : relative;
//   font-size  : 20px;
//   font-weight: 500;
//   left       : -10px;
// }

// .pricing-seven__save {
//   position      : relative;
//   display       : inline-block;
//   font-size     : 12px;
//   font-weight   : 500;
//   line-height   : 12px;
//   color         : var(--customVar-black);
//   margin        : 0;
//   border-radius : 13px;
//   padding       : 6px 15px;
//   background    : #ffdccc;
//   border        : 1px solid #ffb796;
//   text-transform: uppercase;
// }

// .pricing-seven__text {
//   margin       : 0;
//   margin-top   : 5px;
//   margin-bottom: 25px;
// }

// .pricing-seven__btn-box {
//   position: relative;
//   display : block;
// }

// .pricing-seven__btn {
//   width          : 100%;
//   color          : var(--customVar-white);
//   text-align     : center;
//   justify-content: center;
// }

// .pricing-seven__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-seven__points-box {
//   position  : relative;
//   display   : block;
//   margin-top: 40px;
// }

// .pricing-seven__points-title {
//   font-size    : 18px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   font-family  : var(--customVar-body-font);
//   margin-bottom: 20px;
// }

// .pricing-seven__points {
//   position     : relative;
//   display      : block;
//   margin       : 0;
//   margin-bottom: 37px;
// }

// .pricing-seven__points--six {
//   margin-bottom: 0;
// }

// .pricing-seven__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-seven__points li+li {
//   margin-top: 8px;
// }

// .pricing-seven__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-seven__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .pricing-seven__points li .text {
//   margin-left: 20px;
// }

// .pricing-seven__points li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-seven__points li .text p span {
//   color    : #aaaaaa;
//   font-size: 20px;
//   position : relative;
//   display  : inline-block;
// }

// /***********************
// * Data Center Three
// ***********************/
// .data-center-three {
//   position: relative;
//   display : block;
//   padding : 109px 0 120px;
// }

// .data-center-three__top {
//   position : relative;
//   display  : block;
//   max-width: 1100px;
//   width    : 100%;
//   margin   : 0 auto;
// }

// .data-center-three__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .data-center-three__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .data-center-three__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .data-center-three__map {
//   position: relative;
//   display : block;
// }

// .data-center-three__map img {
//   width: 100%;
// }

// .data-center-three__location-1 {
//   position: absolute;
//   width   : 105px;
//   height  : 45px;
//   top     : 32%;
//   left    : 9%;
// }

// .data-center-three__map-markar {
//   position        : absolute;
//   top             : -50px;
//   left            : 38px;
//   border-radius   : 50%;
//   background-color: rgba(25, 213, 100, 0.38);
//   z-index         : 5;
//   height          : 30px;
//   width           : 30px;
//   cursor          : pointer;
// }

// .data-center-three__map-markar:before {
//   position        : absolute;
//   top             : 5px;
//   bottom          : 5px;
//   right           : 5px;
//   left            : 5px;
//   background-color: #19cf62;
//   content         : "";
//   border-radius   : 50%;
// }

// .data-center-three__popup-box {
//   position                  : absolute;
//   top                       : 0px;
//   left                      : 0px;
//   width                     : 100%;
//   z-index                   : 5;
//   opacity                   : 1;
//   transform                 : perspective(400px) rotateX(90deg) translateY(10px);
//   transform-origin          : bottom;
//   transition-delay          : 0.1s;
//   transition-timing-function: ease-in-out;
//   transition-duration       : 0.5s;
//   transition-property       : all;
// }

// .data-center-three__location-1:hover .data-center-three__popup-box {
//   opacity                   : 1;
//   transform                 : perspective(400px) rotateX(0deg) translateY(0);
//   transition-delay          : 0.3s;
//   transition-timing-function: ease-in-out;
//   transition-duration       : 0.5s;
//   transition-property       : all;
// }

// .data-center-three__popup {
//   position: relative;
//   display : block;
//   width   : 105px;
//   width   : 100%;
// }

// .data-center-three__popup-inner {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   text-align      : center;
//   padding         : 14px 0 7px;
//   box-shadow      : 0px 4px 40px rgba(0, 0, 0, 0.15);
//   border-radius   : 2px;
// }

// .data-center-three__popup-inner:before {
//   content      : "";
//   position     : absolute;
//   top          : -14px;
//   left         : 50%;
//   transform    : translateX(-50%);
//   border-bottom: 14px solid var(--customVar-white);
//   border-right : 7px solid transparent;
//   border-left  : 7px solid transparent;
// }

// .data-center-three__popup-title {
//   font-size  : 16px;
//   font-weight: 500;
//   color      : var(--customVar-black);
//   line-height: 16px;
// }

// .data-center-three__location-2 {
//   left: 22%;
//   top : 38%;
// }

// .data-center-three__location-3 {
//   left  : 24%;
//   top   : auto;
//   bottom: 23%;
// }

// .data-center-three__location-4 {
//   left  : 39%;
//   top   : auto;
//   bottom: 28%;
// }

// .data-center-three__location-5 {
//   left: 50%;
//   top : 40%;
// }

// .data-center-three__location-6 {
//   left : auto;
//   right: 17.5%;
//   top  : 31%;
// }

// .data-center-three__location-7 {
//   left  : auto;
//   top   : auto;
//   right : 28%;
//   bottom: 33%;
// }

// .data-center-three__location-8 {
//   left  : auto;
//   top   : auto;
//   right : 12%;
//   bottom: 7%;
// }

// /***********************
// * Faster One
// ***********************/
// .faster-one {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 107px 0 114px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .faster-one__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .faster-one__left {
//   position: relative;
//   display : block;
// }

// .faster-one__img {
//   position: relative;
//   display : block;
// }

// .faster-one__img img {
//   width: 100%;
// }

// .faster-one__right {
//   position   : relative;
//   display    : block;
//   margin-left: 65px;
// }

// .faster-one__top-title {
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 30px;
// }

// .faster-one__list {
//   position     : relative;
//   display      : block;
//   margin       : 0;
//   margin-bottom: 40px;
// }

// .faster-one__list li {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   background   : #263d97;
//   box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   padding      : 27px 30px 23px;
// }

// .faster-one__list li+li {
//   margin-top: 20px;
// }

// .faster-one__list li .icon {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   height          : 50px;
//   width           : 50px;
//   background-color: var(--customVar-white);
//   border-radius   : 50%;
//   font-size       : 15px;
//   color           : var(--customVar-secondary);
// }

// .faster-one__list li .content {
//   position   : relative;
//   display    : block;
//   max-width  : 445px;
//   width      : 100%;
//   margin-left: 25px;
// }

// .faster-one__list li .content h3 {
//   font-weight   : 600;
//   font-size     : 22px;
//   line-height   : 22px;
//   letter-spacing: -0.015em;
//   color         : #ffffff;
//   margin        : 0;
//   margin-bottom : 15px;
// }

// .faster-one__list li .content p {
//   letter-spacing: -0.015em;
//   color         : #ffffff;
//   margin        : 0;
//   line-height   : 26px;
// }

// .faster-one__btn {
//   color     : var(--customVar-white);
//   box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .faster-one__btn:hover {
//   color: var(--customVar-black);
// }

// .faster-one__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .faster-one__btn::after {
//   background-color: var(--customVar-white);
// }

// /***********************
// * Feature Six
// ***********************/
// .feature-six {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 70px;
// }

// .feature-six .container {
//   max-width: 1066px;
// }

// .feature-six .row {
//   --bs-gutter-x: 55px;
// }

// .feature-six__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-six__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .feature-six__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .feature-six__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-six__icon {
//   position: relative;
//   display : block;
// }

// .feature-six__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 32px;
//   margin-top   : 17px;
//   margin-bottom: 10px;
// }

// .feature-six__text {
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin        : 0;
// }

// /***********************
// * Get Started
// ***********************/
// .get-started {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 100px 0 75px;
//   z-index   : 1;
// }

// .get-started__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .get-started__left {
//   position   : relative;
//   display    : block;
//   margin-left: 165px;
// }

// .get-started__img {
//   position: relative;
//   display : block;
// }

// .get-started__img img {
//   width: 100%;
// }

// .get-started__right {
//   position    : relative;
//   display     : block;
//   margin-left : 75px;
//   margin-right: 165px;
//   margin-top  : 74px;
// }

// .get-started__title {
//   font-size  : 40px;
//   color      : var(--customVar-white);
//   font-weight: 700;
//   margin     : 0;
//   line-height: 50px;
// }

// .get-started__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   color         : var(--customVar-white);
//   margin-top    : 23px;
//   margin-bottom : 22px;
// }

// .get-started__btn {
//   color     : var(--customVar-white);
//   box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .get-started__btn:hover {
//   color: var(--customVar-black);
// }

// .get-started__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .get-started__btn::after {
//   background-color: var(--customVar-white);
// }

// /***********************
// * Game Hosting Page Banner
// ***********************/
// .game-hosting-page-banner {
//   padding-bottom: 65px;
// }

// .game-hosting-page-banner .page-banner-three__right {
//   margin-left: 0;
//   margin-top : -35px;
// }

// /***********************
// * Feature Seven
// ***********************/
// .feature-seven {
//   position        : relative;
//   display         : block;
//   padding         : 109px 0 70px;
//   background-color: #fafbff;
// }

// .feature-seven__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-seven__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .feature-seven__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .feature-seven__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-seven__icon {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   height            : 70px;
//   width             : 70px;
//   background        : #f0f5ff;
//   border-radius     : 20px;
//   color             : var(--customVar-secondary);
//   font-size         : 17px;
//   margin-bottom     : 27px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-seven__single:hover .feature-seven__icon {
//   background-color: var(--customVar-black);
//   color           : var(--customVar-white);
// }

// .feature-seven__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 22px;
//   margin-bottom: 15px;
// }

// .feature-seven__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// /***********************
// * Popular Games
// ***********************/
// .popular-games {
//   position  : relative;
//   display   : block;
//   background: #f5f7ff;
//   padding   : 109px 0 120px;
//   z-index   : 1;
// }

// .popular-games__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .popular-games__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .popular-games__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .popular-games__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   margin-bottom: 30px;
// }

// .popular-games__img {
//   position     : relative;
//   display      : block;
//   border-radius: 20px;
//   overflow     : hidden;
//   z-index      : 1;
// }

// .popular-games__img:before {
//   content           : "";
//   position          : absolute;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   background-color  : rgba(var(--customVar-black-rgb), .70);
//   box-shadow        : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius     : 20px;
//   -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
//   visibility        : hidden;
//   opacity           : 0;
//   -webkit-transform : translateY(-70%);
//   transform         : translateY(-70%);
//   z-index           : 1;
// }

// .popular-games__single:hover .popular-games__img:before {
//   visibility       : visible;
//   -webkit-transform: translateY(0%);
//   transform        : translateY(0%);
//   opacity          : 1;
// }

// .popular-games__img img {
//   width        : 100%;
//   border-radius: 20px;
// }

// .popular-games__btn-box {
//   position          : absolute;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
//   transition        : opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
//   visibility        : hidden;
//   opacity           : 0;
//   -webkit-transform : translateY(70%);
//   transform         : translateY(70%);
//   z-index           : 2;
// }

// .popular-games__single:hover .popular-games__btn-box {
//   visibility       : visible;
//   -webkit-transform: translateY(0%);
//   transform        : translateY(0%);
//   opacity          : 1;
// }

// .popular-games__btn {
//   color: var(--customVar-white);
// }

// .popular-games__btn:hover {
//   color: var(--customVar-black);
// }

// .popular-games__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .popular-games__btn::after {
//   background-color: var(--customVar-white);
// }

// .popular-games__content {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   padding        : 32px 30px 32px;
// }

// .popular-games__title {
//   font-size  : 22px;
//   font-weight: 600;
//   color      : var(--customVar-black);
//   margin     : 0;
//   line-height: 22px;
// }

// .popular-games__price {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 22px;
//   font-weight: 600;
//   color      : var(--customVar-text);
//   margin     : 0;
//   line-height: 22px;
//   font-family: var(--customVar-title-font);
// }

// .popular-games__price span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 16px;
//   margin-right: 10px;
// }

// .popular-games__price span:before {
//   content         : "";
//   position        : absolute;
//   bottom          : 9px;
//   left            : -4px;
//   right           : -4px;
//   height          : 3px;
//   background-color: #ff6b71;
//   transform       : rotate(-9deg);
// }

// .popular-games__btn-two-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   margin-top: 20px;
// }

// .popular-games__btn-two {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .popular-games__btn-two::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /***********************
// * Why Choose Four
// ***********************/
// .why-choose-four {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 120px;
// }

// .why-choose-four__left {
//   position    : relative;
//   display     : block;
//   margin-right: 65px;
// }

// .why-choose-four__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : #121212;
//   margin     : 0;
// }

// .why-choose-four__text {
//   margin       : 0;
//   line-height  : 32px;
//   font-size    : 18px;
//   margin-top   : 21px;
//   margin-bottom: 25px;
// }

// .why-choose-four__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .why-choose-four__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .why-choose-four__right {
//   position   : relative;
//   display    : block;
//   margin-left: 65px;
// }

// .why-choose-four__points-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .why-choose-four__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .why-choose-four__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .why-choose-four__points li+li {
//   margin-top: 14px;
// }

// .why-choose-four__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .why-choose-four__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .why-choose-four__points li .text {
//   margin-left: 20px;
// }

// .why-choose-four__points li .text p {
//   font-size: 18px;
//   margin   : 0;
// }

// .why-choose-four__points--two {
//   margin-left: 85px;
// }

// /***********************
// * Testimonial Four
// ***********************/
// .testimonial-four {
//   position: relative;
//   display : block;
//   padding : 109px 0 120px;
//   z-index : 1;
// }

// .testimonial-four__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .testimonial-four__inner {
//   position: relative;
//   display : block;
// }

// .testimonial-four__title {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   text-align   : center;
//   color        : #121212;
//   margin-bottom: 30px;
// }

// .testimonial-four__carousel {
//   position: relative;
//   display : block;
// }

// .testimonial-four__carousel.owl-carousel .owl-stage-outer {
//   overflow: visible;
// }

// .testimonial-four__carousel.owl-carousel .owl-item {
//   opacity   : 0;
//   visibility: hidden;
//   transition: opacity 500ms ease, visibility 500ms ease;
// }

// .testimonial-four__carousel.owl-carousel .owl-item.active {
//   opacity   : 1;
//   visibility: visible;
// }

// .testimonial-four__carousel.owl-theme .owl-nav {
//   position       : absolute;
//   top            : 42%;
//   left           : 0;
//   right          : 0;
//   transform      : translateY(-50%);
//   margin         : 0;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   z-index        : 100;
//   height         : 0;
//   line-height    : 0;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next {
//   height            : 60px;
//   width             : 60px;
//   line-height       : 60px;
//   color             : #d4d7e5;
//   border-radius     : 50%;
//   background-color  : transparent;
//   font-size         : 14px;
//   margin            : 0;
//   text-align        : center;
//   border            : 2px solid #d4d7e5;
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   position          : relative;
//   display           : inline-block;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next:before,
// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev:before {
//   position        : absolute;
//   top             : 0;
//   left            : 0;
//   right           : 0;
//   bottom          : 0;
//   content         : "";
//   background      : linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
//   border-radius   : 10px;
//   transform       : scale(0);
//   transform-origin: center;
//   transform-style : preserve-3d;
//   transition      : all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
//   z-index         : -1;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev {
//   height            : 60px;
//   width             : 60px;
//   line-height       : 60px;
//   color             : #d4d7e5;
//   border-radius     : 50%;
//   background-color  : transparent;
//   font-size         : 14px;
//   margin            : 0;
//   text-align        : center;
//   border            : 2px solid #d4d7e5;
//   border-radius     : 10px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
//   position          : relative;
//   display           : inline-block;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next {
//   margin-right: -110px;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev {
//   margin-left: -110px;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next span,
// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev span {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev span {
//   transform: rotate(180deg);
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next:hover,
// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev:hover {
//   border: 2px solid transparent;
//   color : var(--customVar-white);
// }

// .testimonial-four__carousel.owl-theme .owl-nav .owl-next:hover:before,
// .testimonial-four__carousel.owl-theme .owl-nav .owl-prev:hover:before {
//   transform: scaleX(1);
// }

// /***********************
// * Game Server
// ***********************/
// .game-server {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 85px 0 84px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .game-server__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .game-server__left {
//   position    : relative;
//   display     : block;
//   margin-right: 54px;
// }

// .game-server__img {
//   position: relative;
//   display : block;
// }

// .game-server__img img {
//   width: 100%;
// }

// .game-server__right {
//   position   : relative;
//   display    : block;
//   margin-top : 89px;
//   margin-left: 65px;
// }

// .game-server__title {
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : #ffffff;
//   margin     : 0;
// }

// .game-server__text {
//   font-size    : 18px;
//   line-height  : 32px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-top   : 22px;
//   margin-bottom: 40px;
// }

// .game-server__points {
//   position: relative;
//   display : block;
// }

// .game-server__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .game-server__points li+li {
//   margin-top: 34px;
// }

// .game-server__points li .icon {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   height            : 40px;
//   width             : 40px;
//   background        : #f0f5ff;
//   border-radius     : 50%;
//   font-size         : 13px;
//   color             : var(--customVar-secondary);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .game-server__points li:hover .icon {
//   background-color: var(--customVar-black);
//   color           : var(--customVar-white);
// }

// .game-server__points li .text {
//   margin-left: 25px;
// }

// .game-server__points li .text h4 {
//   font-weight   : 600;
//   font-size     : 22px;
//   line-height   : 22px;
//   letter-spacing: -0.015em;
//   color         : #ffffff;
//   margin        : 0;
// }

// /***********************
// * Get Access
// ***********************/
// .get-access {
//   position        : relative;
//   display         : block;
//   padding         : 204px 0 270px;
//   overflow        : hidden;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .get-access__img {
//   position: absolute;
//   top     : -102px;
//   right   : -15px;
//   z-index : -1;
// }

// .get-access__img img {
//   width: auto;
// }

// .get-access__left {
//   position    : relative;
//   display     : block;
//   margin-right: 96px;
// }

// .get-access__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   margin     : 0;
//   color      : #121212;
// }

// .get-access__text {
//   font-size    : 18px;
//   line-height  : 32px;
//   margin       : 0;
//   margin-top   : 21px;
//   margin-bottom: 24px;
// }

// .get-access__btn-box {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .get-access__btn-box-content {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
// }

// .get-access__btn-box-content--two {
//   margin-left: 20px;
// }

// .get-access__btn-icon {
//   position     : relative;
//   display      : block;
//   width        : 120px;
//   border-radius: 50%;
//   z-index      : 2;
// }

// .get-access__btn-icon img {
//   width        : 100%;
//   border-radius: 50%;
// }

// .get-access__btn {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-size         : 18px;
//   font-weight       : 600;
//   font-family       : var(--customVar-title-font);
//   color             : var(--customVar-white);
//   background-color  : var(--customVar-black);
//   border-radius     : 10px;
//   padding           : 14px 35px 14px;
//   margin-left       : -32px;
//   margin-top        : 3px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .get-access__btn span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 13px;
//   padding-left: 10px;
// }

// .get-access__btn:hover {
//   background-color: var(--customVar-secondary);
// }

// /***********************
// * guides One
// ***********************/
// .guides-one {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
// }

// .guides-one__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .guides-one__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .guides-one__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .guides-one__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
//   border-radius: 20px;
//   margin-bottom: 30px;
// }

// .guides-one__img {
//   position               : relative;
//   display                : block;
//   border-top-right-radius: 20px;
//   border-top-left-radius : 20px;
//   overflow               : hidden;
//   z-index                : 1;
// }

// .guides-one__img img {
//   width                  : 100%;
//   border-top-right-radius: 20px;
//   border-top-left-radius : 20px;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .guides-one__single:hover .guides-one__img img {
//   transform: scale(1.05);
// }

// .guides-one__content {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   padding   : 27px 37px 40px;
// }

// .guides-one__title {
//   font-weight: 500;
//   font-size  : 22px;
//   line-height: 22px;
//   margin     : 0;
// }

// .guides-one__title a {
//   color             : var(--customVar-black);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .guides-one__title a:hover {
//   color: var(--customVar-secondary);
// }

// .guides-one__text {
//   line-height   : 26px;
//   margin        : 0;
//   letter-spacing: -0.015em;
//   margin-top    : 13px;
//   margin-bottom : 27px;
// }

// .guides-one__read-more {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   font-weight       : 600;
//   font-size         : 18px;
//   line-height       : 18px;
//   text-align        : center;
//   letter-spacing    : 0.01em;
//   color             : #3852ff;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .guides-one__read-more span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 13px;
//   padding-left: 10px;
// }

// .guides-one__read-more:hover {
//   color: var(--customVar-black);
// }

// .game-hosting-faq-page {
//   padding-top: 80px;
// }

// /***********************
// * Page Banner Four
// ***********************/
// .page-banner-four {
//   background-image   : url(../images/template/background/page-header-1-3-bg.png);
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center center;
//   padding-top        : 160px;
//   padding-bottom     : 83px;
// }

// .page-banner-four__left {
//   position  : relative;
//   display   : block;
//   margin-top: 45px;
// }

// .page-banner-four__title {
//   font-weight  : 700;
//   font-size    : 60px;
//   line-height  : 76px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 10px;
// }

// .page-banner-four__text {
//   font-size    : 18px;
//   line-height  : 32px;
//   color        : #ffffff;
//   margin       : 0;
//   margin-bottom: 30px;
// }

// .page-banner-four__form {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   max-width  : 590px;
//   width      : 100%;
//   z-index    : 2;
// }

// .page-banner-four__input-box {
//   position : relative;
//   display  : block;
//   max-width: 590px;
//   width    : 100%;
// }

// .page-banner-four__input-box input[type="text"] {
//   outline           : none;
//   -webkit-appearance: none;
//   -moz-appearance   : none;
//   appearance        : none;
//   background        : rgba(255, 255, 255, 1);
//   border            : 1.5px solid #e9e9e9;
//   border-radius     : 10px;
//   display           : block;
//   width             : 100%;
//   height            : 60px;
//   color             : var(--customVar-text);
//   font-size         : 16px;
//   font-weight       : 400;
//   padding-left      : 25px;
//   padding-right     : 176px;
// }

// .page-banner-four__btn {
//   padding        : 0;
//   color          : var(--customVar-white);
//   margin-left    : 0;
//   position       : absolute;
//   top            : 0;
//   bottom         : 0;
//   right          : 0;
//   height         : 60px;
//   width          : 60px;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .page-banner-four__btn:before {
//   background-color: var(--customVar-secondary);
// }

// .page-banner-four__btn span {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .page-banner-four__btn i {
//   font-size         : 20px;
//   color             : inherit;
//   margin-left       : 0;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .page-banner-four__name {
//   display          : -webkit-inline-box;
//   display          : -ms-inline-flexbox;
//   display          : inline-flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   position         : absolute;
//   top              : 50%;
//   right            : 90px;
//   transform        : translateY(-50%);
//   border-left      : 1.5px solid #e9e9e9;
//   padding-left     : 20px;
//   height           : 30px;
// }

// .page-banner-four__name .bootstrap-select>.dropdown-toggle {
//   font-size  : 16px;
//   font-family: var(--customVar-body-font);
//   font-weight: 400;
//   color      : var(--customVar-black);
// }

// .page-banner-four__name .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 17px;
//   position   : relative;
//   top        : 2px;
// }

// .page-banner-four__bottom {
//   position      : relative;
//   display       : block;
//   padding-top   : 30px;
//   padding-bottom: 27px;
// }

// .page-banner-four__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   flex-wrap  : wrap;
//   margin     : 0;
// }

// .page-banner-four__list li {
//   position : relative;
//   flex     : 0 0 20%;
//   max-width: 20%;
//   width    : 100%;
// }

// .page-banner-four__list li+li {
//   margin-left: 2px;
// }

// .page-banner-four__type {
//   font-size     : 20px;
//   letter-spacing: -0.015em;
//   color         : #0ea5ff;
//   font-weight   : 700;
//   line-height   : 20px;
//   margin        : 0;
// }

// .page-banner-four__price {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-white);
// }

// .page-banner-four__list li:nth-child(2) .page-banner-four__type {
//   color: #00c14d;
// }

// .page-banner-four__list li:nth-child(3) .page-banner-four__type {
//   color: #ff5a74;
// }

// .page-banner-four__list li:nth-child(4) .page-banner-four__type {
//   color: #ffa800;
// }

// .page-banner-four__bottom-text {
//   font-size  : 18px;
//   line-height: 32px;
//   color      : #ffffff;
//   margin     : 0;
// }

// .page-banner-four__bottom-text a {
//   position   : relative;
//   display    : inline-block;
//   color      : #ffffff;
//   font-weight: 700;
// }

// .page-banner-four__bottom-text a:before {
//   content         : "";
//   position        : absolute;
//   bottom          : 6px;
//   left            : 0;
//   right           : 0;
//   height          : 1px;
//   background-color: var(--customVar-white);
// }

// .page-banner-four__right {
//   position   : relative;
//   display    : block;
//   margin-left: 121px;
// }

// .page-banner-four__img {
//   position: relative;
//   display : block;
// }

// .page-banner-four__img img {
//   width: 100%;
// }

// /***********************
// * Buy Domain
// ***********************/
// .buy-domain {
//   position  : relative;
//   display   : block;
//   background: #f5f7ff;
//   padding   : 109px 0 90px;
// }

// .buy-domain__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .buy-domain__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .buy-domain__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .buy-domain__single {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   border-radius: 20px;
//   padding      : 30px 30px 30px;
//   margin-bottom: 30px;
// }

// .buy-domain__tag {
//   position       : absolute;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   background     : #000d61;
//   border-radius  : 0px 20px 0px 15px;
//   max-width      : 140px;
//   width          : 100%;
//   top            : 0;
//   right          : 0;
// }

// .buy-domain__tag-icon {
//   position: relative;
//   display : block;
//   top     : -2px;
// }

// .buy-domain__tag-icon img {
//   width: auto;
// }

// .buy-domain__tag-text {
//   font-size     : 11px;
//   margin        : 0;
//   line-height   : 11px;
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   text-transform: uppercase;
//   margin-left   : 5px;
// }

// .buy-domain__title {
//   font-weight  : 700;
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #ff8383;
//   margin       : 0;
//   margin-bottom: 8px;
// }

// .buy-domain__text {
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin        : 0;
//   margin-bottom : 30px;
// }

// .buy-domain__price-box {
//   font-weight: 800;
//   font-size  : 50px;
//   line-height: 60px;
//   color      : #121212;
//   margin     : 0;
// }

// .buy-domain__price-point {
//   font-size: 20px;
//   position : relative;
//   left     : -10px;
// }

// .buy-domain__price-validity {
//   font-size  : 20px;
//   font-weight: 500;
//   position   : relative;
//   left       : -19px;
// }

// .buy-domain__save {
//   color        : #2959eb;
//   font-size    : 16px;
//   line-height  : 16px;
//   margin       : 0;
//   font-weight  : 400;
//   position     : relative;
//   display      : block;
//   margin-top   : -4px;
//   margin-bottom: 32px;
// }

// .buy-domain__btn {
//   width          : 100%;
//   color          : var(--customVar-white);
//   text-align     : center;
//   justify-content: center;
// }

// .buy-domain__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .buy-domain__title--online {
//   color: #f7c64b;
// }

// .buy-domain__title--store {
//   color: #93db7a;
// }

// .buy-domain__title--info {
//   color: #63ead2;
// }

// .buy-domain__title--co {
//   color: #59afff;
// }

// .buy-domain__title--org {
//   color: #ff9fe4;
// }

// .buy-domain__title--net {
//   color: #b28dff;
// }

// .buy-domain__title--live {
//   color: #ffb571;
// }

// .buy-domain__title--tech {
//   color: #86a8ff;
// }

// .buy-domain__title--social {
//   color: #f0e940;
// }

// .buy-domain__title--shop {
//   color: #e09fff;
// }

// .buy-domain__title--site {
//   color: #87ffb7;
// }

// /***********************
// * Benefits One
// ***********************/
// .benefits-one {
//   position        : relative;
//   display         : block;
//   padding         : 109px 0 70px;
//   background-color: #fafbff;
// }

// .benefits-one-top__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   text-align   : center;
//   margin       : 0;
//   margin-bottom: 42px;
// }

// .benefits-one__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .benefits-one__icon {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   justify-content   : center;
//   height            : 70px;
//   width             : 70px;
//   background        : #f0f5ff;
//   border-radius     : 20px;
//   color             : var(--customVar-secondary);
//   font-size         : 17px;
//   margin-bottom     : 27px;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .benefits-one__single:hover .benefits-one__icon {
//   background-color: var(--customVar-black);
//   color           : var(--customVar-white);
// }

// .benefits-one__title {
//   font-size    : 22px;
//   font-weight  : 600;
//   color        : var(--customVar-black);
//   margin       : 0;
//   line-height  : 22px;
//   margin-bottom: 15px;
// }

// .benefits-one__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// /***********************
// * Feature Nine
// ***********************/
// .feature-nine {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 70px;
// }

// .feature-nine__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 60px;
// }

// .feature-nine__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .feature-nine__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .feature-nine__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 50px;
// }

// .feature-nine__img-box {
//   position    : relative;
//   display     : block;
//   margin-left : 170px;
//   margin-right: 76px;
// }

// .feature-nine__img-box img {
//   width: 100%;
// }

// .feature-nine__content-box {
//   position    : relative;
//   display     : block;
//   margin-top  : 37px;
//   margin-right: 165px;
// }

// .feature-nine__title {
//   font-weight: 700;
//   font-size  : 26px;
//   line-height: 33px;
//   color      : #121212;
//   margin     : 0;
// }

// .feature-nine__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin-top    : 16px;
//   margin-bottom : 16px;
// }

// .feature-nine__text-2 {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
//   margin-bottom : 26px;
// }

// .feature-nine__read-more {
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-weight       : 600;
//   font-size         : 18px;
//   line-height       : 18px;
//   text-align        : center;
//   letter-spacing    : 0.01em;
//   color             : #3852ff;
//   font-family       : var(--customVar-title-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .feature-nine__read-more span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 13px;
//   padding-left: 10px;
//   top         : 2px;
// }

// .feature-nine__read-more:hover {
//   color: var(--customVar-black);
// }

// .feature-nine__single--two .feature-nine__content-box {
//   margin-top  : 52px;
//   margin-right: 0;
//   margin-left : 173px;
// }

// .feature-nine__single--two .feature-nine__img-box {
//   margin-left : 105px;
//   margin-right: 200px;
// }

// .feature-nine__single--three .feature-nine__img-box {
//   margin-left : 209px;
//   margin-right: 120px;
// }

// .feature-nine__single--three .feature-nine__content-box {
//   margin-top: 53px;
// }

// /***********************
// * Buy Domain Two
// ***********************/
// .buy-domain-two {
//   position: relative;
//   display : block;
//   padding : 104px 0 105px;
//   z-index : 1;
// }

// .buy-domain-two__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-position: center;
//   background-size    : cover;
//   z-index            : -1;
// }

// .buy-domain-two__left {
//   position: relative;
//   display : block;
// }

// .buy-domain-two__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : #121212;
//   margin     : 0;
// }

// .buy-domain-two__text {
//   font-size    : 18px;
//   line-height  : 32px;
//   margin       : 0;
//   margin-top   : 21px;
//   margin-bottom: 30px;
// }

// .buy-domain-two__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .buy-domain-two__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .buy-domain-two__right {
//   position   : relative;
//   display    : block;
//   margin-left: 50px;
// }

// .buy-domain-two__points {
//   position  : relative;
//   display   : block;
//   margin-top: 5px;
// }

// .buy-domain-two__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .buy-domain-two__points li+li {
//   margin-top: 14px;
// }

// .buy-domain-two__points li .icon {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .buy-domain-two__points li .icon i {
//   font-size: 22px;
//   color    : #00c14d;
// }

// .buy-domain-two__points li .text {
//   margin-left: 20px;
// }

// .buy-domain-two__points li .text p {
//   font-size: 18px;
//   color    : var(--customVar-black);
//   margin   : 0;
// }

// /***********************
// * About One
// ***********************/
// .about-one {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 0;
// }

// .about-one__left {
//   position    : relative;
//   display     : block;
//   margin-right: 89px;
// }

// .about-one__title {
//   font-weight  : 700;
//   font-size    : 38px;
//   line-height  : 48px;
//   color        : #121212;
//   margin       : 0;
//   margin-bottom: 30px;
// }

// .about-one__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .about-one__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .about-one__right {
//   position   : relative;
//   display    : block;
//   margin-left: 55px;
// }

// .about-one__text-1 {
//   font-size    : 18px;
//   line-height  : 32px;
//   margin       : 0;
//   margin-bottom: 20px;
// }

// .about-one__text-2 {
//   font-size  : 18px;
//   line-height: 32px;
//   margin     : 0;
// }

// /***********************
// * About Page Img Box
// ***********************/
// .about-page-img-box {
//   position: relative;
//   display : block;
// }

// .about-page-img-box__img {
//   position: relative;
//   display : block;
// }

// .about-page-img-box__img img {
//   width: 100%;
// }

// /***********************
// * Services Page Why Choose
// ***********************/
// .services-page-why-choose {
//   margin-top      : 0;
//   padding         : 0px 0 110px;
//   background-color: #fafbff;
// }

// .services-page-why-choose .why-choose-two__bg {
//   display: none;
// }

// .services-page-why-choose .why-choose-two__top-title {
//   color: var(--customVar-black);
// }

// /***********************
// * comparision Page
// ***********************/
// .comparision-page {
//   padding-top: 109px;
// }

// /*************************************************************
// * Client Logo
// **************************************************************/
// .web-hosting-page-banner {
//   padding-bottom: 52px;
// }

// .web-hosting-page-banner .page-banner-three__right {
//   margin-left: 40px;
//   margin-top : -7px;
// }

// /*********************************************
// * Pricing Five
// **********************************************/
// .pricing-five {
//   position        : relative;
//   display         : block;
//   background-color: #f4f7fe;
//   padding         : 108px 0 90px;
//   z-index         : 1;
// }

// .pricing-five__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-five__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-five__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-five ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-five ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-five ul.switch-toggler-list li.active a {
//   color: var(--customVar-black);
// }

// .pricing-five .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-five .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-five .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-five .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-five .slider.round {
//   border-radius: 5px;
// }

// .pricing-five .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-five__single {
//   position        : relative;
//   display         : block;
//   background-color: var(--customVar-white);
//   border-radius   : 20px;
//   text-align      : center;
//   padding         : 35px 30px 30px;
//   overflow        : hidden;
// }

// .pricing-five__save-month {
//   position          : absolute;
//   top               : 0;
//   right             : 0;
//   opacity           : 0;
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .pricing-five__single:hover .pricing-five__save-month {
//   opacity: 1;
// }

// .pricing-five__save-month p {
//   font-weight              : 600;
//   font-size                : 11px;
//   line-height              : 21px;
//   color                    : var(--customVar-white);
//   background-color         : #000d61;
//   text-transform           : uppercase;
//   margin                   : 0;
//   padding                  : 6px 15px 5px;
//   border-bottom-left-radius: 15px;
// }

// .pricing-five__img {
//   position: relative;
//   display : block;
// }

// .pricing-five__img img {
//   width: auto;
// }

// .pricing-five__title {
//   margin     : 9px 0 2px;
//   font-weight: 600;
//   font-size  : 26px;
//   line-height: 33px;
//   color      : #121212;
// }

// .pricing-five__text {
//   margin: 0;
// }

// .pricing-five__price-box {
//   position     : relative;
//   display      : block;
//   margin-top   : 23px;
//   margin-bottom: 26px;
// }

// .pricing-five__price-box p {
//   margin     : 0;
//   color      : #2959eb;
//   line-height: 18px;
// }

// .pricing-five__price {
//   margin     : 0;
//   font-weight: 800;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : #2959eb;
// }

// .pricing-five__price-validity {
//   font-weight: 500;
//   font-size  : 20px;
//   line-height: 25px;
//   color      : #2959eb;
// }

// .pricing-five__points-list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-five__points-list li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-five__points-list li+li {
//   margin-top: 8px;
// }

// .pricing-five__points-list li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 2px;
// }

// .pricing-five__points-list li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 20px;
//   color    : #00c14d;
// }

// .pricing-five__points-list li .text {
//   margin-left: 20px;
// }

// .pricing-five__points-list li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-five__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 32px;
// }

// .pricing-five__btn {
//   color   : var(--customVar-white);
//   position: relative;
//   display : block;
// }

// .pricing-five__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-five__btn::after {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /*************************************************************
// * Get Faster
// **************************************************************/
// .get-faster {
//   position        : relative;
//   display         : block;
//   padding         : 106px 0 159px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .get-faster__left {
//   position    : relative;
//   display     : block;
//   margin-right: 54px;
// }

// .get-faster__img {
//   position: relative;
//   display : block;
// }

// .get-faster__img img {
//   width: 100%;
// }

// .get-faster__right {
//   position   : relative;
//   display    : block;
//   margin-left: -25px;
//   margin-top : 13px;
// }

// .get-faster__title {
//   margin     : 0 0 32px;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : #121212;
// }

// .get-faster__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .get-faster__points li {
//   position: relative;
//   display : flex;
// }

// .get-faster__points li+li {
//   margin-top: 21px;
// }

// .get-faster__points li .icon {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   max-width       : 50px;
//   width           : 100%;
//   height          : 50px;
//   background-color: #f1f5ff;
//   border-radius   : 50%;
//   top             : 7px;
// }

// .get-faster__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 16px;
//   color    : var(--customVar-secondary);
// }

// .get-faster__points li .content {
//   position   : relative;
//   display    : block;
//   margin-left: 30px;
// }

// .get-faster__points li .content h3 {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : #121212;
// }

// .get-faster__points li .content p {
//   margin        : 5px 0 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// .get-faster__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 33px;
// }

// .get-faster__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .get-faster__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /***********************
// * faster-wp-hosting
// ***********************/
// .faster-wp-hosting {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 0px 0 110px;
// }

// .faster-wp-hosting__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .faster-wp-hosting__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .faster-wp-hosting__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 32px;
// }

// .faster-wp-hosting__table-responsive {
//   position  : relative;
//   display   : block;
//   width     : 100%;
//   overflow-x: auto;
// }

// .faster-wp-hosting__table {
//   position : relative;
//   margin   : 0;
//   min-width: 1320px;
//   width    : 100%;
// }

// .faster-wp-hosting__table thead tr {
//   position  : relative;
//   text-align: center;
//   background: #eef0ff;
//   border    : 1px solid #eef0ff;
// }

// .faster-wp-hosting__table thead th {
//   padding       : 0;
//   border        : none;
//   font-size     : 22px;
//   font-weight   : 600;
//   color         : var(--customVar-black);
//   font-family   : var(--customVar-title-font);
//   text-align    : center;
//   vertical-align: middle;
//   padding       : 24px 0;
// }

// .faster-wp-hosting__table tbody {
//   position        : relative;
//   background-color: transparent;
// }

// .faster-wp-hosting__table tbody tr {
//   position    : relative;
//   text-align  : center;
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .faster-wp-hosting__table tbody tr:last-child {
//   border-bottom: 1px solid #dde1fd;
// }

// .faster-wp-hosting__table tbody tr td {
//   font-size     : 18px;
//   font-weight   : 400;
//   border        : 0;
//   padding       : 24px 0;
//   vertical-align: middle;
//   color         : var(--customVar-text);
//   border-right  : 1px solid #dde1fd;
// }

// .faster-wp-hosting__table tbody tr td:first-child {
//   font-weight: 600;
//   color      : var(--customVar-black);
// }

// .faster-wp-hosting__table tbody tr td:last-child {
//   border-right: 0;
// }

// .faster-wp-hosting__bottom {
//   font-size  : 18px;
//   line-height: 32px;
//   text-align : center;
//   color      : #40a51c;
//   position   : relative;
//   display    : block;
//   margin     : 0;
//   margin-top : 31px;
// }

// .faster-wp-hosting__bottom a {
//   position          : relative;
//   display           : inline-block;
//   color             : #3852ff;
//   font-family       : var(--customVar-title-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faster-wp-hosting__bottom a:before {
//   content           : "";
//   position          : absolute;
//   bottom            : 7px;
//   left              : 0;
//   right             : 25px;
//   height            : 1px;
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .faster-wp-hosting__bottom a span {
//   position    : relative;
//   display     : inline-block;
//   font-size   : 12px;
//   padding-left: 3px;
// }

// .faster-wp-hosting__bottom a:hover {
//   color: var(--customVar-black);
// }

// .faster-wp-hosting__bottom a:hover:before {
//   background-color: var(--customVar-black);
// }

// /*************************************************************
// * Testimonial Three
// **************************************************************/
// .testimonial-three {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 120px;
//   z-index         : 1;
// }

// .testimonial-three .testimonial-one__title {
//   color: #121212;
// }

// .testimonial-three .testimonial-one__carousel.owl-theme .owl-nav .owl-prev {
//   border: 2px solid #d4d7e5;
//   color : #d4d7e5;
// }

// .testimonial-three .testimonial-one__carousel.owl-theme .owl-nav .owl-next {
//   border: 2px solid #d4d7e5;
//   color : #d4d7e5;
// }

// .testimonial-three .testimonial-one__carousel.owl-theme .owl-nav .owl-next:hover,
// .testimonial-three .testimonial-one__carousel.owl-theme .owl-nav .owl-prev:hover {
//   border: 2px solid transparent;
//   color : var(--customVar-white);
// }

// /*************************************************************
// * Feature Four
// **************************************************************/
// .feature-four {
//   position        : relative;
//   display         : block;
//   background-color: #f5f7ff;
//   padding         : 109px 0 90px;
//   z-index         : 1;
// }

// .feature-four__bg {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : 0;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .feature-four__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 43px;
// }

// .feature-four__section-title__title {
//   font-size    : 38px;
//   font-weight  : 700;
//   line-height  : 48px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 16px;
// }

// .feature-four__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 26px;
// }

// .feature-four__single {
//   background-color: #ffffff;
//   border-radius   : 20px;
//   padding         : 32px 40px 37px;
//   margin-bottom   : 30px;
// }

// .feature-four__icon {
//   position: relative;
//   display : inline-block;
// }

// .feature-four__icon img {
//   width                   : auto;
//   -webkit-transition      : all 500ms linear;
//   transition              : all 500ms linear;
//   -webkit-transition-delay: 0.1s;
//   transition-delay        : 0.1s;
// }

// .feature-four__single:hover .feature-four__icon img {
//   transform: scale(0.9);
// }

// .feature-four__title {
//   margin     : 24px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : #121212;
// }

// .feature-four__title span {
//   font-weight     : 600;
//   font-size       : 13px;
//   line-height     : 30px;
//   background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   color           : var(--customVar-white);
//   padding         : 4px 14px 5px;
//   border-radius   : 20px;
//   position        : relative;
//   top             : -4px;
//   margin-right    : 12px;
// }

// .feature-four__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /*************************************************************
// * Reseller Hosting Page Banner
// **************************************************************/
// .reseller-hosting-page-banner {
//   padding-bottom: 16px;
// }

// .reseller-hosting-page-banner .page-banner-three__right {
//   margin-left: 70px;
//   margin-top : -52px;
// }

// /*************************************************************
// * Pricing Eight
// **************************************************************/
// .pricing-eight {
//   position        : relative;
//   display         : block;
//   background-color: #f4f7fe;
//   padding         : 109px 0 120px;
//   z-index         : 1;
// }

// .pricing-eight__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-eight__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-eight__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-eight ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-eight ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-eight ul.switch-toggler-list li.active a {
//   color: var(--customVar-black);
// }

// .pricing-eight .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-eight .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-eight .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-eight .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-eight .slider.round {
//   border-radius: 5px;
// }

// .pricing-eight .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-eight .tabed-content {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   border          : 1px solid #dde1fd;
//   border-radius   : 20px;
// }

// .pricing-eight .tabed-content .row {
//   --bs-gutter-x: 0;
// }

// .pricing-eight__single {
//   position: relative;
//   display : block;
//   padding : 46px 40px 36px;
//   z-index : 1;
// }

// .pricing-eight__single-2 {
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .pricing-eight__title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 26px;
//   line-height: 33px;
//   text-align : center;
//   color      : var(--customVar-black);
// }

// .pricing-eight__price-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .pricing-eight__price {
//   font-weight: 800;
//   font-size  : 50px;
//   line-height: 60px;
//   text-align : center;
//   margin     : 13px 0 5px;
//   color      : var(--customVar-black);
// }

// .pricing-eight__price-validity {
//   font-weight: 500;
//   font-size  : 20px;
//   line-height: 25px;
// }

// .pricing-eight__price-box p {
//   font-weight     : 400;
//   font-size       : 14px;
//   line-height     : 14px;
//   text-align      : center;
//   margin          : 0;
//   background-color: #ebecfe;
//   position        : relative;
//   display         : inline-block;
//   color           : var(--customVar-black);
//   padding         : 5px 16px 6px;
//   border-radius   : 15px;
// }

// .pricing-eight__text {
//   margin     : 8px 0 30px;
//   line-height: 26px;
//   text-align : center;
// }

// .pricing-eight__btn-box {
//   position: relative;
//   display : block;
// }

// .pricing-eight__btn {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   color     : var(--customVar-white);
// }

// .pricing-eight__btn::before {
//   background-color: var(--customVar-secondary);
// }

// .pricing-eight__points-box {
//   position   : relative;
//   display    : block;
//   margin-left: 27px;
//   margin-top : 27px;
// }

// .pricing-eight__points-list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-eight__points-list li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-eight__points-list li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 3px;
// }

// .pricing-eight__points-list li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 21px;
//   color    : #00c14d;
// }

// .pricing-eight__points-list li .text {
//   position   : relative;
//   display    : block;
//   margin-left: 20px;
// }

// .pricing-eight__points-list li .text p {
//   margin        : 0;
//   font-weight   : 400;
//   font-size     : 16px;
//   line-height   : 40px;
//   letter-spacing: -0.015em;
//   color         : var(--customVar-text);
// }

// .pricing-eight__points-list li .text p>span {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 17px;
//   color      : #aaaaaa;
//   margin-left: 10px;
//   top        : 2px;
// }

// .pricing-eight__points-title {
//   font-weight: 600;
//   font-size  : 18px;
//   line-height: 22px;
//   color      : #121212;
//   font-family: var(--customVar-body-font);
//   margin     : 40px 0 17px;
// }

// /*************************************************************
// * Feature Five
// **************************************************************/
// .feature-five {
//   position        : relative;
//   display         : block;
//   padding         : 109px 0 56px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .feature-five__section-title {
//   position   : relative;
//   display    : block;
//   margin     : 0 0 40px;
//   text-align : center;
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : var(--customVar-black);
// }

// .feature-five__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 57px;
// }

// .feature-five__icon {
//   position: relative;
//   display : inline-block;
// }

// .feature-five__icon img {
//   width                   : auto;
//   -webkit-transition      : all 500ms linear;
//   transition              : all 500ms linear;
//   -webkit-transition-delay: 0.1s;
//   transition-delay        : 0.1s;
// }

// .feature-five__single:hover .feature-five__icon img {
//   transform: scale(0.9);
// }

// .feature-five__title {
//   margin     : 21px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
// }

// .feature-five__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /*************************************************************
// * Which Plan
// **************************************************************/
// .which-plan {
//   position   : relative;
//   display    : block;
//   padding-top: 70px;
// }

// .which-plan__left {
//   position    : relative;
//   display     : block;
//   margin-right: -15px;
//   margin-top  : 70px;
// }

// .which-plan__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : var(--customVar-black);
//   margin     : 0 0 35px;
// }

// .which-plan__points {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .which-plan__points li {
//   position: relative;
//   display : flex;
// }

// .which-plan__points li+li {
//   margin-top: 22px;
// }

// .which-plan__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 10px;
// }

// .which-plan__points li .icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 48px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .which-plan__points li .content {
//   position   : relative;
//   display    : block;
//   margin-left: 38px;
// }

// .which-plan__points li .content h3 {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
// }

// .which-plan__points li .content p {
//   margin        : 10px 0 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// .which-plan__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 33px;
// }

// .which-plan__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .which-plan__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .which-plan__right {
//   position   : relative;
//   display    : block;
//   margin-left: -11px;
// }

// .which-plan__img {
//   position: relative;
//   display : block;
//   z-index : 2;
// }

// .which-plan__img img {
//   width: 100%;
// }

// .which-plan__img-2 {
//   position: absolute;
//   top     : 70px;
//   left    : 90px;
//   z-index : -1;
// }

// .which-plan__img-2 img {
//   width: auto;
// }

// .which-plan__img-3 {
//   position: absolute;
//   top     : 156px;
//   right   : 0;
//   z-index : -1;
// }

// .which-plan__img-3 img {
//   width: auto;
// }

// /*************************************************************
// * Why Us Two
// **************************************************************/
// .why-us-two {
//   position: relative;
//   display : block;
//   padding : 108px 0 120px;
//   z-index : 1;
// }

// .why-us-two .container {
//   max-width: 710px;
// }

// .why-us-two__title {
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   text-align : center;
//   color      : var(--customVar-black);
//   margin     : 0;
// }

// .why-us-two__points-box {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   margin-top     : 39px;
//   margin-bottom  : 28px;
// }

// .why-us-two__points {
//   position: relative;
//   display : block;
// }

// .why-us-two__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .why-us-two__points li+li {
//   margin-top: 20px;
// }

// .why-us-two__points li .icon {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   width           : 50px;
//   height          : 50px;
//   border-radius   : 50%;
//   background-color: #f1f5ff;
// }

// .why-us-two__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 15px;
//   color    : var(--customVar-secondary);
// }

// .why-us-two__points li .text {
//   margin-left: 30px;
// }

// .why-us-two__points li .text p {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   font-family: var(--customVar-title-font);
//   color      : var(--customVar-black);
// }

// .why-us-two__btn-box {
//   position  : relative;
//   display   : block;
//   text-align: center;
// }

// .why-us-two__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .why-us-two__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /*************************************************************
// * Awarded Two
// **************************************************************/
// .awarded-two {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 110px 0 0;
// }

// .awarded-two__left {
//   position    : relative;
//   display     : block;
//   margin-right: 43px;
//   margin-top  : 37px;
// }

// .awarded-two__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : var(--customVar-black);
// }

// .awarded-two__text {
//   margin     : 21px 0 22px;
//   font-size  : 18px;
//   line-height: 32px;
// }

// .awarded-two__btn-box {
//   position: relative;
//   display : block;
// }

// .awarded-two__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .awarded-two__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .awarded-two__right {
//   position   : relative;
//   display    : block;
//   margin-left: 95px;
// }

// .awarded-two__list {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: space-between;
//   flex-wrap      : wrap;
//   margin         : 0;
// }

// .awarded-two__list li {
//   position     : relative;
//   display      : block;
//   margin-bottom: 40px;
// }

// .awarded-two__img {
//   position: relative;
//   display : block;
// }

// .awarded-two__img img {
//   width: auto;
// }

// /*************************************************************
// * Reseller Hosting Faq One
// **************************************************************/
// .reseller-hosting-faq-one {
//   padding-top: 64px;
// }

// /*************************************************************
// * comparision Three
// **************************************************************/
// .comparision-three {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 109px 0 120px;
//   overflow  : hidden;
//   z-index   : 1;
// }

// .comparision-three__shape-1 {
//   position: absolute;
//   top     : 0;
//   right   : 0;
//   z-index : -1;
// }

// .comparision-three__shape-1 img {
//   width: auto;
// }

// .comparision-three__shape-2 {
//   position: absolute;
//   bottom  : 0;
//   right   : 0;
//   z-index : -1;
// }

// .comparision-three__shape-2 img {
//   width: auto;
// }

// .comparision-three__shape-3 {
//   position: absolute;
//   bottom  : 0;
//   left    : 0;
//   z-index : -1;
// }

// .comparision-three__shape-3 img {
//   width: auto;
// }

// .comparision-three__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 40px;
// }

// .comparision-three__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-white);
//   margin       : 0;
//   margin-bottom: 9px;
// }

// .comparision-three__section-title__text {
//   font-size  : 18px;
//   color      : var(--customVar-white);
//   margin     : 0;
//   line-height: 32px;
// }

// .comparision-three__inner {
//   position     : relative;
//   display      : block;
//   background   : #ffffff;
//   border-radius: 20px;
//   padding      : 120px 110px 100px;
//   padding-left : 80px;
// }

// .comparision-three__progress-box {
//   position: relative;
//   display : block;
//   z-index : 1;
// }

// .comparision-three__progress-box:before {
//   content : "";
//   position: absolute;
//   top     : -50px;
//   bottom  : -50px;
//   left    : 217px;
//   border  : 1px dashed #cccfe9;
//   z-index : -1;
// }

// .comparision-three__progress-box:after {
//   content : "";
//   position: absolute;
//   top     : -50px;
//   bottom  : -50px;
//   right   : 100px;
//   border  : 1px dashed #cccfe9;
//   z-index : -1;
// }

// .comparision-three__progress-box-border {
//   position: absolute;
//   top     : -50px;
//   bottom  : -50px;
//   right   : 492px;
//   border  : 1px dashed #cccfe9;
//   z-index : -1;
// }

// .comparision-three__progress {
//   position     : relative;
//   display      : flex;
//   align-items  : center;
//   margin-bottom: 80px;
// }

// .comparision-three__progress-title {
//   font-size   : 16px;
//   font-weight : 500;
//   line-height : 26px;
//   position    : relative;
//   display     : block;
//   max-width   : 210px;
//   width       : 100%;
//   margin      : 0;
//   margin-right: 9px;
// }

// .comparision-three__progress .bar {
//   position                  : relative;
//   width                     : 100%;
//   height                    : 100px;
//   background                : transparent;
//   border-top-right-radius   : 10px;
//   border-bottom-right-radius: 10px;
// }

// .comparision-three__progress .bar-inner {
//   position                  : relative;
//   display                   : block;
//   width                     : 0px;
//   height                    : 100px;
//   border-top-right-radius   : 10px;
//   border-bottom-right-radius: 10px;
//   background                : #00ad6f;
//   -webkit-transition        : all 1500ms ease;
//   -ms-transition            : all 1500ms ease;
//   -o-transition             : all 1500ms ease;
//   -moz-transition           : all 1500ms ease;
//   transition                : all 1500ms ease;
// }

// .comparision-three__progress .bar-inner-two {
//   background: linear-gradient(-85deg, #f86b3f 0%, #0eb679 22.9%, #00ad6f 100%);
// }

// .comparision-three__progress .count-text {
//   position          : absolute;
//   right             : 30px;
//   top               : 50%;
//   transform         : translateY(-50%);
//   color             : var(--customVar-white);
//   line-height       : 16px;
//   font-size         : 16px;
//   text-align        : center;
//   font-weight       : 500;
//   opacity           : 0;
//   -webkit-transition: all 500ms ease;
//   -ms-transition    : all 500ms ease;
//   -o-transition     : all 500ms ease;
//   -moz-transition   : all 500ms ease;
//   transition        : all 500ms ease;
// }

// .comparision-three__progress .bar-inner.counted .count-text {
//   opacity: 1;
// }

// .comparision-three__progress.marb-0 {
//   margin-bottom: 0;
// }

// /*************************************************************
// * Dedicated Hosting Page Banner
// **************************************************************/
// .dedicated-hosting-page-banner {
//   padding-bottom: 24px;
// }

// .dedicated-hosting-page-banner .page-banner-three__right {
//   margin-left: 35px;
//   margin-top : -64px;
// }

// /*************************************************************
// * Pricing Nine
// **************************************************************/
// .pricing-nine {
//   position        : relative;
//   display         : block;
//   background-color: #f4f7fe;
//   padding         : 109px 0 80px;
//   z-index         : 1;
// }

// .pricing-nine .row {
//   --bs-gutter-x: 40px;
// }

// .pricing-nine__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 19px;
// }

// .pricing-nine__section-title__title {
//   font-size    : 40px;
//   font-weight  : 700;
//   line-height  : 50px;
//   color        : var(--customVar-black);
//   margin       : 0;
//   margin-bottom: 7px;
// }

// .pricing-nine__section-title__text {
//   font-size  : 18px;
//   margin     : 0;
//   line-height: 28px;
// }

// .pricing-nine ul.switch-toggler-list {
//   display          : -webkit-box;
//   display          : -ms-flexbox;
//   display          : flex;
//   -webkit-box-align: center;
//   -ms-flex-align   : center;
//   align-items      : center;
//   -webkit-box-pack : center;
//   -ms-flex-pack    : center;
//   justify-content  : center;
//   margin-bottom    : 40px;
// }

// .pricing-nine ul.switch-toggler-list li a {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-black);
//   padding-left  : 10px;
//   padding-right : 10px;
//   display       : block;
//   letter-spacing: 0.01em;
// }

// .pricing-nine ul.switch-toggler-list li.active a {
//   color: var(--customVar-black);
// }

// .pricing-nine .switch {
//   position      : relative;
//   display       : inline-block;
//   vertical-align: middle;
//   margin        : 0;
//   border-radius : 5px;
//   border        : 2px solid #e4e4e4;
//   width         : 50px;
//   height        : 30px;
// }

// .pricing-nine .slider {
//   position          : absolute;
//   cursor            : pointer;
//   top               : 0;
//   left              : 0;
//   right             : 0;
//   bottom            : 0;
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
//   background-color  : rgb(255, 255, 255);
// }

// .pricing-nine .slider:before {
//   position          : absolute;
//   content           : "";
//   height            : 20px;
//   width             : 20px;
//   left              : 3px;
//   right             : 3px;
//   top               : 50%;
//   -webkit-transform : translateX(0px) translateY(-50%);
//   transform         : translateX(0px) translateY(-50%);
//   background-color  : var(--customVar-secondary);
//   -webkit-transition: 0.4s;
//   transition        : 0.4s;
// }

// .pricing-nine .switch.off .slider:before {
//   -webkit-transform: translateX(20px) translateY(-50%);
//   transform        : translateX(20px) translateY(-50%);
// }

// .pricing-nine .slider.round {
//   border-radius: 5px;
// }

// .pricing-nine .slider.round:before {
//   border-radius: 5px;
// }

// .pricing-nine__single {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   box-shadow      : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   padding         : 36px 40px 36px;
//   margin-bottom   : 40px;
// }

// .pricing-nine__offer {
//   position        : absolute;
//   top             : 44px;
//   right           : 40px;
//   background-color: #ebedff;
//   border-radius   : 13px;
//   font-weight     : 400;
//   font-size       : 14px;
//   line-height     : 14px;
//   color           : var(--customVar-black);
//   padding         : 5px 16px 6px;
// }

// .pricing-nine__price-box {
//   position: relative;
//   display : block;
// }

// .pricing-nine__price-sub-title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 26px;
//   line-height: 33px;
//   color      : var(--customVar-black);
// }

// .pricing-nine__price {
//   margin     : 12px 0 13px;
//   font-weight: 800;
//   font-size  : 50px;
//   line-height: 63px;
//   color      : var(--customVar-black);
// }

// .pricing-nine__price-validity {
//   font-weight: 500;
//   font-size  : 20px;
//   line-height: 25px;
// }

// .pricing-nine__points-title {
//   margin     : 0;
//   font-weight: 500;
//   font-size  : 16px;
//   line-height: 26px;
//   color      : var(--customVar-black);
// }

// .pricing-nine__points-box {
//   position     : relative;
//   display      : flex;
//   margin-top   : 15px;
//   margin-bottom: 23px;
// }

// .pricing-nine__points-list {
//   position: relative;
//   display : block;
//   margin  : 0;
// }

// .pricing-nine__points-list-2 {
//   margin-left: 67px;
// }

// .pricing-nine__points-list li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .pricing-nine__points-list li+li {
//   margin-top: 8px;
// }

// .pricing-nine__points-list li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 2px;
// }

// .pricing-nine__points-list li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 20px;
//   color    : #00c14d;
// }

// .pricing-nine__points-list li .text {
//   margin-left: 20px;
// }

// .pricing-nine__points-list li .text p {
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .pricing-nine__points-list li .text p>span {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 19px;
//   color      : #aaaaaa;
//   margin-left: 10px;
//   top        : 2px;
// }

// .pricing-nine__btn-box {
//   position: relative;
//   display : block;
// }

// .pricing-nine__btn {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   color     : var(--customVar-white);
// }

// .pricing-nine__btn::before {
//   background-color: var(--customVar-secondary);
// }

// /*************************************************************
// * Power Control
// **************************************************************/
// .power-control {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 109px 0 90px;
//   z-index         : 1;
// }

// .power-control__section-title {
//   margin     : 0 0 40px;
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   text-align : center;
//   color      : var(--customVar-black);
// }

// .power-control__single {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   box-shadow      : 0px 15px 20px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   text-align      : center;
//   padding         : 31px 40px 31px;
//   margin-bottom   : 30px;
// }

// .power-control__icon {
//   position: relative;
//   display : inline-block;
// }

// .power-control__icon img {
//   width                   : auto;
//   -webkit-transition      : all 500ms linear;
//   transition              : all 500ms linear;
//   -webkit-transition-delay: 0.1s;
//   transition-delay        : 0.1s;
// }

// .power-control__single:hover .power-control__icon img {
//   transform: scale(0.9);
// }

// .power-control__title {
//   margin     : 16px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
// }

// .power-control__text {
//   margin     : 0;
//   line-height: 26px;
// }

// .power-control__btn {
//   position  : relative;
//   display   : inline-block;
//   margin-top: 31px;
// }

// .power-control__btn a {
//   font-weight       : 600;
//   font-size         : 18px;
//   line-height       : 18px;
//   text-align        : center;
//   letter-spacing    : 0.01em;
//   color             : #3852ff;
//   position          : relative;
//   display           : flex;
//   align-items       : center;
//   font-family       : var(--customVar-title-font);
//   -webkit-transition: all 500ms ease;
//   transition        : all 500ms ease;
// }

// .power-control__btn a:hover {
//   color: var(--customVar-base);
// }

// .power-control__btn a span {
//   position   : relative;
//   display    : inline-block;
//   font-size  : 12px;
//   margin-left: 12px;
// }

// /*************************************************************
// * Extreme performance
// **************************************************************/
// .extreme-performance {
//   position        : relative;
//   display         : block;
//   background-color: #fafbff;
//   padding         : 0 0 114px;
//   z-index         : 1;
// }

// .extreme-performance__left {
//   position: relative;
//   display : block;
// }

// .extreme-performance__img img {
//   width         : 100%;
//   mix-blend-mode: multiply;
// }

// .extreme-performance__right {
//   position   : relative;
//   display    : block;
//   margin-left: 140px;
//   margin-top : 68px;
// }

// .extreme-performance__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : var(--customVar-black);
// }

// .extreme-performance__text {
//   margin     : 19px 0 29px;
//   font-size  : 18px;
//   line-height: 32px;
// }

// .extreme-performance__points {
//   margin      : 0;
//   margin-left : 20px;
//   margin-right: -3px;
// }

// .extreme-performance__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .extreme-performance__points li+Li {
//   margin-top: 13px;
// }

// .extreme-performance__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 3px;
// }

// .extreme-performance__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 20px;
//   color    : #00c14d;
// }

// .extreme-performance__points li .text {
//   margin-left: 20px;
// }

// .extreme-performance__points li .text p {
//   margin: 0;
// }

// .extreme-performance__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 40px;
// }

// .extreme-performance__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .extreme-performance__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// /*************************************************************
// * Fully Loaded
// **************************************************************/
// .fully-loaded {
//   position  : relative;
//   display   : block;
//   background: linear-gradient(90.25deg, #436cff -20.47%, #000b3c 98.18%);
//   padding   : 41px 0 36px;
//   z-index   : 1;
// }

// .fully-loaded__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .fully-loaded__left {
//   position  : relative;
//   display   : block;
//   margin-top: 68px;
// }

// .fully-loaded__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : var(--customVar-white);
// }

// .fully-loaded__points {
//   position: relative;
//   display : block;
//   margin  : 32px 0 37px;
// }

// .fully-loaded__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .fully-loaded__points li+li {
//   margin-top: 13px;
// }

// .fully-loaded__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 3px;
// }

// .fully-loaded__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 20px;
//   color    : #00c14d;
// }

// .fully-loaded__points li .text {
//   margin-left: 19px;
// }

// .fully-loaded__points li .text p {
//   margin: 0;
//   color : var(--customVar-white);
// }

// .fully-loaded__btn-box {
//   position: relative;
//   display : block;
// }

// .fully-loaded__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .fully-loaded__btn:hover {
//   color: var(--customVar-black);
// }

// .fully-loaded__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .fully-loaded__btn::after {
//   background-color: var(--customVar-white);
// }

// .fully-loaded__right {
//   position    : relative;
//   display     : block;
//   margin-right: 32px;
//   margin-left : 173px;
// }

// .fully-loaded__img {
//   position: relative;
//   display : block;
// }

// .fully-loaded__img img {
//   width: 100%;
// }

// /*************************************************************
// * Feature Eight
// **************************************************************/
// .feature-eight {
//   padding: 109px 0 77px;
// }

// .feature-eight .feature-five__section-title {
//   margin: 0 0 50px;
// }

// .feature-eight .feature-five__single {
//   margin-bottom: 36px;
// }

// /*************************************************************
// * Support Two
// **************************************************************/
// .support-two {
//   position: relative;
//   display : block;
//   padding : 22px 0 0;
//   z-index : 1;
// }

// .support-two__left {
//   position    : relative;
//   display     : block;
//   margin-right: 45px;
//   margin-top  : 121px;
// }

// .support-two__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   color      : var(--customVar-black);
// }

// .support-two__text {
//   margin     : 21px 0 34px;
//   font-size  : 18px;
//   line-height: 32px;
// }

// .support-two__details {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   flex-wrap       : wrap;
//   max-width       : 534px;
//   width           : 100%;
//   background-color: #011496;
//   box-shadow      : 0px 20px 20px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   margin          : 0;
// }

// .support-two__details li {
//   position  : relative;
//   flex      : 0 0 33.333333%;
//   max-width : 33.333333%;
//   width     : 100%;
//   text-align: center;
// }

// .support-two__single {
//   position: relative;
//   display : block;
//   padding : 40px 0 38px;
// }

// .support-two__single:before {
//   content         : "";
//   position        : absolute;
//   top             : 42px;
//   bottom          : 42px;
//   left            : 0;
//   width           : 1px;
//   background-color: #dbdbdb;
// }

// .support-two__details li:first-child .support-two__single:before {
//   display: none;
// }

// .support-two__icon {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   margin-bottom  : 16px;
// }

// .support-two__icon span {
//   position               : relative;
//   display                : inline-block;
//   font-size              : 38px;
//   background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
//   -webkit-background-clip: text;
//   -moz-background-clip   : text;
//   background-clip        : text;
//   -webkit-text-fill-color: transparent;
//   -webkit-transition     : all 500ms ease;
//   transition             : all 500ms ease;
// }

// .support-two__text-2 {
//   font-size     : 18px;
//   font-weight   : 500;
//   color         : var(--customVar-white);
//   line-height   : 18px;
//   margin        : 0;
//   letter-spacing: -0.015em;
// }

// .support-two__right {
//   position    : relative;
//   display     : block;
//   margin-left : 20px;
//   margin-right: 54px;
// }

// .support-two__img {
//   position: relative;
//   display : block;
// }

// .support-two__img img {
//   width: 100%;
// }

// /*************************************************************
// * SSL Certificate Page Banner
// **************************************************************/
// .ssl-certificate-page-banner {
//   padding-bottom: 51px;
// }

// .ssl-certificate-page-banner .page-banner-three__right {
//   margin-left: 46px;
//   margin-top : -48px;
// }

// /*************************************************************
// * SSL Certificate
// **************************************************************/
// .ssl-certificate {
//   position: relative;
//   display : block;
//   padding : 109px 0 87px;
// }

// .ssl-certificate .container {
//   max-width: 1050px;
// }

// .ssl-certificate .row {
//   --bs-gutter-x: 88px;
// }

// .ssl-certificate__section-title {
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   text-align : center;
//   color      : var(--customVar-black);
//   margin     : 0;
// }

// .ssl-certificate__section-text {
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 32px;
//   text-align : center;
//   margin     : 10px 0 30px;
// }

// .ssl-certificate__single {
//   position     : relative;
//   display      : block;
//   margin-bottom: 26px;
// }

// .ssl-certificate__icon {
//   position: relative;
//   display : inline-block;
// }

// .ssl-certificate__icon img {
//   width: auto;
// }

// .ssl-certificate__title {
//   margin     : 18px 0 10px;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
// }

// .ssl-certificate__text {
//   margin        : 0;
//   letter-spacing: -0.015em;
//   line-height   : 26px;
// }

// /*************************************************************
// * SSL Solution
// **************************************************************/
// .ssl-solution {
//   position        : relative;
//   display         : block;
//   background-color: #f4f7fe;
//   padding         : 109px 0 120px;
//   z-index         : 1;
// }

// .ssl-solution__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 40px;
// }

// .ssl-solution__section-title__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   text-align : center;
//   color      : #121212;
// }

// .ssl-solution__section-title__text {
//   font-size  : 18px;
//   line-height: 32px;
//   margin     : 0;
// }

// .ssl-solution__inner {
//   position: relative;
//   display : flex;
// }

// .ssl-solution__left {
//   position  : relative;
//   display   : block;
//   max-width : 315px;
//   width     : 100%;
//   margin-top: 25px;
// }

// .ssl-solution__list {
//   position: relative;
//   display : block;
// }

// .ssl-solution__list li {
//   position                 : relative;
//   display                  : block;
//   font-size                : 16px;
//   font-weight              : 500;
//   line-height              : 16px;
//   color                    : var(--customVar-text);
//   letter-spacing           : -0.015em;
//   padding                  : 32px 30px 32px;
//   border-top-left-radius   : 10px;
//   border-bottom-left-radius: 10px;
//   background               : #fdfdff;
// }

// .ssl-solution__list li:nth-child(1) {
//   background-color: transparent;
// }

// .ssl-solution__list li:nth-child(3) {
//   background-color: transparent;
// }

// .ssl-solution__list li:nth-child(5) {
//   background-color: transparent;
// }

// .ssl-solution__list li:nth-child(7) {
//   background-color: transparent;
// }

// .ssl-solution__list li:nth-child(9) {
//   background-color: transparent;
// }

// .ssl-solution__list li:nth-child(11) {
//   background-color: transparent;
// }

// .ssl-solution__right {
//   position: relative;
//   display : block;
//   width   : 100%;
// }

// .ssl-solution__right .row {
//   --bs-gutter-x: 0px;
// }

// .ssl-solution__table {
//   position     : relative;
//   margin       : 0;
//   background   : #ffffff;
//   box-shadow   : 0px 8px 20px rgba(42, 42, 46, 0.03);
//   border-radius: 20px;
// }

// .ssl-solution__table li:nth-child(2) {
//   border-left : 1px solid #dde1fd;
//   border-right: 1px solid #dde1fd;
// }

// .ssl-solution__single {
//   position  : relative;
//   display   : block;
//   text-align: center;
//   padding   : 55px 30px 35px;
// }

// .ssl-solution__tag {
//   position       : absolute;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   background     : #000d61;
//   border-radius  : 0px 20px 0px 15px;
//   max-width      : 140px;
//   width          : 100%;
//   top            : 0;
//   right          : 0;
// }

// .ssl-solution__tag-icon {
//   position: relative;
//   display : block;
//   top     : -2px;
// }

// .ssl-solution__tag-icon img {
//   width: auto;
// }

// .ssl-solution__tag-text {
//   font-size     : 11px;
//   margin        : 0;
//   line-height   : 11px;
//   color         : var(--customVar-white);
//   font-weight   : 500;
//   text-transform: uppercase;
//   margin-left   : 5px;
// }

// .ssl-solution__icon {
//   position: relative;
//   display : block;
// }

// .ssl-solution__icon img {
//   width: auto;
// }

// .ssl-solution__star {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
// }

// .ssl-solution__star i {
//   position : relative;
//   display  : inline-block;
//   font-size: 25px;
//   color    : #ffa800;
// }

// .ssl-solution__star i+i {
//   margin-left: 8px;
// }

// .ssl-solution__title {
//   font-size    : 40px;
//   line-height  : 50px;
//   color        : #121212;
//   font-weight  : 800;
//   margin       : 0;
//   margin-top   : 42px;
//   margin-bottom: 40px;
// }

// .ssl-solution__point {
//   font-size  : 20px;
//   position   : relative;
//   left       : -9px;
//   line-height: 20px;
// }

// .ssl-solution__validity {
//   font-size  : 20px;
//   font-weight: 500;
//   position   : relative;
//   left       : -14px;
//   line-height: 20px;
// }

// .ssl-solution__check-icon {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   flex-direction : column;
// }

// .ssl-solution__check-icon i {
//   position        : relative;
//   display         : flex;
//   align-items     : center;
//   justify-content : center;
//   height          : 25px;
//   width           : 25px;
//   background-color: #00c14d;
//   border-radius   : 50%;
//   font-size       : 12px;
//   color           : var(--customVar-white);
// }

// .ssl-solution__check-icon i+i {
//   margin-top: 55px;
// }

// .ssl-solution__text {
//   font-weight   : 600;
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
//   margin-top    : 53px;
//   margin-bottom : 33px;
// }

// .ssl-solution__btn {
//   width          : 100%;
//   text-align     : center;
//   justify-content: center;
//   color          : var(--customVar-white);
// }

// .ssl-solution__btn::before {
//   background-color: var(--customVar-secondary);
// }

// /*************************************************************
// * How It Work Two
// **************************************************************/
// .how-it-work-two {
//   position        : relative;
//   display         : block;
//   counter-reset   : count;
//   background-color: #fafbff;
//   padding         : 109px 0 143px;
//   z-index         : 1;
// }

// .how-it-work-two__inner {
//   position: relative;
//   display : block;
//   z-index : 1;
// }

// .how-it-work-two__shape-1 {
//   position  : absolute;
//   bottom    : -29px;
//   left      : 0;
//   right     : 0;
//   text-align: center;
// }

// .how-it-work-two__shape-1 img {
//   width: auto;
// }

// .how-it-work-two__section-title {
//   position     : relative;
//   display      : block;
//   margin-bottom: 40px;
// }

// .how-it-work-two__section-title__title {
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   text-align : center;
//   color      : var(--customVar-black);
//   margin     : 0;
// }

// .how-it-work-two__section-title__text {
//   font-size  : 18px;
//   line-height: 32px;
//   text-align : center;
//   margin     : 12px 0 0;
// }

// .how-it-work-two__list {
//   position   : relative;
//   display    : flex;
//   align-items: center;
//   margin     : 0;
// }

// .how-it-work-two__list li {
//   position: relative;
//   display : block;
// }

// .how-it-work-two__list li:nth-child(1) {
//   padding-right: 93px;
// }

// .how-it-work-two__list li:nth-child(2) {
//   padding-left : 30px;
//   padding-right: 63px;
// }

// .how-it-work-two__list li:nth-child(3) {
//   padding-left : 50px;
//   padding-right: 30px;
// }

// .how-it-work-two__list li:nth-child(4) {
//   padding-left: 93px;
// }

// .how-it-work-two__single {
//   position     : relative;
//   display      : block;
//   text-align   : center;
//   margin-bottom: 23px;
// }

// .how-it-work-two__title {
//   font-size  : 22px;
//   font-weight: 600;
//   color      : var(--customVar-black);
//   margin     : 22px 0 10px;
//   line-height: 32px;
// }

// .how-it-work-two__text {
//   letter-spacing: -0.015em;
//   margin        : 0;
//   line-height   : 26px;
// }

// .how-it-work-two__count {
//   position       : relative;
//   display        : flex;
//   align-items    : center;
//   justify-content: center;
//   width          : 70px;
//   height         : 70px;
//   background     : #f0f5ff;
//   border-radius  : 20px;
//   text-align     : center;
//   margin         : 0 auto;
// }

// .how-it-work-two__count:before {
//   position         : relative;
//   font-size        : 35px;
//   line-height      : 35px;
//   font-weight      : 700;
//   counter-increment: count;
//   content          : "0"counter(count);
//   transition       : all 200ms linear;
//   transition-delay : 0.1s;
//   color            : #3852ff;
//   font-family      : var(--customVar-title-font);
// }

// /*************************************************************
// * Why Us Three
// **************************************************************/
// .why-us-three {
//   position        : relative;
//   display         : block;
//   padding         : 0 0 76px;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .why-us-three__left {
//   position    : relative;
//   display     : block;
//   margin-right: 43px;
//   margin-top  : 36px;
// }

// .why-us-three__section-title {
//   position: relative;
//   display : block;
// }

// .why-us-three__section-title__title {
//   margin     : 0;
//   font-weight: 700;
//   font-size  : 38px;
//   line-height: 48px;
//   color      : var(--customVar-black);
// }

// .why-us-three__section-title__text {
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 32px;
//   margin     : 19px 0 19px;
// }

// .why-us-three__points {
//   margin     : 0;
//   position   : relative;
//   display    : block;
//   margin-left: 28px;
// }

// .why-us-three__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .why-us-three__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 1px;
// }

// .why-us-three__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 21px;
//   color    : #00c14d;
// }

// .why-us-three__points li .text {
//   margin-left: 20px;
// }

// .why-us-three__points li .text p {
//   margin     : 0;
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 46px;
//   color      : var(--customVar-black);
// }

// .why-us-three__btn-box {
//   position  : relative;
//   display   : block;
//   margin-top: 32px;
// }

// .why-us-three__btn {
//   color             : var(--customVar-white);
//   -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
//   box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
// }

// .why-us-three__btn::before {
//   background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
// }

// .why-us-three__right {
//   position   : relative;
//   display    : block;
//   margin-left: 50px;
// }

// .why-us-three__img {
//   position: relative;
//   display : block;
// }

// .why-us-three__img img {
//   width: 100%;
// }

// /*************************************************************
// * Https
// **************************************************************/
// .https {
//   position        : relative;
//   display         : block;
//   padding         : 71px 0 0;
//   background-color: #fafbff;
//   z-index         : 1;
// }

// .https__bg-box {
//   position  : absolute;
//   top       : 0;
//   left      : 0;
//   right     : 0;
//   height    : 520px;
//   background: linear-gradient(90.25deg, #436cff -20.47%, #000b3c 98.18%);
//   z-index   : -1;
// }

// .https__shape-1 {
//   position           : absolute;
//   top                : 0;
//   left               : 0;
//   right              : 0;
//   bottom             : -30px;
//   background-repeat  : no-repeat;
//   background-size    : cover;
//   background-position: center;
//   z-index            : -1;
// }

// .https .container {
//   max-width: 1130px;
// }

// .https__section-title {
//   font-weight: 700;
//   font-size  : 40px;
//   line-height: 50px;
//   text-align : center;
//   color      : var(--customVar-white);
//   margin     : 0 0 50px;
// }

// .https__single {
//   position        : relative;
//   display         : block;
//   background-color: #ffffff;
//   box-shadow      : 0px 30px 60px rgba(0, 0, 0, 0.05);
//   border-radius   : 20px;
//   padding         : 38px 78px 36px;
//   margin-bottom   : 30px;
// }

// .https__link {
//   position   : relative;
//   display    : block;
//   margin-left: 11px;
// }

// .https__link img {
//   width: auto;
// }

// .https__points-box {
//   position  : relative;
//   display   : block;
//   margin-top: -14px;
// }

// .https__points-title {
//   margin     : 0;
//   font-weight: 600;
//   font-size  : 22px;
//   line-height: 32px;
//   color      : var(--customVar-black);
// }

// .https__points {
//   position: relative;
//   display : block;
//   margin  : 15px 0 0;
// }

// .https__points li {
//   position   : relative;
//   display    : flex;
//   align-items: center;
// }

// .https__points li .icon {
//   position: relative;
//   display : inline-block;
//   top     : 5px;
// }

// .https__points li .icon span {
//   position : relative;
//   display  : inline-block;
//   font-size: 22px;
//   color    : #00c14d;
// }

// .https__points li .text {
//   margin-left: 20px;
// }

// .https__points li .text p {
//   margin     : 0;
//   font-weight: 400;
//   font-size  : 18px;
//   line-height: 40px;
//   color      : var(--customVar-black);
// }

// /*************************************************************
// * SSL Sertificate Page
// **************************************************************/
// .ssl-certificate-page-faq {
//   padding-top: 80px;
// }

// /***********************
// * Client Logo
// ***********************/


















@media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  // .robust-one__left {
  //   margin-right: -78px;
  // }

  // .banner-two__title {
  //   font-size  : 50px;
  //   line-height: 60px;
  // }

  // .choose-domain__right {
  //   margin-top: 20px;
  // }

  // .pricing-two__points--two {
  //   margin-left: 30px;
  // }

  // .support-one__img {
  //   right: 0;
  // }

  // .support-one__title {
  //   font-size: 38px;
  // }

  // .footer-widget-two {
  //   margin-right: 0;
  // }

  // .footer-widget-two--domain {
  //   margin-left: 0;
  // }

  // .footer-widget-two--services {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-widget-two--company {
  //   margin-left: 0;
  // }

  // .footer-widget-two--help {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-main-two__newsletter {
  //   margin-left: 0;
  // }

  // .footer-main-two__newsletter-btn {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .footer-main-two__contact-list {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .footer-main-two__contact-list li+li {
  //   margin-left: 0;
  //   margin-top : 30px;
  // }

  // .footer-main-two__contact {
  //   margin-top  : 0;
  //   margin-right: 0;
  // }

  // .right-hosting__inner .tab-buttons .tab-btn+.tab-btn {
  //   margin-left: 40px;
  // }

  // .right-hosting__points-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .right-hosting__points--two {
  //   margin-left: 0;
  //   margin-top : 8px;
  // }

  // .right-hosting {
  //   padding: 120px 0 109px;
  // }

  // .services-two__list li {
  //   padding: 35px 10px 31px;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .banner-four__title {
  //   font-size  : 50px;
  //   line-height: 66px;
  // }

  // .banner-four__text br {
  //   display: none;
  // }

  // .contact-two__section-title__text br {
  //   display: none;
  // }

  // .about-one__left {
  //   margin-right: 0;
  // }

  // .vps-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .cloud-hosting-page-banner .page-banner-three__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .page-banner-three__title {
  //   font-size  : 50px;
  //   line-height: 66px;
  // }

  // .why-choose-four__left {
  //   margin-right: 0;
  // }

  // .why-choose-four__right {
  //   margin-left: 0;
  // }

  // .why-choose-four__points--two {
  //   margin-left: 50px;
  // }

  // .get-access__left {
  //   margin-right: 0;
  // }

  // .get-access__btn-box-content--two {
  //   margin-left: 19px;
  // }

  // .get-access__img {
  //   right: -240px;
  // }

  // .dedicated-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }
}
















// /* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  // .main-header-three .mobile-nav__toggler span {
  //   background-color: var(--customVar-black);
  // }

  // .client-logo__point {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .pricing-one__single {
  //   padding: 48px 10px 40px;
  // }

  // .pricing-one__shape-12 {
  //   display: none;
  // }

  // .feature-one__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .feature-one__list li:nth-child(2) {
  //   padding-right: 0;
  //   padding-left : 0;
  // }

  // .feature-one__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .feature-one__list li:nth-child(4) {
  //   padding-left: 0;
  // }

  // .robust-one__left {
  //   max-width: 600px;
  //   margin   : 65px auto 0;
  // }

  // .robust-one__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .why-choose-one__right {
  //   margin-left: 0;
  // }

  // .why-choose-one__title {
  //   font-size: 35px;
  // }

  // .why-choose-one {
  //   padding: 120px 0 120px;
  // }

  // .apps-one__left {
  //   max-width   : 685px;
  //   margin-left : auto;
  //   margin-right: auto;
  // }

  // .apps-one__right {
  //   max-width: 685px;
  //   margin   : 50px auto 0;
  // }

  // .blog-one__user-and-date {
  //   flex-direction: column;
  // }

  // .banner-two__right {
  //   margin-left: 0;
  // }

  // .banner-two__title {
  //   font-size  : 40px;
  //   line-height: 50px;
  // }

  // .choose-domain__right {
  //   margin-top: 20px;
  // }

  // .choose-domain__bottom {
  //   padding-left : 50px;
  //   padding-right: 50px;
  // }

  // .customar-rating__right {
  //   margin-top: 50px;
  // }

  // .max-performance__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .max-performance__right {
  //   max-width: 600px;
  //   margin   : 40px auto 0;
  // }

  // .testimonial-two__client-info {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-two__client-content {
  //   margin-bottom: 20px;
  // }

  // .support-one__img {
  //   display: none;
  // }

  // .footer-widget-two {
  //   margin-right: 0;
  // }

  // .footer-widget-two--domain {
  //   margin-left: 0;
  // }

  // .footer-widget-two--services {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-widget-two--company {
  //   margin-left: 0;
  // }

  // .footer-widget-two--help {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-main-two__newsletter {
  //   margin-left: 0;
  // }

  // .footer-main-two__contact {
  //   margin-top   : 0;
  //   margin-right : 0;
  //   margin-bottom: 30px;
  // }

  // .why-choose-two__single {
  //   padding: 48px 20px 25px;
  // }

  // .right-hosting__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .right-hosting__right {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .right-hosting {
  //   padding: 120px 0 109px;
  // }

  // .choose-domain-two__left {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .choose-domain-two__right {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .services-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .services-two__list li:nth-child(1) {
  //   border-top-right-radius: 0;
  // }

  // .services-two__list li:nth-child(4) {
  //   border-bottom-right-radius: 0;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .how-it-work__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(2) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(4) {
  //   padding-left: 0;
  // }

  // .how-it-work__list {
  //   flex-wrap: wrap;
  // }

  // .blog-two__user-and-date {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .blog-two__user {
  //   margin-bottom: 20px;
  // }

  // .banner-four__left {
  //   margin-top: 0;
  // }

  // .banner-four__title {
  //   font-size  : 50px;
  //   line-height: 66px;
  // }

  // .banner-four__text br {
  //   display: none;
  // }

  // .banner-four__right {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .banner-four {
  //   padding: 277px 0 55px;
  // }

  // .pricing-three__content {
  //   padding: 32px 20px 35px;
  // }

  // .faq-two__img {
  //   left: -115px;
  // }

  // .comming-soon__content {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .comming-soon__title-box {
  //   margin-bottom: 20px;
  // }

  // .why-choose-three__points {
  //   justify-content: inherit;
  //   flex-wrap      : wrap;
  // }

  // .why-choose-three__points li {
  //   flex     : 0 0 50%;
  //   max-width: 50%;
  //   width    : 100%;
  // }

  // .blog-three__user-and-date {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .blog-three__user {
  //   margin-bottom: 20px;
  // }

  // .contact-two__right {
  //   margin-left: 0;
  //   margin-top : 25px;
  // }

  // .about-one__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .page-banner-three__title {
  //   font-size  : 40px;
  //   line-height: 50px;
  // }

  // .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .page-banner-three {
  //   padding-bottom: 120px;
  // }

  // .feature-two__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .feature-two__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .shared-hosting__left {
  //   margin-right: 0;
  // }

  // .web-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .pricing-five__single {
  //   padding: 35px 10px 30px;
  // }

  // .vps-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .pricing-six__single {
  //   padding: 47px 15px 35px;
  // }

  // .feature-three__single {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .feature-three__content {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .cloud-hosting-page-banner .page-banner-three__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .faster-one__right {
  //   margin-left: 0;
  // }

  // .get-started__left {
  //   margin-left: 0;
  // }

  // .get-started__right {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .game-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .why-choose-four__left {
  //   margin-right: 0;
  // }

  // .why-choose-four__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .testimonial-four .testimonial-one__client-info {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-four .testimonial-one__client-content {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .game-server__left {
  //   margin-right: 0;
  // }

  // .game-server__right {
  //   margin-left: 0;
  // }

  // .game-server__title {
  //   font-size: 36px;
  // }

  // .get-access__img {
  //   display: none;
  // }

  // .get-access {
  //   padding: 109px 0 109px;
  // }

  // .reseller-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .pricing-eight__single {
  //   padding: 46px 10px 36px;
  // }

  // .which-plan__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .which-plan__right {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .awarded-two__left {
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .awarded-two__right {
  //   margin-left: 0;
  // }

  // .dedicated-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .pricing-nine__points-box {
  //   flex-direction: column;
  // }

  // .pricing-nine__points-list-2 {
  //   margin-left: 0;
  //   margin-top : 8px;
  // }

  // .extreme-performance__right {
  //   margin-left: 0;
  //   margin-top : 68px;
  // }

  // .fully-loaded__right {
  //   margin-right: 0;
  //   margin-left : 0;
  // }

  // .fully-loaded__left {
  //   margin-top: 0;
  // }

  // .fully-loaded {
  //   padding: 109px 0 120px;
  // }

  // .support-two__left {
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .support-two__title {
  //   font-size: 35px;
  // }

  // .page-banner-four__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .page-banner-four__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .page-banner-four {
  //   padding-bottom: 120px;
  // }

  // .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__single--two .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__single--three .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .buy-domain-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .ssl-certificate-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .how-it-work-two__shape-1 {
  //   display: none;
  // }

  // .how-it-work-two__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(2) {
  //   padding-left : 10px;
  //   padding-right: 10px;
  // }

  // .how-it-work-two__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(4) {
  //   padding-left: 10px;
  // }

  // .how-it-work-two {
  //   padding: 109px 0 44px;
  // }

  // .why-us-three__left {
  //   margin-right: 0;
  // }

  // .why-us-three__right {
  //   margin-left: 0;
  // }

  // .why-us-three {
  //   padding: 0 0 120px;
  // }

  // .https__single {
  //   padding: 38px 30px 36px;
  // }

  // .sidebar {
  //   margin-top: 50px;
  // }

  // .blog-single__pagenation {
  //   flex-direction: column;
  // }

  // .blog-single__social-box {
  //   position     : relative;
  //   top          : 0;
  //   left         : 0;
  //   transform    : translateX(0%) translateY(0%);
  //   margin-bottom: 50px;
  // }

  // .blog-single__pagenation-left {
  //   margin-bottom: 30px;
  // }

  // .ssl-solution__left {
  //   max-width: 270px;
  // }

  // .ssl-solution__single {
  //   padding: 55px 10px 35px;
  // }
  // .banner-one__left {
  //   margin-top: 0;
  // }

  // .banner-one__title {
  //   font-size  : 48px;
  //   line-height: 58px;
  // }
}



















// /* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  // .main-header-three .mobile-nav__toggler span {
  //   background-color: var(--customVar-black);
  // }

  // .main-header-three {
  //   top       : 0;
  //   background: #f5f4ff;
  // }

  // .sale-one {
  //   display: none;
  // }

  // .main-header-four {
  //   top       : 0;
  //   background: #f5f4ff;
  //   margin-top: 0;
  // }

  // .client-logo__list li {
  //   flex         : 0 0 50%;
  //   max-width    : 50%;
  //   margin-bottom: 20px;
  // }

  // .client-logo__list li:nth-child(3) {
  //   margin-bottom: 0;
  // }

  // .client-logo__list li:nth-child(4) {
  //   margin-bottom: 0;
  // }

  // .pricing-one__shape-12 {
  //   display: none;
  // }

  // .feature-one__list li {
  //   flex         : 0 0 50%;
  //   max-width    : 50%;
  //   margin-bottom: 30px;
  // }

  // .feature-one__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .feature-one__list li:nth-child(2) {
  //   padding-right: 0;
  //   padding-left : 0;
  // }

  // .feature-one__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  //   margin-bottom: 0;
  // }

  // .feature-one__list li:nth-child(4) {
  //   padding-left : 0;
  //   margin-bottom: 0;
  // }

  // .robust-one__left {
  //   max-width: 600px;
  //   margin   : 65px auto 0;
  // }

  // .robust-one__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .why-choose-one__right {
  //   margin-left: 0;
  // }

  // .why-choose-one {
  //   padding: 120px 0 120px;
  // }

  // .apps-one__left {
  //   max-width   : 685px;
  //   margin-left : auto;
  //   margin-right: auto;
  // }

  // .apps-one__right {
  //   max-width: 685px;
  //   margin   : 50px auto 0;
  // }

  // .banner-two__left {
  //   margin-top: 0;
  // }

  // .banner-two__right {
  //   margin-left: 0;
  // }

  // .banner-two {
  //   padding: 193px 0 260px;
  // }

  // .choose-domain__right {
  //   margin-top: 20px;
  // }

  // .choose-domain__btn {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .choose-domain__bottom {
  //   padding-left : 50px;
  //   padding-right: 50px;
  // }

  // .customar-rating__right {
  //   margin-top: 50px;
  // }

  // .pricing-two__points--two {
  //   margin-left: 64px;
  // }

  // .max-performance__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .max-performance__right {
  //   max-width: 600px;
  //   margin   : 40px auto 0;
  // }

  // .support-one__img {
  //   display: none;
  // }

  // .footer-widget-two {
  //   margin-right: 0;
  // }

  // .footer-main-two__top-inner {
  //   flex-direction: column;
  //   text-align    : center;
  // }

  // .footer-main-two__top-inner .language-switcher {
  //   margin-top: 20px;
  // }

  // .footer-widget-two--domain {
  //   margin-left: 0;
  // }

  // .footer-widget-two--services {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-widget-two--company {
  //   margin-left: 0;
  // }

  // .footer-widget-two--help {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-main-two__newsletter {
  //   margin-left: 0;
  // }

  // .footer-main-two__contact {
  //   margin-top   : 0;
  //   margin-right : 0;
  //   margin-bottom: 30px;
  // }

  // .banner-three__title {
  //   font-size: 55px;
  // }

  // .banner-three__text br {
  //   display: none;
  // }

  // .banner-three__shape-1,
  // .banner-three__shape-2 {
  //   display: none;
  // }

  // .banner-three {
  //   padding: 195px 0 405px;
  // }

  // .right-hosting__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .right-hosting__right {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .right-hosting {
  //   padding: 120px 0 109px;
  // }

  // .choose-domain-two {
  //   padding: 68px 0 109px;
  // }

  // .services-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .services-two__list li:nth-child(1) {
  //   border-top-right-radius: 0;
  // }

  // .services-two__list li:nth-child(4) {
  //   border-bottom-right-radius: 0;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .how-it-work__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(2) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(4) {
  //   padding-left: 0;
  // }

  // .how-it-work__list {
  //   flex-wrap: wrap;
  // }

  // .comparision__inner .tab-buttons {
  //   flex-direction: column;
  // }

  // .banner-four__left {
  //   margin-top: 0;
  // }

  // .banner-four__right {
  //   margin-top  : 50px;
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .banner-four {
  //   padding: 183px 0 55px;
  // }

  // .faq-two__img {
  //   position: relative;
  //   top     : 0;
  //   left    : 0;
  // }

  // .comming-soon__content {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .comming-soon__title-box {
  //   margin-bottom: 20px;
  // }

  // .why-choose-three__points {
  //   justify-content: inherit;
  //   flex-wrap      : wrap;
  // }

  // .why-choose-three__points li {
  //   flex     : 0 0 50%;
  //   max-width: 50%;
  //   width    : 100%;
  // }

  // .contact-two__right {
  //   margin-left: 0;
  //   margin-top : 25px;
  // }

  // .about-one__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .testimonial-four__carousel.owl-theme .owl-nav {
  //   display: none;
  // }

  // .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  //   margin-top : 60px;
  // }

  // .page-banner-three {
  //   padding-bottom: 120px;
  // }

  // .feature-two__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .feature-two__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .web-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  //   margin-top : 60px;
  // }

  // .get-faster__left {
  //   margin-right: 0;
  // }

  // .get-faster__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .vps-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .cloud-hosting-page-banner .page-banner-three__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 50px;
  // }

  // .faster-one__right {
  //   margin-left: 0;
  // }

  // .get-started__left {
  //   margin-left: 0;
  // }

  // .get-started__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 40px;
  // }

  // .get-started {
  //   padding: 100px 0 100px;
  // }

  // .game-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .why-choose-four__left {
  //   margin-right: 0;
  // }

  // .why-choose-four__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .testimonial-four .testimonial-one__client-info {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-four .testimonial-one__client-content {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .game-server__left {
  //   margin-right: 0;
  // }

  // .game-server__right {
  //   margin-top : 40px;
  //   margin-left: 0;
  // }

  // .get-access__img {
  //   display: none;
  // }

  // .get-access {
  //   padding: 109px 0 109px;
  // }

  // .get-access__left {
  //   margin-right: 0;
  // }

  // .reseller-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .which-plan__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .which-plan__right {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .awarded-two__left {
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .awarded-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .dedicated-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .extreme-performance__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .fully-loaded__right {
  //   margin-right: 0;
  //   margin-left : 0;
  // }

  // .fully-loaded__left {
  //   margin-top: 0;
  // }

  // .fully-loaded {
  //   padding: 109px 0 120px;
  // }

  // .support-two__left {
  //   margin-bottom: 30px;
  // }

  // .page-banner-four__left {
  //   max-width: 600px;
  //   margin   : 0 auto 0;
  // }

  // .page-banner-four__right {
  //   max-width: 600px;
  //   margin   : 50px auto 0;
  // }

  // .page-banner-four {
  //   padding-bottom: 120px;
  // }

  // .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__content-box {
  //   margin-right: 0;
  // }

  // .feature-nine__single--two .feature-nine__content-box {
  //   margin-left: 0;
  // }

  // .feature-nine__single--two .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__single--three .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .buy-domain-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .ssl-certificate-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .how-it-work-two__shape-1 {
  //   display: none;
  // }

  // .how-it-work-two__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(2) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(4) {
  //   padding-left: 0px;
  // }

  // .how-it-work-two {
  //   padding: 109px 0 44px;
  // }

  // .why-us-three__left {
  //   margin-right: 0;
  // }

  // .why-us-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .why-us-three {
  //   padding: 0 0 120px;
  // }

  // .https__single {
  //   padding: 38px 30px 36px;
  // }

  // .https__link img {
  //   width: 100%;
  // }

  // .sidebar {
  //   margin-top: 50px;
  // }

  // .banner-one__left {
  //   margin-top: 0;
  // }

  // .banner-one__right {
  //   margin-top: 50px;
  // }
}




















// /* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  // .main-header-three .mobile-nav__toggler span {
  //   background-color: var(--customVar-black);
  // }

  // .main-header-three {
  //   top       : 0;
  //   background: #f5f4ff;
  // }

  // .sale-one {
  //   display: none;
  // }

  // .main-header-four {
  //   top       : 0;
  //   background: #f5f4ff;
  //   margin-top: 0;
  // }

  // .main-header-one__cart {
  //   display: none;
  // }

  // .main-header-one .language-switcher {
  //   display: none;
  // }

  // .pricing-one__section-title__title,
  // .services-one__section-title__title,
  // .comparision-two__section-title__title,
  // .services-two__section-title__title,
  // .comparision__section-title__title,
  // .blog-two__section-title__title,
  // .blog-three__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-one__section-title__title br,
  // .services-one__section-title__title br,
  // .comparision-two__section-title__title br,
  // .services-two__section-title__title br,
  // .comparision__section-title__title br,
  // .blog-two__section-title__title br,
  // .blog-three__section-title__title br {
  //   display: none;
  // }

  // .pricing-one__section-title__text br,
  // .services-one__section-title__text br,
  // .comparision-two__section-title__text br,
  // .services-two__section-title__text br,
  // .comparision__section-title__text br,
  // .blog-two__section-title__text br,
  // .blog-three__section-title__text br {
  //   display: none;
  // }













  // .client-logo__list li {
  //   flex         : 0 0 100%;
  //   max-width    : 100%;
  //   margin-bottom: 20px;
  // }

  // .client-logo__list li:last-child {
  //   margin-bottom: 0;
  // }

  // .pricing-one__shape-12,
  // .pricing-one__shape-9,
  // .pricing-one__shape-10 {
  //   display: none;
  // }

  // .pricing-one__single {
  //   padding: 48px 10px 40px;
  // }

  // .feature-one__list li {
  //   flex         : 0 0 100%;
  //   max-width    : 100%;
  //   margin-bottom: 30px;
  // }

  // .feature-one__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .feature-one__list li:nth-child(2) {
  //   padding-right: 0;
  //   padding-left : 0;
  // }

  // .feature-one__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  //   margin-bottom: 0;
  // }

  // .feature-one__list li:nth-child(4) {
  //   padding-left : 0;
  //   margin-bottom: 0;
  // }

  // .feature-one__sec-title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .feature-one__sec-title br {
  //   display: none;
  // }

  // .robust-one__left {
  //   margin-right: 0;
  // }

  // .robust-one__counter-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .robust-one__counter-box li+li {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .robust-one__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 50px;
  // }

  // .why-choose-one__left {
  //   margin-right: 0;
  // }

  // .why-choose-one__right {
  //   margin-left: 0;
  // }

  // .why-choose-one__points li {
  //   padding: 25px 20px 26px;
  // }

  // .why-choose-one {
  //   padding: 120px 0 120px;
  // }

  // .testimonial-one__inner {
  //   margin-right: 0;
  // }

  // .testimonial-one__carousel.owl-theme .owl-nav {
  //   display: none;
  // }

  // .testimonial-one__client-info {
  //   padding       : 20px 20px 23px;
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-one__client-content {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .testimonial-one__content-box {
  //   padding: 35px 20px 42px;
  // }

  // .apps-one__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .apps-one__list {
  //   flex-direction: column;
  // }

  // .apps-one__list li+li {
  //   margin-left: 0;
  // }

  // .blog-one__user-and-date {
  //   flex-direction: column;
  // }

  // .banner-two__left {
  //   margin-top: 0;
  // }

  // .banner-two__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .banner-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .banner-two {
  //   padding: 192px 0 260px;
  // }

  // .choose-domain__inner-top {
  //   padding: 42px 20px 42px;
  // }

  // .choose-domain__right {
  //   margin-top: 20px;
  // }

  // .choose-domain__btn {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .choose-domain__bottom {
  //   padding-left : 20px;
  //   padding-right: 20px;
  // }

  // .choose-domain__list li {
  //   flex     : 0 0 100%;
  //   max-width: 100%;
  // }

  // .choose-domain__list li+li {
  //   margin-top: 20px;
  // }

  // .customar-rating__left {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .customar-rating__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 50px;
  // }

  // .customar-rating__list li {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .customar-rating__rating-point-and-star {
  //   margin-top: 20px;
  // }

  // .services-one__shape-1 {
  //   display: none;
  // }

  // .pricing-two__tab-buttons-box {
  //   margin-right: 0;
  // }

  // .pricing-two__inner-content {
  //   padding: 38px 20px 35px;
  // }

  // .pricing-two__points--two {
  //   margin-left: 0;
  //   margin-top : 8px;
  // }

  // .awarded-one__inner {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .awarded-one__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .awarded-one__title br {
  //   display: none;
  // }

  // .max-performance__left {
  //   margin-right: 0;
  // }

  // .max-performance__right {
  //   margin-top: 40px;
  // }

  // .max-performance__list li {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .max-performance__list-left {
  //   margin-bottom: 15px;
  // }

  // .testimonial-two__client-info {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-two__client-content {
  //   margin-bottom: 20px;
  // }

  // .testimonial-two__top-title {
  //   font-size  : 25px;
  //   line-height: 35px;
  // }

  // .support-one__img {
  //   display: none;
  // }

  // .support-one__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .support-one__title br {
  //   display: none;
  // }

  // .support-one__details {
  //   flex-direction: column;
  // }

  // .support-one__single:before {
  //   display: none;
  // }


  // .footer-main-two__top-inner {
  //   flex-direction: column;
  //   text-align    : center;
  // }

  // .footer-main-two__top-inner .language-switcher {
  //   margin-top: 20px;
  // }

  // .footer-widget-two {
  //   margin-right: 0;
  // }

  // .footer-widget-two--domain {
  //   margin-left: 0;
  // }

  // .footer-widget-two--services {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-widget-two--company {
  //   margin-left: 0;
  // }

  // .footer-widget-two--help {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .footer-main-two__newsletter {
  //   margin-left: 0;
  // }

  // .footer-main-two__contact {
  //   margin-top   : 0;
  //   margin-right : 0;
  //   margin-bottom: 30px;
  // }

  // .footer-main-two__contact-list li+li {
  //   margin-left: 0;
  //   margin-top : 30px;
  // }

  // .footer-main-two__newsletter-btn {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .banner-three__sub-title {
  //   padding-left: 15px;
  // }

  // .banner-three__flase-sale {
  //   position: relative;
  // }

  // .banner-three__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .banner-three__title br {
  //   display: none;
  // }

  // .banner-three__text br {
  //   display: none;
  // }

  // .banner-three__btn-box {
  //   flex-direction: column;
  // }

  // .banner-three__btn-2 {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .banner-three__shape-1,
  // .banner-three__shape-2,
  // .banner-three__shape-3,
  // .banner-three__shape-6 {
  //   display: none;
  // }

  // .banner-three {
  //   padding: 195px 0 405px;
  // }

  // .why-choose-two__single {
  //   padding: 48px 20px 25px;
  // }

  // .right-hosting__left {
  //   margin-right: 0;
  // }

  // .right-hosting__right {
  //   margin-left: 0;
  // }

  // .right-hosting__inner .tab-buttons {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .right-hosting__inner .tab-buttons .tab-btn+.tab-btn {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .right-hosting__inner .tab-buttons .tab-btn:before {
  //   bottom: -4px;
  // }

  // .right-hosting__points-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .right-hosting__points--two {
  //   margin-left: 0;
  //   margin-top : 8px;
  // }

  // .right-hosting {
  //   padding: 120px 0 109px;
  // }

  // .choose-domain-two__left {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .choose-domain-two__right {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .choose-domain-two {
  //   padding: 68px 0 109px;
  // }

  // .services-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .services-two__list li {
  //   flex-direction: column;
  //   align-items   : baseline;
  //   padding       : 35px 20px 31px;
  // }

  // .services-two__list li .content {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .services-two__list li:nth-child(1) {
  //   border-top-right-radius: 0;
  // }

  // .services-two__list li:nth-child(4) {
  //   border-bottom-right-radius: 0;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .how-it-work__shape-1 {
  //   display: none;
  // }

  // .how-it-work__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(2) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work__list li:nth-child(4) {
  //   padding-left: 0;
  // }

  // .how-it-work__list {
  //   flex-wrap     : wrap;
  //   flex-direction: column;
  // }

  // .comparision__inner .tab-buttons {
  //   flex-direction: column;
  // }

  // .comparision__inner .tabs-content {
  //   margin-top: 0;
  // }

  // .comparision__shape-1,
  // .comparision__shape-2,
  // .comparision__shape-3 {
  //   display: none;
  // }

  // .blog-two__user-and-date {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .blog-two__user {
  //   margin-bottom: 20px;
  // }

  // .banner-four__left {
  //   margin-top: 0;
  // }

  // .banner-four__title {
  //   font-size  : 30px;
  //   line-height: 45px;
  // }

  // .banner-four__text br {
  //   display: none;
  // }

  // .banner-four__right {
  //   margin-top  : 50px;
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .banner-four {
  //   padding: 183px 0 55px;
  // }

  // .pricing-three__content {
  //   padding: 32px 20px 35px;
  // }

  // .faq-two__img {
  //   position: relative;
  //   top     : 0;
  //   left    : 0;
  // }

  // .faq-two__img img {
  //   width: 100%;
  // }

  // .comming-soon__content {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .comming-soon__title-box {
  //   margin-bottom: 20px;
  // }

  // .nfs-one__btn-box {
  //   flex-direction: column;
  // }

  // .nfs-one__btn-2 {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .why-choose-three__points {
  //   justify-content: inherit;
  //   flex-wrap      : wrap;
  // }

  // .why-choose-three__points li {
  //   flex     : 0 0 100%;
  //   max-width: 100%;
  //   width    : 100%;
  // }

  // .why-choose-three__ratting-and-text {
  //   padding       : 16px 20px 21px;
  //   flex-direction: column;
  // }

  // .why-choose-three__ratting-box {
  //   padding-right: 0;
  //   border-right : 0;
  //   margin-right : 0;
  //   margin-bottom: 20px;
  // }

  // .blog-three__user-and-date {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .blog-three__user {
  //   margin-bottom: 20px;
  // }

  // .contact-two__right {
  //   margin-left: 0;
  //   margin-top : 25px;
  // }

  // .contact-two__left {
  //   margin-right: 0;
  // }

  // .contact-two__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .contact-two__section-title__title br {
  //   display: none;
  // }

  // .contact-two__section-title__text br {
  //   display: none;
  // }

  // .contact-two__platform-list {
  //   flex-direction: column;
  // }

  // .contact-two__platform-list-1 {
  //   margin-right : 0;
  //   margin-bottom: 0;
  // }

  // .about-one__left {
  //   margin-right: 0;
  // }

  // .about-one__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .testimonial-four__carousel.owl-theme .owl-nav {
  //   display: none;
  // }

  // .page-banner-three__title {
  //   font-size  : 31px;
  //   line-height: 41px;
  // }

  // .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  //   margin-top : 60px;
  // }

  // .page-banner-three {
  //   padding-bottom: 120px;
  // }

  // .pricing-four__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-four__section-title__title br {
  //   display: none;
  // }

  // .pricing-four__section-title__text br {
  //   display: none;
  // }

  // .feature-two__left {
  //   margin-top: 0;
  // }

  // .feature-two__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .feature-two__title br {
  //   display: none;
  // }

  // .feature-two__right {
  //   margin-top: 50px;
  // }

  // .shared-hosting__left {
  //   margin-right: 0;
  // }

  // .shared-hosting__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .shared-hosting__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .web-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  //   margin-top : 60px;
  // }

  // .pricing-five__single {
  //   padding: 35px 10px 30px;
  // }

  // .pricing-five__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-five__section-title__title br {
  //   display: none;
  // }

  // .pricing-five__section-title__text br {
  //   display: none;
  // }

  // .get-faster__left {
  //   margin-right: 0;
  // }

  // .get-faster__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .faster-wp-hosting__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .faster-wp-hosting__section-title__title br {
  //   display: none;
  // }

  // .faster-wp-hosting__section-title__text br {
  //   display: none;
  // }

  // .feature-four__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .feature-four__section-title__title br {
  //   display: none;
  // }

  // .vps-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 0;
  // }

  // .pricing-six__single {
  //   padding: 47px 15px 35px;
  // }

  // .pricing-six__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-six__section-title__title br {
  //   display: none;
  // }

  // .pay-less__inner {
  //   margin: 0 0px;
  // }

  // .pay-less__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pay-less__section-title__title br {
  //   display: none;
  // }

  // .feature-three__single {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .feature-three__content {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .competitors__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .competitors__section-title__title br {
  //   display: none;
  // }

  // .database-two-top__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .database-two-top__title br {
  //   display: none;
  // }

  // .cloud-hosting-page-banner .page-banner-three__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 50px;
  // }

  // .data-center-three__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .data-center-three__section-title__title br {
  //   display: none;
  // }

  // .faster-one__right {
  //   margin-left: 0;
  // }

  // .get-started__left {
  //   margin-left: 0;
  // }

  // .get-started__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 40px;
  // }

  // .get-started {
  //   padding: 100px 0 100px;
  // }

  // .game-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .feature-seven__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .feature-seven__section-title__title br {
  //   display: none;
  // }

  // .popular-games__content {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .popular-games__price {
  //   margin-top: 20px;
  // }

  // .why-choose-four__left {
  //   margin-right: 0;
  // }

  // .why-choose-four__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .why-choose-four__points-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .why-choose-four__points--two {
  //   margin-left: 0;
  //   margin-top : 14px;
  // }

  // .testimonial-four .testimonial-one__client-info {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .testimonial-four .testimonial-one__client-content {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .game-server__left {
  //   margin-right: 0;
  // }

  // .game-server__right {
  //   margin-top : 40px;
  //   margin-left: 0;
  // }

  // .game-server__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .game-server__title br {
  //   display: none;
  // }

  // .game-server__points li .text h4 {
  //   font-size: 20px;
  // }

  // .get-access {
  //   padding: 109px 0 109px;
  // }

  // .get-access__left {
  //   margin-right: 0;
  // }

  // .get-access__img {
  //   display: none;
  // }

  // .get-access__btn-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .get-access__btn-box-content--two {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .get-access__btn-box-content {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .guides-one__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .guides-one__section-title__title br {
  //   display: none;
  // }

  // .reseller-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .pricing-eight__single {
  //   padding: 46px 10px 36px;
  // }

  // .pricing-eight__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-eight__section-title__title br {
  //   display: none;
  // }

  // .feature-five__section-title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .which-plan__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .which-plan__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .why-us-two__points-box {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .why-us-two__points--two {
  //   margin-top: 4px;
  // }

  // .why-us-two__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .comparision-three__inner {
  //   padding     : 120px 20px 100px;
  //   padding-left: 20px;
  // }

  // .comparision-three__progress-box:before,
  // .comparision-three__progress-box:after,
  // .comparision-three__progress-box-border {
  //   display: none;
  // }

  // .comparision-three__progress {
  //   margin-bottom : 30px;
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .comparision-three__progress-title {
  //   margin-right : 0;
  //   margin-bottom: 20px;
  // }

  // .comparision-three__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .comparision-three__section-title__title br,
  // .comparision-three__shape-1 {
  //   display: none;
  // }

  // .awarded-two__left {
  //   margin-right: 0;
  //   margin-top  : 0;
  // }

  // .awarded-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .awarded-two__list {
  //   flex-direction: column;
  // }

  // .dedicated-hosting-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .pricing-nine__points-box {
  //   flex-direction: column;
  // }

  // .pricing-nine__points-list-2 {
  //   margin-left: 0;
  //   margin-top : 8px;
  // }

  // .pricing-nine__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .pricing-nine__section-title__title br {
  //   display: none;
  // }

  // .pricing-nine__single {
  //   padding: 36px 20px 36px;
  // }

  // .extreme-performance__right {
  //   margin-left: 0;
  //   margin-top : 40px;
  // }

  // .extreme-performance__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .extreme-performance__title br {
  //   display: none;
  // }

  // .fully-loaded__right {
  //   margin-right: 0;
  //   margin-left : 0;
  //   margin-top  : 50px;
  // }

  // .fully-loaded__left {
  //   margin-top: 0;
  // }

  // .fully-loaded {
  //   padding: 109px 0 120px;
  // }

  // .fully-loaded__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .support-two__left {
  //   margin-right: 0;
  // }

  // .support-two__details {
  //   flex-direction: column;
  // }

  // .support-two__single:before {
  //   display: none;
  // }

  // .support-two__right {
  //   margin-left : 0;
  //   margin-right: 0;
  //   margin-top  : 30px;
  // }

  // .page-banner-four__left {
  //   margin-top: 0;
  // }

  // .page-banner-four__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .page-banner-four__title br {
  //   display: none;
  // }

  // .page-banner-four__list li {
  //   flex     : 0 0 100%;
  //   max-width: 100%;
  // }

  // .page-banner-four__list li+li {
  //   margin-left: 0;
  //   margin-top : 20px;
  // }

  // .page-banner-four__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .buy-domain__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .buy-domain__section-title__title br {
  //   display: none;
  // }

  // .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__content-box {
  //   margin-right: 0;
  // }

  // .feature-nine__single--two .feature-nine__content-box {
  //   margin-left: 0;
  // }

  // .feature-nine__single--two .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__single--three .feature-nine__img-box {
  //   margin-left : 0;
  //   margin-right: 0;
  // }

  // .feature-nine__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .feature-nine__section-title__title br {
  //   display: none;
  // }

  // .buy-domain-two__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .buy-domain-two__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }


  // .page-banner-four__text br {
  //   display: none;
  // }

  // .ssl-certificate-page-banner .page-banner-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .ssl-certificate__section-title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .ssl-certificate__section-title br {
  //   display: none;
  // }

  // .how-it-work-two__shape-1 {
  //   display: none;
  // }

  // .how-it-work-two__list li:nth-child(1) {
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(2) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(3) {
  //   padding-left : 0;
  //   padding-right: 0;
  // }

  // .how-it-work-two__list li:nth-child(4) {
  //   padding-left: 0px;
  // }

  // .how-it-work-two__list {
  //   flex-direction: column;
  // }

  // .how-it-work-two {
  //   padding: 109px 0 44px;
  // }

  // .why-us-three__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .why-us-three__left {
  //   margin-right: 0;
  // }

  // .why-us-three__right {
  //   margin-left: 0;
  //   margin-top : 50px;
  // }

  // .why-us-three {
  //   padding: 0 0 120px;
  // }

  // .https__single {
  //   padding: 38px 30px 36px;
  // }

  // .https__link img {
  //   width: 100%;
  // }

  // .sidebar {
  //   margin-top: 50px;
  // }

  // .sidebar__sale-box-content-box .countdown-timer li {
  //   display: block;
  //   margin : 0px auto 0px;
  //   height : 70px;
  //   width  : 70px;
  // }

  // .sidebar__sale-box-content-box .countdown-timer li+li {
  //   margin-top: 10px;
  // }

  // .sidebar__sale-box-content-box .countdown-timer li:first-child {
  //   margin-left: auto;
  // }

  // .sidebar__sale-box-content-box .countdown-timer li:last-child {
  //   margin-right: auto;
  // }

  // .sidebar__sale-box-text br {
  //   display: none;
  // }

  // .blog-single__user-and-date {
  //   flex-direction: column;
  //   align-items   : baseline;
  // }

  // .blog-single__date {
  //   margin-left: 0;
  //   margin-top : 10px;
  // }

  // .blog-single__quote-box {
  //   padding       : 58px 30px 52px;
  //   flex-direction: column;
  //   text-align    : center;
  // }

  // .blog-single__quote-text {
  //   margin-left: 0;
  // }

  // .blog-single__pagenation {
  //   flex-direction: column;
  // }

  // .blog-single__social-box {
  //   position     : relative;
  //   top          : 0;
  //   left         : 0;
  //   transform    : translateX(0%) translateY(0%);
  //   margin-bottom: 50px;
  // }

  // .blog-single__pagenation-left {
  //   margin-bottom: 30px;
  // }

  // .comment-one__single {
  //   flex-direction: column;
  // }

  // .comment-one__content {
  //   margin-left: 0;
  //   margin-top : 20px;
  //   padding    : 26px 20px 32px;
  // }

  // .comment-one__btn {
  //   position   : relative;
  //   display    : block;
  //   top        : 0;
  //   right      : 0;
  //   padding-top: 10px;
  // }

  // .comment-one__single-2 {
  //   margin-left: 0;
  // }

  // .sidebar__title-box::before,
  // .sidebar__title-box::after {
  //   display: none;
  // }

  // .ssl-solution__inner {
  //   flex-direction: column;
  // }

  // .ssl-solution__section-title__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .ssl-solution__section-title__title br {
  //   display: none;
  // }

  // .banner-one__left {
  //   margin-top: 0;
  // }

  // .banner-one__right {
  //   margin-top: 50px;
  // }

  // .banner-one__title {
  //   font-size  : 30px;
  //   line-height: 40px;
  // }

  // .banner-one__text br {
  //   display: none;
  // }

  // .banner-one {
  //   padding-top: 163px;
  // }
}