/***********************
* Banner Two
***********************/
.banner-two {
  position  : relative;
  display   : block;
  background: linear-gradient(189.06deg, #000739 10.6%, #1a2258 93.35%);
  padding   : 204px 0 260px;
  z-index   : 1;

  .banner-two__shape-1 {
    position           : absolute;
    top                : 70px;
    left               : 0;
    right              : 0;
    height             : 416px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .banner-two__shape-2 {
    position: absolute;
    bottom  : 0;
    right   : -30px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .banner-two__left {
    position  : relative;
    display   : block;
    margin-top: 179px;
  }

  .banner-two__sub-title-box {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .banner-two__sub-title-icon {
    position   : relative;
    display    : flex;
    align-items: center;

    span {
      font-size: 20px;
      color    : #00c14d;
      position : relative;
      display  : inline-block;
    }
  }

  .banner-two__sub-title {
    color      : var(--customVar-white);
    margin     : 0;
    margin-left: 10px;
  }

  .banner-two__title {
    font-weight  : 700;
    font-size    : 60px;
    line-height  : 76px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-top   : 17px;
    margin-bottom: 15px;
  }

  .banner-two__text {
    color        : var(--customVar-white);
    margin       : 0;
    line-height  : 32px;
    margin-bottom: 30px;

    .count-text {
      font-size  : 18px;
      font-weight: 700;
    }
  }

  .banner-two__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &:after {
      background-color: var(--customVar-white);
    }
  }

  .banner-two__right {
    position   : relative;
    display    : block;
    margin-left: 65px;
  }

  .banner-two__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/***********************
* Choose domain
***********************/
.choose-domain {
  position  : relative;
  display   : block;
  margin-top: -145px;
  z-index   : 3;

  .choose-domain__inner {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
  }

  .choose-domain__inner-top {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    border-bottom  : 1px solid rgba(221, 221, 221, 0.4);
    padding        : 42px 50px 42px;
    z-index        : 3;
  }

  .choose-domain__title {
    font-size  : 30px;
    font-weight: 700;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 40px;
  }

  .choose-domain__right {
    position : relative;
    display  : block;
    max-width: 730px;
    width    : 100%;
  }

  .choose-domain__form {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .choose-domain__input-box {
    position : relative;
    display  : block;
    max-width: 480px;
    width    : 100%;

    input[type="text"] {
      outline           : none;
      -webkit-appearance: none;
      -moz-appearance   : none;
      appearance        : none;
      background        : rgba(255, 255, 255, 1);
      border            : 1.5px solid #e9e9e9;
      border-radius     : 10px;
      display           : block;
      max-width         : 480px;
      width             : 100%;
      height            : 60px;
      color             : var(--customVar-text);
      font-size         : 16px;
      font-weight       : 400;
      padding-left      : 25px;
      padding-right     : 106px;
    }
  }

  .choose-domain__btn {
    padding    : 14px 50px;
    color      : var(--customVar-white);
    margin-left: 15px;

    &:before {
      background-color: var(--customVar-secondary);
    }
  }

  .choose-domain__name {
    display          : -webkit-inline-box;
    display          : -ms-inline-flexbox;
    display          : inline-flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    position         : absolute;
    top              : 50%;
    right            : 20px;
    transform        : translateY(-50%);
    border-left      : 1.5px solid #e9e9e9;
    padding-left     : 20px;
    height           : 30px;
  }

  .choose-domain__bottom {
    position      : relative;
    display       : block;
    padding-top   : 13px;
    padding-bottom: 9px;
    padding-left  : 295px;
    padding-right : 295px;
  }

  .choose-domain__list {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-wrap      : wrap;
    margin         : 0;

    li {
      position  : relative;
      flex      : 0 0 20%;
      max-width : 20%;
      width     : 100%;
      text-align: center;

      &:nth-child(2) .choose-domain__type {
        color: #00c14d;
      }

      &:nth-child(3) .choose-domain__type {
        color: #ff5a74;
      }

      &:nth-child(4) .choose-domain__type {
        color: #ffa800;
      }

      &:nth-child(5) .choose-domain__type {
        color: #586eff;
      }
    }
  }

  .choose-domain__type {
    font-size     : 20px;
    letter-spacing: -0.015em;
    color         : #0ea5ff;
    font-weight   : 700;
    line-height   : 20px;
    margin        : 0;
  }

  .choose-domain__price {
    margin        : 0;
    letter-spacing: -0.015em;
  }
}

/***********************
* Customar Rating
***********************/
.customar-rating {
  position        : relative;
  display         : block;
  padding         : 120px 0 120px;
  background-color: #fafbff;
  z-index         : 1;

  .customar-rating__left {
    position    : relative;
    display     : block;
    margin-left : 39px;
    margin-right: 39px;
  }

  .customar-rating__left-content {
    position  : relative;
    display   : block;
    max-width : 455px;
    width     : 100%;
    text-align: center;
    z-index   : 1;
  }

  .customar-rating__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .customar-rating__left-content-inner {
    position: relative;
    display : block;
    overflow: hidden;
    padding : 34px 0 45px;
    z-index : 1;
  }

  .customar-rating__star-1 {
    position: absolute;
    top     : 55px;
    left    : 116px;

    img {
      width: auto;
    }
  }

  .customar-rating__star-2 {
    position: absolute;
    top     : 46px;
    right   : 98px;

    img {
      width: auto;
    }
  }

  .customar-rating__star-3 {
    position: absolute;
    bottom  : 152px;
    left    : 50px;

    img {
      width: auto;
    }
  }

  .customar-rating__star-4 {
    position: absolute;
    bottom  : 65px;
    right   : 50px;

    img {
      width: auto;
    }
  }

  .customar-rating__icon {
    position: relative;
    display : block;

    span {
      position : relative;
      display  : inline-block;
      font-size: 60px;
      color    : var(--customVar-white);
    }
  }

  .customar-rating__best-number {
    font-size     : 40px;
    line-height   : 40px;
    letter-spacing: 0.02em;
    color         : var(--customVar-white);
    font-weight   : 700;
    margin        : 0;
    margin-top    : 3px;
    margin-bottom : 9px;
  }

  .customar-rating__curved-circle {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 55px;
    width          : 235px;
    margin         : 0 auto;

    .curved-circle {
      position      : absolute;
      font-size     : 30px;
      font-weight   : 800;
      color         : #fff;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      top           : 20px;
      left          : 0;
      right         : 0;
    }
  }

  .customar-rating__title-two {
    font-size     : 50px;
    color         : var(--customVar-white);
    font-weight   : 600;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    line-height   : 55px;
    margin        : 0;
    margin-top    : 40px;
    margin-bottom : 6px;
  }

  .customar-rating__year {
    font-size     : 45px;
    font-weight   : 800;
    letter-spacing: 0.02em;
    color         : #ffd60c;
    line-height   : 45px;
  }

  .customar-rating__right {
    position    : relative;
    display     : block;
    margin-left : 40px;
    margin-right: 40px;
  }

  .customar-rating__list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      flex-wrap      : wrap;
      border-bottom  : 1px solid rgba(221, 221, 221, 0.4);
      padding-bottom : 24px;
      margin-bottom  : 27px;

      &:last-child {
        border-bottom : 0;
        padding-bottom: 0;
        margin-bottom : 0;
      }
    }
  }

  .customar-rating__content {
    position: relative;
    display : block;
  }

  .customar-rating__logo {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .customar-rating__text {
    letter-spacing: -0.015em;
    color         : #000000;
    margin        : 0;
  }

  .customar-rating__rating-point-and-star {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .customar-rating__rating-point {
    font-size    : 30px;
    font-weight  : 600;
    color        : var(--customVar-black);
    line-height  : 30px;
    margin       : 0;
    margin-bottom: 13px;
  }

  .customar-rating__star {
    position   : relative;
    display    : flex;
    align-items: center;

    svg {
      font-size: 18px;
      color    : #ffa800;

      +svg {
        margin-left: 5px;
      }
    }
  }
}

/***********************
* Services One
***********************/
.services-one {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 108px 0 90px;
  z-index         : 1;

  .services-one__shape-1 {
    position: absolute;
    top     : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .services-one__shape-2 {
    position: absolute;
    bottom  : 0;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .services-one__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .services-one__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .services-one__section-title__text {
    font-size  : 16px;
    margin     : 0;
    line-height: 26px;
  }

  .services-one__single {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    border-radius   : 20px;
    padding         : 40px 40px 34px;
    margin-bottom   : 30px;
  }

  .services-one__tag {
    position       : absolute;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : #000d61;
    border-radius  : 0px 20px 0px 15px;
    max-width      : 140px;
    width          : 100%;
    top            : 0;
    right          : 0;
  }

  .services-one__tag-icon {
    position: relative;
    display : block;
    top     : -2px;

    img {
      width: auto;
    }
  }

  .services-one__tag-text {
    font-size     : 11px;
    margin        : 0;
    line-height   : 11px;
    color         : var(--customVar-white);
    font-weight   : 500;
    text-transform: uppercase;
    margin-left   : 5px;
  }

  .services-one__icon {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .services-one__title {
    font-size    : 22px;
    font-weight  : 600;
    line-height  : 32px;
    margin       : 0;
    margin-top   : 15px;
    margin-bottom: 10px;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-secondary);
      }
    }
  }

  .services-one__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }

  .services-one__bottom-text {
    font-weight: 600;
    color      : var(--customVar-secondary);
    margin     : 0;
    margin-top : 18px;
  }
}

/***********************
* Pricing Two
***********************/
.pricing-two {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 108px 0 120px;
  z-index   : 1;

  .pricing-two__shape-1 {
    position: absolute;
    left    : 0;
    top     : 180px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .pricing-two__shape-2 {
    position: absolute;
    top     : 0;
    right   : 186px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .pricing-two__shape-3 {
    position: absolute;
    bottom  : 0;
    right   : -30px;

    img {
      width: auto;
    }
  }

  .pricing-two__shape-4 {
    position: absolute;
    top     : 0;
    left    : 1074px;
    opacity : 0.2;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .pricing-two__shape-5 {
    position: absolute;
    top     : 0;
    right   : 0;
    opacity : 0.1;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .pricing-two__title {
    position     : relative;
    display      : block;
    text-align   : center;
    font-size    : 40px;
    font-weight  : 700;
    color        : var(--customVar-white);
    line-height  : 50px;
    margin       : 0;
    margin-bottom: 40px;
  }

  .pricing-two__inner {
    position: relative;
    display : block;

    .tab-buttons {
      position: relative;
      display : block;

      .tab-btn {
        position        : relative;
        display         : flex;
        align-items     : center;
        border          : 1.5px solid rgba(255, 255, 255, 0.3);
        border-radius   : 20px;
        background-color: transparent;
        cursor          : pointer;
        padding         : 31px 30px 29px;
        overflow        : hidden;
        transition      : all 0.3s ease;
        z-index         : 1;

        &:before {
          content      : "";
          position     : absolute;
          top          : 0;
          left         : 0;
          right        : 0;
          bottom       : 0;
          height       : 0%;
          background   : rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          transition   : all 0.3s ease;
          z-index      : -1;
        }

        &.active-btn:before {
          height: 100%;
        }

        &+.tab-btn {
          margin-top: 10px;
        }

        .icon {
          position   : relative;
          display    : flex;
          align-items: center;

          span {
            position : relative;
            display  : inline-block;
            font-size: 45px;
            color    : var(--customVar-white);
          }
        }

        .content {
          position   : relative;
          display    : block;
          margin-left: 30px;

          h3 {
            font-size    : 22px;
            font-weight  : 600;
            color        : var(--customVar-white);
            margin       : 0;
            margin-bottom: 10px;
          }

          p {
            font-size     : 16px;
            line-height   : 26px;
            letter-spacing: -0.015em;
            color         : var(--customVar-white);
            margin        : 0;
          }
        }
      }
    }

    .tabs-content {
      position: relative;
      display : block;

      .tab {
        visibility: hidden;
        position  : absolute;
        opacity   : 0;
        @include transformMixin(translateY(35px));
        @include transitionMixin(transform 600ms ease);
        z-index: 10;

        &.active-tab {
          visibility: visible;
          opacity   : 1;
          @include transformMixin(translateY(0px));
        }
      }
    }
  }

  .pricing-two__tab-buttons-box {
    position    : relative;
    display     : block;
    margin-right: -25px;
    margin-top  : 55px;
  }

  .pricing-two__tabs-content-box {
    position   : relative;
    display    : block;
    margin-left: -5px;
  }

  .pricing-two__inner-content {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    border-radius   : 20px;
    padding         : 38px 50px 35px;
  }

  .pricing-two__inner-content-top {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin-bottom  : 19px;
  }

  .pricing-two__inner-content-top-left {
    position: relative;
    display : block;

    p {
      font-size    : 13px;
      line-height  : 13px;
      font-weight  : 600;
      color        : #ff756f;
      margin       : 0;
      background   : #ffebea;
      border       : 1.5px solid #ffd3d2;
      border-radius: 15px;
      position     : relative;
      display      : inline-block;
      padding      : 7.5px 11px;
      margin-bottom: 3px;
    }

    h3 {
      font-size  : 22px;
      font-weight: 600;
      line-height: 32px;
      margin     : 0;
      color      : var(--customVar-black);
    }
  }

  .pricing-two__price {
    font-size      : 60px;
    font-weight    : 800;
    color          : var(--customVar-black);
    line-height    : 60px;
    margin         : 0;
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .pricing-two__price-point {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    top        : 11px;
  }

  .pricing-two__price-validity {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    font-weight: 500;
    top        : 11px;
  }

  .pricing-two__renew-box {
    position      : relative;
    display       : block;
    border-bottom : 1px dashed #d9d9d9;
    padding-top   : 17px;
    padding-bottom: 6px;
    margin-bottom : 30px;

    p {
      font-size     : 16px;
      line-height   : 20px;
      text-align    : center;
      letter-spacing: -0.015em;
      color         : #2959eb;
      font-family   : var(--customVar-title-font);
    }
  }

  .pricing-two__btn {
    width          : 100%;
    text-align     : center;
    justify-content: center;
    color          : var(--customVar-white);

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &:after {
      background-color: var(--customVar-secondary);
    }
  }

  .pricing-two__points-box {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .pricing-two__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;
      }

      .icon i {
        font-size: 22px;
        color    : #00c14d;
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }

  .pricing-two__points--two {
    margin-left: 110px;
  }
}

/***********************
* Max Performance
***********************/
.max-performance {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 0 0 120px;
  z-index         : 1;

  .max-performance__left {
    position    : relative;
    display     : block;
    margin-right: 125px;
  }

  .max-performance__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .max-performance__right {
    position  : relative;
    display   : block;
    margin-top: 16px;
  }

  .max-performance__title-top {
    position   : relative;
    display    : block;
    font-size  : 40px;
    color      : var(--customVar-black);
    font-weight: 700;
    line-height: 50px;
    margin     : 0;
  }

  .max-performance__list {
    position     : relative;
    display      : block;
    margin       : 0;
    margin-top   : 47px;
    margin-bottom: 45px;

    li {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      flex-wrap      : wrap;
      border-bottom  : 1px solid #dfdfdf;
      padding-bottom : 26px;

      +li {
        margin-top: 17px;
      }

      &:last-child {
        border-bottom : 0;
        padding-bottom: 0;
      }

      &:hover .max-performance__icon span {
        transform: scale(0.9);
      }
    }
  }

  .max-performance__list-left {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .max-performance__icon {
    position   : relative;
    display    : flex;
    align-items: center;

    span {
      position                : relative;
      display                 : inline-block;
      font-size               : 45px;
      background              : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip : text;
      -moz-background-clip    : text;
      background-clip         : text;
      -webkit-text-fill-color : transparent;
      -webkit-transition      : all 500ms linear;
      transition              : all 500ms linear;
      -webkit-transition-delay: 0.1s;
      transition-delay        : 0.1s;
    }
  }

  .max-performance__title {
    font-size  : 22px;
    color      : var(--customVar-black);
    line-height: 26px;
    font-weight: 600;
    margin     : 0;
    margin-left: 25px;
  }

  .max-performance__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }

  .max-performance__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &:after {
      background-color: var(--customVar-black);
    }
  }
}

/***********************
* Testimonial Two
***********************/
.testimonial-two {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 108px 0 120px;
  z-index         : 1;

  .testimonial-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .testimonial-two__top-title {
    position     : relative;
    display      : block;
    text-align   : center;
    font-size    : 40px;
    line-height  : 50px;
    font-weight  : 700;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 40px;
  }

  .testimonial-two__top {
    position: relative;
    display : block;
  }

  .testimonial-two__carousel-one {
    position: relative;
    display : block;
  }

  .testimonial-two__carousel-two {
    margin-top: 25px;
  }

  .testimonial-two__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding      : 40px 35px 40px;
    margin-bottom: 30px;
  }

  .testimonial-two__quote {
    position     : relative;
    display      : flex;
    align-items  : center;
    margin-bottom: 32px;

    span {
      color    : #111b5d;
      font-size: 40px;
    }
  }

  .testimonial-two__quote-text {
    font-size    : 20px;
    font-weight  : 600;
    color        : var(--customVar-black);
    line-height  : 20px;
    margin       : 0;
    margin-bottom: 13px;
  }

  .testimonial-two__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }

  .testimonial-two__client-info {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin-top     : 95px;
  }

  .testimonial-two__client-content {
    position: relative;
    display : block;
  }

  .testimonial-two__client-name {
    font-size  : 20px;
    font-weight: 600;
    line-height: 20px;
    color      : var(--customVar-black);
    margin     : 0;
  }

  .testimonial-two__client-sub-title {
    font-size    : 15px;
    color        : var(--customVar-secondary);
    line-height  : 20px;
    margin       : 0;
    margin-top   : 6px;
    margin-bottom: 6px;
  }

  .testimonial-two__rating-star {
    position   : relative;
    display    : flex;
    align-items: center;

    i {
      color    : #ffa800;
      font-size: 15px;

      +i {
        margin-left: 4px;
      }
    }
  }

  .testimonial-two__client-img {
    position     : relative;
    display      : block;
    width        : 70px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }
}

/***********************
* Support One
***********************/
.support-one {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 134px 0 135px;
  overflow  : hidden;
  z-index   : 1;

  .support-one__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .support-one__img {
    position: absolute;
    bottom  : 0;
    right   : 330px;

    img {
      width: auto;
    }
  }

  .support-one__left {
    position: relative;
    display : block;
  }

  .support-one__title {
    font-size  : 40px;
    font-weight: 700;
    line-height: 50px;
    color      : var(--customVar-white);
    margin     : 0;
  }

  .support-one__text {
    letter-spacing: -0.015em;
    line-height   : 26px;
    color         : var(--customVar-white);
    margin        : 0;
    margin-top    : 25px;
    margin-bottom : 31px;
  }

  .support-one__details {
    position     : relative;
    display      : flex;
    align-items  : center;
    flex-wrap    : wrap;
    background   : #ffffff;
    box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin       : 0;

    li {
      position  : relative;
      flex      : 0 0 33.333333%;
      max-width : 33.333333%;
      width     : 100%;
      text-align: center;
    }
  }

  .support-one__single {
    position: relative;
    display : block;
    padding : 40px 0 38px;

    &:before {
      content         : "";
      position        : absolute;
      top             : 42px;
      bottom          : 42px;
      left            : 0;
      width           : 1px;
      background-color: #dbdbdb;

      .support-one__details li:first-child & {
        display: none;
      }
    }
  }

  .support-one__icon {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 16px;

    span {
      position               : relative;
      display                : inline-block;
      font-size              : 38px;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }

  .support-one__text-2 {
    font-size     : 18px;
    font-weight   : 500;
    color         : var(--customVar-black);
    line-height   : 18px;
    margin        : 0;
    letter-spacing: -0.015em;
  }
}

/***********************
* Faq one
***********************/
.faq-one {
  padding-top   : 110px;
  padding-bottom: 110px;

  .faq-one__top__title {
    margin     : 0;
    font-size  : 30px;
    color      : var(--customVar-black, #121212);
    font-weight: bold;
  }

  .faq-one__top__text {
    margin     : 0;
    font-size  : 16px;
    line-height: 1.7777777777777777em;
  }

  .faq-one__tab__btns {
    margin-top      : 34px;
    margin-bottom   : 40px;
    display         : -webkit-inline-box;
    display         : -ms-inline-flexbox;
    display         : inline-flex;
    border          : 2px solid #e9eaf2;
    border-radius   : 10px;
    padding         : 5px;
    -webkit-box-pack: center;
    -ms-flex-pack   : center;
    justify-content : center;

    .nav-link {
      border       : none;
      color        : var(--customVar-black, #121212);
      font-size    : 16px;
      font-weight  : 600;
      padding      : 9px 25px;
      border-radius: 10px;

      &.active {
        background-color: var(--customVar-secondary, #3852ff);
        color           : #fff;
      }
    }
  }

  .accordion {

    // .faq-one__accordion__item {
    .accordion-item {
      background-color: #fff;
      border          : 0;
      border-radius   : 20px;
      padding-top     : 28px;
      padding-bottom  : 28px;

      .accordion-button {
        font-size  : 18px;
        font-weight: bold;

        &:focus {
          -webkit-box-shadow: none;
          box-shadow        : none;
        }

        &::before {
          content         : '';
          width           : 6px;
          height          : 6px;
          background-color: currentColor;
          border-radius   : 50%;
          margin-right    : 13px;
        }

        &::after {
          color           : var(--customVar-secondary, #3852ff);
          font-size       : 30px;
          font-weight     : lighter;
          content         : "+";
          width           : 35px;
          height          : 35px;
          background-color: #E0E4FF;
          background-image: none;
          border-radius   : 50%;
          transform       : none;
          display         : flex;
          align-items     : center;
          justify-content : center;
        }
      }

      &.accordion--active {
        -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
        box-shadow        : 0px 20px 20px rgba(0, 0, 0, 0.05);

        .accordion-button {
          color: var(--customVar-secondary, #3852ff);

          &::after {
            content: "-";
          }

          &:not(.collapsed) {
            background-color: var(--bs-accordion-btn-bg);
            ;
          }
        }
      }

      +.accordion-item {
        margin-top: 15px;
      }
    }

    .accordion-body {
      padding    : 15px 30px 0px 46px;
      font-size  : 16px;
      line-height: 160%;
    }
  }

  .faq-one__bottom {
    color     : var(--customVar-text, #666666);
    font-size : 16px;
    margin-top: 36px;

    a {
      margin-left       : 10px;
      color             : #1061ff;
      display           : -webkit-inline-box;
      display           : -ms-inline-flexbox;
      display           : inline-flex;
      -webkit-box-align : center;
      -ms-flex-align    : center;
      align-items       : center;
      line-height       : 1em;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;
      font-weight       : 600;
      font-family       : var(--customVar-title-font, "Outfit", sans-serif);

      &:hover {
        color: var(--customVar-primary, #ff5a74);
      }

      span {
        display           : inline-block;
        padding-bottom    : 2px;
        background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
        background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;

        &:hover {
          background-size: 100% 1px;
        }
      }

      i {
        font-size  : 12px;
        margin-left: 7px;
      }
    }
  }
}


@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .banner-two {
    .banner-two__title {
      font-size  : 50px;
      line-height: 60px;
    }
  }

  .pricing-two {
    .pricing-two__points--two {
      margin-left: 30px;
    }
  }

  .support-one {
    .support-one__img {
      right: 0;
    }

    .support-one__title {
      font-size: 38px;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .banner-two {
    .banner-two__right {
      margin-left: 0;
    }

    .banner-two__title {
      font-size  : 40px;
      line-height: 50px;
    }
  }

  .choose-domain {
    .choose-domain__right {
      margin-top: 20px;
    }

    .choose-domain__bottom {
      padding-left : 50px;
      padding-right: 50px;
    }
  }

  .customar-rating {
    .customar-rating__right {
      margin-top: 50px;
    }
  }

  .max-performance {
    .max-performance__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .max-performance__right {
      max-width: 600px;
      margin   : 40px auto 0;
    }
  }

  .testimonial-two {
    .testimonial-two__client-info {
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-two__client-content {
      margin-bottom: 20px;
    }
  }

  .support-one {
    .support-one__img {
      display: none;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .banner-two {
    padding: 193px 0 260px;

    .banner-two__left {
      margin-top: 0;
    }

    .banner-two__right {
      margin-left: 0;
    }
  }

  .choose-domain {
    .choose-domain__right {
      margin-top: 20px;
    }

    .choose-domain__btn {
      margin-left: 0;
      margin-top : 20px;
    }

    .choose-domain__bottom {
      padding-left : 50px;
      padding-right: 50px;
    }
  }

  .customar-rating {
    .customar-rating__right {
      margin-top: 50px;
    }
  }

  .pricing-two {
    .pricing-two__points--two {
      margin-left: 64px;
    }
  }

  .max-performance {
    .max-performance__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .max-performance__right {
      max-width: 600px;
      margin   : 40px auto 0;
    }
  }

  .support-one {
    .support-one__img {
      display: none;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .banner-two {
    padding: 192px 0 260px;

    .banner-two__left {
      margin-top: 0;
    }

    .banner-two__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .banner-two__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .choose-domain {
    .choose-domain__inner-top {
      padding: 42px 20px 42px;
    }

    .choose-domain__right {
      margin-top: 20px;
    }

    .choose-domain__btn {
      margin-left: 0;
      margin-top : 20px;
    }

    .choose-domain__bottom {
      padding-left : 20px;
      padding-right: 20px;
    }

    .choose-domain__list li {
      flex     : 0 0 100%;
      max-width: 100%;

      +li {
        margin-top: 20px;
      }
    }
  }

  .customar-rating {
    .customar-rating__left {
      margin-left : 0;
      margin-right: 0;
    }

    .customar-rating__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 50px;
    }

    .customar-rating__list li {
      flex-direction: column;
      align-items   : baseline;
    }

    .customar-rating__rating-point-and-star {
      margin-top: 20px;
    }
  }

  .services-one {
    .services-one__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .services-one__section-title__text br {
      display: none;
    }

    .services-one__shape-1 {
      display: none;
    }
  }

  .pricing-two {
    .pricing-two__tab-buttons-box {
      margin-right: 0;
    }

    .pricing-two__inner-content {
      padding: 38px 20px 35px;
    }

    .pricing-two__points--two {
      margin-left: 0;
      margin-top : 8px;
    }
  }

  .max-performance {
    .max-performance__left {
      margin-right: 0;
    }

    .max-performance__right {
      margin-top: 40px;
    }

    .max-performance__list li {
      flex-direction: column;
      align-items   : baseline;
    }

    .max-performance__list-left {
      margin-bottom: 15px;
    }
  }

  .testimonial-two {
    .testimonial-two__client-info {
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-two__client-content {
      margin-bottom: 20px;
    }

    .testimonial-two__top-title {
      font-size  : 25px;
      line-height: 35px;
    }
  }

  .support-one {
    .support-one__img {
      display: none;
    }

    .support-one__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .support-one__details {
      flex-direction: column;
    }

    .support-one__single:before {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .faq-one {
    .faq-one__top__title {
      font-size: 32px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .faq-one {
    .container {
      max-width: 905px;
    }

    .faq-one__top__title {
      font-size: 38px;
    }

    .faq-one__top__text {
      font-size: 18px;
    }

    .accordion {
      .accordion-item {
        .accordion-button {
          font-size: 20px;
        }
      }
    }

    .faq-one__bottom {
      font-size: 18px;
    }
  }
}