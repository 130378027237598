.page-banner-two {
  background-image   : url(../../../images/template/background/page-header-1-2-bg.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  text-align         : center;
  padding-top        : 117px;
  padding-bottom     : 117px;

  &.page-banner-two--has-bottom-padding {
    padding-bottom: 250px;
  }

  .page-banner-two__title {
    margin    : 0;
    font-size : 35px;
    color     : #fff;
    margin-top: 8px;
  }

  .thm-breadcrumb {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin           : 0;

    li {
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      color            : #fff;
      font-size        : 14px;

      &:not(:last-child)::after {
        content     : "/";
        margin-left : 6px;
        margin-right: 6px;
      }
    }

    a {
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base, #ffa065);
      }
    }

    a,
    span {
      color: inherit;
    }
  }
}

/***********************
* About One
***********************/
.about-one {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 0;

  .about-one__left {
    position    : relative;
    display     : block;
    margin-right: 89px;
  }

  .about-one__title {
    font-weight  : 700;
    font-size    : 38px;
    line-height  : 48px;
    color        : #121212;
    margin       : 0;
    margin-bottom: 30px;
  }

  .about-one__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .about-one__right {
    position   : relative;
    display    : block;
    margin-left: 55px;
  }

  .about-one__text-1 {
    font-size    : 18px;
    line-height  : 32px;
    margin       : 0;
    margin-bottom: 20px;
  }

  .about-one__text-2 {
    font-size  : 18px;
    line-height: 32px;
    margin     : 0;
  }
}

/***********************
* About Page Img Box
***********************/
.about-page-img-box {
  position: relative;
  display : block;

  .about-page-img-box__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/***********************
* Testimonial Four
***********************/
.testimonial-four {
  position: relative;
  display : block;
  padding : 109px 0 120px;
  z-index : 1;

  .testimonial-four__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .testimonial-four__inner {
    position: relative;
    display : block;
  }

  .testimonial-four__title {
    position     : relative;
    display      : block;
    text-align   : center;
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    text-align   : center;
    color        : #121212;
    margin-bottom: 30px;
  }

  .testimonial-four__carousel {
    position: relative;
    display : block;
  }
}



@media (min-width: $breakpoint-lg) {
  .page-banner-two {
    padding-top: 178px;

    .page-banner-two__title {
      font-size: 46px;
    }
  }
}

@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .about-one {
    .about-one__left {
      margin-right: 0;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .about-one {
    .about-one__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .testimonial-four {
    .testimonial-one__client-info {
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-one__client-content {
      margin-left: 0;
      margin-top : 10px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .about-one {
    .about-one__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .testimonial-four {
    .testimonial-one__client-info {
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-one__client-content {
      margin-left: 0;
      margin-top : 10px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .about-one {
    .about-one__left {
      margin-right: 0;
    }

    .about-one__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .testimonial-four {
    .testimonial-one__client-info {
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-one__client-content {
      margin-left: 0;
      margin-top : 10px;
    }
  }
}