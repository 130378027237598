/***********************
* Client Logo
***********************/
.client-logo {
  position: relative;
  display : block;
  padding : 74px 0 71px;

  .client-logo__list {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    margin     : 0;

    li {
      position : relative;
      flex     : 0 0 25%;
      max-width: 25%;
      width    : 100%;
    }
  }

  .client-logo__single {
    position: relative;
    display : block;
  }

  .client-logo__img {
    position     : relative;
    display      : block;
    margin-bottom: 20px;

    img {
      width: auto;
    }
  }

  .client-logo__rating-box {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
  }

  .client-logo__rate {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;

    svg {
      font-size: 24px;
      color    : #ffa801;

      +svg {
        margin-left: 10px;
      }
    }
  }

  .client-logo__point {
    margin     : 0;
    font-size  : 22px;
    color      : var(--customVar-black);
    font-weight: 600;
    margin-left: 10px;
  }
}

/***********************
* Pricing One
***********************/
.pricing-one {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  overflow        : hidden;
  padding         : 108px 0 90px;
  z-index         : 1;

  .pricing-one__shape-1 {
    position: absolute;
    top     : 50px;
    right   : 485px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-2 {
    position      : absolute;
    top           : 0;
    right         : 0;
    mix-blend-mode: multiply;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-3 {
    position: absolute;
    top     : 0;
    right   : 0;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-4 {
    position: absolute;
    top     : 0;
    right   : 288px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-5 {
    position: absolute;
    top     : 0;
    right   : 270px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-6 {
    position: absolute;
    top     : 30px;
    right   : 0px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-7 {
    position: absolute;
    top     : 120px;
    right   : 290px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-8 {
    position: absolute;
    top     : 285px;
    right   : 45px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-9 {
    position: absolute;
    top     : 185px;
    right   : 425px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-10 {
    position: absolute;
    top     : 295px;
    right   : 275px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-11 {
    position: absolute;
    bottom  : 350px;
    left    : 0px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-12 {
    position: absolute;
    bottom  : 450px;
    left    : 30px;
    z-index : 2;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-13 {
    position: absolute;
    bottom  : 100px;
    left    : 465px;
    z-index : 1;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-14 {
    position: absolute;
    bottom  : 375px;
    left    : 210px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-15 {
    position: absolute;
    bottom  : 0px;
    left    : 0px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-16 {
    position: absolute;
    bottom  : 82px;
    left    : 0px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-17 {
    position: absolute;
    bottom  : 100px;
    left    : 0px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-18 {
    position: absolute;
    bottom  : 100px;
    left    : 77px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-19 {
    position: absolute;
    bottom  : 0px;
    left    : 40px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-20 {
    position: absolute;
    bottom  : 0px;
    left    : 167px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-21 {
    position: absolute;
    bottom  : 185px;
    left    : 290px;

    img {
      width: auto;
    }
  }

  .pricing-one__shape-22 {
    position: absolute;
    top     : 116px;
    right   : 30px;
    z-index : 2;

    img {
      width: auto;
    }
  }

  .pricing-one__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-one__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-one__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;

    li {
      a {
        font-size     : 18px;
        font-weight   : 500;
        color         : var(--customVar-black);
        padding-left  : 10px;
        padding-right : 10px;
        display       : block;
        letter-spacing: 0.01em;
      }

      &.active a {
        color: var(--customVar-secondary);
      }
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;

    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    // &.off .slider:before {
    //   -webkit-transform: translateX(20px) translateY(-50%);
    //   transform        : translateX(20px) translateY(-50%);
    // }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content>div {
    // position  : absolute;
    position  : relative;
    height    : 0px;
    visibility: hidden;
    opacity   : 0;
    @include transformMixin(translateY(35px));
    @include transitionMixin(transform 600ms ease);

    &.active {
      height    : auto;
      visibility: visible;
      opacity   : 1;
      @include transformMixin(translateY(0px));
    }
  }

  .pricing-one__single {
    position          : relative;
    display           : block;
    background-color  : var(--customVar-white);
    padding           : 48px 30px 40px;
    border-radius     : 20px;
    margin-bottom     : 30px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
    z-index           : 1;

    &:hover {
      box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
    }
  }

  .pricing-one__price-box {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .pricing-one__price {
    font-size      : 50px;
    font-weight    : 800;
    color          : var(--customVar-black);
    line-height    : 50px;
    margin         : 0;
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .pricing-one__price-point {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    top        : 11px;
  }

  .pricing-one__price-validity {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    font-weight: 500;
    top        : 11px;
  }

  .pricing-one__price-renew {
    color        : #2959eb;
    font-weight  : 400;
    line-height  : 20px;
    margin       : 0;
    margin-top   : 10px;
    margin-bottom: 9px;
  }

  .pricing-one__title {
    font-size  : 26px;
    font-weight: 600;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 36px;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-secondary);
      }
    }
  }

  .pricing-one__img {
    position     : relative;
    display      : block;
    text-align   : center;
    margin-top   : 26px;
    margin-bottom: 26px;

    img {
      width: auto;
    }
  }

  .pricing-one__btn-box {
    position: relative;
    display : block;
  }

  .pricing-one__btn {
    width          : 100%;
    text-align     : center;
    justify-content: center;
    color          : var(--customVar-white);

    &:hover {
      -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
      box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
    }

    &:before {
      background-color: var(--customVar-secondary);
    }

    &:after {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .pricing-one__points {
    position  : relative;
    display   : block;
    margin-top: 35px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }
}

/***********************
* Feature One
***********************/
.feature-one {
  position        : relative;
  display         : block;
  padding         : 108px 0 90px;
  background-color: #fafbff;

  .feature-one__sec-title {
    position     : relative;
    display      : block;
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 40px;
    text-align   : center;
  }

  .feature-one__list {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;

    li {
      position     : relative;
      flex         : 0 0 25%;
      max-width    : 25%;
      width        : 100%;
      margin-bottom: 3px;

      &:nth-child(1) {
        padding-right: 75px;
      }

      &:nth-child(2) {
        padding-right: 50px;
        padding-left : 20px;

        .feature-one__title {
          margin-top: -17px;
        }
      }

      &:nth-child(3) {
        padding-left : 40px;
        padding-right: 40px;

        .feature-one__title {
          margin-top: -12px;
        }
      }

      &:nth-child(4) {
        padding-left: 50px;
      }
    }
  }

  .feature-one__single {
    position: relative;
    display : block;
  }

  .feature-one__icon {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .feature-one__title {
    position     : relative;
    display      : block;
    font-size    : 22px;
    font-weight  : 600;
    line-height  : 32px;
    margin       : 0;
    margin-bottom: 7px;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-secondary);
      }
    }
  }

  .feature-one__text {
    letter-spacing: -0.015em;
    margin        : 0;
  }
}

/***********************
* Robust One
***********************/
.robust-one {
  position  : relative;
  display   : block;
  background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 63px 0 120px;
  overflow  : hidden;
  z-index   : 1;

  .robust-one__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .robust-one__left {
    position    : relative;
    display     : block;
    margin-top  : 65px;
    margin-right: -26px;
  }

  .robust-one__title {
    font-size    : 40px;
    color        : var(--customVar-white);
    font-weight  : 700;
    line-height  : 50px;
    margin       : 0;
    margin-bottom: 30px;
  }

  .robust-one__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 5px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        span {
          font-size: 20px;
          color    : var(--customVar-white);
        }
      }

      .text {
        margin-left: 20px;

        p {
          color         : var(--customVar-white);
          letter-spacing: -0.015em;
        }
      }
    }
  }

  .robust-one__counter-box {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    margin     : 0;
    margin-top : 25px;

    li {
      position     : relative;
      display      : block;
      background   : #ffffff;
      box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding      : 40px 30px 30px;
      max-width    : 260px;
      width        : 100%;

      +li {
        margin-left: 20px;
      }
    }
  }

  .robust-one__counter-count-box {
    position   : relative;
    display    : flex;
    align-items: center;

    h3 {
      font-size     : 40px;
      color         : var(--customVar-black);
      letter-spacing: -0.035em;
      font-weight   : 700;
      margin        : 0;
      line-height   : 40px;
    }

    i {
      position               : relative;
      display                : inline-block;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      transform              : rotate(-90deg);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      font-size              : 15px;
      padding-top            : 5px;
    }
  }

  .robust-one__counter-text {
    font-size     : 40px;
    color         : var(--customVar-black);
    letter-spacing: -0.035em;
    font-weight   : 700;
    margin        : 0;
    line-height   : 40px;
  }

  .robust-one__counter-text-2 {
    margin        : 0;
    color         : var(--customVar-black);
    letter-spacing: -0.015em;
    margin-top    : 7px;
  }

  .robust-one__right {
    position    : relative;
    display     : block;
    margin-left : 67px;
    margin-right: -11px;
  }

  .robust-one__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/***********************
* Data Center Two
***********************/
.data-center-two {
  position: relative;
  display : block;
  padding : 95px 0 0;

  .data-center-two__top {
    position : relative;
    display  : block;
    max-width: 1100px;
    width    : 100%;
    margin   : 0 auto;
  }

  .data-center-two__top-title {
    position     : relative;
    display      : block;
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    text-align   : center;
    margin       : 0;
    margin-bottom: 50px;
  }

  .data-center-two__map {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .data-center-two__map-markar {
    position        : absolute;
    top             : 179px;
    left            : 117px;
    border-radius   : 50%;
    background-color: rgba(25, 213, 100, 0.38);
    z-index         : 5;
    height          : 30px;
    width           : 30px;
    cursor          : pointer;

    &:before {
      position        : absolute;
      top             : 5px;
      bottom          : 5px;
      right           : 5px;
      left            : 5px;
      background-color: #19cf62;
      content         : "";
      border-radius   : 50%;
    }
  }

  .data-center-two__popup-box {
    position                  : absolute;
    top                       : 0px;
    left                      : 0px;
    z-index                   : 5;
    opacity                   : 1;
    transform                 : perspective(400px) rotateX(90deg) translateY(10px);
    transform-origin          : bottom;
    transition-delay          : 0.1s;
    transition-timing-function: ease-in-out;
    transition-duration       : 0.5s;
    transition-property       : all;
  }

  .data-center-two__popup {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    box-shadow      : 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
    max-width       : 192px;
    width           : 100%;
    padding         : 5px;

    &:before {
      content      : "";
      position     : absolute;
      bottom       : -15px;
      right        : 68px;
      border-bottom: 15px solid transparent;
      border-right : 15px solid var(--customVar-white);
    }
  }

  .data-center-two__popup-inner {
    position        : relative;
    display         : block;
    background-color: var(--customVar-base);
    padding         : 17px 17px 18px;

    &:before {
      content      : "";
      position     : absolute;
      bottom       : -15px;
      right        : 58px;
      border-bottom: 15px solid transparent;
      border-right : 15px solid var(--customVar-base);
    }
  }

  .data-center-two__popup-title {
    font-size  : 16px;
    font-weight: 700;
    color      : var(--customVar-white);
    line-height: 18px;
  }

  .data-center-two__popup-text {
    font-size  : 12px;
    color      : var(--customVar-white);
    line-height: 20px;
    padding-top: 7px;
  }

  .data-center-two__popup-km {
    font-size     : 12px;
    font-weight   : 700;
    color         : var(--customVar-white);
    text-transform: uppercase;
    line-height   : 12px;
    display       : block;
    text-align    : right;
    margin-top    : -3px;
  }

  .data-center-two__location-1 {
    position: absolute;
    width   : 192px;
    height  : 155px;
    top     : -8%;
    left    : 2%;

    &:hover .data-center-two__popup-box {
      opacity                   : 1;
      transform                 : perspective(400px) rotateX(0deg) translateY(0);
      transition-delay          : 0.3s;
      transition-timing-function: ease-in-out;
      transition-duration       : 0.5s;
      transition-property       : all;
    }
  }

  .data-center-two__location-2 {
    left: 15%;
    top : 0%;
  }

  .data-center-two__map-markar-2 {
    left: 120px;
    top : 172px;
  }

  .data-center-two__location-3 {
    left  : 17%;
    top   : auto;
    bottom: 43%;
  }

  .data-center-two__map-markar-3 {
    left : auto;
    right: 40px;
    top  : 175px;
  }

  .data-center-two__location-4 {
    left  : 31%;
    top   : auto;
    bottom: 48%;
  }

  .data-center-two__map-markar-4 {
    left : auto;
    right: 35px;
    top  : 177px;
  }

  .data-center-two__location-5 {
    left: 42%;
    top : 0%;
  }

  .data-center-two__map-markar-5 {
    left : auto;
    right: 35px;
    top  : 177px;
  }

  .data-center-two__location-6 {
    left : auto;
    right: 18%;
    top  : -8%;
  }

  .data-center-two__map-markar-6 {
    left : auto;
    right: 35px;
    top  : 177px;
  }

  .data-center-two__location-7 {
    left  : auto;
    top   : auto;
    right : 28%;
    bottom: 52%;
  }

  .data-center-two__map-markar-7 {
    left : auto;
    right: 35px;
    top  : 177px;
  }

  .data-center-two__location-8 {
    left  : auto;
    top   : auto;
    right : 12%;
    bottom: 27%;
  }

  .data-center-two__bottom {
    position: relative;
    display : block;
    padding : 50px 0 100px;

    .row {
      --bs-gutter-x: 20px;
    }
  }

  .data-center-two__single {
    position     : relative;
    display      : flex;
    align-items  : center;
    background   : #ffffff;
    box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 20px;
    padding      : 30px 30px 30px;
  }

  .data-center-two__flag {
    position     : relative;
    display      : block;
    width        : 40px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .data-center-two__country-name {
    font-size  : 22px;
    font-weight: 600;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 32px;
    margin-left: 20px;
  }
}

/***********************
* Why Choose One
***********************/
.why-choose-one {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 120px 0 93px;
  z-index         : 1;

  .why-choose-one__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    mix-blend-mode     : multiply;
    opacity            : 0.08;
    z-index            : -1;
  }

  .why-choose-one__left {
    position    : relative;
    display     : block;
    margin-right: 14px;
  }

  .why-choose-one__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .why-choose-one__right {
    position   : relative;
    display    : block;
    margin-top : 12px;
    margin-left: 70px;
  }

  .why-choose-one__title {
    font-size    : 40px;
    font-weight  : 700;
    color        : var(--customVar-black);
    line-height  : 50px;
    margin       : 0;
    margin-bottom: 25px;
  }

  .why-choose-one__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position     : relative;
      display      : flex;
      align-items  : center;
      background   : #ffffff;
      box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding      : 25px 48px 26px;

      +li {
        margin-top: 15px;
      }
    }
  }

  .why-choose-one__icon {
    position   : relative;
    display    : flex;
    align-items: center;

    &.svg {
      border: 2px solid #ffa800;
      border-radius: 50%;
      padding: 5px;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }

    svg {
      color: #ffffff;
      // // color             : red;
      // background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      // // background: -webkit-linear-gradient(#eee, #333);
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    span {
      position               : relative;
      display                : inline-block;
      font-size              : 50px;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }

  .why-choose-one__content {
    margin-left: 35px;
  }

  .why-choose-one__content-title {
    font-size    : 22px;
    font-weight  : 600;
    line-height  : 32px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 5px;
  }

  .why-choose-one__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}

/***********************
* Testimonial One
***********************/
.testimonial-one {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 109px 0 120px;
  overflow  : hidden;
  z-index   : 1;

  .testimonial-one__shape-1 {
    position: absolute;
    top     : 0;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .testimonial-one__shape-2 {
    position: absolute;
    bottom  : 0;
    right   : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .testimonial-one__inner {
    position       : relative;
    display        : block;
    // margin-right: -450px;
  }

  .testimonial-one__title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 40px;
  }

  .testimonial-one__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }

  .testimonial-one__content-box {
    position     : relative;
    display      : block;
    border-bottom: 1px solid #e3e3e3;
    padding      : 35px 35px 42px;
  }

  .testimonial-one__text {
    letter-spacing: -0.015em;
    line-height   : 26px;
    margin        : 0;

    span {
      font-weight: 600;
      color      : var(--customVar-black);
    }
  }

  .testimonial-one__rating-and-quote-box {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin-top     : 26px;
  }

  .testimonial-one__rating-logo {
    position     : relative;
    display      : block;
    margin-bottom: 11px;

    img {
      width: auto !important;
    }
  }

  .testimonial-one__rating-star {
    position   : relative;
    display    : flex;
    align-items: center;

    svg {
      position : relative;
      display  : inline-block;
      font-size: 20px;
      color    : #ffa800;

      +svg {
        margin-left: 5px;
      }
    }
  }

  .testimonial-one__quote {
    position: relative;
    display : block;

    img {
      width: auto !important;
    }
  }

  .testimonial-one__client-info {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    padding    : 20px 35px 23px;
  }

  .testimonial-one__client-img {
    position     : relative;
    display      : block;
    width        : 70px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .testimonial-one__client-content {
    margin-left: 20px;
  }

  .testimonial-one__name {
    font-size    : 20px;
    color        : var(--customVar-black);
    font-weight  : 600;
    line-height  : 30px;
    margin       : 0;
    margin-bottom: 3px;
  }

  .testimonial-one__sub-title {
    font-size  : 15px;
    line-height: 15px;
    margin     : 0;
    color      : var(--customVar-secondary);
  }

  .section__title {
    flex-grow: 1;
    display  : flex;
  }

  .section__control {
    flex-grow: 0;

    .swiper-button-prev,
    .swiper-button-next {
      height            : 60px;
      width             : 60px;
      line-height       : 60px;
      color             : var(--customVar-white);
      background-color  : transparent;
      font-size         : 14px;
      margin            : 0;
      text-align        : center;
      border            : 2px solid var(--customVar-white);
      border-radius     : 10px;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;
      position          : relative;
      // display: inline-block;

      &:before {
        position        : absolute;
        top             : 0;
        left            : 0;
        right           : 0;
        bottom          : 0;
        content         : "";
        background      : linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
        border-radius   : 10px;
        transform       : scale(0);
        transform-origin: center;
        transform-style : preserve-3d;
        transition      : all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
        z-index         : -1;
      }

      &:hover {
        border: 2px solid transparent;

        &:before {
          transform: scaleX(1);
        }
      }
    }

    .swiper-button-prev {
      margin-right: 5px;
    }

    .swiper-button-next {
      margin-left: 5px;
    }
  }
}

/***********************
* Apps One
***********************/
.apps-one {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 120px 0 90px;
  z-index         : 1;

  .apps-one__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .apps-one__left {
    position  : relative;
    display   : block;
    margin-top: 10px;
  }

  .apps-one__title {
    font-size  : 40px;
    font-weight: 700;
    color      : var(--customVar-black);
    line-height: 50px;
    margin     : 0;
  }

  .apps-one__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
    margin-top    : 23px;
    margin-bottom : 27px;
  }

  .apps-one__btn {
    color     : var(--customVar-white);
    box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-black);
    }
  }

  .apps-one__right {
    position   : relative;
    display    : block;
    margin-left: 74px;
  }

  .apps-one__list {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-wrap      : wrap;
    margin         : 0;

    li {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: center;
      background     : #ffffff;
      box-shadow     : 0px 15px 40px rgba(0, 0, 0, 0.05);
      border-radius  : 20px;
      height         : 100px;
      width          : 210px;
      margin-bottom  : 30px;

      +li {
        margin-left: 26px;
      }
    }
  }

  .apps-one__logo {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }
}

/***********************
* Blog One
***********************/
.blog-one {
  position        : relative;
  display         : block;
  padding         : 109px 0 90px;
  background-color: #fafbff;
  z-index         : 1;

  .blog-one__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .blog-one__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .blog-one__section-title__text {
    font-size     : 16px;
    margin        : 0;
    line-height   : 26px;
    letter-spacing: -0.015em;
  }

  .blog-one__single {
    position     : relative;
    display      : block;
    background   : #ffffff;
    box-shadow   : 0px 15px 40px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 30px;

    &:hover .blog-one__img img {
      transform: scale(1.05);
    }
  }

  .blog-one__img {
    position               : relative;
    display                : block;
    border-top-left-radius : 20px;
    border-top-right-radius: 20px;
    overflow               : hidden;
    z-index                : 1;

    img {
      width             : 100%;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;
    }
  }

  .blog-one__content {
    position: relative;
    display : block;
    padding : 35px 30px 25px;
  }

  .blog-one__title {
    font-size  : 22px;
    font-weight: 500;
    line-height: 32px;
    margin     : 0;

    a {
      color             : var(--customVar-black);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .blog-one__text {
    margin        : 0;
    line-height   : 26px;
    letter-spacing: -0.015em;
    margin-top    : 5px;
    margin-bottom : 22px;
  }

  .blog-one__btn {
    position          : relative;
    display           : flex;
    align-items       : center;
    font-size         : 18px;
    font-weight       : 600;
    letter-spacing    : 0.01em;
    color             : var(--customVar-secondary);
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    span {
      position    : relative;
      display     : inline-block;
      padding-left: 10px;
      font-size   : 12px;
    }

    &:hover {
      color: var(--customVar-base);
    }
  }

  .blog-one__user-and-date {
    position        : absolute;
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    flex-wrap       : wrap;
    background-color: #333333;
    border-radius   : 20px;
    padding-right   : 23px;
    top             : -20px;
    left            : 30px;
    right           : 30px;
    z-index         : 2;
  }

  .blog-one__user {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .blog-one__user-img {
    position     : relative;
    display      : block;
    height       : 35px;
    width        : 35px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-one__user-name {
    font-size     : 12px;
    color         : var(--customVar-white);
    font-weight   : 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin        : 0;
    line-height   : 12px;
    margin-left   : 10px;
  }

  .blog-one__date {
    font-size     : 12px;
    color         : var(--customVar-white);
    font-weight   : 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin        : 0;
    line-height   : 12px;
  }
}


@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .robust-one {
    .robust-one__left {
      margin-right: -78px;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .client-logo {
    .client-logo__point {
      margin-left: 0;
      margin-top : 10px;
    }
  }

  .pricing-one {
    .pricing-one__single {
      padding: 48px 10px 40px;
    }

    .pricing-one__shape-12 {
      display: none;
    }
  }

  .feature-one {
    .feature-one__list li {
      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-right: 0;
        padding-left : 0;
      }

      &:nth-child(3) {
        padding-left : 0;
        padding-right: 0;
      }

      &:nth-child(4) {
        padding-left: 0;
      }
    }
  }

  .robust-one {
    .robust-one__left {
      max-width: 600px;
      margin   : 65px auto 0;
    }

    .robust-one__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }

  .why-choose-one {
    padding: 120px 0 120px;

    .why-choose-one__right {
      margin-left: 0;
    }

    .why-choose-one__title {
      font-size: 35px;
    }
  }

  .apps-one {
    .apps-one__left {
      max-width   : 685px;
      margin-left : auto;
      margin-right: auto;
    }

    .apps-one__right {
      max-width: 685px;
      margin   : 50px auto 0;
    }
  }

  .blog-one {
    .blog-one__user-and-date {
      flex-direction: column;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .client-logo {
    .client-logo__list li {
      flex         : 0 0 50%;
      max-width    : 50%;
      margin-bottom: 20px;

      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .pricing-one {
    .pricing-one__shape-12 {
      display: none;
    }
  }

  .feature-one {
    .feature-one__list li {
      flex         : 0 0 50%;
      max-width    : 50%;
      margin-bottom: 30px;

      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-right: 0;
        padding-left : 0;
      }

      &:nth-child(3) {
        padding-left : 0;
        padding-right: 0;
        margin-bottom: 0;
      }

      &:nth-child(4) {
        padding-left : 0;
        margin-bottom: 0;
      }
    }
  }

  .robust-one {
    .robust-one__left {
      max-width: 600px;
      margin   : 65px auto 0;
    }

    .robust-one__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }

  .why-choose-one {
    padding: 120px 0 120px;

    .why-choose-one__right {
      margin-left: 0;
    }
  }

  .apps-one {
    .apps-one__left {
      max-width   : 685px;
      margin-left : auto;
      margin-right: auto;
    }

    .apps-one__right {
      max-width: 685px;
      margin   : 50px auto 0;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .client-logo {
    .client-logo__list li {
      flex         : 0 0 100%;
      max-width    : 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pricing-one {
    .pricing-one__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .pricing-one__section-title__text br {
      display: none;
    }

    .pricing-one__shape-12,
    .pricing-one__shape-9,
    .pricing-one__shape-10 {
      display: none;
    }

    .pricing-one__single {
      padding: 48px 10px 40px;
    }
  }

  .feature-one {
    .feature-one__list li {
      flex         : 0 0 100%;
      max-width    : 100%;
      margin-bottom: 30px;

      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-right: 0;
        padding-left : 0;
      }

      &:nth-child(3) {
        padding-left : 0;
        padding-right: 0;
        margin-bottom: 0;
      }

      &:nth-child(4) {
        padding-left : 0;
        margin-bottom: 0;
      }
    }

    .feature-one__sec-title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .robust-one {
    .robust-one__left {
      margin-right: 0;
    }

    .robust-one__counter-box {
      flex-direction: column;
      align-items   : baseline;

      li+li {
        margin-left: 0;
        margin-top : 20px;
      }
    }

    .robust-one__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 50px;
    }
  }

  .why-choose-one {
    padding: 120px 0 120px;

    .why-choose-one__left {
      margin-right: 0;
    }

    .why-choose-one__right {
      margin-left: 0;
    }

    .why-choose-one__points li {
      padding: 25px 20px 26px;
    }
  }

  .testimonial-one {
    .testimonial-one__inner {
      margin-right: 0;
    }

    .testimonial-one__client-info {
      padding       : 20px 20px 23px;
      flex-direction: column;
      align-items   : baseline;
    }

    .testimonial-one__client-content {
      margin-left: 0;
      margin-top : 10px;
    }

    .testimonial-one__content-box {
      padding: 35px 20px 42px;
    }

    .section__control {
      visibility: hidden;
    }
  }

  .apps-one {
    .apps-one__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .apps-one__list {
      flex-direction: column;

      li+li {
        margin-left: 0;
      }
    }
  }

  .blog-one {
    .blog-one__user-and-date {
      flex-direction: column;
    }
  }
}