/***********************
* Language switcher
***********************/

.language-switcher {
  display          : -webkit-inline-box;
  display          : -ms-inline-flexbox;
  display          : inline-flex;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
  margin-left      : 30px;

  i {
    color       : #fff;
    font-size   : 18px;
    margin-right: 8px;
  }

  button {
    font-size  : 17px;
    font-family: var(--customVar-title-font, "Outfit", sans-serif);
    font-weight: 400;
    color      : #fff;

    &.show {
      color      : #fff;
    }

    &:hover {
      color: var(--customVar-primary, #ff5a74);
    }
  }

  .topbar-dropdown-menu {
    // border        : 0;
    padding-top   : 0;
    padding-bottom: 0;
    margin-top    : 0;
    z-index       : 991;
    border-radius : 0;

    a.dropdown-item {
      font-size         : 14px;
      font-weight       : 400;
      padding           : 4px 20px;
      // color             : #ffffff;
      // background        : var(--customVar-base, #FFA065);
      -webkit-transition: all 0.4s ease;
      transition        : all 0.4s ease;
      display           : -webkit-inline-box;
      display           : -ms-inline-flexbox;
      display           : inline-flex;
      -webkit-box-align : center;
      -ms-flex-align    : center;
      align-items       : center;

      &.selected,
      &:hover {
        background: var(--bs-gray-200, #e9ecef);
        color: var(--customVar-gray-600, #6c757d);
      }
    }
  }

  .main-header-three &,
  .main-header-four & {
    margin-left      : 15px;

    i, button {
      font-weight: 400;
      color: var(--customVar-text);
    }

    &:before {
      content: "";
      // position: absolute;
      // top: 7px;
      // bottom: 7px;
      // left: -15px;
      // width: 1px;
      margin-right: 15px;
      height: 20px;
      width: 1px;
      background-color: var(--customVar-text);
    }
  }

  .mobile-nav__wrapper & {
    display: none;
    margin-left: 0;
    .icon-globe {
      font-size: 16px;
      color: var(--customVar-base, #ffa065);
      margin-right: 15px;
    }

    .dropdown {
      display: inline-block;
    }
  }  
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .language-switcher {
    display: none;

    .mobile-nav__wrapper & {
      display: block;
    }
  }
}





// .main-header-one .language-switcher .bootstrap-select>.dropdown-toggle::after {
//   margin-left: 8px;
//   font-size  : 12px;
// }


// .main-header-three .language-switcher {
//   position: relative;
// }

// .main-header-three .language-switcher:before {
//   content         : "";
//   position        : absolute;
//   top             : 7px;
//   bottom          : 7px;
//   left            : -15px;
//   width           : 1px;
//   background-color: var(--customVar-text);
// }

// .main-header-three .language-switcher i {
//   color: var(--customVar-text);
// }

// .main-header-three .language-switcher .bootstrap-select>.dropdown-toggle {
//   color: var(--customVar-text);
// }
