/***********************
* Banner Four
***********************/
.banner-four {
  position: relative;
  display : block;
  padding : 217px 0 55px;
  z-index : 1;

  .banner-four__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    background-image   : url(../../../images/template/background/banner-four-bg.jpg);
    z-index            : -1;
  }

  .banner-four__left {
    position  : relative;
    display   : block;
    margin-top: 185px;
  }

  .banner-four__right {
    position    : relative;
    display     : block;
    margin-left : 54px;
    margin-right: -54px;
  }

  .banner-four__title {
    font-size  : 60px;
    color      : var(--customVar-white);
    font-weight: 700;
    line-height: 76px;
    margin     : 0;
  }

  .banner-four__text {
    font-size    : 18px;
    line-height  : 32px;
    color        : #ffffff;
    margin       : 0;
    margin-top   : 16px;
    margin-bottom: 30px;
  }

  .banner-four__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white, #ffffff);
    }
  }

  .banner-four__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/**********************************************
* Pricing Three
***********************************************/
.pricing-three {
  position        : relative;
  display         : block;
  background-color: #0e122d;
  padding         : 108px 0 90px;
  z-index         : 1;

  .pricing-three__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .pricing-three__section-title__title {
    font-size    : 38px;
    font-weight  : 700;
    line-height  : 48px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 9px;
  }

  .pricing-three__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 26px;
    color      : var(--customVar-white);
  }

  .pricing-three__single {
    position        : relative;
    display         : block;
    background-color: #151939;
    border-radius   : 20px;
    padding         : 0 0 50px;
    margin-bottom   : 30px;
  }

  .pricing-three__img {
    position: relative;
    display : block;

    img {
      width                  : 100%;
      border-top-left-radius : 20px;
      border-top-right-radius: 20px;
    }
  }

  .pricing-three__img-text {
    position       : absolute;
    top            : 0;
    left           : 0;
    bottom         : 0;
    right          : 0;
    text-align     : center;
    display        : flex;
    align-items    : center;
    justify-content: center;

    p {
      margin     : 0;
      font-weight: 600;
      font-size  : 26px;
      font-family: var(--customVar-title-font);
      color      : var(--customVar-white);
    }
  }

  .pricing-three__content {
    position: relative;
    display : block;
    padding : 32px 40px 35px;
  }

  .pricing-three__text {
    font-weight   : 400;
    font-size     : 16px;
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : #dfdfdf;
    margin        : 0;
    margin-bottom : 21px;
  }

  .pricing-three__price-box {
    position: relative;
    display : block;
  }

  .pricing-three__price {
    font-size  : 40px;
    font-weight: 800;
    color      : var(--customVar-white);
    line-height: 40px;
    margin     : 0;
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .pricing-three__price-point {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    font-weight: 500;
    top        : 8px;
  }

  .pricing-three__price-validity {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    font-weight: 500;
    top        : 8px;
  }

  .pricing-three__price-renew {
    margin        : 0;
    font-weight   : 400;
    font-size     : 16px;
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : #dfdfdf;
    margin-top    : 5px;
  }

  .pricing-three__points-title {
    margin        : 23px 0 13px;
    font-weight   : 600;
    letter-spacing: -0.015em;
    color         : #dfdfdf;
  }

  .pricing-three__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;
      }

      .icon i {
        font-size: 22px;
        color    : #00c14d;
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;
          color         : #dfdfdf;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }

  .pricing-three__btn-box {
    position  : relative;
    display   : block;
    text-align: center;
    padding   : 0 30px 0;
  }

  .pricing-three__btn {
    color          : var(--customVar-white);
    width          : 100%;
    justify-content: center;

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background-color: #3852ff;
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}

/***********************
* Faq Two
***********************/
.faq-two {
  position  : relative;
  display   : block;
  background: #0f122c;
  padding   : 130px 0 120px;
  z-index   : 3;

  .faq-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .faq-two__left {
    position: relative;
    display : block;
  }

  .faq-two__img {
    position: absolute;
    top     : -32px;
    left    : 90px;

    img {
      width: auto;
    }
  }

  .faq-two__right {
    position     : relative;
    display      : block;
    counter-reset: count;

    .accrodion-grp {
      margin-left: 85px;
    }

    .faq-two-accrodion {
      .card {
        background-color: transparent;
        color           : var(--customVar-white);
        border          : none;
        margin-bottom   : 10px;

        .card-header {
          border: none;

          h4 {
            cursor: pointer;
          }

          .accrodion__trigger {
            position   : absolute;
            font-size  : 22px;
            line-height: 22px;
            font-weight: 600;
            color      : var(--customVar-white);
            background : transparent;
            border     : none;
            height     : 30px;
            width      : 40px;
            top        : 5px;
            left       : -62px;
            font-family: var(--customVar-title-font);
          }
        }

        &:not(:last-child):before {
          content   : "";
          position  : absolute;
          top       : 35px;
          bottom    : -15px;
          left      : -46px;
          width     : 3px;
          background: rgb(255, 255, 255);
          background: linear-gradient(180deg, rgba(255, 255, 255, 1) 33%, rgba(238, 242, 255, 0) 100%);
        }

        &.show {
          .card-header .accrodion__trigger {
            background             : linear-gradient(93.28deg, #FFA065 -5.32%, #FF5A74 105.23%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip        : text;
          }

          &:not(:last-child):before {
            background: rgb(255, 96, 15);
            background: linear-gradient(180deg, rgba(255, 96, 15, 1) 33%, rgba(16, 18, 44, 0.07) 100%);
          }
        }
      }
    }
  }

  .faq-two__title {
    font-weight  : 700;
    font-size    : 38px;
    line-height  : 48px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 43px;
  }

  .faq-two__btn {
    color             : var(--customVar-white);
    padding           : 14px 45px;
    margin-top        : 50px;
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}

/***********************
* Comming Soon
***********************/
.comming-soon {
  position        : relative;
  display         : block;
  background-color: #0f122c;
  padding         : 120px 0 90px;
  z-index         : 1;

  .comming-soon__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    opacity            : 0.6;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .comming-soon__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .comming-soon__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 8px;
  }

  .comming-soon__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 26px;
    color      : var(--customVar-white);
  }

  .comming-soon__single {
    position        : relative;
    display         : block;
    border-radius   : 20px;
    background-color: #1e2348;
    margin-bottom   : 30px;
  }

  .comming-soon__img-box {
    position: relative;
    display : block;
  }

  .comming-soon__img {
    position: relative;
    display : block;

    img {
      width        : 100%;
      border-radius: 20px;
    }
  }

  .comming-soon__sub-title {
    position        : absolute;
    bottom          : -16px;
    left            : 50%;
    transform       : translateX(-50%);
    background-color: var(--customVar-white);
    max-width       : 405px;
    width           : 100%;
    text-align      : center;
    text-transform  : uppercase;
    border-radius   : 18px;
    padding         : 12px 5px 11px;

    p {
      font-weight   : 500;
      font-size     : 12px;
      line-height   : 12px;
      letter-spacing: 0.1em;
      color         : #121212;
      margin        : 0;
    }
  }

  .comming-soon__content {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 45px 30px 39px;
  }

  .comming-soon__title-box {
    position: relative;
    display : block;
  }

  .comming-soon__title {
    margin     : 0;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;

    a {
      color             : var(--customVar-white);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .comming-soon__price-box {
    position: relative;
    display : block;

    p {
      margin     : 3px 0 0;
      font-weight: 600;
      font-size  : 22px;
      line-height: 32px;
      color      : var(--customVar-white);

      span {
        font-weight : 600;
        font-size   : 16px;
        line-height : 26px;
        margin-right: 15px;
        position    : relative;
        display     : inline-block;

        &::before {
          content         : "";
          position        : absolute;
          bottom          : 12px;
          left            : 0;
          width           : 54px;
          height          : 3px;
          background-color: #ff6b71;
          transform       : rotate(-9.64deg);
        }
      }
    }
  }

  .comming-soon__btn-box {
    position: relative;
    display : block;
  }

  .comming-soon__btn {
    padding           : 14px 35px;
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::after {
      background-color: var(--customVar-white);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/********************************************************
* NFS One
*********************************************************/
.nfs-one {
  position        : relative;
  display         : block;
  background-color: #0f122c;
  padding         : 104px 0 110px;
  z-index         : 1;

  .nfs-one__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .nfs-one__inner {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .nfs-one__img {
    position : relative;
    display  : block;
    max-width: 512px;
    width    : 100%;
    margin   : 0 auto;

    img {
      width: 100%;
    }
  }

  .nfs-one__text {
    margin     : -30px 0 32px;
    font-weight: 400;
    font-size  : 18px;
    line-height: 32px;
    color      : var(--customVar-white);
  }

  .nfs-one__btn-box {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  .nfs-one__btn {
    padding           : 14px 40px;
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::after {
      background-color: var(--customVar-white);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .nfs-one__btn-2 {
    color           : var(--customVar-white);
    border          : 2px solid var(--customVar-white);
    background-color: transparent;
    margin-left     : 11px;
    padding         : 12px 21px;

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background-color: transparent;
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}

/********************************************************
* Why Choose Three
*********************************************************/
.why-choose-three {
  position        : relative;
  display         : block;
  background-color: #0f122c;
  padding         : 109px 0 120px;
  overflow        : hidden;
  z-index         : 1;

  .why-choose-three__shape-1 {
    position: absolute;
    top     : 0;
    left    : 0;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .why-choose-three__inner {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .why-choose-three__title {
    margin     : 0 0 44px;
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    text-align : center;
    color      : var(--customVar-white);
  }

  .why-choose-three__points {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin         : 0;

    li {
      position     : relative;
      display      : block;
      margin-bottom: 23px;

      .icon {
        position: relative;
        display : inline-block;
      }

      .icon span {
        position               : relative;
        display                : inline-block;
        font-size              : 80px;
        color                  : rgba(255, 90, 116, 1);
        background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
        -webkit-background-clip: text;
        -moz-background-clip   : text;
        background-clip        : text;
        -webkit-text-fill-color: transparent;
        -webkit-transition     : all 500ms ease;
        transition             : all 500ms ease;
      }

      h3 {
        margin     : 19px 0 10px;
        font-weight: 600;
        font-size  : 22px;
        line-height: 32px;
        color      : var(--customVar-white);
      }

      p {
        font-weight   : 400;
        font-size     : 16px;
        line-height   : 26px;
        letter-spacing: -0.015em;
        color         : var(--customVar-white);
        margin        : 0;
      }
    }
  }

  .why-choose-three__bottom {
    position  : relative;
    display   : block;
    margin-top: 30px;
  }

  .why-choose-three__ratting-and-text {
    position        : relative;
    display         : flex;
    align-items     : center;
    margin          : 0 auto;
    max-width       : 950px;
    background-color: #151939;
    border-radius   : 10px;
    padding         : 16px 52px 21px;
    width           : 100%;
  }

  .why-choose-three__ratting-box {
    position     : relative;
    display      : flex;
    padding-right: 30px;
    border-right : 1px solid #333866;
    align-items  : center;
    margin-right : 30px;
  }

  .why-choose-three__ratting-text {
    position: relative;
    display : inline-block;
    z-index : 1;

    p {
      margin      : 0;
      font-weight : 700;
      font-size   : 30px;
      line-height : 40px;
      color       : #ffffff;
      font-family : var(--hostuo-title-font);
      margin-right: 34px;
    }
  }

  .why-choose-three__ratting-shape-1 {
    position: absolute;
    top     : -15px;
    left    : -14px;
    z-index : -1;

    img {
      width: auto;
    }
  }

  .why-choose-three__ratting-title {
    margin     : 0;
    font-weight: 600;
    font-size  : 20px;
    line-height: 30px;
    color      : var(--customVar-white);
  }

  .why-choose-three__ratting {
    position   : relative;
    display    : flex;
    align-items: center;
    margin-top : 5px;

    span {
      position : relative;
      display  : inline-block;
      font-size: 18px;
      color    : #ffa800;
    }
  }

  .why-choose-three__text-box {
    position: relative;
    display : block;
  }

  .why-choose-three__text {
    color      : var(--customVar-white);
    margin     : 0;
    line-height: 32px;
    position   : relative;
    display    : inline-block;

    span {
      font-size  : 16px;
      font-weight: 700;
    }

    .count-text {
      font-size  : 16px;
      font-weight: 700;
    }
  }

  .why-choose-three__text-2 {
    color      : var(--customVar-white);
    margin     : 0;
    line-height: 32px;
    position   : relative;
    display    : inline-block;

    span {
      font-size  : 16px;
      font-weight: 700;
    }

    .count-text {
      font-size  : 16px;
      font-weight: 700;
    }
  }
}

/********************************************************
* BLog Three
*********************************************************/
.blog-three {
  position        : relative;
  display         : block;
  background-color: #0f122c;
  padding-bottom  : 90px;
  overflow        : hidden;
  z-index         : 1;

  .blog-three__shape-1 {
    position: absolute;
    top     : 56px;
    left    : 0;

    img {
      width: auto;
    }
  }

  .blog-three__shape-2 {
    position: absolute;
    top     : 93px;
    right   : 235px;

    img {
      width: auto;
    }
  }

  .blog-three__shape-3 {
    position: absolute;
    bottom  : 0;
    right   : -30px;

    img {
      width: auto;
    }
  }

  .blog-three__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .blog-three__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 9px;
  }

  .blog-three__section-title__text {
    font-size     : 16px;
    margin        : 0;
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : var(--customVar-white);
  }

  .blog-three__single {
    position        : relative;
    display         : block;
    background-color: #151939;
    box-shadow      : 0px 15px 40px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    margin-bottom   : 30px;

    &:hover .blog-three__img img {
      transform: scale(1.05);
    }
  }

  .blog-three__img-box {
    position: relative;
    display : block;
  }

  .blog-three__img {
    position               : relative;
    display                : block;
    overflow               : hidden;
    border-top-left-radius : 20px;
    border-top-right-radius: 20px;
    z-index                : 1;

    img {
      width                  : 100%;
      border-top-left-radius : 20px;
      border-top-right-radius: 20px;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }

  .blog-three__date {
    position: absolute;
    top     : 30px;
    left    : 30px;
    z-index : 2;

    p {
      font-weight     : 600;
      font-size       : 12px;
      line-height     : 12px;
      letter-spacing  : 0.05em;
      color           : #121212;
      background-color: var(--customVar-white);
      text-transform  : uppercase;
      padding         : 7px 17px 11px;
      border-radius   : 17px;
      margin          : 0;

      span {
        position    : relative;
        display     : inline-block;
        font-size   : 16px;
        margin-right: 7px;
        top         : 2px;
      }
    }
  }

  .blog-three__content {
    position     : relative;
    display      : block;
    padding      : 21px 30px 19px;
    border-bottom: 1px solid #333866;
  }

  .blog-three__title {
    margin     : 0;
    font-weight: 500;
    font-size  : 22px;
    line-height: 32px;

    a {
      color             : var(--customVar-white);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }
    }
  }

  .blog-three__text {
    letter-spacing: -0.015em;
    margin        : 8px 0 0;
    line-height   : 26px;
    color         : var(--customVar-white);
  }

  .blog-three__user-and-date {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 15px 30px 18px;
  }

  .blog-three__user {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .blog-three__user-img {
    position     : relative;
    display      : block;
    height       : 40px;
    width        : 40px;
    border-radius: 50%;

    img {
      width        : 100%;
      border-radius: 50%;
    }
  }

  .blog-three__user-name {
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    letter-spacing: 0.1em;
    color         : var(--customVar-white);
    margin        : 0 0 0 10px;
    text-transform: uppercase;
  }

  .blog-three__date-2 {
    font-weight   : 500;
    font-size     : 12px;
    line-height   : 22px;
    letter-spacing: 0.1em;
    color         : var(--customVar-white);
    text-transform: uppercase;
    margin        : 0;

    span {
      position               : relative;
      display                : inline-block;
      font-size              : 18px;
      top                    : 3px;
      margin-right           : 10px;
      color                  : rgba(255, 90, 116, 1);
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
    }
  }
}

/********************************************************
* Contact Two
*********************************************************/
.contact-two {
  position        : relative;
  display         : block;
  background-color: #0f122c;
  padding         : 109px 0 90px;
  z-index         : 1;

  .contact-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-size    : cover;
    background-position: center;
    background-repeat  : no-repeat;
    z-index            : -1;
  }

  .contact-two__left {
    position    : relative;
    display     : block;
    margin-right: 53px;
  }

  .contact-two__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 50px;
  }

  .contact-two__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 15px;
  }

  .contact-two__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
    color      : var(--customVar-white);
  }

  .contact-two__platform-box {
    position: relative;
    display : block;
  }

  .contact-two__platform-list {
    margin         : 0;
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;

    li {
      position     : relative;
      display      : block;
      margin-bottom: 30px;
    }
  }

  .contact-two__platform-list-1 {
    margin-right : 38px;
    margin-bottom: 25px;
  }

  .contact-two__platform-img {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .contact-two__right {
    position   : relative;
    display    : block;
    margin-left: 50px;
    margin-top : 12px;
  }

  .contact-two__form-box {
    position: relative;
    display : block;

    .row {
      --bs-gutter-x: 20px;
    }
  }

  .contact-two__form {
    position: relative;
    display : block;
  }

  .contact-two__input-box {
    position     : relative;
    display      : block;
    margin-bottom: 20px;

    input[type="text"],
    input[type="email"] {
      height          : 60px;
      width           : 100%;
      border          : none;
      background-color: transparent;
      border          : 2px solid var(--customVar-white);
      border-radius   : 10px;
      padding-left    : 22px;
      padding-right   : 30px;
      outline         : none;
      font-size       : 16px;
      color           : var(--customVar-white);
      display         : block;
      font-weight     : 400;
    }

    textarea {
      font-size       : 16px;
      font-weight     : 400;
      color           : var(--customVar-white);
      height          : 140px;
      width           : 100%;
      background-color: transparent;
      border          : 2px solid var(--customVar-white);
      padding         : 20px 22px 30px;
      border-radius   : 10px;
      outline         : none;
      margin-bottom   : 0px;
    }

    &.text-message-box {
      height: 140px;
    }
  }

  .contact-two__showing-sort {
    position: relative;
    display : block;
    width   : 100%;
  }


  .contact-two__showing-sort .dropdown>.dropdown-toggle {
    position           : relative;
    height             : 60px;
    outline            : none !important;
    border-radius      : 10px;
    background-color   : transparent;
    border             : 2px solid var(--customVar-white);
    margin             : 0;
    padding            : 0;
    padding-left       : 22px;
    padding-right      : 30px;
    color              : var(--customVar-white) !important;
    font-size          : 16px;
    line-height        : 63px;
    font-weight        : 400;
    box-shadow         : none !important;
    background-repeat  : no-repeat;
    background-size    : 14px 12px;
    background-position: right 25.75px center;
    width              : 100%;
    text-align         : left;
  }

  .contact-two__btn-box {
    position: relative;
    display : block;
  }

  .contact-two__btn {
    border            : none;
    color             : var(--customVar-white);
    padding           : 14px 50px;
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}



@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .banner-four {
    .banner-four__title {
      font-size  : 50px;
      line-height: 66px;
    }

    .banner-four__text br {
      display: none;
    }
  }

  .contact-two {
    .contact-two__section-title__text br {
      display: none;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .banner-four {
    padding: 277px 0 55px;

    .banner-four__left {
      margin-top: 0;
    }

    .banner-four__title {
      font-size  : 50px;
      line-height: 66px;
    }

    .banner-four__text br {
      display: none;
    }

    .banner-four__right {
      margin-left : 0;
      margin-right: 0;
    }
  }

  .pricing-three {
    .pricing-three__content {
      padding: 32px 20px 35px;
    }
  }

  .faq-two {
    .faq-two__img {
      left: -115px;
    }
  }

  .comming-soon {
    .comming-soon__content {
      flex-direction: column;
      align-items   : baseline;
    }

    .comming-soon__title-box {
      margin-bottom: 20px;
    }
  }

  .why-choose-three {
    .why-choose-three__points {
      justify-content: inherit;
      flex-wrap      : wrap;

      li {
        flex     : 0 0 50%;
        max-width: 50%;
        width    : 100%;
      }
    }
  }

  .blog-three {
    .blog-three__user-and-date {
      flex-direction: column;
      align-items   : baseline;
    }

    .blog-three__user {
      margin-bottom: 20px;
    }
  }

  .contact-two {
    .contact-two__right {
      margin-left: 0;
      margin-top : 25px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .banner-four {
    padding: 183px 0 55px;

    .banner-four__left {
      margin-top: 0;
    }

    .banner-four__right {
      margin-top  : 50px;
      margin-left : 0;
      margin-right: 0;
    }
  }

  .faq-two {
    .faq-two__img {
      position: relative;
      top     : 0;
      left    : 0;
    }
  }

  .comming-soon {
    .comming-soon__content {
      flex-direction: column;
      align-items   : baseline;
    }

    .comming-soon__title-box {
      margin-bottom: 20px;
    }
  }

  .why-choose-three {
    .why-choose-three__points {
      justify-content: inherit;
      flex-wrap      : wrap;

      li {
        flex     : 0 0 50%;
        max-width: 50%;
        width    : 100%;
      }
    }
  }

  .contact-two {
    .contact-two__right {
      margin-left: 0;
      margin-top : 25px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .banner-four {
    padding: 183px 0 55px;

    .banner-four__left {
      margin-top: 0;
    }

    .banner-four__title {
      font-size  : 30px;
      line-height: 45px;
    }

    .banner-four__text br {
      display: none;
    }

    .banner-four__right {
      margin-top  : 50px;
      margin-left : 0;
      margin-right: 0;
    }
  }

  .pricing-three {
    .pricing-three__content {
      padding: 32px 20px 35px;
    }
  }

  .faq-two {
    .faq-two__img {
      position: relative;
      top     : 0;
      left    : 0;

      img {
        width: 100%;
      }
    }
  }

  .comming-soon {
    .comming-soon__content {
      flex-direction: column;
      align-items   : baseline;
    }

    .comming-soon__title-box {
      margin-bottom: 20px;
    }
  }

  .nfs-one {
    .nfs-one__btn-box {
      flex-direction: column;
    }

    .nfs-one__btn-2 {
      margin-left: 0;
      margin-top : 10px;
    }
  }

  .why-choose-three {
    .why-choose-three__points {
      justify-content: inherit;
      flex-wrap      : wrap;

      li {
        flex     : 0 0 100%;
        max-width: 100%;
        width    : 100%;
      }
    }

    .why-choose-three__ratting-and-text {
      padding       : 16px 20px 21px;
      flex-direction: column;
    }

    .why-choose-three__ratting-box {
      padding-right: 0;
      border-right : 0;
      margin-right : 0;
      margin-bottom: 20px;
    }
  }

  .blog-three {
    .blog-three__user-and-date {
      flex-direction: column;
      align-items   : baseline;
    }

    .blog-three__user {
      margin-bottom: 20px;
    }
  }

  .contact-two {
    .contact-two__right {
      margin-left: 0;
      margin-top : 25px;
    }

    .contact-two__left {
      margin-right: 0;
    }

    .contact-two__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .contact-two__section-title__text br {
      display: none;
    }

    .contact-two__platform-list {
      flex-direction: column;
    }

    .contact-two__platform-list-1 {
      margin-right : 0;
      margin-bottom: 0;
    }
  }
}