/*************************************************************
* Description one
**************************************************************/
.description-one {
  position        : relative;
  display         : block;
  padding         : 100px 0;
  background-color: #fafbff;
  z-index         : 1;


  .description-one__left {
    position    : relative;
    display     : block;
    margin-right: 54px;
  }

  .description-one__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .description-one__right {
    position   : relative;
    display    : block;
    margin-left: -25px;
    margin-top : 13px;
  }

  .description-one__title {
    margin     : 0 0 10px;
    font-weight: 700;
    font-size  : 30px;
    line-height: 40px;
    color      : #121212;
  }

  .description-one__subtitle {
    margin     : 0 0 32px;
    font-weight: 700;
    font-size  : 20px;
    line-height: 30px;
    color      : #121212;
  }

  .description-one__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position: relative;
      display : flex;

      +li {
        margin-top: 21px;
      }

      .icon {
        position        : relative;
        display         : flex;
        align-items     : center;
        justify-content : center;
        max-width       : 50px;
        width           : 100%;
        height          : 50px;
        background-color: #f1f5ff;
        border-radius   : 50%;
        top             : 7px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 16px;
          color    : var(--customVar-secondary);
        }
      }

      .content {
        position   : relative;
        display    : block;
        margin-left: 30px;

        h3 {
          margin     : 0;
          font-weight: 600;
          font-size  : 22px;
          line-height: 32px;
          color      : #121212;
        }

        p {
          margin        : 5px 0 0;
          letter-spacing: -0.015em;
          line-height   : 26px;
        }
      }
    }

    &.compact {
      li {
        +li {
          margin-top: 5px;
        }

        .icon {
          max-width       : 25px;
          height          : 25px;
  
          span {
            font-size: 10px;
          }
        }

        .content {
          margin-left: 15px;
        }  
      }
    }
  }

  .description-one__btn-box {
    position  : relative;
    display   : block;
    margin-top: 33px;
  }

  .description-one__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .swiper__img {
    max-width: 600px;
  }
}


/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .web-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-five {
    .pricing-five__single {
      padding: 35px 10px 30px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .description-one {
    .description-one__left {
      margin-right: 0;
    }

    .description-one__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .description-one {
    .description-one__left {
      margin-right: 0;
    }

    .description-one__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}