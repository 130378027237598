@import "bootstrap/scss/variables";
@import "./bootstrapVariables";


:root {
  --customVar-body-font    : "Arial", sans-serif;
  --customVar-title-font   : "Outfit", sans-serif;
  --customVar-base         : #ffa065;
  --customVar-base-rgb     : 255, 160, 101;
  --customVar-primary      : #ff5a74;
  --customVar-primary-rgb  : 255, 90, 116;
  --customVar-secondary    : #3852ff;
  --customVar-secondary-rgb: 56, 82, 255;
  --customVar-black        : #121212;
  --customVar-black-rgb    : 18, 18, 18;
  --customVar-dark         : #000932;
  --customVar-dark-rgb     : 0, 9, 50;
  --customVar-gray         : #c2c2c2;
  --customVar-gray-rgb     : 194, 194, 194;
  --customVar-text         : #666666;
  --customVar-text-rgb     : 102, 102, 102;
  --customVar-white        : #ffffff;
  --customVar-white-rgb    : 255, 255, 255;
}


$breakpoint-xs: map-get($grid-breakpoints, xs);   // 0
$breakpoint-sm: map-get($grid-breakpoints, sm);   // 576px
$breakpoint-md: map-get($grid-breakpoints, md);   // 768px
$breakpoint-lg: map-get($grid-breakpoints, lg);   // 992px
$breakpoint-xl: map-get($grid-breakpoints, xl);   // 1200px
$breakpoint-xxl: map-get($grid-breakpoints, xxl); // 1400px