/***********************
*Cloud Hosting Page Banner
***********************/
.cloud-hosting-page-banner {
  padding-bottom: 60px;

  .page-banner-three__right {
    margin-left : 138px;
    margin-right: -6px;
    margin-top  : -47px;
  }
}

/***********************
*Pricing Seven
***********************/
.pricing-seven {
  position  : relative;
  display   : block;
  background: #f5f7ff;
  padding   : 109px 0 120px;

  .pricing-seven__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-seven__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-seven__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;

    li {
      a {
        font-size     : 18px;
        font-weight   : 500;
        color         : var(--customVar-black);
        padding-left  : 10px;
        padding-right : 10px;
        display       : block;
        letter-spacing: 0.01em;
      }

      &.active a {
        color: var(--customVar-secondary);
      }
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;

    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content {
    position     : relative;
    display      : block;
    background   : #ffffff;
    border       : 1px solid #dde1fd;
    border-radius: 20px;

    .row {
      --bs-gutter-x: 0px;
    }

    >div {
      position  : relative;
      height    : 0px;
      visibility: hidden;
      opacity   : 0;
      @include transformMixin(translateY(35px));
      @include transitionMixin(transform 600ms ease);

      &.active {
        height    : auto;
        visibility: visible;
        opacity   : 1;
        @include transformMixin(translateY(0px));
      }
    }
  }

  .pricing-seven__single {
    position: relative;
    display : block;
    padding : 47px 35px 35px;
  }

  .pricing-seven__single--two {
    border-left : 1px solid #dde1fd;
    border-right: 1px solid #dde1fd;
  }

  .pricing-seven__top {
    position  : relative;
    display   : block;
    text-align: center;
  }

  .pricing-seven__pack-name {
    font-size    : 26px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 26px;
    margin-bottom: 21px;
  }

  .pricing-seven__price {
    font-size  : 50px;
    font-weight: 800;
    color      : var(--customVar-black);
    margin     : 0;
    line-height: 50px;

    span {
      position   : relative;
      font-size  : 20px;
      font-weight: 500;
      left       : -10px;
    }
  }

  .pricing-seven__save {
    position      : relative;
    display       : inline-block;
    font-size     : 12px;
    font-weight   : 500;
    line-height   : 12px;
    color         : var(--customVar-black);
    margin        : 0;
    border-radius : 13px;
    padding       : 6px 15px;
    background    : #ffdccc;
    border        : 1px solid #ffb796;
    text-transform: uppercase;
  }

  .pricing-seven__text {
    margin       : 0;
    margin-top   : 5px;
    margin-bottom: 25px;
  }

  .pricing-seven__btn-box {
    position: relative;
    display : block;
  }

  .pricing-seven__btn {
    width          : 100%;
    color          : var(--customVar-white);
    text-align     : center;
    justify-content: center;

    &::before {
      background-color: var(--customVar-secondary);
    }
  }

  .pricing-seven__points-box {
    position  : relative;
    display   : block;
    margin-top: 40px;
  }

  .pricing-seven__points-title {
    font-size    : 18px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    font-family  : var(--customVar-body-font);
    margin-bottom: 20px;
  }

  .pricing-seven__points {
    position     : relative;
    display      : block;
    margin       : 0;
    margin-bottom: 37px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        i {
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          span {
            color    : #aaaaaa;
            font-size: 20px;
            position : relative;
            display  : inline-block;
          }
        }
      }
    }
  }

  .pricing-seven__points--six {
    margin-bottom: 0;
  }
}

/***********************
* Data Center Three
***********************/
.data-center-three {
  position: relative;
  display : block;
  padding : 109px 0 120px;

  .data-center-three__top {
    position : relative;
    display  : block;
    max-width: 1100px;
    width    : 100%;
    margin   : 0 auto;
  }

  .data-center-three__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .data-center-three__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .data-center-three__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .data-center-three__map {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .data-center-three__map-markar {
    position        : absolute;
    top             : -50px;
    left            : 38px;
    border-radius   : 50%;
    background-color: rgba(25, 213, 100, 0.38);
    z-index         : 5;
    height          : 30px;
    width           : 30px;
    cursor          : pointer;

    &:before {
      position        : absolute;
      top             : 5px;
      bottom          : 5px;
      right           : 5px;
      left            : 5px;
      background-color: #19cf62;
      content         : "";
      border-radius   : 50%;
    }
  }

  .data-center-three__popup-box {
    position                  : absolute;
    top                       : 0px;
    left                      : 0px;
    width                     : 100%;
    z-index                   : 5;
    opacity                   : 1;
    transform                 : perspective(400px) rotateX(90deg) translateY(10px);
    transform-origin          : bottom;
    transition-delay          : 0.1s;
    transition-timing-function: ease-in-out;
    transition-duration       : 0.5s;
    transition-property       : all;
  }

  .data-center-three__popup {
    position: relative;
    display : block;
    width   : 105px;
    width   : 100%;
  }

  .data-center-three__popup-inner {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    text-align      : center;
    padding         : 14px 0 7px;
    box-shadow      : 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius   : 2px;

    &:before {
      content      : "";
      position     : absolute;
      top          : -14px;
      left         : 50%;
      transform    : translateX(-50%);
      border-bottom: 14px solid var(--customVar-white);
      border-right : 7px solid transparent;
      border-left  : 7px solid transparent;
    }
  }

  .data-center-three__popup-title {
    font-size  : 16px;
    font-weight: 500;
    color      : var(--customVar-black);
    line-height: 16px;
  }

  .data-center-three__location-1 {
    position: absolute;
    width   : 105px;
    height  : 45px;
    top     : 32%;
    left    : 9%;

    &:hover .data-center-three__popup-box {
      opacity                   : 1;
      transform                 : perspective(400px) rotateX(0deg) translateY(0);
      transition-delay          : 0.3s;
      transition-timing-function: ease-in-out;
      transition-duration       : 0.5s;
      transition-property       : all;
    }
  }

  .data-center-three__location-2 {
    left: 22%;
    top : 38%;
  }

  .data-center-three__location-3 {
    left  : 24%;
    top   : auto;
    bottom: 23%;
  }

  .data-center-three__location-4 {
    left  : 39%;
    top   : auto;
    bottom: 28%;
  }

  .data-center-three__location-5 {
    left: 50%;
    top : 40%;
  }

  .data-center-three__location-6 {
    left : auto;
    right: 17.5%;
    top  : 31%;
  }

  .data-center-three__location-7 {
    left  : auto;
    top   : auto;
    right : 28%;
    bottom: 33%;
  }

  .data-center-three__location-8 {
    left  : auto;
    top   : auto;
    right : 12%;
    bottom: 7%;
  }
}

/***********************
* Faster One
***********************/
.faster-one {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 107px 0 114px;
  overflow  : hidden;
  z-index   : 1;

  .faster-one__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .faster-one__left {
    position: relative;
    display : block;
  }

  .faster-one__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .faster-one__right {
    position   : relative;
    display    : block;
    margin-left: 65px;
  }

  .faster-one__top-title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 30px;
  }

  .faster-one__list {
    position     : relative;
    display      : block;
    margin       : 0;
    margin-bottom: 40px;

    li {
      position     : relative;
      display      : flex;
      align-items  : center;
      background   : #263d97;
      box-shadow   : 0px 20px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding      : 27px 30px 23px;

      +li {
        margin-top: 20px;
      }

      .icon {
        position        : relative;
        display         : flex;
        align-items     : center;
        justify-content : center;
        height          : 50px;
        width           : 50px;
        background-color: var(--customVar-white);
        border-radius   : 50%;
        font-size       : 15px;
        color           : var(--customVar-secondary);
      }

      .content {
        position   : relative;
        display    : block;
        max-width  : 445px;
        width      : 100%;
        margin-left: 25px;

        h3 {
          font-weight   : 600;
          font-size     : 22px;
          line-height   : 22px;
          letter-spacing: -0.015em;
          color         : #ffffff;
          margin        : 0;
          margin-bottom : 15px;
        }

        p {
          letter-spacing: -0.015em;
          color         : #ffffff;
          margin        : 0;
          line-height   : 26px;
        }
      }
    }
  }

  .faster-one__btn {
    color     : var(--customVar-white);
    box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}

/***********************
* Feature Six
***********************/
.feature-six {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 70px;


  .feature-six .container {
    max-width: 1066px;
  }

  .feature-six .row {
    --bs-gutter-x: 55px;
  }

  .feature-six__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .feature-six__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .feature-six__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .feature-six__single {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .feature-six__icon {
    position: relative;
    display : block;
  }

  .feature-six__title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-black);
    margin       : 0;
    line-height  : 32px;
    margin-top   : 17px;
    margin-bottom: 10px;
  }

  .feature-six__text {
    letter-spacing: -0.015em;
    line-height   : 26px;
    margin        : 0;
  }
}

/***********************
* Get Started
***********************/
.get-started {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 100px 0 75px;
  z-index   : 1;

  .get-started__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .get-started__left {
    position   : relative;
    display    : block;
    margin-left: 165px;
  }

  .get-started__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .get-started__right {
    position    : relative;
    display     : block;
    margin-left : 75px;
    margin-right: 165px;
    margin-top  : 74px;
  }

  .get-started__title {
    font-size  : 40px;
    color      : var(--customVar-white);
    font-weight: 700;
    margin     : 0;
    line-height: 50px;
  }

  .get-started__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
    color         : var(--customVar-white);
    margin-top    : 23px;
    margin-bottom : 22px;
  }

  .get-started__btn {
    color     : var(--customVar-white);
    box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }
}



@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .cloud-hosting-page-banner {
    .page-banner-three__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 0;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .cloud-hosting-page-banner {
    .page-banner-three__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 0;
    }
  }

  .faster-one {
    .faster-one__right {
      margin-left: 0;
    }
  }

  .get-started {
    .get-started__left {
      margin-left: 0;
    }

    .get-started__right {
      margin-left : 0;
      margin-right: 0;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .cloud-hosting-page-banner {
    .page-banner-three__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 50px;
    }
  }

  .faster-one {
    .faster-one__right {
      margin-left: 0;
    }
  }

  .get-started {
    padding: 100px 0 100px;

    .get-started__left {
      margin-left: 0;
    }

    .get-started__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 40px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .cloud-hosting-page-banner {
    .page-banner-three__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 50px;
    }
  }

  .data-center-three {
    .data-center-three__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .faster-one {
    .faster-one__right {
      margin-left: 0;
    }
  }

  .get-started {
    padding: 100px 0 100px;

    .get-started__left {
      margin-left: 0;
    }

    .get-started__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 40px;
    }
  }
}