/*************************************************************
* Dedicated Hosting Page Banner
**************************************************************/
.dedicated-hosting-page-banner {
  padding-bottom: 24px;

  .page-banner-three__right {
    margin-left: 35px;
    margin-top : -64px;
  }
}

/*************************************************************
* Pricing Nine
**************************************************************/
.pricing-nine {
  position        : relative;
  display         : block;
  background-color: #f4f7fe;
  padding         : 109px 0 80px;
  z-index         : 1;

  .row {
    --bs-gutter-x: 40px;
  }

  .pricing-nine__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-nine__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-nine__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;
  }

  ul.switch-toggler-list li {
    a {
      font-size     : 18px;
      font-weight   : 500;
      color         : var(--customVar-black);
      padding-left  : 10px;
      padding-right : 10px;
      display       : block;
      letter-spacing: 0.01em;
    }

    &.active a {
      color: var(--customVar-secondary);
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;

    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content>div {
    // position  : absolute;
    position  : relative;
    height    : 0px;
    visibility: hidden;
    opacity   : 0;
    @include transformMixin(translateY(35px));
    @include transitionMixin(transform 600ms ease);

    &.active {
      height    : auto;
      visibility: visible;
      opacity   : 1;
      @include transformMixin(translateY(0px));
    }
  }

  .pricing-nine__single {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    box-shadow      : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    padding         : 36px 40px 36px;
    margin-bottom   : 40px;
  }

  .pricing-nine__offer {
    position        : absolute;
    top             : 44px;
    right           : 40px;
    background-color: #ebedff;
    border-radius   : 13px;
    font-weight     : 400;
    font-size       : 14px;
    line-height     : 14px;
    color           : var(--customVar-black);
    padding         : 5px 16px 6px;
  }

  .pricing-nine__price-box {
    position: relative;
    display : block;
  }

  .pricing-nine__price-sub-title {
    margin     : 0;
    font-weight: 600;
    font-size  : 26px;
    line-height: 33px;
    color      : var(--customVar-black);
  }

  .pricing-nine__price {
    margin     : 12px 0 13px;
    font-weight: 800;
    font-size  : 50px;
    line-height: 63px;
    color      : var(--customVar-black);
  }

  .pricing-nine__price-validity {
    font-weight: 500;
    font-size  : 20px;
    line-height: 25px;
  }

  .pricing-nine__points-title {
    margin     : 0;
    font-weight: 500;
    font-size  : 16px;
    line-height: 26px;
    color      : var(--customVar-black);
  }

  .pricing-nine__points-box {
    position     : relative;
    display      : flex;
    margin-top   : 15px;
    margin-bottom: 23px;
  }

  .pricing-nine__points-list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position: relative;
        display : inline-block;
        top     : 2px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 20px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;

          >span {
            position   : relative;
            display    : inline-block;
            font-size  : 19px;
            color      : #aaaaaa;
            margin-left: 10px;
            top        : 2px;
          }
        }
      }
    }
  }

  .pricing-nine__points-list-2 {
    margin-left: 67px;
  }

  .pricing-nine__btn-box {
    position: relative;
    display : block;
  }

  .pricing-nine__btn {
    position  : relative;
    display   : block;
    text-align: center;
    color     : var(--customVar-white);

    &::before {
      background-color: var(--customVar-secondary);
    }
  }
}

/*************************************************************
* Power Control
**************************************************************/
.power-control {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 109px 0 90px;
  z-index         : 1;

  .power-control__section-title {
    margin     : 0 0 40px;
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    text-align : center;
    color      : var(--customVar-black);
  }

  .power-control__single {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    box-shadow      : 0px 15px 20px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    text-align      : center;
    padding         : 31px 40px 31px;
    margin-bottom   : 30px;

    &:hover .power-control__icon img {
      transform: scale(0.9);
    }
  }

  .power-control__icon {
    position: relative;
    display : inline-block;

    img {
      width                   : auto;
      -webkit-transition      : all 500ms linear;
      transition              : all 500ms linear;
      -webkit-transition-delay: 0.1s;
      transition-delay        : 0.1s;
    }
  }

  .power-control__title {
    margin     : 16px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : var(--customVar-black);
  }

  .power-control__text {
    margin     : 0;
    line-height: 26px;
  }

  .power-control__btn {
    position  : relative;
    display   : inline-block;
    margin-top: 31px;

    a {
      font-weight       : 600;
      font-size         : 18px;
      line-height       : 18px;
      text-align        : center;
      letter-spacing    : 0.01em;
      color             : #3852ff;
      position          : relative;
      display           : flex;
      align-items       : center;
      font-family       : var(--customVar-title-font);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-base);
      }

      span {
        position   : relative;
        display    : inline-block;
        font-size  : 12px;
        margin-left: 12px;
      }
    }
  }
}

/*************************************************************
* Extreme performance
**************************************************************/
.extreme-performance {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 0 0 114px;
  z-index         : 1;

  .extreme-performance__left {
    position: relative;
    display : block;
  }

  .extreme-performance__img img {
    width         : 100%;
    mix-blend-mode: multiply;
  }

  .extreme-performance__right {
    position   : relative;
    display    : block;
    margin-left: 140px;
    margin-top : 68px;
  }

  .extreme-performance__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : var(--customVar-black);
  }

  .extreme-performance__text {
    margin     : 19px 0 29px;
    font-size  : 18px;
    line-height: 32px;
  }

  .extreme-performance__points {
    margin      : 0;
    margin-left : 20px;
    margin-right: -3px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +Li {
        margin-top: 13px;
      }

      .icon {
        position: relative;
        display : inline-block;
        top     : 3px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 20px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin: 0;
        }
      }
    }
  }

  .extreme-performance__btn-box {
    position  : relative;
    display   : block;
    margin-top: 40px;
  }

  .extreme-performance__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/*************************************************************
* Fully Loaded
**************************************************************/
.fully-loaded {
  position  : relative;
  display   : block;
  background: linear-gradient(90.25deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 41px 0 36px;
  z-index   : 1;

  .fully-loaded__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .fully-loaded__left {
    position  : relative;
    display   : block;
    margin-top: 68px;
  }

  .fully-loaded__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : var(--customVar-white);
  }

  .fully-loaded__points {
    position: relative;
    display : block;
    margin  : 32px 0 37px;
  }

  .fully-loaded__points li {
    position   : relative;
    display    : flex;
    align-items: center;

    +li {
      margin-top: 13px;
    }

    .icon {
      position: relative;
      display : inline-block;
      top     : 3px;

      span {
        position : relative;
        display  : inline-block;
        font-size: 20px;
        color    : #00c14d;
      }
    }

    .text {
      margin-left: 19px;

      p {
        margin: 0;
        color : var(--customVar-white);
      }
    }
  }

  .fully-loaded__btn-box {
    position: relative;
    display : block;
  }

  .fully-loaded__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black);
    }

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }

  .fully-loaded__right {
    position    : relative;
    display     : block;
    margin-right: 32px;
    margin-left : 173px;
  }

  .fully-loaded__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/*************************************************************
* Feature Eight
**************************************************************/
.feature-eight {
  padding: 109px 0 77px;

  .feature-five__section-title {
    margin: 0 0 50px;
  }

  .feature-five__single {
    margin-bottom: 36px;
  }
}

/*************************************************************
* Support Two
**************************************************************/
.support-two {
  position: relative;
  display : block;
  padding : 22px 0 0;
  z-index : 1;

  .support-two__left {
    position    : relative;
    display     : block;
    margin-right: 45px;
    margin-top  : 121px;
  }

  .support-two__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : var(--customVar-black);
  }

  .support-two__text {
    margin     : 21px 0 34px;
    font-size  : 18px;
    line-height: 32px;
  }

  .support-two__details {
    position        : relative;
    display         : flex;
    align-items     : center;
    flex-wrap       : wrap;
    max-width       : 534px;
    width           : 100%;
    background-color: #011496;
    box-shadow      : 0px 20px 20px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    margin          : 0;

    li {
      position  : relative;
      flex      : 0 0 33.333333%;
      max-width : 33.333333%;
      width     : 100%;
      text-align: center;

      &:first-child .support-two__single:before {
        display: none;
      }
    }
  }

  .support-two__single {
    position: relative;
    display : block;
    padding : 40px 0 38px;

    &:before {
      content         : "";
      position        : absolute;
      top             : 42px;
      bottom          : 42px;
      left            : 0;
      width           : 1px;
      background-color: #dbdbdb;
    }
  }

  .support-two__icon {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 16px;

    span {
      position               : relative;
      display                : inline-block;
      font-size              : 38px;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      -webkit-transition     : all 500ms ease;
      transition             : all 500ms ease;
    }
  }

  .support-two__text-2 {
    font-size     : 18px;
    font-weight   : 500;
    color         : var(--customVar-white);
    line-height   : 18px;
    margin        : 0;
    letter-spacing: -0.015em;
  }

  .support-two__right {
    position    : relative;
    display     : block;
    margin-left : 20px;
    margin-right: 54px;
  }

  .support-two__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}



@media only screen and (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .dedicated-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }
}

/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .dedicated-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-nine {
    .pricing-nine__points-box {
      flex-direction: column;
    }

    .pricing-nine__points-list-2 {
      margin-left: 0;
      margin-top : 8px;
    }
  }

  .extreme-performance {
    .extreme-performance__right {
      margin-left: 0;
      margin-top : 68px;
    }
  }

  .fully-loaded {
    padding: 109px 0 120px;

    .fully-loaded__right {
      margin-right: 0;
      margin-left : 0;
    }

    .fully-loaded__left {
      margin-top: 0;
    }
  }

  .support-two {
    .support-two__left {
      margin-right: 0;
      margin-top  : 0;
    }

    .support-two__title {
      font-size: 35px;
    }
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .dedicated-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .extreme-performance {
    .extreme-performance__right {
      margin-left: 0;
      margin-top : 40px;
    }
  }

  .fully-loaded {
    padding: 109px 0 120px;

    .fully-loaded__right {
      margin-right: 0;
      margin-left : 0;
    }

    .fully-loaded__left {
      margin-top: 0;
    }
  }

  .support-two {
    .support-two__left {
      margin-bottom: 30px;
    }
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .dedicated-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .pricing-nine {
    .pricing-nine__points-box {
      flex-direction: column;
    }

    .pricing-nine__points-list-2 {
      margin-left: 0;
      margin-top : 8px;
    }

    .pricing-nine__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .pricing-nine__single {
      padding: 36px 20px 36px;
    }
  }

  .extreme-performance {
    .extreme-performance__right {
      margin-left: 0;
      margin-top : 40px;
    }

    .extreme-performance__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .fully-loaded {
    padding: 109px 0 120px;

    .fully-loaded__right {
      margin-right: 0;
      margin-left : 0;
      margin-top  : 50px;
    }

    .fully-loaded__left {
      margin-top: 0;
    }

    .fully-loaded__title {
      font-size  : 30px;
      line-height: 40px;
    }
  }

  .support-two {
    .support-two__left {
      margin-right: 0;
    }

    .support-two__details {
      flex-direction: column;
    }

    .support-two__single:before {
      display: none;
    }

    .support-two__right {
      margin-left : 0;
      margin-right: 0;
      margin-top  : 30px;
    }
  }
}