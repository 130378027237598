/***********************
* Awarded One
***********************/
.awarded-one {
  position        : relative;
  display         : block;
  padding         : 108px 0 90px;
  background-color: #fafbff;
  z-index         : 1;

  .awarded-one__inner {
    position    : relative;
    display     : block;
    margin-left : 154px;
    margin-right: 154px;
  }

  .awarded-one__title {
    position     : relative;
    display      : block;
    font-size    : 40px;
    color        : var(--customVar-black);
    font-weight  : 700;
    line-height  : 50px;
    text-align   : center;
    margin       : 0;
    margin-bottom: 37px;
  }

  .awarded-one__list {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    margin         : 0;

    li {
      position     : relative;
      display      : block;
      margin-bottom: 26px;
    }
  }

  .awarded-one__img {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .awarded-one {
    .awarded-one__inner {
      margin-left : 0;
      margin-right: 0;
    }

    .awarded-one__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }
}