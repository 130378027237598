/***********************
* Header one
***********************/
.main-header-one {
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 100%;
  z-index         : 99;
  position        : relative;
  background-color: var(--customVar-black, #121212);

  .main-header-one__top {
    position  : relative;
    display   : block;
    background: #00031c;

    .container {
      padding-top   : 19px;
      padding-bottom: 19px;
    }

    .main-header-two__inner {
      position       : relative;
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      flex-wrap      : wrap;
      flex           : 1;
    }

    .main-header-two__call {
      position   : relative;
      display    : flex;
      align-items: center;
    }

    .main-header-two__call-icon {
      position   : relative;
      display    : flex;
      align-items: center;

      span {
        position : relative;
        display  : inline-block;
        font-size: 16px;
        color    : var(--customVar-white);
      }
    }

    .main-header-two__call-number {
      color         : var(--customVar-white);
      font-weight   : 500;
      letter-spacing: -0.015em;
      margin        : 0;
      margin-left   : 10px;

      a {
        font-weight: 800;
        color      : var(--customVar-white);
      }
    }

    .main-header-two__support-and-language-switcher {
      position   : relative;
      display    : flex;
      align-items: center;
    }

    .main-header-two__support-box {
      position   : relative;
      display    : flex;
      align-items: center;
    }

    .main-header-two__support-icon {
      position   : relative;
      display    : flex;
      align-items: center;

      span {
        font-size: 20px;
        color    : var(--customVar-white);
      }
    }

    .main-header-two__support-text {
      font-weight   : 500;
      letter-spacing: -0.015em;
      color         : var(--customVar-white);
      margin        : 0;
      margin-left   : 10px;
    }
  }

  .container {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    padding-top      : 30px;
    padding-bottom   : 30px;
  }

  .main-header-one__right {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
  }

  .main-header-one__btn {
    font-size     : 17px;
    padding-top   : 9px;
    padding-bottom: 9px;
    color         : #fff;
    display       : none;
    margin-left   : 30px;

    &:hover {
      color: var(--customVar-black, #121212);
    }

    &::before {
      background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }

    &::after {
      background-color: #fff;
    }
  }

  .main-header-one__cart {
    margin-left: 30px;

    i {
      color    : #fff;
      font-size: 18px;
    }
  }

  .main-header-one__logo {
    width: 400px;
  }

  .mobile-nav__toggler {
    display              : none;
    -webkit-box-align    : center;
    -ms-flex-align       : center;
    align-items          : center;
    -webkit-box-orient   : vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction   : column;
    flex-direction       : column;

    span {
      display         : block;
      width           : 20px;
      height          : 2px;
      background-color: #fff;

      &:nth-child(2) {
        margin-top   : 3px;
        margin-bottom: 3px;
      }
    }
  }

  &.sticky-header {
    position              : fixed;
    z-index               : 991;
    // top               : 0;
    // left              : 0;
    background-color      : var(--customVar-black, #121212);
    width                 : 100%;
    // visibility        : hidden;
    // -webkit-transform : translateY(-120%);
    // transform         : translateY(-120%);
    // -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
    // transition        : visibility 500ms ease, -webkit-transform 500ms ease;
    // transition        : transform 500ms ease, visibility 500ms ease;
    // transition        : transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    // -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
    box-shadow            : 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
    // &.sticky-fixed {
    //   -webkit-transform: translateY(0);
    //   transform        : translateY(0);
    //   visibility       : visible;
    // }
  }
}

/***********************
* Main Header Two
***********************/
.main-header-two {
  .main-header-one__cart {
    font-size: 17px;
    color    : var(--customVar-white);

    i {
      position     : relative;
      padding-right: 5px;
    }
  }
}

/***********************
* Main Header Three
***********************/
.main-header-three {
  background-color: transparent !important;

  &:not(.sticky-header) {
    top: 40px;
  }

  .main-header-three__nav-box {
    .container {
      padding-top     : 15px;
      padding-bottom  : 15px;
      padding-left    : 30px;
      padding-right   : 30px;
      background-color: var(--customVar-white);
      box-shadow      : 0px 10px 20px rgba(0, 0, 0, 0.05);
      border-radius   : 5px;
      // border-radius   : 15px;
    }

    &.sticky-header {
      background-color: transparent;
      box-shadow      : none;
    }
  }

  .main-header-three__nav {
    .main-menu__list>li>a {
      color: var(--customVar-text);
    }

    >li:hover>a,
    >li.current>a {
      text-shadow: 0 0 1px currentColor;
      color      : var(--customVar-primary, #ff5a74);
    }
  }

  .main-header-one__cart i {
    color: var(--customVar-text);
  }

  .main-header-one__btn {
    &:hover {
      color: var(--customVar-white);
    }

    &::after {
      background-color: var(--customVar-black);
    }
  }

  .mobile-nav__toggler {
    // border: 2px solid red;
    span {
      background-color: var(--customVar-black);
    }
  }
}

/***********************
* Main Header Four
***********************/
.main-header-four {
  &:not(.sticky-header) {
    top: 90px;
  }
}

@media (min-width: $breakpoint-lg) {

  /***********************
  * Header one
  ***********************/
  .main-header-one {
    position        : absolute;
    background-color: transparent;

    .main-header-one__btn {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }
}

@media (max-width: calc($breakpoint-xl - 1px)) {

  /***********************
  * Header one
  ***********************/
  .main-header-one {
    .mobile-nav__toggler {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  /***********************
  * Main Header Four
  ***********************/
  .main-header-four {
    top       : 0;
    background: #f5f4ff;
    margin-top: 0;
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {

  /***********************
  * Header one
  ***********************/
  .main-header-one {
    .main-header-one__cart {
      display: none;
    }
  }

  /***********************
  * Main Header Two
  ***********************/
  .main-header-two {
    .main-header-two__top {
      display: none;
    }
  }

  /***********************
  * Main Header Four
  ***********************/
  .main-header-four {
    top       : 0;
    background: #f5f4ff;
    margin-top: 0;
  }
}