/*************************************************************
* Client Logo
**************************************************************/
.web-hosting-page-banner {
  padding-bottom: 52px;

  .page-banner-three__right {
    margin-left: 40px;
    margin-top : -7px;
  }
}

/*********************************************
* Pricing Five
**********************************************/
.pricing-five {
  position        : relative;
  display         : block;
  background-color: #f4f7fe;
  padding         : 108px 0 90px;
  z-index         : 1;

  .pricing-five__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-five__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-five__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;

    li {
      a {
        font-size     : 18px;
        font-weight   : 500;
        color         : var(--customVar-black);
        padding-left  : 10px;
        padding-right : 10px;
        display       : block;
        letter-spacing: 0.01em;
      }

      &.active a {
        color: var(--customVar-secondary);
      }
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;


    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content>div {
    position  : relative;
    height    : 0px;
    visibility: hidden;
    opacity   : 0;
    @include transformMixin(translateY(35px));
    @include transitionMixin(transform 600ms ease);

    &.active {
      height    : auto;
      visibility: visible;
      opacity   : 1;
      @include transformMixin(translateY(0px));
    }
  }

  .pricing-five__single {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    border-radius   : 20px;
    text-align      : center;
    padding         : 35px 30px 30px;
    overflow        : hidden;

    &:hover .pricing-five__save-month {
      opacity: 1;
    }
  }

  .pricing-five__save-month {
    position          : absolute;
    top               : 0;
    right             : 0;
    opacity           : 0;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    p {
      font-weight              : 600;
      font-size                : 11px;
      line-height              : 21px;
      color                    : var(--customVar-white);
      background-color         : #000d61;
      text-transform           : uppercase;
      margin                   : 0;
      padding                  : 6px 15px 5px;
      border-bottom-left-radius: 15px;
    }
  }

  .pricing-five__img {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .pricing-five__title {
    margin     : 9px 0 2px;
    font-weight: 600;
    font-size  : 26px;
    line-height: 33px;
    color      : #121212;
  }

  .pricing-five__text {
    margin: 0;
  }

  .pricing-five__price-box {
    position     : relative;
    display      : block;
    margin-top   : 23px;
    margin-bottom: 26px;

    p {
      margin     : 0;
      color      : #2959eb;
      line-height: 18px;
    }
  }

  .pricing-five__price {
    margin     : 0;
    font-weight: 800;
    font-size  : 40px;
    line-height: 50px;
    color      : #2959eb;
  }

  .pricing-five__price-validity {
    font-weight: 500;
    font-size  : 20px;
    line-height: 25px;
    color      : #2959eb;
  }

  .pricing-five__points-list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      +li {
        margin-top: 8px;
      }

      .icon {
        position: relative;
        display : inline-block;
        top     : 2px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 20px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin        : 0;
          letter-spacing: -0.015em;
        }
      }
    }
  }

  .pricing-five__btn-box {
    position  : relative;
    display   : block;
    margin-top: 32px;
  }

  .pricing-five__btn {
    color   : var(--customVar-white);
    position: relative;
    display : block;

    &::before {
      background-color: var(--customVar-secondary);
    }

    &::after {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/*************************************************************
* Get Faster
**************************************************************/
.get-faster {
  position        : relative;
  display         : block;
  padding         : 106px 0 159px;
  background-color: #fafbff;
  z-index         : 1;


  .get-faster__left {
    position    : relative;
    display     : block;
    margin-right: 54px;
  }

  .get-faster__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .get-faster__right {
    position   : relative;
    display    : block;
    margin-left: -25px;
    margin-top : 13px;
  }

  .get-faster__title {
    margin     : 0 0 32px;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : #121212;
  }

  .get-faster__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position: relative;
      display : flex;

      +li {
        margin-top: 21px;
      }

      .icon {
        position        : relative;
        display         : flex;
        align-items     : center;
        justify-content : center;
        max-width       : 50px;
        width           : 100%;
        height          : 50px;
        background-color: #f1f5ff;
        border-radius   : 50%;
        top             : 7px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 16px;
          color    : var(--customVar-secondary);
        }
      }

      .content {
        position   : relative;
        display    : block;
        margin-left: 30px;

        h3 {
          margin     : 0;
          font-weight: 600;
          font-size  : 22px;
          line-height: 32px;
          color      : #121212;
        }

        p {
          margin        : 5px 0 0;
          letter-spacing: -0.015em;
          line-height   : 26px;
        }
      }
    }
  }

  .get-faster__btn-box {
    position  : relative;
    display   : block;
    margin-top: 33px;
  }

  .get-faster__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/***********************
* faster-wp-hosting
***********************/
.faster-wp-hosting {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 0px 0 110px;

  .faster-wp-hosting__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .faster-wp-hosting__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 9px;
  }

  .faster-wp-hosting__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 32px;
  }

  .faster-wp-hosting__table-responsive {
    position  : relative;
    display   : block;
    width     : 100%;
    overflow-x: auto;
  }

  .faster-wp-hosting__table {
    position : relative;
    margin   : 0;
    min-width: 1320px;
    width    : 100%;

    thead {
      tr {
        position  : relative;
        text-align: center;
        background: #eef0ff;
        border    : 1px solid #eef0ff;
      }

      th {
        padding       : 0;
        border        : none;
        font-size     : 22px;
        font-weight   : 600;
        color         : var(--customVar-black);
        font-family   : var(--customVar-title-font);
        text-align    : center;
        vertical-align: middle;
        padding       : 24px 0;
        background: transparent;
      }
    }

    tbody {
      position        : relative;
      background-color: transparent;

      tr {
        position    : relative;
        text-align  : center;
        border-left : 1px solid #dde1fd;
        border-right: 1px solid #dde1fd;

        &:last-child {
          border-bottom: 1px solid #dde1fd;
        }

        td {
          font-size     : 18px;
          font-weight   : 400;
          border        : 0;
          padding       : 24px 0;
          vertical-align: middle;
          color         : var(--customVar-text);
          border-right  : 1px solid #dde1fd;

          &:first-child {
            font-weight: 600;
            color      : var(--customVar-black);
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  .faster-wp-hosting__bottom {
    font-size  : 18px;
    line-height: 32px;
    text-align : center;
    color      : #40a51c;
    position   : relative;
    display    : block;
    margin     : 0;
    margin-top : 31px;

    a {
      position          : relative;
      display           : inline-block;
      color             : #3852ff;
      font-family       : var(--customVar-title-font);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:before {
        content           : "";
        position          : absolute;
        bottom            : 7px;
        left              : 0;
        right             : 25px;
        height            : 1px;
        background-color  : var(--customVar-secondary);
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
      }

      span {
        position    : relative;
        display     : inline-block;
        font-size   : 12px;
        padding-left: 3px;
      }

      &:hover {
        color: var(--customVar-black);

        &:before {
          background-color: var(--customVar-black);
        }
      }
    }
  }
}

/*************************************************************
* Testimonial Three
**************************************************************/
.testimonial-three {
  position        : relative;
  display         : block;
  background: #fafbff;
  padding         : 109px 0 120px;
  z-index         : 1;

  .testimonial-one__title {
    color: #121212;
  }

  .section__control {
    .swiper-button-prev,
    .swiper-button-next {
      color             : #d4d7e5;
      border            : 2px solid #d4d7e5;
    }
  }
}

/*************************************************************
* Feature Four
**************************************************************/
.feature-four {
  position        : relative;
  display         : block;
  background-color: #f5f7ff;
  padding         : 109px 0 90px;
  z-index         : 1;

  .feature-four__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .feature-four__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .feature-four__section-title__title {
    font-size    : 38px;
    font-weight  : 700;
    line-height  : 48px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 16px;
  }

  .feature-four__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 26px;
  }

  .feature-four__single {
    background-color: #ffffff;
    border-radius   : 20px;
    padding         : 32px 40px 37px;
    margin-bottom   : 30px;

    &:hover .feature-four__icon img {
      transform: scale(0.9);
    }
  }

  .feature-four__icon {
    position: relative;
    display : inline-block;

    img {
      width                   : auto;
      -webkit-transition      : all 500ms linear;
      transition              : all 500ms linear;
      -webkit-transition-delay: 0.1s;
      transition-delay        : 0.1s;
    }
  }

  .feature-four__title {
    margin     : 24px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : #121212;

    span {
      font-weight     : 600;
      font-size       : 13px;
      line-height     : 30px;
      background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      color           : var(--customVar-white);
      padding         : 4px 14px 5px;
      border-radius   : 20px;
      position        : relative;
      top             : -4px;
      margin-right    : 12px;
    }
  }

  .feature-four__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}



/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .web-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .pricing-five {
    .pricing-five__single {
      padding: 35px 10px 30px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .web-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
      margin-top : 60px;
    }
  }

  .get-faster {
    .get-faster__left {
      margin-right: 0;
    }

    .get-faster__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .web-hosting-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
      margin-top : 60px;
    }
  }

  .pricing-five {
    .pricing-five__single {
      padding: 35px 10px 30px;
    }

    .pricing-five__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .pricing-five__section-title__text br {
      display: none;
    }
  }

  .get-faster {
    .get-faster__left {
      margin-right: 0;
    }

    .get-faster__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .faster-wp-hosting {
    .faster-wp-hosting__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .faster-wp-hosting__section-title__text br {
      display: none;
    }
  }

  .feature-four {
    .feature-four__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }
}