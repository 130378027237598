/*************************************************************
* SSL Certificate Page Banner
**************************************************************/
.ssl-certificate-page-banner {
  padding-bottom: 51px;

  .page-banner-three__right {
    margin-left: 46px;
    margin-top : -48px;
  }
}

/*************************************************************
* SSL Certificate
**************************************************************/
.ssl-certificate {
  position: relative;
  display : block;
  padding : 109px 0 87px;

  .ssl-certificate .container {
    max-width: 1050px;
  }

  .ssl-certificate .row {
    --bs-gutter-x: 88px;
  }

  .ssl-certificate__section-title {
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    text-align : center;
    color      : var(--customVar-black);
    margin     : 0;
  }

  .ssl-certificate__section-text {
    font-weight: 400;
    font-size  : 18px;
    line-height: 32px;
    text-align : center;
    margin     : 10px 0 30px;
  }

  .ssl-certificate__single {
    position     : relative;
    display      : block;
    margin-bottom: 26px;
  }

  .ssl-certificate__icon {
    position: relative;
    display : inline-block;

    img {
      width: auto;
    }
  }

  .ssl-certificate__title {
    margin     : 18px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : var(--customVar-black);
  }

  .ssl-certificate__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}

/*************************************************************
* SSL Solution
**************************************************************/
.ssl-solution {
  position        : relative;
  display         : block;
  background-color: #f4f7fe;
  padding         : 109px 0 120px;
  z-index         : 1;

  .ssl-solution__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 40px;
  }

  .ssl-solution__section-title__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    text-align : center;
    color      : #121212;
  }

  .ssl-solution__section-title__text {
    font-size  : 18px;
    line-height: 32px;
    margin     : 0;
  }

  .ssl-solution__inner {
    position: relative;
    display : flex;
  }

  .ssl-solution__left {
    position  : relative;
    display   : block;
    max-width : 315px;
    width     : 100%;
    margin-top: 25px;
  }

  .ssl-solution__list {
    position: relative;
    display : block;

    li {
      position                 : relative;
      display                  : block;
      font-size                : 16px;
      font-weight              : 500;
      line-height              : 16px;
      color                    : var(--customVar-text);
      letter-spacing           : -0.015em;
      padding                  : 32px 30px 32px;
      border-top-left-radius   : 10px;
      border-bottom-left-radius: 10px;
      background               : #fdfdff;

      &:nth-child(1) {
        background-color: transparent;
      }

      &:nth-child(3) {
        background-color: transparent;
      }

      &:nth-child(5) {
        background-color: transparent;
      }

      &:nth-child(7) {
        background-color: transparent;
      }

      &:nth-child(9) {
        background-color: transparent;
      }

      &:nth-child(11) {
        background-color: transparent;
      }
    }
  }

  .ssl-solution__right {
    position: relative;
    display : block;
    width   : 100%;

    .row {
      --bs-gutter-x: 0px;
    }
  }

  .ssl-solution__table {
    position     : relative;
    margin       : 0;
    background   : #ffffff;
    box-shadow   : 0px 8px 20px rgba(42, 42, 46, 0.03);
    border-radius: 20px;

    li:nth-child(2) {
      border-left : 1px solid #dde1fd;
      border-right: 1px solid #dde1fd;
    }
  }

  .ssl-solution__single {
    position  : relative;
    display   : block;
    text-align: center;
    padding   : 55px 30px 35px;
  }

  .ssl-solution__tag {
    position       : absolute;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : #000d61;
    border-radius  : 0px 20px 0px 15px;
    max-width      : 140px;
    width          : 100%;
    top            : 0;
    right          : 0;
  }

  .ssl-solution__tag-icon {
    position: relative;
    display : block;
    top     : -2px;

    img {
      width: auto;
    }
  }

  .ssl-solution__tag-text {
    font-size     : 11px;
    margin        : 0;
    line-height   : 11px;
    color         : var(--customVar-white);
    font-weight   : 500;
    text-transform: uppercase;
    margin-left   : 5px;
  }

  .ssl-solution__icon {
    position: relative;
    display : block;

    img {
      width: auto;
    }
  }

  .ssl-solution__star {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;

    i {
      position : relative;
      display  : inline-block;
      font-size: 25px;
      color    : #ffa800;

      +i {
        margin-left: 8px;
      }
    }
  }

  .ssl-solution__title {
    font-size    : 40px;
    line-height  : 50px;
    color        : #121212;
    font-weight  : 800;
    margin       : 0;
    margin-top   : 42px;
    margin-bottom: 40px;
  }

  .ssl-solution__point {
    font-size  : 20px;
    position   : relative;
    left       : -9px;
    line-height: 20px;
  }

  .ssl-solution__validity {
    font-size  : 20px;
    font-weight: 500;
    position   : relative;
    left       : -14px;
    line-height: 20px;
  }

  .ssl-solution__check-icon {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-direction : column;

    i {
      position        : relative;
      display         : flex;
      align-items     : center;
      justify-content : center;
      height          : 25px;
      width           : 25px;
      background-color: #00c14d;
      border-radius   : 50%;
      font-size       : 12px;
      color           : var(--customVar-white);

      +i {
        margin-top: 55px;
      }
    }
  }

  .ssl-solution__text {
    font-weight   : 600;
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
    margin-top    : 53px;
    margin-bottom : 33px;
  }

  .ssl-solution__btn {
    width          : 100%;
    text-align     : center;
    justify-content: center;
    color          : var(--customVar-white);

    &::before {
      background-color: var(--customVar-secondary);
    }
  }
}

/*************************************************************
* How It Work Two
**************************************************************/
.how-it-work-two {
  position        : relative;
  display         : block;
  counter-reset   : count;
  background-color: #fafbff;
  padding         : 109px 0 143px;
  z-index         : 1;

  .how-it-work-two__inner {
    position: relative;
    display : block;
    z-index : 1;
  }

  .how-it-work-two__shape-1 {
    position  : absolute;
    bottom    : -29px;
    left      : 0;
    right     : 0;
    text-align: center;

    img {
      width: auto;
    }
  }

  .how-it-work-two__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 40px;
  }

  .how-it-work-two__section-title__title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    text-align : center;
    color      : var(--customVar-black);
    margin     : 0;
  }

  .how-it-work-two__section-title__text {
    font-size  : 18px;
    line-height: 32px;
    text-align : center;
    margin     : 12px 0 0;
  }

  .how-it-work-two__list {
    position   : relative;
    display    : flex;
    align-items: center;
    margin     : 0;

    li {
      position: relative;
      display : block;

      &:nth-child(1) {
        padding-right: 93px;
      }

      &:nth-child(2) {
        padding-left : 30px;
        padding-right: 63px;
      }

      &:nth-child(3) {
        padding-left : 50px;
        padding-right: 30px;
      }

      &:nth-child(4) {
        padding-left: 93px;
      }
    }
  }

  .how-it-work-two__single {
    position     : relative;
    display      : block;
    text-align   : center;
    margin-bottom: 23px;
  }

  .how-it-work-two__title {
    font-size  : 22px;
    font-weight: 600;
    color      : var(--customVar-black);
    margin     : 22px 0 10px;
    line-height: 32px;
  }

  .how-it-work-two__text {
    letter-spacing: -0.015em;
    margin        : 0;
    line-height   : 26px;
  }

  .how-it-work-two__count {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 70px;
    height         : 70px;
    background     : #f0f5ff;
    border-radius  : 20px;
    text-align     : center;
    margin         : 0 auto;

    &:before {
      position         : relative;
      font-size        : 35px;
      line-height      : 35px;
      font-weight      : 700;
      counter-increment: count;
      content          : "0"counter(count);
      transition       : all 200ms linear;
      transition-delay : 0.1s;
      color            : #3852ff;
      font-family      : var(--customVar-title-font);
    }
  }
}

/*************************************************************
* Why Us Three
**************************************************************/
.why-us-three {
  position        : relative;
  display         : block;
  padding         : 0 0 76px;
  background-color: #fafbff;
  z-index         : 1;

  .why-us-three__left {
    position    : relative;
    display     : block;
    margin-right: 43px;
    margin-top  : 36px;
  }

  .why-us-three__section-title {
    position: relative;
    display : block;
  }

  .why-us-three__section-title__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    color      : var(--customVar-black);
  }

  .why-us-three__section-title__text {
    font-weight: 400;
    font-size  : 18px;
    line-height: 32px;
    margin     : 19px 0 19px;
  }

  .why-us-three__points {
    margin     : 0;
    position   : relative;
    display    : block;
    margin-left: 28px;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      .icon {
        position: relative;
        display : inline-block;
        top     : 1px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 21px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin     : 0;
          font-weight: 400;
          font-size  : 18px;
          line-height: 46px;
          color      : var(--customVar-black);
        }
      }
    }
  }

  .why-us-three__btn-box {
    position  : relative;
    display   : block;
    margin-top: 32px;
  }

  .why-us-three__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .why-us-three__right {
    position   : relative;
    display    : block;
    margin-left: 50px;
  }

  .why-us-three__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/*************************************************************
* Https
**************************************************************/
.https {
  position        : relative;
  display         : block;
  padding         : 71px 0 0;
  background-color: #fafbff;
  z-index         : 1;

  .https__bg-box {
    position  : absolute;
    top       : 0;
    left      : 0;
    right     : 0;
    height    : 520px;
    background: linear-gradient(90.25deg, #436cff -20.47%, #000b3c 98.18%);
    z-index   : -1;
  }

  .https__shape-1 {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .https .container {
    max-width: 1130px;
  }

  .https__section-title {
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    text-align : center;
    color      : var(--customVar-white);
    margin     : 0 0 50px;
  }

  .https__single {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    box-shadow      : 0px 30px 60px rgba(0, 0, 0, 0.05);
    border-radius   : 20px;
    padding         : 38px 78px 36px;
    margin-bottom   : 30px;
  }

  .https__link {
    position   : relative;
    display    : block;
    margin-left: 11px;

    img {
      width: auto;
    }
  }

  .https__points-box {
    position  : relative;
    display   : block;
    margin-top: -14px;
  }

  .https__points-title {
    margin     : 0;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : var(--customVar-black);
  }

  .https__points {
    position: relative;
    display : block;
    margin  : 15px 0 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      .icon {
        position: relative;
        display : inline-block;
        top     : 5px;

        span {
          position : relative;
          display  : inline-block;
          font-size: 22px;
          color    : #00c14d;
        }
      }

      .text {
        margin-left: 20px;

        p {
          margin     : 0;
          font-weight: 400;
          font-size  : 18px;
          line-height: 40px;
          color      : var(--customVar-black);
        }
      }
    }
  }
}



/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .ssl-certificate-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .how-it-work-two {
    padding: 109px 0 44px;

    .how-it-work-two__shape-1 {
      display: none;
    }

    .how-it-work-two__list li {
      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-left : 10px;
        padding-right: 10px;
      }

      &:nth-child(3) {
        padding-left : 0;
        padding-right: 0;
      }

      &:nth-child(4) {
        padding-left: 10px;
      }
    }
  }

  .why-us-three {
    padding: 0 0 120px;

    .why-us-three__left {
      margin-right: 0;
    }

    .why-us-three__right {
      margin-left: 0;
    }
  }

  .https {
    .https__single {
      padding: 38px 30px 36px;
    }
  }

  .ssl-solution {
    .ssl-solution__left {
      max-width: 270px;
    }

    .ssl-solution__single {
      padding: 55px 10px 35px;
    }
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .ssl-certificate-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .how-it-work-two {
    padding: 109px 0 44px;

    .how-it-work-two__shape-1 {
      display: none;
    }

    .how-it-work-two__list li {
      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-left : 0;
        padding-right: 0;
      }

      &:nth-child(3) {
        padding-left : 0;
        padding-right: 0;
      }

      &:nth-child(4) {
        padding-left: 0px;
      }
    }
  }

  .why-us-three {
    padding: 0 0 120px;

    .why-us-three__left {
      margin-right: 0;
    }

    .why-us-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .https {
    .https__single {
      padding: 38px 30px 36px;
    }

    .https__link img {
      width: 100%;
    }
  }
}

// /* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .ssl-certificate-page-banner {
    .page-banner-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .ssl-certificate {
    .ssl-certificate__section-title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }

  .how-it-work-two {
    padding: 109px 0 44px;

    .how-it-work-two__shape-1 {
      display: none;
    }

    .how-it-work-two__list {
      flex-direction: column;

      li {
        &:nth-child(1) {
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(3) {
          padding-left : 0;
          padding-right: 0;
        }

        &:nth-child(4) {
          padding-left: 0px;
        }
      }
    }
  }

  .why-us-three {
    padding: 0 0 120px;

    .why-us-three__section-title__title {
      font-size  : 30px;
      line-height: 40px;
    }

    .why-us-three__left {
      margin-right: 0;
    }

    .why-us-three__right {
      margin-left: 0;
      margin-top : 50px;
    }
  }

  .https {
    .https__single {
      padding: 38px 30px 36px;
    }

    .https__link img {
      width: 100%;
    }
  }

  .ssl-solution {
    .ssl-solution__inner {
      flex-direction: column;
    }

    .ssl-solution__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }
  }
}