/***********************
* Under construction
***********************/
.under-construction {
  padding-top   : 120px;
  padding-bottom: 120px;

  .under-construction__image {
    margin-bottom: 50px;

    img {
      max-width: 100%;
    }
  }

  .under-construction__title {
    margin     : 0;
    color      : var(--customVar-black, #121212);
    text-align : center;
    font-weight: bold;
    font-size  : 35px;
  }


  .under-construction__text {
    margin         : 0;
    font-size      : 18px;
    text-align     : center;
    line-height    : 1.7777777777777777em;
    // max-width   : 476px;
    margin-left    : auto;
    margin-right   : auto;
  }

  .under-construction__form {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-top       : 26px;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;

    input[type="text"],
    input[type="email"] {
      border       : none;
      outline      : none;
      width        : 100%;
      max-width    : 520px;
      font-size    : 16px;
      color        : var(--customVar-text, #666666);
      border-radius: 10px;
      border       : 2px solid #ebebeb;
      padding-left : 20px;
      padding-right: 20px;
      height       : 60px;
      margin-right : 10px;
      margin-bottom: 10px;
    }
  }

  .under-construction__btn {
    color  : #fff;
    padding: 14px 52px;
    width  : fit-content;
    margin: 20px auto;

    &::before {
      background-image: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }
  }
}


@media (min-width: $breakpoint-lg) {
  .under-construction {
    .under-construction__image {
      margin-bottom: 80px;
    }

    .under-construction__title {
      font-size: 46px;
    }

    .under-construction__form {

      input[type="text"],
      input[type="email"] {
        margin-bottom: 0;
      }
    }
  }
}


@media (min-width: $breakpoint-xl) {
  .under-construction {
    .container {
      max-width: 778px;
    }
  }
}