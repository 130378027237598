/********************************************************
* Blog Page
*********************************************************/
.blog-page {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding         : 120px 0 120px;
  z-index         : 1;

  .blog-page__pagination {
    position  : relative;
    display   : block;
    margin-top: 50px;

    .pg-pagination {
      position: relative;
      display : block;
      margin  : 0;

      li {
        display     : inline-block;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }

        a {
          height            : 55px;
          width             : 55px;
          text-align        : center;
          line-height       : 54px;
          display           : inline-block;
          border            : 2px solid #141414;
          font-weight       : 400;
          font-size         : 16px;
          border-radius     : 10px;
          letter-spacing    : -0.015em;
          color             : var(--customVar-black);
          -webkit-transition: all 500ms ease;
          transition        : all 500ms ease;

          &:hover {
            background-color: var(--customVar-secondary);
            border          : 2px solid var(--customVar-secondary);
            color           : var(--customVar-white);

            span {
              color: var(--customVar-white);
            }
          }

          span {
            font-size         : 12px;
            color             : var(--customVar-black);
            -webkit-transition: all 500ms ease;
            transition        : all 500ms ease;
          }
        }
      }
    }
  }
}

/*--------------------------------------------------------------
## Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: relative;
  display : block;
  z-index : 1;

  .sidebar__search {
    position: relative;
    display : block;
  }

  .sidebar__search-form-inner {
    position: relative;
    display : block;
  }

  .sidebar__search-form {
    position: relative;
    display : block;

    input[type="search"] {
      position        : relative;
      display         : block;
      outline         : none;
      background-color: var(--customVar-white);
      font-size       : 16px;
      padding-left    : 20px;
      height          : 60px;
      font-weight     : 400;
      width           : 100%;
      padding-right   : 80px;
      border          : 2px solid #eaedf8;
      border-radius   : 10px;
      letter-spacing  : -0.015em;
      color           : #666666;
    }

    button[type="submit"] {
      color             : var(--customVar-white);
      font-size         : 21px;
      position          : absolute;
      top               : 0;
      right             : 0;
      width             : 60px;
      height            : 60px;
      outline           : none;
      border            : none;
      display           : flex;
      align-items       : center;
      justify-content   : center;
      padding           : 0;
      border-radius     : 10px;
      background-color  : var(--customVar-secondary);
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        background-color: var(--customVar-base);
        color           : var(--customVar-white);
      }
    }
  }

  .sidebar__single+.sidebar__single {
    margin-top: 40px;
  }

  .sidebar__category {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    border          : 2px solid #eaedf8;
    border-radius   : 10px;
    padding         : 21px 28px 26px;
  }

  .sidebar__title-box {
    position     : relative;
    display      : flex;
    align-items  : center;
    margin-bottom: 21px;

    &::before {
      content         : "";
      position        : absolute;
      top             : 17px;
      right           : 0;
      width           : 60%;
      height          : 2px;
      background-color: #d1d5f1;
    }

    &::after {
      content   : "";
      position  : absolute;
      top       : 17px;
      right     : 41%;
      width     : 19%;
      height    : 2px;
      background: linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
    }
  }

  .sidebar__title {
    font-weight: 500;
    font-size  : 22px;
    line-height: 32px;
    color      : var(--customVar-black);
    margin     : 0;
  }

  .sidebar__category-list {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position: relative;
      display : block;

      +li {
        margin-top: 14px;
      }

      a {
        position          : relative;
        display           : flex;
        align-items       : center;
        font-weight       : 400;
        font-size         : 16px;
        line-height       : 26px;
        letter-spacing    : -0.015em;
        color             : #666666;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;

        >span {
          font-weight: 600;
          margin-left: 5px;
        }

        >i {
          position               : relative;
          display                : inline-block;
          font-size              : 18px;
          margin-right           : 18px;
          background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
          -webkit-background-clip: text;
          -moz-background-clip   : text;
          background-clip        : text;
          -webkit-text-fill-color: transparent;
        }
      }

      &:hover a {
        color: var(--customVar-base);
      }
    }
  }

  .sidebar__post {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    border          : 2px solid #eaedf8;
    border-radius   : 10px;
    padding         : 21px 28px 33px;
    z-index         : 1;

    .sidebar__title-box {
      margin-bottom: 19px;
    }
  }

  .sidebar__post-list {
    margin: 0;

    li {
      position         : relative;
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;

      +li {
        margin-top: 19px;
      }
    }
  }

  .sidebar__post-image {
    margin-right : 20px;
    border-radius: 50%;

    >img {
      width        : 70px;
      border-radius: 50%;
    }
  }

  .sidebar__post-content {
    position: relative;
    top     : -3px;

    h3 {
      font-size  : 18px;
      margin     : 0;
      line-height: 26px;

      a {
        color             : var(--customVar-black);
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
        display           : block;
        font-size         : 18px;
        font-weight       : 500;
        line-height       : 22px;
        margin-top        : 2px;

        &:hover {
          color: var(--customVar-base);
        }
      }
    }
  }

  .sidebar__post-content-meta {
    line-height       : 30px;
    font-size         : 12px;
    font-weight       : 500;
    letter-spacing    : 0.05em;
    color             : var(--customVar-text);
    font-family       : var(--customVar-font);
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    i {
      font-size              : 16px;
      padding-right          : 10px;
      top                    : 2px;
      position               : relative;
      background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      -moz-background-clip   : text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
    }
  }

  .sidebar__tags {
    position        : relative;
    display         : block;
    background-color: #ffffff;
    border          : 2px solid #eaedf8;
    border-radius   : 10px;
    padding         : 21px 28px 36px;
    z-index         : 1;

    .sidebar__title-box {
      margin-bottom: 27px;

      &::before {
        width: 77%;
      }

      &::after {
        right: 58%;
      }
    }
  }

  .sidebar__tags-list {
    margin-top : -10px;
    margin-left: -7px;

    a {
      font-size         : 16px;
      color             : var(--customVar-text);
      -webkit-transition: all 0.4s ease;
      transition        : all 0.4s ease;
      display           : inline-block;
      padding           : 6px 19px 7px;
      padding-right     : 16px;
      margin-left       : 7px;
      font-weight       : 400;
      letter-spacing    : -0.015em;
      background-color  : #edeffe;
      border-radius     : 10px;

      +a {
        margin-left: 6px;
        margin-top : 10px;
      }

      &:hover {
        color     : var(--customVar-black);
        background: var(--customVar-base);
      }
    }
  }

  .sidebar__sale-box {
    position     : relative;
    display      : block;
    background   : linear-gradient(380.2deg, #436cff 20.13%, #000b3c 88.51%);
    border-radius: 10px;
    text-align   : center;
    padding      : 80px 0 65px;
    overflow     : hidden;
    z-index      : 1;
  }

  .sidebar__sale-box-bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .sidebar__sale-box-title {
    font-weight   : 800;
    font-size     : 30px;
    line-height   : 30px;
    text-align    : center;
    color         : #ffffff;
    margin        : 0;
    text-transform: uppercase;
  }

  .sidebar__sale-box-content-box {
    position     : relative;
    display      : block;
    margin-top   : 31px;
    margin-bottom: 26px;

    .timer-box {
      position: relative;
      display : block;
    }

    .countdown-timer {

      .default-coundown .box {
        position: relative;
        display : block;
      }

      li {
        position  : relative;
        display   : inline-block;
        float     : none;
        padding   : 0;
        text-align: center;
        margin    : 0px 7px 0px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        span {

          &.days,
          &.hours,
          &.minutes,
          &.seconds {
            position        : relative;
            display         : block;
            width           : 70px;
            height          : 70px;
            color           : var(--customVar-black);
            font-size       : 35px;
            line-height     : 35px;
            text-align      : center;
            background-color: var(--customVar-white);
            font-weight     : 600;
            border-radius   : 10px;
            font-family     : var(--customVar-title-font);
            padding-top     : 10px;
          }

          &.timeRef {
            position      : absolute;
            bottom        : 8px;
            left          : 50%;
            color         : var(--customVar-black);
            font-size     : 14px;
            line-height   : 14px;
            font-weight   : 500;
            text-transform: capitalize;
            display       : block;
            text-align    : center;
            transform     : translateX(-50%);
          }
        }
      }
    }
  }

  .sidebar__sale-box-text {
    font-size    : 22px;
    color        : var(--customVar-white);
    font-weight  : 500;
    margin       : 0;
    line-height  : 35px;
    font-family  : var(--customVar-title-font);
    margin-bottom: 20px;
  }

  .sidebar__sale-box__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/* Medium screen  */
@media only screen and (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .sidebar {
    margin-top: 50px;
  }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .sidebar {
    margin-top: 50px;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: calc($breakpoint-md - 1px)) {
  .sidebar {
    margin-top: 50px;

    .sidebar__sale-box-content-box .countdown-timer li {
      display: block;
      margin : 0px auto 0px;
      height : 70px;
      width  : 70px;

      +li {
        margin-top: 10px;
      }

      &:first-child {
        margin-left: auto;
      }

      :last-child {
        margin-right: auto;
      }
    }

    .sidebar__sale-box-text br {
      display: none;
    }

    .sidebar__title-box::before,
    .sidebar__title-box::after {
      display: none;
    }
  }
}