/*************************************************************
* page-banner-three
**************************************************************/
.page-banner-three {
  background-image   : url(../../../images/template/background/page-header-1-3-bg.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  padding-top        : 178px;
  padding-bottom     : 11px;

  .page-banner-three__left {
    position: relative;
    display : block;
  }

  .page-banner-three__sub-title {
    font-weight: 400;
    font-size  : 18px;
    line-height: 32px;
    color      : var(--customVar-white);
    margin     : 0;
  }

  .page-banner-three__title {
    font-weight: 700;
    font-size  : 60px;
    line-height: 76px;
    color      : var(--customVar-white);
    margin     : 4px 0 16px;
  }

  .page-banner-three__points {
    position    : relative;
    display     : block;
    padding-left: 28px;
    margin      : 0;

    li {
      position: relative;
      display : block;

      .text {
        position: relative;
        display : block;

        &::before {
          content         : "";
          position        : absolute;
          top             : 50%;
          left            : -17px;
          width           : 6px;
          height          : 6px;
          background-color: var(--customVar-white);
          border-radius   : 50%;
          transform       : translateY(-50%);
        }

        p {
          margin     : 0;
          font-weight: 400;
          font-size  : 18px;
          line-height: 40px;
          color      : var(--customVar-white);
        }
      }
    }
  }

  .page-banner-three__title-2 {
    margin     : 21px 0 33px;
    font-weight: 700;
    font-size  : 26px;
    line-height: 33px;
    color      : var(--customVar-white);
  }

  .page-banner-three__btn-box {
    position: relative;
    display : block;
  }

  .page-banner-three__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }

  .page-banner-three__right {
    position   : relative;
    display    : block;
    margin-left: 104px;
    margin-top : -20px;
  }

  .page-banner-three__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/*********************************************
* Pricing Four
**********************************************/
.pricing-four {
  position        : relative;
  display         : block;
  background-color: #f4f7fe;
  padding         : 108px 0 90px;
  z-index         : 1;

  .pricing-four__section-title {
    position     : relative;
    display      : block;
    margin-bottom: 19px;
  }

  .pricing-four__section-title__title {
    font-size    : 40px;
    font-weight  : 700;
    line-height  : 50px;
    color        : var(--customVar-black);
    margin       : 0;
    margin-bottom: 7px;
  }

  .pricing-four__section-title__text {
    font-size  : 18px;
    margin     : 0;
    line-height: 28px;
  }

  ul.switch-toggler-list {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    margin-bottom    : 40px;

    li {
      a {
        font-size     : 18px;
        font-weight   : 500;
        color         : var(--customVar-black);
        padding-left  : 10px;
        padding-right : 10px;
        display       : block;
        letter-spacing: 0.01em;
      }

      &.active a {
        color: var(--customVar-secondary);
      }
    }
  }

  .switch {
    position      : relative;
    display       : inline-block;
    vertical-align: middle;
    margin        : 0;
    border-radius : 5px;
    border        : 2px solid #e4e4e4;
    width         : 50px;
    height        : 30px;

    &.off .slider:before {
      -webkit-transform: translateX(20px) translateY(-50%);
      transform        : translateX(20px) translateY(-50%);
    }
  }

  .slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    -webkit-transition: 0.4s;
    transition        : 0.4s;
    background-color  : rgb(255, 255, 255);

    &:before {
      position          : absolute;
      content           : "";
      height            : 20px;
      width             : 20px;
      left              : 3px;
      right             : 3px;
      top               : 50%;
      -webkit-transform : translateX(0px) translateY(-50%);
      transform         : translateX(0px) translateY(-50%);
      background-color  : var(--customVar-secondary);
      -webkit-transition: 0.4s;
      transition        : 0.4s;
    }

    &.round {
      border-radius: 5px;

      &:before {
        border-radius: 5px;
      }
    }
  }

  .tabed-content>div {
    position  : relative;
    height    : 0px;
    visibility: hidden;
    opacity   : 0;
    @include transformMixin(translateY(35px));
    @include transitionMixin(transform 600ms ease);

    &.active {
      height    : auto;
      visibility: visible;
      opacity   : 1;
      @include transformMixin(translateY(0px));
    }
  }

  .pricing-four__single {
    position        : relative;
    display         : block;
    background-color: var(--customVar-white);
    padding         : 46px 30px 36px;
    border-radius   : 20px;
    margin-bottom   : 30px;
    overflow        : hidden;
    z-index         : 1;

    &:hover .pricing-four__recomanded p {
      opacity: 1;
    }
  }

  .pricing-four__price-box {
    position: relative;
    display : block;
  }

  .pricing-four__price {
    font-size  : 50px;
    font-weight: 800;
    color      : var(--customVar-black);
    line-height: 50px;
    margin     : 0;
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .pricing-four__price-point {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    top        : 11px;
  }

  .pricing-four__price-validity {
    position   : relative;
    display    : inline-block;
    font-size  : 20px;
    line-height: 20px;
    font-weight: 500;
    top        : 11px;
  }

  .pricing-four__price-renew {
    margin     : 12px 0 9px;
    font-weight: 400;
    font-size  : 16px;
    line-height: 20px;
    color      : #2959eb;

    span {
      position: relative;
      display : inline-block;

      &::before {
        content         : "";
        position        : absolute;
        top             : 10px;
        left            : 3px;
        width           : 27px;
        height          : 1px;
        background-color: #2959eb;
      }
    }
  }

  .pricing-four__title {
    margin     : 0;
    font-weight: 600;
    font-size  : 26px;
    line-height: 33px;

    a {
      color             : #121212;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: #2959eb;
      }
    }
  }

  .pricing-four__points-box {
    position  : relative;
    display   : block;
    margin-top: 33px;
  }

  .pricing-four__points-title {
    margin     : 0 0 10px;
    font-weight: 600;
    font-size  : 18px;
    line-height: 22px;
    color      : #121212;

    span {
      position   : relative;
      display    : inline-block;
      font-size  : 17px;
      color      : #aaaaaa;
      margin-left: 10px;
      top        : 2px;
    }
  }

  .pricing-four__points-title-2 {
    margin-top: 31px;
  }

  .pricing-four__points {
    position: relative;
    display : block;
    margin  : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;

      .icon {
        position: relative;
        display : inline-block;
        top     : 3px;

        i {
          position : relative;
          display  : inline-block;
          font-size: 21px;
          color    : #00c14d;
        }
      }

      .text {
        position   : relative;
        display    : block;
        margin-left: 20px;

        p {
          margin        : 0;
          font-weight   : 400;
          font-size     : 16px;
          line-height   : 40px;
          letter-spacing: -0.015em;
          color         : var(--customVar-text);

          span {
            font-weight     : 600;
            font-size       : 12px;
            line-height     : 30px;
            background-color: #020d66;
            color           : var(--customVar-white);
            padding         : 4px 15px 7px;
            letter-spacing  : 0;
            border-radius   : 13px;
            margin-left     : 6px;
            top             : -2px;
            position        : relative;
          }
        }
      }
    }
  }

  .pricing-four__btn-box {
    position  : relative;
    display   : block;
    margin-top: 22px;
  }

  .pricing-four__btn {
    position      : relative;
    letter-spacing: 0.01em;
    display       : block;
    text-align    : center;
    color         : var(--customVar-white);

    &::before {
      background-color: var(--customVar-secondary);
    }

    &::after {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }


  .pricing-four__recomanded {
    position: absolute;
    top     : 0;
    right   : 0;

    p {
      margin                   : 0;
      background-color         : #010d61;
      font-weight              : 600;
      font-size                : 11px;
      line-height              : 21px;
      color                    : var(--customVar-white);
      text-transform           : uppercase;
      padding                  : 6px 20px 5px;
      border-bottom-left-radius: 20px;
      opacity                  : 0;
      -webkit-transition       : all 500ms ease;
      transition               : all 500ms ease;

      span {
        position    : relative;
        display     : inline-block;
        margin-right: 6px;
      }
    }
  }
}

/*************************************************************
* Addons One
**************************************************************/
.addons-one {
  position: relative;
  display : block;
  padding : 109px 0 70px;
  z-index : 1;

  .addons-one__section-title {
    font-weight: 700;
    font-size  : 38px;
    line-height: 48px;
    text-align : center;
    color      : #121212;
    margin     : 0;
  }

  .addons-one__single {
    position     : relative;
    display      : block;
    margin-bottom: 43px;
  }

  .addons-one__icon {
    position: relative;
    display : inline-block;

    span {
      position        : relative;
      display         : flex;
      align-items     : center;
      justify-content : center;
      width           : 70px;
      height          : 70px;
      font-size       : 20px;
      color           : var(--customVar-secondary);
      background-color: #f0f5ff;
      border-radius   : 20px;
    }
  }

  .addons-one__title {
    margin     : 23px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : #121212;
  }

  .addons-one__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}

/*************************************************************
* Featured Two
**************************************************************/
.feature-two {
  position  : relative;
  display   : block;
  background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);
  padding   : 120px 0 115px;
  overflow  : hidden;
  z-index   : 1;

  .feature-two__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : -30px;
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
    z-index            : -1;
  }

  .feature-two__left {
    position     : relative;
    display      : block;
    counter-reset: count;
    margin-top   : 38px;

    .accrodion-grp {
      margin-left: 85px;
    }

    .faq-one-accrodion {
      .card {
        background-color: transparent;
        color           : var(--customVar-white);
        border          : none;
        margin-bottom   : 10px;

        .card-header {
          border: none;

          h4 {
            cursor: pointer;
          }

          .accrodion__trigger {
            position             : absolute;
            font-size            : 22px;
            line-height          : 22px;
            font-weight          : 600;
            color                : var(--customVar-white);
            background           : transparent;
            border               : none;
            height               : 30px;
            width                : 40px;
            top                  : 5px;
            left                 : -62px;
            font-family          : var(--customVar-title-font);
          }
        }

        &:not(:last-child):before {
          content   : "";
          position  : absolute;
          top       : 35px;
          bottom    : -15px;
          left      : -46px;
          width     : 3px;
          background: rgb(255, 255, 255);
          background: linear-gradient(180deg, rgba(255, 255, 255, 1) 33%, rgba(238, 242, 255, 0) 100%);
        }
      }
    }
  }

  .feature-two__title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 41px;
  }

  .feature-two__right {
    position: relative;
    display : block;
  }

  .feature-two__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/*************************************************************
* Shared Hosting
**************************************************************/
.shared-hosting {
  position        : relative;
  display         : block;
  padding         : 40px 0 110px;
  background-color: #fafbff;
  z-index         : 1;

  .shared-hosting__left {
    position    : relative;
    display     : block;
    margin-right: 87px;
  }

  .shared-hosting__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }

  .shared-hosting__right {
    position   : relative;
    display    : block;
    margin-left: 20px;
    margin-top : 130px;
  }

  .shared-hosting__title {
    margin     : 0;
    font-weight: 700;
    font-size  : 40px;
    line-height: 50px;
    color      : #121212;
  }

  .shared-hosting__text-1 {
    line-height   : 26px;
    letter-spacing: -0.015em;
    margin        : 20px 0 15px;
  }

  .shared-hosting__text-2 {
    line-height   : 26px;
    letter-spacing: -0.015em;
    margin        : 0;
  }

  .shared-hosting__btn-box {
    position  : relative;
    display   : block;
    margin-top: 38px;
  }

  .shared-hosting__btn {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &::before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }
  }
}

/*************************************************************
* Why Us
**************************************************************/
.why-us {
  position        : relative;
  display         : block;
  background-color: #fafbff;
  padding-bottom  : 90px;
  z-index         : 1;

  .why-us .row {
    --bs-gutter-x: 20px;
  }

  .why-us__single {
    position        : relative;
    display         : block;
    text-align      : center;
    background-color: var(--customVar-white);
    border-radius   : 20px;
    padding         : 40px 30px 37px;
    margin-bottom   : 30px;
  }

  .why-us__icon {
    position        : relative;
    display         : flex;
    align-items     : center;
    justify-content : center;
    width           : 90px;
    height          : 90px;
    background-color: #f0f5ff;
    border-radius   : 20px;
    margin          : 0 auto;

    img {
      width: auto;
    }
  }

  .why-us__title {
    margin     : 23px 0 10px;
    font-weight: 600;
    font-size  : 22px;
    line-height: 32px;
    color      : #121212;
  }

  .why-us__text {
    margin        : 0;
    letter-spacing: -0.015em;
    line-height   : 26px;
  }
}

/*************************************************************
* Counter One
**************************************************************/
.counter-one {
  position: relative;
  display : block;
  padding : 103px 0 78px;
  z-index : 1;


  .counter-one__bg {
    position           : absolute;
    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;
    z-index            : -1;
  }

  .counter-one__title {
    margin       : 0;
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 50px;
    text-align   : center;
    color        : var(--customVar-white);
    margin-bottom: 58px;
  }

  .counter-one__single {
    position     : relative;
    display      : block;
    text-align   : center;
    margin-bottom: 23px;
  }

  .counter-one__count-box {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: center;

    h3 {
      margin     : 0;
      font-weight: 600;
      font-size  : 50px;
      line-height: 50px;
      color      : var(--customVar-white);
    }

    span {
      margin     : 0;
      font-weight: 600;
      font-size  : 50px;
      line-height: 50px;
      color      : var(--customVar-white);
      top        : -4px;
      position   : relative;
    }
  }

  .counter-one__count-text {
    margin     : 0;
    font-weight: 600;
    font-size  : 50px;
    line-height: 50px;
    color      : var(--customVar-white);
  }

  .counter-one__text {
    line-height   : 26px;
    letter-spacing: -0.015em;
    color         : var(--customVar-white);
    margin        : 6px 0 0;
  }
}



@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .page-banner-three {
    .page-banner-three__title {
      font-size  : 50px;
      line-height: 66px;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .page-banner-three {
    padding-bottom: 120px;

    .page-banner-three__title {
      font-size  : 40px;
      line-height: 50px;
    }

    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
    }
  }

  .feature-two {
    .feature-two__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .feature-two__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }

  .shared-hosting {
    .shared-hosting__left {
      margin-right: 0;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .page-banner-three {
    padding-bottom: 120px;

    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
      margin-top : 60px;
    }
  }

  .feature-two {
    .feature-two__left {
      max-width: 600px;
      margin   : 0 auto 0;
    }

    .feature-two__right {
      max-width: 600px;
      margin   : 50px auto 0;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .page-banner-three {
    padding-bottom: 120px;

    .page-banner-three__title {
      font-size  : 31px;
      line-height: 41px;
    }

    .page-banner-three__right {
      margin-left: 0;
      margin-top : 0;
      margin-top : 60px;
    }
  }

  .pricing-four {
    .pricing-four__section-title__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .pricing-four__section-title__text br {
      display: none;
    }
  }

  .feature-two {
    .feature-two__left {
      margin-top: 0;
    }

    .feature-two__title {
      font-size  : 30px;
      line-height: 40px;

      br {
        display: none;
      }
    }

    .feature-two__right {
      margin-top: 50px;
    }
  }

  .shared-hosting {
    .shared-hosting__left {
      margin-right: 0;
    }

    .shared-hosting__right {
      margin-left: 0;
      margin-top : 50px;
    }

    .shared-hosting__title {
      font-size  : 30px;
      line-height: 40px;
    }
  }
}