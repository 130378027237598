/***********************
* Main Menu
***********************/
.main-header__nav {
  margin-left: auto;

  .main-menu__list {
    margin    : 0;
    padding   : 0;
    list-style: none;

    >li {
      position: relative;
      padding : 9px 0;

      +li {
        margin-left: 40px;
      }

      >a {
        color             : #ffffff;
        font-weight       : 400;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
        display           : block;
        font-size         : 17px;

        &::after {
          content    : "+";
          margin-left: 5px;
        }

        &:only-child::after {
          content: "";
        }
      }

      &:hover>a,
      &.current>a {
        text-shadow: 0 0 1px currentColor;
        color      : var(--customVar-primary, #ff5a74) !important;
      }

      >ul {
        left              : 0;
        top               : 100%;
        margin            : 0;
        padding           : 0;
        list-style        : none;
        position          : absolute;
        min-width         : 220px;
        background-color  : #fff;
        opacity           : 0;
        visibility        : hidden;
        -webkit-transition: 500ms ease;
        transition        : 500ms ease;
        z-index           : 99;
        -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
        box-shadow        : 0px 0px 65px 0px rgba(0, 0, 0, 0.1);

        >li {
          position: relative;

          >a {
            color             : var(--customVar-black, #121212);
            font-weight       : 400;
            -webkit-transition: all 500ms ease;
            transition        : all 500ms ease;
            display           : block;
            padding           : 7px 20px;
            font-size         : 16px;
            font-family       : var(--customVar-title-font, "Outfit", sans-serif);

            &:hover {
              background-color: var(--customVar-primary, #ff5a74);
              color           : #fff;
            }
          }

          >ul {
            left              : 100%;
            top               : 0%;
            margin            : 0;
            padding           : 0;
            list-style        : none;
            position          : absolute;
            min-width         : 220px;
            background-color  : #fff;
            opacity           : 0;
            visibility        : hidden;
            -webkit-transition: 500ms ease;
            transition        : 500ms ease;
            z-index           : 99;
            -webkit-box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
            box-shadow        : 0px 0px 65px 0px rgba(0, 0, 0, 0.1);

            >li {
              >a {
                color             : var(--customVar-black, #121212);
                font-weight       : 400;
                -webkit-transition: all 500ms ease;
                transition        : all 500ms ease;
                display           : block;
                padding           : 7px 20px;
                font-size         : 16px;
                font-family       : var(--customVar-title-font, "Outfit", sans-serif);

                &:hover {
                  background-color: var(--customVar-primary, #ff5a74);
                  color           : #fff;
                }
              }

              >ul {
                display: none;
              }
            }
          }
        }
      }
    }

    li{
      &.active>ul {
        opacity   : 1;
        visibility: visible;
      }

      &.current>a {
        text-shadow: 0 0 1px currentColor;
        color      : var(--customVar-primary, #ff5a74) !important;
      }
    }

    >.megamenu {
      position: static;

      >ul {
        top               : 100% !important;
        left              : 0 !important;
        right             : 0 !important;
        background-color  : transparent;
        -webkit-box-shadow: none;
        box-shadow        : none;

        >li {
          padding: 0 !important;
        }
      }
    }
  }
}

/***********************
* Mobile Drawer
***********************/
.mobile-nav__wrapper {
  position                : fixed;
  z-index                 : 991;
  top                     : 0;
  left                    : 0;
  width                   : 100vw;
  height                  : 100vh;
  -webkit-transform       : translateX(-100%);
  transform               : translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin        : left center;
  -webkit-transition      :
    visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition:
    transform 500ms ease 500ms,
    visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  visibility: hidden;

  &.expanded {
    opacity          : 1;
    -webkit-transform: translateX(0%);
    transform        : translateX(0%);
    -webkit-transition:
      visibility 500ms ease 0ms,
      -webkit-transform 500ms ease 0ms;
    transition:
      transform 500ms ease 0ms,
      visibility 500ms ease 0ms,
      -webkit-transform 500ms ease 0ms;
    visibility: visible;

    .mobile-nav__content {
      opacity          : 1;
      visibility       : visible;
      -webkit-transform: translateX(0);
      transform        : translateX(0);
      -webkit-transition:
        opacity 500ms ease 500ms,
        visibility 500ms ease 500ms,
        -webkit-transform 500ms ease 500ms;
      transition:
        opacity 500ms ease 500ms,
        visibility 500ms ease 500ms,
        transform 500ms ease 500ms,
        -webkit-transform 500ms ease 500ms;
    }
  }

  .mobile-nav__overlay {
    position        : absolute;
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    background-color: #000000;
    opacity         : 0.9;
  }

  .mobile-nav__content {
    width             : 100%;
    max-width         : 400px;
    position          : absolute;
    top               : 0;
    left              : 0;
    overflow          : hidden;
    overflow-y        : auto;
    height            : 100%;
    background-color  : #010b3c;
    -ms-overflow-style: none;
    scrollbar-width   : none;
    padding           : 30px 15px;
    opacity           : 0;
    visibility        : hidden;
    -webkit-transform : translateX(-100%);
    transform         : translateX(-100%);
    -webkit-transition:
      opacity 500ms ease 0ms,
      visibility 500ms ease 0ms,
      -webkit-transform 500ms ease 0ms;
    transition:
      opacity 500ms ease 0ms,
      visibility 500ms ease 0ms,
      transform 500ms ease 0ms,
      -webkit-transform 500ms ease 0ms;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mobile-menu__list {
    margin       : 0;
    padding      : 0;
    list-style   : none;
    margin-top   : 30px;
    margin-bottom: 10px;

    .menu-item-has-children button {
      width            : 30px;
      height           : 30px;
      background-color : #fff;
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      font-size        : 14px;
      -webkit-box-pack : center;
      -ms-flex-pack    : center;
      justify-content  : center;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      color            : var(--customVar-black, #121212);
      border           : none;
      outline          : none;
      float            : right;
      margin-top       : 5px;
    }

    li.current>a {
      color: #fff;
    }

    >li {
      +li {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }

      >a {
        font-weight: 400;
        font-size  : 14px;
        color      : #c2c2c2;
        display    : block;
        line-height: 40px;
      }

      >ul {
        margin         : 0;
        padding        : 0;
        list-style     : none;
        // display     : none;
        padding-left   : 5px;
        border-top     : 1px solid rgba(255, 255, 255, 0.1);

        >li {
          +li {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          }

          >a {
            text-transform: uppercase;
            font-weight   : 400;
            font-size     : 14px;
            color         : #c2c2c2;
            display       : block;
            line-height   : 40px;
          }

          >ul {
            margin         : 0;
            padding        : 0;
            list-style     : none;
            padding-left   : 5px;
            // display     : none;
            border-top     : 1px solid rgba(255, 255, 255, 0.1);

            >li {
              +li {
                border-top: 1px solid rgba(255, 255, 255, 0.1);
              }

              >a {
                text-transform: uppercase;
                font-weight   : 400;
                font-size     : 14px;
                color         : #c2c2c2;
                display       : block;
                line-height   : 40px;
              }

              // >ul {
              // display: none;
              // }
            }
          }
        }
      }
    }
  }

  .mobile-nav__social {
    margin           : 0;
    padding          : 0;
    list-style       : none;
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-left      : -7px;
    margin-right     : -7px;

    li {
      margin: 0 7px;

      a {
        width             : 40px;
        height            : 40px;
        border            : 1px solid var(--customVar-base, #ffa065);
        color             : #fff;
        font-size         : 14px;
        display           : -webkit-box;
        display           : -ms-flexbox;
        display           : flex;
        -webkit-box-align : center;
        -ms-flex-align    : center;
        align-items       : center;
        -webkit-box-pack  : center;
        -ms-flex-pack     : center;
        justify-content   : center;
        text-align        : center;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
        border-radius     : 50%;

        &:hover {
          color           : #fff;
          background-color: var(--customVar-primary, #ff5a74);
        }
      }
    }
  }

  .mobile-nav__contact {
    margin       : 0;
    padding      : 0;
    list-style   : none;
    margin-bottom: 20px;

    li {
      position    : relative;
      padding-left: 33px;
      color       : #c2c2c2;
      font-size   : 16px;
      line-height : 36px;

      >svg {
        font-size: 16px;
        color    : var(--customVar-base, #ffa065);
        position : absolute;
        top      : 11px;
        left     : 0;
      }

      a {
        color             : inherit;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .mobile-nav__close {
    width   : 25px;
    height  : 25px;
    display : block;
    position: absolute;
    top     : 20px;
    right   : 15px;

    span {
      width            : 2px;
      height           : 100%;
      display          : block;
      background-color : #fff;
      position         : absolute;
      top              : 50%;
      left             : 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      transform        : translate(-50%, -50%) rotate(45deg);

      &:nth-child(2) {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform        : translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .mobile-nav__container .domain-showcase {
    .container {
      padding: 0;
    }

    .row {
      --bs-gutter-y: 30px;
    }

    & [class*="col-"] {
      width: 100%;
    }
  }
}

/***********************
// * Domain showcase
// ***********************/
.domain-showcase {
  .container {
    padding-top: 0;
  }

  .domain-showcase__inner {
    background-color: #fafbff;
    display: block;
    width: 100%;
    padding: 30px;
    border-radius: 20px;
  }

  .domain-showcase__icon {
    width: 70px;
    height: 70px;
    background-color: #f0f5ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 20px;
    color: #3852ff;
    font-size: 20px;
  }

  .domain-showcase__title {
    font-family: var(--customVar-title-font, "Outfit", sans-serif);
    font-size: 20px;
    font-weight: bold;
    color: var(--customVar-black, #121212);
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .domain-showcase__tagline {
    margin: 0;
    font-size: 14px;
    line-height: 160%;
  }

  .mobile-nav__container & {
    .container {
      padding: 0;
    }

    .row {
      --bs-gutter-y: 30px;
    }

    [class*="col-"] {
      width: 100%;
    }
  }
}



@media (max-width: calc($breakpoint-xl - 1px)) {
  .main-header__nav {
    .main-menu__list {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .main-header__nav {
    .main-menu__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: auto;

      >li+li {
        margin-left: 58px;
      }
    }
  }
}