/***********************
* Subscribe
***********************/
.subscribe-one {
  padding-bottom: 80px;
  position      : relative;
  z-index       : 10;

  &:before {
    content         : "";
    position        : absolute;
    bottom          : 75px;
    left            : 0;
    right           : 0;
    top             : 0;
    background-color: #fafbff;
    z-index         : -1;
  }

  .subscribe-one__inner {
    background        : linear-gradient(92.84deg, #ffa065 1.25%, #ff5a74 98.84%);
    -webkit-box-shadow: 0px 50px 30px rgba(0, 8, 50, 0.08);
    box-shadow        : 0px 50px 30px rgba(0, 8, 50, 0.08);
    border-radius     : 20px;
  }

  .subscribe-one__top {
    padding-top   : 20px;
    padding-bottom: 30px;
    padding-left  : 30px;
    padding-right : 30px;
  }

  .subscribe-one__title {
    margin     : 0;
    font-size  : 25px;
    color      : #fff;
    font-weight: 700;
  }

  .subscribe-one__btn {
    margin-top: 9px;
  }

  .subscribe-one__form {
    margin-top: 30px;

    input[type="text"],
    input[type="email"] {
      border            : none;
      outline           : none;
      -webkit-appearance: none;
      -moz-appearance   : none;
      appearance        : none;
      background        : rgba(255, 255, 255, 0.1);
      border            : 2px solid #ffffff;
      border-radius     : 10px;
      display           : block;
      width             : 100%;
      height            : 60px;
      color             : #fff;
      font-size         : 16px;
      font-weight       : 400;
      padding-left      : 20px;
      padding-right     : 20px;
    }
  }

  .subscribe-one__list {
    border-top    : 1px solid rgba(255, 255, 255, 0.4);
    padding-left  : 30px;
    padding-right : 30px;
    margin        : 0;
    padding-top   : 30px;
    padding-bottom: 30px;

    li {
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      position         : relative;
      font-size        : 16px;
      font-weight      : 500;
      color            : #fff;
      padding-left     : 34px;

      >[class*="icon-"] {
        position : absolute;
        top      : 7px;
        left     : 0;
        font-size: 18px;
        color    : #fff;
      }
    }
  }
}

/***********************
* Footer
***********************/
.footer-main {
  background-color: #010b3c;
  padding-bottom  : 30px;
  padding-top     : 60px;

  .footer-main__top {
    position        : relative;
    display         : block;
    background-color: #000621;
  }

  .footer-main__top-inner {
    position       : relative;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;
    padding        : 35px 0 35px;
  }

  .footer-main__top-text {
    font-size  : 18px;
    line-height: 28px;
    margin     : 0;
    color      : var(--customVar-white);

    a {
      position: relative;
      display : inline-block;
      color   : var(--customVar-white);

      &:before {
        position        : absolute;
        bottom          : 2px;
        left            : 0;
        right           : 0;
        height          : 1px;
        background-color: var(--customVar-white);
        content         : "";
      }
    }
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .footer-widget__logo {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    img {
      width  : 400px;
    }
  }

  .footer-widget__title {
    font-style   : normal;
    font-weight  : 600;
    font-size    : 22px;
    line-height  : 140%;
    color        : #ffffff;
    margin       : 0;
    margin-bottom: 14px;
  }

  .footer-widget__text {
    margin        : 0;
    font-weight   : 400;
    font-size     : 16px;
    line-height   : 160%;
    letter-spacing: -0.015em;
    color         : #c2c2c2;
    margin-top    : 16px;
    margin-bottom : 28px;
  }

  .footer-widget__phone {
    display          : -webkit-inline-box;
    display          : -ms-inline-flexbox;
    display          : inline-flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-bottom    : 32px;

    i.icon {
      font-size   : 26px;
      color       : #fff;
      margin-right: 10px;
    }

    a {
      font-weight   : 700;
      font-size     : 20px;
      line-height   : 160%;
      letter-spacing: -0.015em;
      color         : #ffffff;
    }
  }

  .footer-widget__payment {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    margin-left      : -6px;
    margin-right     : -6px;
    margin-top       : -12px;

    a {
      display      : inline-block;
      padding-left : 6px;
      padding-right: 6px;
      margin-top   : 12px;
    }
  }

  .footer-widget__nav {
    margin: 0;

    li {
      font-style    : normal;
      font-weight   : 400;
      font-size     : 16px;
      line-height   : 240%;
      letter-spacing: -0.015em;
      color         : #c2c2c2;
      position      : relative;

      a {
        color             : inherit;
        background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
        background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;
        display           : -webkit-inline-box;
        display           : -ms-inline-flexbox;
        display           : inline-flex;
        -webkit-box-align : center;
        -ms-flex-align    : center;
        align-items       : center;

        &:hover {
          background-size: 100% 1px;
        }

        &::before {
          content         : "";
          width           : 5px;
          height          : 5px;
          border-radius   : 50%;
          background-color: currentColor;
          margin-right    : 10px;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .footer-widget__social {
    margin       : 0;
    display      : -webkit-box;
    display      : -ms-flexbox;
    display      : flex;
    -ms-flex-wrap: wrap;
    flex-wrap    : wrap;
    margin-top   : 25px;
    margin-bottom: 63px;

    li {
      +li {
        margin-left: 8px;
      }

      a {
        width             : 40px;
        height            : 40px;
        border            : 2px solid #ffffff;
        border-radius     : 10px;
        display           : -webkit-box;
        display           : -ms-flexbox;
        display           : flex;
        -webkit-box-pack  : center;
        -ms-flex-pack     : center;
        justify-content   : center;
        -webkit-box-align : center;
        -ms-flex-align    : center;
        align-items       : center;
        text-align        : center;
        color             : #fff;
        font-size         : 18px;
        -webkit-transition: all 500ms ease;
        transition        : all 500ms ease;

        &:hover {
          background-color: #fff;
          color           : var(--customVar-black, #121212);
        }
      }
    }
  }

  .footer-widget__contact {
    margin: 0;

    li {
      font-style    : normal;
      font-weight   : 400;
      font-size     : 16px;
      line-height   : 160%;
      letter-spacing: -0.015em;
      color         : #c2c2c2;

      +li {
        margin-top: 10px;
      }

      i {
        font-size   : 20px;
        color       : #fff;
        margin-right: 15px;
      }

      a {
        display          : -webkit-inline-box;
        display          : -ms-inline-flexbox;
        display          : inline-flex;
        -webkit-box-align: center;
        -ms-flex-align   : center;
        align-items      : center;
        color            : inherit;
      }
    }
  }

  .footer__contact-and-newsletter {
    position   : relative;
    display    : block;
    padding-top: 22px;
  }

  .footer__contact {
    position    : relative;
    display     : block;
    margin-top  : 51px;
    margin-right: -30px;
  }

  .footer__contact-list {
    position   : relative;
    display    : flex;
    align-items: center;
    flex-wrap  : wrap;
    margin     : 0;

    li {
      position   : relative;
      display    : flex;
      align-items: center;
      flex-wrap  : wrap;

      +li {
        margin-left: 60px;
      }

      .icon {
        position   : relative;
        display    : flex;
        align-items: center;

        span {
          position               : relative;
          display                : inline-block;
          font-size              : 46px;
          background             : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
          -webkit-background-clip: text;
          -moz-background-clip   : text;
          background-clip        : text;
          -webkit-text-fill-color: transparent;
          -webkit-transition     : all 500ms ease;
          transition             : all 500ms ease;
        }
      }

      .content {
        margin-left: 25px;

        span {
          position      : relative;
          display       : inline-block;
          font-size     : 20px;
          font-weight   : 500;
          line-height   : 20px;
          letter-spacing: -0.015em;
          color         : var(--customVar-white);
        }

        p {
          font-size     : 26px;
          color         : var(--customVar-white);
          line-height   : 26px;
          margin        : 0;
          letter-spacing: -0.015em;
          margin-top    : 8px;

          a {
            color             : var(--customVar-white);
            -webkit-transition: all 500ms ease;
            transition        : all 500ms ease;

            &:hover {
              color: var(--customVar-base);
            }
          }
        }
      }
    }
  }

  .footer__newsletter {
    position   : relative;
    display    : block;
    margin-left: 61px;
  }

  .footer__newsletter-title {
    font-size    : 22px;
    font-weight  : 600;
    color        : var(--customVar-white);
    margin       : 0;
    margin-bottom: 37px;
  }

  .footer__newsletter-form {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -ms-flex-wrap    : wrap;
    flex-wrap        : wrap;

    input[type="text"],
    input[type="email"] {
      border            : none;
      outline           : none;
      -webkit-appearance: none;
      -moz-appearance   : none;
      appearance        : none;
      background        : rgba(255, 255, 255, 1);
      border            : 1.5px solid #e9e9e9;
      border-radius     : 10px;
      display           : block;
      max-width         : 390px;
      width             : 100%;
      height            : 60px;
      color             : var(--customVar-text);
      font-size         : 16px;
      font-weight       : 400;
      padding-left      : 25px;
      padding-right     : 25px;
    }
  }

  .footer__newsletter-btn {
    margin-left: 10px;
    color      : var(--customVar-white);

    &:hover {
      color: var(--customVar-secondary);
    }

    &:before {
      background-color: var(--customVar-secondary);
    }

    &:after {
      background-color: var(--customVar-white);
    }
  }

  &+.footer-bottom {
    background-color: var(--customVar-dark, #000932);

    .footer-bottom__inner {
      display              : -webkit-box;
      display              : -ms-flexbox;
      display              : flex;
      -webkit-box-pack     : center;
      -ms-flex-pack        : center;
      justify-content      : center;
      -webkit-box-align    : center;
      -ms-flex-align       : center;
      align-items          : center;
      -webkit-box-orient   : vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction   : column;
      flex-direction       : column;
      padding              : 29px 0;

      p {
        margin    : 0;
        color     : #c2c2c2;
        text-align: center;

        a {
          color             : inherit;
          background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
          background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
          -webkit-transition: all 500ms ease;
          transition        : all 500ms ease;

          &:hover {
            background-size: 100% 1px;
          }

          &:hover {
            color: #fff;
          }
        }
      }
    }

    .footer-bottom__links {
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      margin           : 0;

      li {
        color: #c2c2c2;

        a {
          color             : inherit;
          background        : -webkit-gradient(linear, left top, right top, from(currentcolor), to(currentcolor)) 0px 95%/0px 1px no-repeat;
          background        : linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
          -webkit-transition: all 500ms ease;
          transition        : all 500ms ease;

          &:hover {
            background-size: 100% 1px;
          }

          &:hover {
            color: #fff;
          }
        }

        +li::before {
          content     : "-";
          margin-left : 9px;
          margin-right: 4px;
        }
      }
    }
  }
}

/***********************
* Footer Main Two
***********************/
.footer-main-two {
  position      : relative;
  display       : block;
  padding-top   : 0;
  padding-bottom: 70px;

  .footer-main-two__middle {
    position   : relative;
    display    : block;
    padding-top: 70px;
  }

  .footer-widget-two {
    position    : relative;
    display     : block;
    margin-right: 40px;

    .footer-widget__social {
      margin-top   : 33px;
      margin-bottom: 0;
    }
  }

  .footer-widget-two__logo {
    position: relative;
    display : block;
  }

  .footer-widget-two__text {
    letter-spacing: -0.015em;
    color         : #c2c2c2;
    margin        : 0;
    line-height   : 26px;
    margin-top    : 25px;
  }

  .footer-widget-two--domain {
    margin-left: 20px;
  }

  .footer-widget-two--services {
    margin-left : 60px;
    margin-right: -20px;
  }

  .footer-widget-two--company {
    margin-left: 73px;
  }

  .footer-widget-two--help {
    margin-left : 48px;
    margin-right: 17px;
  }

  &+.footer-bottom-two {
    background-color: #010b3c;
    border-top      : 1px solid #0f1c5c;
  }
}

@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .footer-main {
    .footer__contact-list {
      flex-direction: column;
      align-items   : baseline;

      li+li {
        margin-left: 0;
        margin-top : 30px;
      }
    }

    .footer__contact {
      margin-top  : 0;
      margin-right: 0;
    }

    .footer__newsletter {
      margin-left: 0;
    }

    .footer__newsletter-btn {
      margin-left: 0;
      margin-top : 10px;
    }
  }

  .footer-main-two {
    .footer-widget-two {
      margin-right: 0;
    }

    .footer-widget-two--domain {
      margin-left: 0;
    }

    .footer-widget-two--services {
      margin-left : 0;
      margin-right: 0;
    }

    .footer-widget-two--company {
      margin-left: 0;
    }

    .footer-widget-two--help {
      margin-left : 0;
      margin-right: 0;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .footer-main {
    .footer__contact {
      margin-top   : 0;
      margin-right : 0;
      margin-bottom: 30px;
    }

    .footer__newsletter {
      margin-left: 0;
    }
  }

  .footer-main-two {
    .footer-widget-two {
      margin-right: 0;
    }

    .footer-widget-two--domain {
      margin-left: 0;
    }

    .footer-widget-two--services {
      margin-left : 0;
      margin-right: 0;
    }

    .footer-widget-two--company {
      margin-left: 0;
    }

    .footer-widget-two--help {
      margin-left : 0;
      margin-right: 0;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .footer-main {
    .footer-main__top-inner {
      flex-direction: column;
      text-align    : center;
    }

    .footer__contact {
      margin-top   : 0;
      margin-right : 0;
      margin-bottom: 30px;
    }

    .footer__newsletter {
      margin-left: 0;
    }
  }

  .footer-main-two {
    .footer-widget-two {
      margin-right: 0;
    }

    .footer-widget-two--domain {
      margin-left: 0;
    }

    .footer-widget-two--services {
      margin-left : 0;
      margin-right: 0;
    }

    .footer-widget-two--company {
      margin-left: 0;
    }

    .footer-widget-two--help {
      margin-left : 0;
      margin-right: 0;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .footer-main {
    .footer-main__top-inner {
      flex-direction: column;
      text-align    : center;
    }

    .footer__contact {
      margin-top   : 0;
      margin-right : 0;
      margin-bottom: 30px;
    }

    .footer__contact-list li+li {
      margin-left: 0;
      margin-top : 30px;
    }

    .footer__newsletter {
      margin-left: 0;
    }

    .footer__newsletter-btn {
      margin-left: 0;
      margin-top : 10px;
    }
  }

  .footer-main-two {
    .footer-widget-two {
      margin-right: 0;
    }

    .footer-widget-two--domain {
      margin-left: 0;
    }

    .footer-widget-two--services {
      margin-left : 0;
      margin-right: 0;
    }

    .footer-widget-two--company {
      margin-left: 0;
    }

    .footer-widget-two--help {
      margin-left : 0;
      margin-right: 0;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .subscribe-one {
    .subscribe-one__form {
      display          : -webkit-box;
      display          : -ms-flexbox;
      display          : flex;
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
      -ms-flex-wrap    : wrap;
      flex-wrap        : wrap;

      input[type="text"],
      input[type="email"] {
        max-width: 300px;
      }

      button[type="submit"] {
        margin-top : 0;
        margin-left: 9px;
      }
    }

    .subscribe-one__list {
      display           : -webkit-box;
      display           : -ms-flexbox;
      display           : flex;
      -webkit-box-align : center;
      -ms-flex-align    : center;
      align-items       : center;
      -webkit-box-pack  : center;
      -ms-flex-pack     : center;
      justify-content   : center;
      -ms-flex-wrap     : wrap;
      flex-wrap         : wrap;
      -webkit-column-gap: 30px;
      column-gap        : 30px;
    }
  }

  .footer-main {
    .footer-bottom {
      .footer-bottom__inner p {
        text-align: left;
      }
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .subscribe-one {
    .subscribe-one__top {
      padding-top   : 43px;
      padding-bottom: 43px;
    }

    .subscribe-one__title {
      font-size: 30px;
    }

    .subscribe-one__form {
      margin-top: 0;
    }

    .subscribe-one__list {
      padding-top       : 21px;
      padding-bottom    : 21px;
      -webkit-column-gap: 55px;
      column-gap        : 55px;

      li {
        font-size: 18px;
      }
    }
  }

  .footer-main {
    .footer-widget__social li a {
      width : 55px;
      height: 55px;
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .subscribe-one {
    padding-bottom: 0;
    margin-bottom : -75px;

    .subscribe-one__top {
      padding-left : 50px;
      padding-right: 50px;
    }

    .subscribe-one__top .row {
      -webkit-box-align: center;
      -ms-flex-align   : center;
      align-items      : center;
    }

    .subscribe-one__form {
      padding-left: 55px;

      input[type="text"],
      input[type="email"] {
        max-width: 394px;
      }

      button[type="submit"] {
        padding: 14px 55.5px;
      }
    }
  }

  .footer-main {
    &:not(.footer-main-two) {
      padding-top: 135px;
    }

    .footer-widget--services {
      padding-left: 80px;
    }

    .footer-widget--contact {
      padding-left: 60px;
    }

    .footer-main--normal-padding {
      padding-top: 120px;
    }


    &+.footer-bottom {
      .footer-bottom__inner {
        -webkit-box-orient   : horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction   : row;
        flex-direction       : row;
        -webkit-box-pack     : justify;
        -ms-flex-pack        : justify;
        justify-content      : space-between;
      }
    }
  }
}